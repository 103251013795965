import React from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { snackBarClosedReset } from '../../redux/slice/VoucherBlockUnBlockSlice';
import Input from '../../components/input';
import SnackBar from '../../components/snackbar';
import { VoucherBulkBlockAction, VoucherBulkUnBlockAction, VoucherBulkUnblockGetFileContent, VoucherBulkUnblockGetFileList, checkClicked, getBulkVoucherBlockUnblockList, handleVoucherSerialBlockUnblock, uploadFile, voucherBulkBlockUnBlockLastAdded } from '../../redux/slice/VoucherBulkBlockUnBlockSlice';
require('jspdf-autotable');

const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            flexDirection: "row",
            justifyContent: "spaceBetween",
            alignItems: "center",
            rowGap: "inherit",
        }
    }
});
const VoucherBulkBlockUnblock = () => {
    const classNamees = useStyles()
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.VoucherBulkBlockUnblockSliceReducer);
    //console.log("selectValuesRe",selectValuesRe)
    console.timeLog("umar")
    const tableData = selectValuesRe?.lists === undefined ? [] : selectValuesRe?.lists;
    const lastAdded = selectValuesRe?.lastAdded;
    //console.log("tableData", tableData)
    const snackbarValues = selectValuesRe.sucessMessage;
    // //console.log("snackbarValues", snackbarValues)
    const blockValues = [1, 6, 7, 8];
    const tableData2 = selectValuesRe?.fileList
    const check = selectValuesRe.check;
    // //console.log("check", check)
    // useEffect(() => {
    //     dispatch(handleVoucherAvailableStockResetDispatch())
    // }, [])



    const handleQuery = () => {
        dispatch(getBulkVoucherBlockUnblockList(lastAdded))
    }

    const handleUpload = (e) => {
        const formData = new FormData();
        formData.append("file", lastAdded.uploaded_file);
        formData.append("fileName", lastAdded.uploaded_file.name);

        dispatch(uploadFile(formData))
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(voucherBulkBlockUnBlockLastAdded({ [name]: value, [name + "Touched"]: true }))
    }

    const handleUploadChange = (e) => {
        const { name } = e.target;
        document.getElementById('file_name').value = e.target.value.split('\\').pop().split('/').pop()
        dispatch(voucherBulkBlockUnBlockLastAdded({ [name]: e.target.files[0], [name + "Touched"]: true }))
    }

    const snackbarClosed = () => {
        dispatch(snackBarClosedReset())
    }

    const handleList = () => {
        dispatch(checkClicked(false))
        dispatch(VoucherBulkUnblockGetFileList())
    }

    const handleCheck = (fileName) => {
        dispatch(VoucherBulkUnblockGetFileContent({ fileName: fileName }))
        dispatch(checkClicked(true))
    }

    const handleBulkBlock = (fileName, vocuherValue, voucherType) => {
        dispatch(VoucherBulkBlockAction({ fileName: fileName, vocuherValue: vocuherValue, voucherType:voucherType }))
    }
    const handleBulkUnBlock = (fileName, vocuherValue, voucherType) => {
        dispatch(VoucherBulkUnBlockAction({ fileName: fileName, vocuherValue: vocuherValue, voucherType:voucherType }))
    }

    const handleSerialBlockUnblock = (startSerialNo, endSerialNumber, vocuherValue, voucherType, type) => {
        dispatch(handleVoucherSerialBlockUnblock({ startSerialNo: startSerialNo, endSerialNo: endSerialNumber, vocuherValue: vocuherValue, voucherType:voucherType, type: type }))
    }
    const columns = [
        {
            accessorKey: 'operators.operatorCode',
            header: 'Operator',
        },
        {
            accessorKey: 'vocuherValue',
            header: 'Denomination',
        },
        {
            accessorKey: 'voucherType',
            header: 'Voucher Type',
        },
        {
            accessorKey: 'count',
            header: 'Count',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                cell.getValue()?.toString()?.toString() === "1" ? "Available" : cell.getValue()?.toString() === "2" ? "Used" : cell.getValue()?.toString() === "3" ? "Expired" : cell.getValue()?.toString() === "4" ? 'Blocked' : cell.getValue()?.toString() === "5" ? "Suspend" : cell.getValue()?.toString() === "6" ? "Reserve" : cell.getValue()?.toString() === "7" ? "Staged" : cell.getValue()?.toString() === "8" ? "Unsold" : "Other"
            )
        },

    ]



    const columns2 = [{
        accessorKey: 'fileName',
        header: 'File Name',
    },];




    return (
        < div classNameName={classNamees.root}>
            <>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row"
                }}>
                    <FormControl>

                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="type"
                            row
                            value={lastAdded.type}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="BF" control={<Radio />} label="By File Name" />
                            <FormControlLabel value="BS" control={<Radio />} label="By Serial No" />
                        </RadioGroup>
                    </FormControl>

                    {lastAdded.type === "BS" ?

                        <>
                            <Input style={{ width: "70%" }} value={lastAdded.startSerialNo} placeholder="Start Serial Number" type="text" touched={lastAdded.startSerialNumberTouched} onChange={handleInputChange} name="startSerialNumber" label="" />&nbsp;
                            <Input value={lastAdded.endSerialNumber} style={{ width: "70%" }} placeholder="End Serial Number" type="text" touched={lastAdded.endSerialNumberTouched} onChange={handleInputChange} name="endSerialNumber" label="" /> &nbsp;
                            <Button variant='contained' color='primary' onClick={handleQuery}>Query</Button>
                        </> :
                        <>
                            <input type="file" name="uploaded_file" id="fileinput" style={{ display: "none" }} accept=".txt" className="fileinput" onChange={handleUploadChange} />&nbsp;
                            <input type="text" placeholder='Click to upload file' disabled name="file_name" id="file_name" style={{ width: "220px", padding: "2%" }} autoComplete="off" readOnly="true" />&nbsp;
                            <label for="fileinput"><img alt="filechoose" src="FileChoose.png" title="Click to upload file" style={{ height: "20px", width: "20px" }} /></label>&nbsp;
                            <Button variant='contained' color='primary' onClick={handleUpload}>Upload</Button>&nbsp;
                            <Button variant='contained' color='primary' onClick={handleList}>List</Button>
                        </>
                    }

                </div>  <div>


                </div>  </>
            {check === true ? <MaterialReactTable
                enableEditing
                columns={columns}
                data={tableData}



                renderRowActions={({ row, table }) => (

                    <Box sx={{ textAlign: "center" }}>
                        {/* {//console.log("checking", blockValues.includes(row?.original?.status))} */}
                        {blockValues.includes(row?.original?.status) ? <Button variant='contained' color='primary' onClick={() => { handleBulkBlock(row?.original?.fileName, row?.original?.vocuherValue, row?.original?.voucherType) }}>Block</Button> : row?.original?.status === 2 ? "-" : <Button variant='contained' color='primary' onClick={() => { handleBulkUnBlock(row?.original?.fileName, row?.original?.vocuherValue, row?.original?.voucherType) }}>UnBlock</Button>}
                    </Box>



                )}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                enableStickyHeader={true}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                positionActionsColumn="last"
            /> : null}
            {check === false ? lastAdded.type === "BS" ? <>    <MaterialReactTable
                enableEditing
                renderRowActions={({ row, table }) => (

                    <Box sx={{ textAlign: "center" }}>
                        {/* {//console.log("checking", blockValues.includes(row?.original?.status))} */}
                        {blockValues.includes(row?.original?.status) ? <Button variant='contained' color='primary' onClick={() => { handleSerialBlockUnblock(lastAdded?.startSerialNumber, lastAdded?.endSerialNumber, row?.original?.vocuherValue, row?.original?.voucherType, 1) }}>Block</Button> : row?.original?.status === 2 ? "-" : <Button variant='contained' color='primary' onClick={() => { handleSerialBlockUnblock(lastAdded?.startSerialNumber, lastAdded?.endSerialNumber, row?.original?.vocuherValue, row?.original?.voucherType, 2) }}>UnBlock</Button>}
                    </Box>

                )}
                positionActionsColumn="last"

                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}

                enableColumnOrdering
            /> </> : <><MaterialReactTable
                enableEditing
                columns={columns2}
                data={tableData2}
                positionActionsColumn="last"
                renderRowActions={({ row, table }) => (
                    <Box sx={{ textAlign: "center" }}>
                        {/* {//console.log("checking", blockValues.includes(row?.original?.status))} */}
                        <Button variant='contained' color='primary' onClick={() => { handleCheck(row?.original?.fileName) }}>Check</Button>
                    </Box>

                )}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                enableStickyHeader={true}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
            /> </> : null}

            <div>

            </div>
            <SnackBar dispatch={dispatch} snackbarClosed={snackbarClosed} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


        </div>

    );

};
export default VoucherBulkBlockUnblock;
