import React from 'react';
import { Box, Grid, Modal } from '@mui/material';
import Input from '../../components/input';
import SelectDropdown, { SelectDropdownWithOptions } from "../../components/Select";
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { SERVICE_PROVIDER_NAME_IS_REQUIRED } from '../../constfiles/formValidation';
import { branchLastAdded, popupOpenAndClosed,  branchUpdate, branchAdd, handleReset } from '../../redux/slice/BranchSlice';
import { useEffect } from 'react';
import { branchTypeFetchActive } from '../../redux/slice/CommonSlice';
import { useSelector } from 'react-redux';


export default function BranchAddandEdit({ createModalOpen, popupMode, lastAdded }) {
  //console.log("Last added", lastAdded)
  const dispatch = useDispatch();
  const selectValuesRe = useSelector(state => state.CommonReducer);
  const branchTypes = selectValuesRe.branchTypes;
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    borderRadius: "4px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
useEffect(()=>{
    dispatch(branchTypeFetchActive())
},[])

  function validation() {
    const errorObj = {}
    const {branchName,branchType} = lastAdded;
   
      if(branchName) {
        if(branchName.trim()===""){
          //  dispatch(branchLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["branchName" + "Error"]: true,["branchName" + "HelperText"]:SERVICE_PROVIDER_NAME_IS_REQUIRED })
        }
      }
      if(branchType) {
        if(branchType.toString().trim()===""){
          //  dispatch(branchLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["branchType" + "Error"]: true,["branchType" + "HelperText"]:SERVICE_PROVIDER_NAME_IS_REQUIRED })
        }
      }
    
    
    
      dispatch(branchLastAdded(errorObj))
      const check = Object.values(errorObj).includes(SERVICE_PROVIDER_NAME_IS_REQUIRED)    
      return check
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // if(validation()) {
    
    dispatch(branchLastAdded({ [name]: value, [name + "Touched"]: true}))
    // dispatch(branchLastAddedValidate({[name]: value }))
    //}
  }
const buttonUpdate = () =>{
  // alert("i")
  if(!validation()){
    dispatch(branchUpdate({branchId:lastAdded.branchId,  branchName:lastAdded.branchName,active:lastAdded.active,branchType:lastAdded.branchType}))
  }
}
  const buttonSave = () => {     
      if(!validation()){
        dispatch(branchAdd({branchName:lastAdded.branchName, active:lastAdded.active, branchType:lastAdded.branchType}))
      }
     
  }
  const popupClose = () =>{
    dispatch(handleReset())
    dispatch(popupOpenAndClosed({ open: false }))
  }
  return (
    <>

      <Modal
        open={createModalOpen}
        onClose={()=>{popupClose()}}
        aria-labelledby="serviceProvider-title"
        aria-describedby="serviceProvider-description"
      >
        <Box sx={style}>
          <h3>{popupMode === "add" ? "Branch Create": "Branch Edit - "+`${lastAdded.branchName}` }</h3>
          <Grid container spacing={2}>
            {/* <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.serviceProviderCode} touched={lastAdded.serviceProviderCodeTouched} error={lastAdded.serviceProviderCodeError } helperText={lastAdded.serviceProviderCodeHelperText} onChange={handleInputChange} name="serviceProviderCode" label="serviceProvider Code" fullWidth={true} maxLength="3" />
            </Grid> */}

            <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.branchName} touched={lastAdded.branchNameTouched} onChange={handleInputChange} error={lastAdded.branchNameError} helperText={lastAdded.branchNameHelperText} name="branchName" label="Branch Name" fullWidth={true} maxLength="20" />
            </Grid>

            <Grid item lg="12" md={12} sx="12" sm={12}>
              <SelectDropdownWithOptions options={branchTypes} value={lastAdded.branchType} onChange={handleInputChange} name="branchType" label="Branch Type" fullWidth={true} />
            </Grid>

            <Grid item lg="12" md={12} sx="12" sm={12}>
              <SelectDropdown value={lastAdded.active} onChange={handleInputChange} name="active" label="Active" fullWidth={true} />
            </Grid>

            <Grid item lg="12" md={12} xs="12" sx={{
              textAlign: "center", display: "flex", columnGap: "2%", justifyContent: "center"
            }} sm={12}>
             {popupMode === "add" ?  <ButtonComponent onClick={buttonSave} label="Save" name="branchTypeAddButton"
                color="primary"
                disabled={(( lastAdded.branchName.length === 0 ) &&( lastAdded.branchType.length === 0 )) ? true : false}
                type="add"
                variant="contained" /> :  <ButtonComponent onClick={buttonUpdate} label="Update" name="branchTypeUpdateButton"
                color="primary"
                disabled={(lastAdded.branchName.length) === 0 ? true : false}
                type="add"
                variant="contained" />}
              <ButtonComponent label="Close" name="close"
                color="primary"
                disabled={false}
                onClick={()=>{popupClose()}}
                type="close"
                variant="contained" />




            </Grid>
          </Grid>


        </Box>
      </Modal>
    </>
  );
};
