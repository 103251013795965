import React from 'react';
import { Box, Grid, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import Input from '../../../components/input';
import SelectDropdown from "../../../components/Select";
import ButtonComponent from '../../../components/button';
import { useDispatch } from 'react-redux';
import { profileAdd, profileLastAdded,  profileUpdate, popupOpenAndClosed } from '../../../redux/slice/ProfileSlice';
import { PROFILE_CODE_IS_REQUIRED, PROFILE_CODE_MINIMUM_3_CHACRTERS, PROFILE_NAME_IS_REQUIRED } from '../../../constfiles/formValidation';

export default function ProfileCreateAndEdit({ createModalOpen, popupMode, lastAdded }) {
  ////console.log("Last added", lastAdded)
  const dispatch = useDispatch()
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    borderRadius: "4px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // const handleSubmit = () => {
  //   //put your validation logic here
  //   onSubmit(values);
  //   onClose();
  // };

  function validation() {
    const errorObj = {}
    const {profileCode,profileDescription} = lastAdded;
    //   ////console.log("lastAdded",lastAdded)
      if(profileCode) {
        if(profileCode.trim() ===""){
      //  dispatch(profileLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["profileCode" + "Error"]: true,["profileCode" + "HelperText"]:PROFILE_CODE_IS_REQUIRED })
          
        }
        if(profileCode.length < 1) {
          Object.assign(errorObj,{["profileCode" + "Error"]: true,["profileCode" + "HelperText"]:PROFILE_CODE_MINIMUM_3_CHACRTERS  })
      //  dispatch(profileLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_MINIMUM_3_CHACRTERS  }))
        }
        else{
          Object.assign(errorObj,{["profileCode" + "Error"]: false,["profileCode" + "HelperText"]:""})
        // dispatch(profileLastAdded())
        }
      }
      if(profileDescription) {
        if(profileDescription.trim()===""){
          //  dispatch(profileLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["profileDescription" + "Error"]: true,["profileDescription" + "HelperText"]:PROFILE_NAME_IS_REQUIRED })
        }
      }
      ////console.log("errorObj",errorObj)
    
    
      dispatch(profileLastAdded(errorObj))
      const check = Object.values(errorObj).includes(PROFILE_CODE_MINIMUM_3_CHACRTERS || PROFILE_NAME_IS_REQUIRED || PROFILE_CODE_IS_REQUIRED)
      ////console.log(" Object.values(errorObj)", Object.values(errorObj))
      ////console.log("check",check)
      return check
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // if(validation()) {
    
    dispatch(profileLastAdded({ [name]: value, [name + "Touched"]: true}))
    // dispatch(profileLastAddedValidate({[name]: value }))
    //}
  }
const buttonUpdate = () =>{
  // alert("i")
  if(!validation()){
    dispatch(profileUpdate({profileId:lastAdded.profileId, profileCode:lastAdded.profileCode, profileDescription:lastAdded.profileDescription,active:lastAdded.active}))
  }
}
  const buttonSave = () => {
   
     
      if(!validation()){
        dispatch(profileAdd(lastAdded))
      }
      //alert(check)
      //  dispatch(profileAdd(lastAdded))
    
      //////console.log("checkingFlag",checkingFlag)
      //alert(checkingFlag)
    // else {
    //   dispatch(profileAdd(lastAdded))
    // }
  }
  return (
    <>

      <Modal
        open={createModalOpen}
        onClose={() => dispatch(popupOpenAndClosed({ open: false }))}
        aria-labelledby="profile-title"
        aria-describedby="profile-description"
      >
        <Box sx={style}>
          <h3>{popupMode === "add" ? "Profile Create": "Profile Edit - "+`${lastAdded.profileDescription}` }</h3>
          <Grid container spacing={2}>
            <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.profileCode} touched={lastAdded.profileCodeTouched} error={lastAdded.profileCodeError } helperText={lastAdded.profileCodeHelperText} onChange={handleInputChange} name="profileCode" label="Profile Code" fullWidth={true} maxLength="1" />
            </Grid>

            <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.profileDescription} touched={lastAdded.profileDescriptionTouched} onChange={handleInputChange} error={lastAdded.profileDescriptionError} helperText={lastAdded.profileDescriptionHelperText} name="profileDescription" label="Profile Description" fullWidth={true} maxLength="20" />
            </Grid>


            <Grid item lg="12" md={12} sx="12" sm={12}>
              <SelectDropdown value={lastAdded.active} onChange={handleInputChange} name="active" label="Active" fullWidth={true} />
            </Grid>

            <Grid item lg="12" md={12} xs="12" sx={{
              textAlign: "center", display: "flex", columnGap: "2%", justifyContent: "center"
            }} sm={12}>
             {popupMode === "add" ?  <ButtonComponent onClick={buttonSave} label="Save" name="profileAddButton"
                color="primary"
                disabled={(lastAdded.profileCode.length && lastAdded.profileDescription.length) === 0 ? true : false}
                type="add"
                variant="contained" /> :  <ButtonComponent onClick={buttonUpdate} label="Update" name="profileUpdateButton"
                color="primary"
                disabled={(lastAdded.profileCode.length && lastAdded.profileDescription.length) === 0 ? true : false}
                type="add"
                variant="contained" />}
              <ButtonComponent label="Close" name="close"
                color="primary"
                disabled={false}
                onClick={() => dispatch(popupOpenAndClosed({ open: false }))}
                type="close"
                variant="contained" />




            </Grid>
          </Grid>


        </Box>
      </Modal>
    </>
  );
};
ProfileCreateAndEdit.prototype = {
  columns: PropTypes.array
}