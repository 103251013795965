import { InputLabel, MenuItem, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import React, { useState } from "react";

export default function SelectDropdown(props) {
    const { name, value, label, disabled, onChange, error = false } = props;

    return (
        <>


            <TextField
                fullWidth="true"
                value={value}
                disabled={disabled}
                name={name}
                error={error}
                onChange={onChange}
                select // tell TextField to render select
                label={label}
            >
                <MenuItem key={"empty1"} value="">
                    --Select Active--
                </MenuItem>
                <MenuItem key={1} value="Y">
                    Yes
                </MenuItem>
                <MenuItem key={2} value="N">
                    No
                </MenuItem>
            </TextField>
        </>
    )
}
export function SelectDropdownProfiles(props) {
    const { name, value, label, error = false, onChange, options, disabled } = props;
    console.log("options", options,name)

    return (
        <>


            <TextField
                fullWidth="true"
                value={value}
                name={name}
                disabled={disabled}
                onChange={onChange}
                select // tell TextField to render select
                label={label}
                error={error}
            >
                <MenuItem key={"empty2"} value="">
                    --Select Profile--
                </MenuItem>
                {options.map((item, index) => {
                    return <MenuItem key={item.id + item.profileDescription} value={item.id}>
                        {item.profileCode + " - " + item.profileDescription}
                    </MenuItem>
                })}

            </TextField>
        </>
    )
}
export function SelectDropdownRechargeFactor(props) {
    const { name, value, label, disabled, onChange } = props;

    return (
        <>


            <TextField
                fullWidth="true"
                value={value}
                disabled={disabled}
                name={name}
                onChange={onChange}
                select // tell TextField to render select
                label={label}
            >
                <MenuItem key={1} value="F">
                    Fixed
                </MenuItem>
                <MenuItem key={2} value="P">
                    Percentage
                </MenuItem>
            </TextField>
        </>
    )
}

export function SelectDropdownWithOptions(props) {
    const { name, value, label, disabled, onChange, error = false, options } = props;

    return (
        <>


            <TextField
                fullWidth="true"
                value={value}
                disabled={disabled}
                name={name}
                error={error}
                onChange={onChange}
                select // tell TextField to render select
                label={label}
            >
                {options.map((item, index) => {
                    return (
                      
                        <MenuItem key={item + index} value={item.id}>
                            {item.name}
                        </MenuItem>
                    )
                })}

            </TextField>
        </>
    )
}