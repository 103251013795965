import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    lists: [],
    loader: false,
    lastAdded: {
        "startDate": currentDate,
        "endDate": currentDate,
    },
    VoucherStatusInfoModal: {
        open: false,
    },
    VoucherStatusEditModal: {
        open: false,
    },
    infoModalValues: {},
    editModalValues: {},
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    }
}




export const listFetch = createAsyncThunk(
    "CardVoucherRequestStatusFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/cardVoucher/list', arg);
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const CardVoucherRequestUpdate = createAsyncThunk(
    "CardVoucherRequestUpdate/update", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/cardVoucher/update', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)



const CardVoucherRequestStatusReducerSlice = createSlice({
    name: "CardVoucherRequestStatusReducer",
    initialState,
    reducers: {
        voucherRequestStatusLastAdded(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        requestUpdateLastAdded(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                editModalValues: { ...state.editModalValues, ...action.payload },
            }
        },
        snackbarClosed(state, action) {

            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state, action) {
            //console.log("errpr ope", action.payload)
            return {
                ...state,
                VoucherStatusInfoModal: {
                    open: action.payload
                },
            }
        },
        editPopupOpenAndClosed(state, action) {

            return {
                ...state,
                VoucherStatusEditModal: {
                    open: action.payload
                },
            }
        },
        emptyTable(state, action) {
            return {
                ...state,
                lists: [],
                lastAdded: {
                    "startDate": currentDate,
                    "endDate": currentDate,
                },
            }
        },
        lastAddedReset(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,

                lastAdded: {
                    "startDate": currentDate,
                    "endDate": currentDate,
                }
            }
        },
        InfoModalValues(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                infoModalValues: action.payload
            }
        },
        EditModalValues(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                editModalValues: action.payload
            }
        },
        snackbarClosed(state, action) {
            //  //console.log("action", action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(listFetch.fulfilled, (state, action) => {
            return {
                ...state,
                lists: action.payload,
                loader: false
            }
        })
            .addCase(listFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(listFetch.rejected, (state, action) => {
                return {
                    ...state,
                    loader: false
                }
            })
            .addCase(CardVoucherRequestUpdate.pending, (state, action) => {
                return {
                    ...state,
                    // loader:true
                }
            })
            .addCase(CardVoucherRequestUpdate.rejected, (state, action) => {

                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(CardVoucherRequestUpdate.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "Request Update Added Failed",
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        lists: action.payload.lists

                    }
                }
            })

    }

})

export const { editPopupOpenAndClosed,emptyTable, voucherRequestStatusLastAdded, snackbarClosed, lastAddedReset, popupOpenAndClosed, InfoModalValues, EditModalValues, requestUpdateLastAdded } = CardVoucherRequestStatusReducerSlice.actions;
export const CardVoucherRequestStatusReducer = CardVoucherRequestStatusReducerSlice.reducer