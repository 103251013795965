import { Autocomplete, Grid, Paper, TextField } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { StockAllocationAllocate, stockAllocationLastAdded } from "../../redux/slice/StockAllocationSlice";
import { useDispatch } from "react-redux";
import ButtonComponent from "../../components/button";

export default function StockAllocationTable(props) {
    const { list, lastAdded } = props;
    const dispatch = useDispatch()

    const commonValues = useSelector(state => state.CommonReducer);
    //console.log("commonValues", commonValues)
    const branches = commonValues?.branches;
    const handleChange = (e, branchValues) => {
     //   console.log("agentCode", branchValues)
        //   console.log("agentCode2",e.tar)
        dispatch(stockAllocationLastAdded({ branch: branchValues.id, branchDisplay: branchValues.name }))
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(stockAllocationLastAdded({ [name]: value }))
    }
    const handleReset = () => {

    }
    const handleAllocated = () => {
        dispatch(StockAllocationAllocate({branchText:lastAdded?.branchDisplay,branchId:lastAdded?.branch,reqStartSerialNo:lastAdded?.requestssno,reqEndSerialNo:lastAdded?.requestesno,availableCount:list[0]?.aCount,requestCount:lastAdded?.requestCount}))
    }
    return (
        <>
            <Paper style={{ paddingTop: "2%", marginTop: "1%" }}>
                <table id="serialEnquieryTable" style={{ width: "100%", border: "1px solid black" }}>
                    <tr>
                        <td>Operator</td>
                        <td>{list[0]?.operator}</td>
                        <td>Denomination</td>
                        <td>{list[0]?.denomination}</td>
                    </tr>

                    <tr>
                        <td>Start Start Serial No</td>
                        <td>{list[0]?.ssno}</td>
                        <td>End End Serial No</td>
                        <td>{list[0]?.esno}</td>
                    </tr>
                    <tr>
                        <td>Request Start Serial No</td>
                        <td><TextField value={list[0]?.requestssno} autoFocus style={{ padding: "6.5px 14px" }} name="requestssno" id="requestssno" onChange={handleInputChange} /></td>
                        <td>Request End Serial No</td>
                        <td><TextField value={list[0]?.requestesno} name="requestesno" id="requestesno" style={{ padding: "6.5px 14px" }} onChange={handleInputChange} /></td>
                    </tr>
                    <tr>
                        <td>Available Count</td>
                        <td>{list[0]?.aCount}</td>
                        <td>Request Count</td>
                        <td><TextField value={list[0]?.requestCount} name="requestCount" id="requestCount" style={{ padding: "6.5px 14px" }} onChange={handleInputChange} /></td>
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <Autocomplete
                                id="BranchCode"
                                options={branches}
                                value={lastAdded.branchCodeDisplay}
                                onChange={(e, newValue, name) => { handleChange(e, newValue) }}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='Branch' error={lastAdded.BranchError}  {...params} label="Branch" /></Grid>}
                            />

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} style={{textAlign:"center"}}> <ButtonComponent
                            variant='contained' color='primary' label="Allocate"
                            onClick={handleAllocated}
                        >
                        </ButtonComponent> <ButtonComponent
                            variant='contained' color='primary' label="Reset"
                            onClick={handleReset}
                        >
                            </ButtonComponent></td>
                    </tr>
                </table>
            </Paper>
        </>
    )
}