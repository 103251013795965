import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { THEME_COLOR } from './constfiles/theme';
const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);
console.log(process.env.NODE_ENV)
const outerTheme = createTheme({
  palette: {
    primary: {
      main:"#FE7659",
    },    
    // text: {
    //   primary: "#000000"
    // },
    // button: {
    //   primary: "#FFFFFF", color :  "#FFFFFF"
    // }
  },
  // button : {
  //   allVariants: {
  //     color: "pink"
  //   },
  //   primary : {
  //     color:"red"
  //   }
  // }
});
root.render(
 
    <Provider store={store}>
      <BrowserRouter >
      <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={outerTheme}>
        <App />
        </ThemeProvider>
       </PersistGate>
      </BrowserRouter>
    </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
