import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    lists: [],
    lastAdded : {type:"Batch", typeTouched:false, inputValue:"",inputValueTouched:false},
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    

}

export const getVoucherBlockUnblockList = createAsyncThunk(
    "voucherBlock/list", async (arg, thunkAPI) => {
        //console.log("arg",arg)
        //console.log("thunkAPI",thunkAPI)
        try {
            const response = await axios.post('/voucherBlock/list',arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const VoucherBlockAction = createAsyncThunk(
    "voucherBlock/block", async (arg, thunkAPI) => {
        //console.log("arg",arg)
        //console.log("thunkAPI",thunkAPI)
        try {
            const response = await axios.post('/voucherBlock/block',arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const VoucherUnblockAction = createAsyncThunk(
    "voucherBlock/unblock", async (arg, thunkAPI) => {       
        try {
            const response = await axios.post('/voucherBlock/unblock',arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)




// //console.log("availableStockFetch", getVoucherBlockUnblockList)

const VoucherBlockUnblockSlice = createSlice({
    name: "VoucherBlockUnblockReducer",
    initialState,

    reducers: {
        voucherBlockUnBlockLastAdded (state, action) {         
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        snackBarClosedReset (state, action) {
            return {
                ...state,
                sucessMessage: {
                    snackbarOpen: false,
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVoucherBlockUnblockList.fulfilled, (state, action) => {
            return {
                ...state,
                lists: action.payload
            }
        })
            .addCase(getVoucherBlockUnblockList.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(getVoucherBlockUnblockList.rejected, (state, action) => {
                //console.log(action)
            })
            builder.addCase(VoucherBlockAction.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:" action.payload.message" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },   
                        lists : action.payload.voucherInfo                   
                    }
                }
            })
                .addCase(VoucherBlockAction.pending, (state, action) => {
                    //console.log(action)
                })
                .addCase(VoucherBlockAction.rejected, (state, action) => {
                    //console.log(VoucherBlockAction)
                })

                builder.addCase(VoucherUnblockAction.fulfilled, (state, action) => {
                    //console.log("action fulfilled fulfilled==>", action)
                    if (action.payload.toString() === "500") {
                        return {
                            ...state,
                            sucessMessage: {
                                message:" action.payload.message" ,
                                snackbarOpen: true,
                                mode: "warning"
                            }
                        }
                    }
                    else {
                        return {
                            ...state,
                            sucessMessage: {
                                message: action.payload.message,
                                snackbarOpen: true
                            },   
                            lists : action.payload.voucherInfo                   
                        }
                    }
                })
                    .addCase(VoucherUnblockAction.pending, (state, action) => {
                        //console.log(action)
                    })
                    .addCase(VoucherUnblockAction.rejected, (state, action) => {
                        //console.log(VoucherUnblockAction)
                    })
    }

})
export const {voucherBlockUnBlockLastAdded,handleVoucherAllStockResetDispatch,snackBarClosedReset} = VoucherBlockUnblockSlice.actions
export const VoucherBlockUnblockSliceReducer = VoucherBlockUnblockSlice.reducer