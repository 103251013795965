import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Box, Button,  IconButton,  Tooltip,} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import PromoConfigAddandEdit from './PromoConfigAddAndEdit';
import { useDispatch, useSelector } from 'react-redux';
import { promoConfigDelete,promoConfigFetch,promoConfigLastAdded,  popupOpenAndClosed, snackbarClosed } from '../../redux/slice/PromoConfigSlice';
import SnackBar from '../../components/snackbar';
import { promoDistributionsFetch } from '../../redux/slice/CommonSlice';



const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent : "end",
      flexDirection:"row-reverse"
    }
  }
});
const PromoConfig = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state=>state.PromoConfigReducer);
  const commonReducer = useSelector(state=>state.CommonReducer);
  const promoDistribution = commonReducer.promoDistributions   === undefined ? [] : commonReducer?.promoDistributions;
  //console.log("selectValuesRe",selectValuesRe)
  const lastAdded = selectValuesRe.lastAdded;
  //console.log("lastAdded",lastAdded)
  const snackbarValues = selectValuesRe.sucessMessage;
  //console.log("snackbarValues",snackbarValues)
  const promoConfigAddAndEditModal = selectValuesRe.promoConfigAddAndEditModal;
  // const [createModalOpen, setCreateModalOpen] = useState(false);
  ///const [tableData, setTableData] = useState(selectValuesRe.promoConfigReducer.promoConfigs);

  // const [tableData, setTableData] = useState(selectValuesRe.promoConfigReducer.promoConfigs);
  const tableData = selectValuesRe.promoConfigs  === undefined ? [] : selectValuesRe?.promoConfigs;
  

  useEffect(()=>{
    dispatch(promoConfigFetch()) 
    dispatch(promoDistributionsFetch())
},[])




  const columns = useMemo(
    () => [
      {
        accessorKey: 'winner_text',
        header: 'Winner Text',
       
      },
      {
        accessorKey: 'activeLabel',
        header: 'Active',
       
      },     
      
      {
        accessorKey: 'recharge_price',
        header: 'Recharge Price',
        
      },
      {
        accessorKey: 'recharge_factor',
        header: 'Recharge Factor',
        
      },
      {
        accessorKey: 'recharge_value',
        header: 'Recharge Value',
        
      },
    ],
    [],
  );
  
  const snackbarClosedReset= () => {
    dispatch(snackbarClosed({ snackbarOpen: false }))
  }
  const handleDelete = (row) => {
   // const findElement = tableData.find(element=>element.id === row.original.id)
    console.log("tableData",row)
    dispatch(promoConfigDelete({promoConfigId:row.original.winner_id}))

  }
  const openMod = () => {
   // alert("s")
    dispatch(popupOpenAndClosed ({open:true,mode:"add",active:"Y"}));
    var resetObj = {};
    for(const x in lastAdded) {
      //console.log("x",x)
      if(x !== "active") {
       Object.assign(resetObj,{[x]:""})
      }
    }
    //console.log("resetObj",resetObj)
    dispatch(promoConfigLastAdded(resetObj, {active:"Y"}))
  }
  const handleEditPopUp = (row) =>{
   // console.log("row",row)
    const findElement = tableData.find(element=>element.winner_id === row.original.winner_id)
 // console.log("tableData",findElement)
    dispatch(popupOpenAndClosed ({open:true,mode:"edit"}))
    dispatch(promoConfigLastAdded({promoConfigWinnerText:findElement.winner_text,rechargeFactor:findElement.recharge_factor,rechargePrice:findElement.recharge_price, rechargeValue:findElement.recharge_value,  winnerDistribution:findElement.winner_distro_id,active:findElement.active,promoConfigId:findElement.winner_id}))
  }
  return (
    < div className={classes.root }>
      <MaterialReactTable
     muiTablePaginationProps={{
      //rowsPerPage : 5
     }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}
        
        muiTableBodyRowProps={{
          sx:{
            padding:"0px"
          }
        }}
       muiTableContainerProps={{
        sx:{
          maxHeight:"400px",
          minHeight:"400PX"
        }
       }}
       initialState={{
        density: "compact"
      }}
        positionActionsColumn = "last"
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Edit">
              <IconButton onClick={()=>handleEditPopUp(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton  onClick={()=>handleDelete(row)} color="error" >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
          <Button
            color="primary"
            onClick={() => {openMod() }}
            variant="contained"
          >
            Create Promo Config
          </Button></>
        )}
      />
  
        <PromoConfigAddandEdit promoDistribution={promoDistribution} lastAdded={lastAdded} popupMode={promoConfigAddAndEditModal.mode} createModalOpen={promoConfigAddAndEditModal.open} />


<SnackBar snackbarClosed={snackbarClosedReset} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


    <div>
      
    </div>
  
     
    </div>
  );
};



export default PromoConfig;
