import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Grid, IconButton } from '@mui/material';
import { THIS_FIELD_IS_REQUIRED } from '../../constfiles/formValidation';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { profileSet, userDetailsSet, userMenusSet } from '../../redux/slice/CommonSlice';




const theme = createTheme();

export default function SuperAdminSystemSignin() {
  const history = useNavigate();
  //  const [src, setSrc] = React.useState("")
  const [formData, setFormData] = React.useState({ userName: "", password: "", token: "", captcha: "" });
  const [securityCode, setSecurityCode] = React.useState("");
  const [errors, setErrors] = React.useState({ userName: "", password: "", token: "", captcha: "" });
  const SECURITY_CODE_LENGTH = 6;
  const dispatch = useDispatch()
  const [error, setError] = React.useState({})
  // React.useEffect(()=>{

  // },[])
  const generateSecurityCodeImage = () => {
    const code = Array.from(Array(SECURITY_CODE_LENGTH), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join("");
    //alert(code)
    const securityCodeImageElement = document.getElementById(
      "securityCodeImage"
    );
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 50;
    canvas.height = 20;

    const imgElement = document.createElement("img");

    imgElement.addEventListener("load", loadImage);
    imgElement.src = `data:image/svg+xml,${encodeURIComponent(
      `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="100"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml" style="display:block;"><span style="margin:auto;">${code}</span></div></foreignObject></svg>`
    )}`;

    function loadImage(e) {
      ctx.drawImage(e.target, 0, 0);
      securityCodeImageElement.src = canvas.toDataURL();
    }

    setSecurityCode(code);
  };



  React.useEffect(() => {
    localStorage.clear()
    // alert(window.location.pathname )
    if (window.location.pathname !== "/ussdweb/system/") {
      window.location.href = "/ussdweb/system/"
    }
    generateSecurityCodeImage();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorsSubmit = {}
    if (formData.userName.trim() === "") {

      Object.assign(errorsSubmit, { userName: THIS_FIELD_IS_REQUIRED, userNameError: "error" })
    }
    if (formData.password === "") {
      Object.assign(errorsSubmit, { password: THIS_FIELD_IS_REQUIRED, passwordError: "error" })
    }
    if (formData.token === "") {
      Object.assign(errorsSubmit, { token: THIS_FIELD_IS_REQUIRED, tokenError: "error" })
    }
    if (formData.captcha === "") {
      Object.assign(errorsSubmit, { captcha: THIS_FIELD_IS_REQUIRED, captchaError: "error" })
    }

   // console.log("errorsSubmit", errorsSubmit)
    setErrors(errorsSubmit)
    const check = Object.values(errorsSubmit).some((item) => item.includes("error"));
   // console.log("check", Object.values(errors))
    //console.log("errors", errors)
    if (!check) {
      setErrors((prevState) => {
        return {
          ...prevState,
          userName: "",
          userNameError: "",
          password: "",
          passwordError: "",
          token: "",
          tokenError: "",
          captcha: "",
          captchaError: ""
        }
      })
      if (securityCode === formData.captcha) {
        axios.post("/ssignin", formData).then(response => {
         // console.log(response.data)
          //    setSrc(response.data)
          if (response.data.statusCode === 200) {
            if (response.data.redirect === 0) {
              // alert(response.data.auth)
              axios.defaults.headers.common["AuthCode"] = response.data.auth;
              axios.defaults.headers.common["UserId"] = response.data.userId;
              localStorage.setItem("auth", response.data.auth);
              //  window.location.href = response.data.redirectTo;
              dispatch(profileSet({ auth: response.data.auth }))
              dispatch(userDetailsSet(response.data.details))
              dispatch(userMenusSet(response.data.menus))

              history(response.data.redirectTo)
            }
          }
          else {
            setError(response.data)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      else {
        setErrors((prevState) => {
          return {
            ...prevState,
            captcha: "Captcha is Incorrect",
            captchaError: "error"
          }
        })
      }
    }
  };
  console.log(errors)
  function handleInputChangeValidation(name, value) {
    if (value.toString() === "") {
      setErrors((prevState) => {
        return {
          ...prevState,
          [name]: true, [name]: THIS_FIELD_IS_REQUIRED
        }
      })
    }
    else {
      setErrors((prevState) => {
        return {
          ...prevState,
          [name]: false, [name]: ""
        }
      })

    }

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
 //   console.log(name, value);
    handleInputChangeValidation(name, value)
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }
  console.log(errors)
  return (
    <ThemeProvider theme={theme}>
      {/* <Container  maxWidth="xs"> */}
      <CssBaseline />
      <Grid container>
        <Grid lg={6} item r sx={{ background: 'url(../kadick_background.png) no-repeat', backgroundPosition: "center" }}>
          {/* <img src={src}/> */}
        </Grid>
        <Grid lg={5} item>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate >

              <Grid container>
                <Grid lg={12} item>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="userName"
                    label="User Name "
                    name="userName"
                    autoComplete="off"
                    autoFocus
                    error={errors.userName}
                    helperText={errors.userName}
                    onChange={handleInputChange}
                  /></Grid>
                <Grid lg={12} item>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="off"
                    onChange={handleInputChange}
                    error={errors.password}
                    helperText={errors.password}
                  /></Grid>
                <Grid lg={12} item>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="token"
                    label="Token"
                    name="token"
                    autoComplete="token"
                    error={errors.token}
                    helperText={errors.token}
                    inputProps={{ maxLength: 12 }}
                    onChange={handleInputChange}
                  /></Grid>
                <Grid lg={7} item>
                  <TextField
                    margin="normal"
                    required
                    error={errors.captcha}
                    helperText={errors.captcha}
                    id="captcha"
                    label="Captcha"
                    name="captcha"
                    autoComplete="captcha"
                    onChange={handleInputChange}
                  /></Grid>

                <Grid lg={4} item sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    id="securityCodeImage"
                    alt="Security Code Challange"
                    style={{
                      //     objectFit: "contain",
                      width: "100%",
                      height: "50%",
                      //  paddingLeft: ".5rem",
                      //  paddingRight: ".5rem"
                    }}
                  /></Grid>
                <Grid lg={1} item sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={generateSecurityCodeImage}><RefreshIcon /></IconButton></Grid>
              </Grid>
              {/* <CaptchaTest /> */}

              <Button
                type="button"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2 }}
              >
                Log in
              </Button>
              <div style={{textAlign:"center"}}><span style={{color:"red"}}>{error?.message}</span></div>
            </Box>
          </Box></Grid>
      </Grid>
      {/* </Container> */}
    </ThemeProvider>
  );
}