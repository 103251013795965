import React, { useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, Grid, TextField, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/input';
import { ModifiedDate } from '../../utlis/CurrentDateGenerator';
import { retailersFetch } from '../../redux/slice/CommonSlice';
import { retailerWalletFetch, retailerWalletLastAdded, retailerWalletLastAddedReset } from '../../redux/slice/RetailerWalletSlice';
import jsPDF from 'jspdf';
import ActionComponent from '../../components/ActionComponent';
import { MainContext } from '../../ComponentBuilld';
import { THEME_COLOR } from '../../constfiles/theme';

require('jspdf-autotable');

const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row"
    },
    '& .MuiIconButton-colorPrimary:hover': {
      background: THEME_COLOR,
    },

  }
});
const RetailerWallet = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.RetailerWalletReducer);
  const lastAdded = selectValuesRe.lastAdded;
  const tableData = selectValuesRe?.retailerWallets === undefined ? [] : selectValuesRe?.retailerWallets;
  const CommonReducer = useSelector(state => state.CommonReducer);
 const retailers = CommonReducer.retailers  === undefined ? [] : CommonReducer.retailers;
 // console.log("lastAdded", lastAdded)
  const loader = selectValuesRe?.loader;
  const GetMainContext = React.useContext(MainContext)
  // window.onload = (event) => {

  //   dispatch(retailerWalletLastAddedReset())
  // }
  useEffect(() => {
    dispatch(retailerWalletLastAddedReset())

    if (retailers.length <= 0) {
      dispatch(retailersFetch())
    }
    return () => {
      dispatch(retailerWalletLastAddedReset())
    }
  }, [])

  const handleQuery = () => {
    dispatch(retailerWalletFetch({ retailerCode: lastAdded.retailerCode, date: lastAdded.date }))
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: false,
        //  size: 80,
      },
      {
        accessorKey: 'retailer_code',
        header: 'Retailer Code',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: true,
        //  size: 80,
      },

      {
        accessorKey: 'user_name',
        header: 'User Name',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: true,
        //  size: 80,
      },
      {
        accessorKey: 'agent_code',
        header: 'Agent Code',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: true,
        //  size: 80,
      },
      {
        accessorKey: 'available_credit',
        header: 'Available Credit',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: true,
        //  size: 80,
      },
      {
        accessorKey: 'current_balance',
        header: 'Current Balance',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: true,
        //  size: 80,
      },
      {
        accessorKey: 'advance_amount',
        header: 'Advance Amount',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: true,
        //  size: 80,
      },
      {
        accessorKey: 'credit_limit',
        header: 'Credit Limit',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: true,
        //  size: 80,
      },
      {
        accessorKey: 'daily_limit',
        header: 'Daily Limit',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: true,
        //  size: 80,
      },
    ],
    [],
  );
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
   // console.log("name", name)
    if (name === "date") {
      dispatch(retailerWalletLastAdded({ dateModified: ModifiedDate(value), date: value }))
    }
  }
  const retailerCodeChange = (e, retailerCodeNewValue) => {
    dispatch(retailerWalletLastAdded({ "retailerCode": retailerCodeNewValue.retailer_code, "retailerCodeTouched": true }))

  }



  const handleReset = () => {
    dispatch(retailerWalletLastAddedReset())
  }

  const handlePrint = (type) => {

    const tableColumns = ["Date", "Reteailer Code", "Name", "Agent Code", "Avail.Crt", "Curr.Bal", "Adv.Amt", "Cre.Lmt", "DaiilyLmt"];
    const doc = new jsPDF();
    const firstArray = [];
    var secondArray = [];
    tableData.forEach(element => {
      secondArray = [];
      //console.log("element",element)
      for (const x in element) {
        // //console.log("element2",x)
        // //console.log("element3",element[x])
        secondArray.push(element[x])
      }
      firstArray.push(secondArray)
    });


    //console.log("firstArray",firstArray)
    doc.autoTable(tableColumns, firstArray, { startY: 20, startX: 0, autoSize: true, });

    doc.text("Retailer Wallet", 14, 15);
    if (type === 1) {
      doc.autoPrint();
    }
    else {
      doc.save('retailer_wallet.pdf');

    }
    window.open(doc.output('bloburl'), '_blank');
  }
  return (
    < div className={classes.root} style={{ maxWidth: "1150px" }}>
      {/* <Paper sx={{ padding: "2%", margin: "2% 0%" }}>

       
      </Paper> */}
      <MaterialReactTable
        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}

        displayColumnDefOptions={{

          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        state={{
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        initialState={{
          density: "compact",
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}
        muiTableBodyRowProps={{
          sx: {
            padding: "0px",
            tableLayout: "fixed"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX",
            tableLayout: "fixed"
          }
        }}
        renderTopToolbarCustomActions={() => (
          <>
            <Grid container >
              <Grid item lg={4} md={4} sm={4}>
                <FormControl >

                  <Autocomplete
                    id="Retailers"
                    options={retailers}
                    onChange={retailerCodeChange}
                    value={lastAdded.retailerCode}
                    sx={{ width: GetMainContext.main === false ? "300px" : "250px" }}
                    renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='retailerInput' {...params} label="Retailers" /></Grid>}
                  />
                </FormControl>

              </Grid>

              <Grid item lg={4} md={4} sm={4} >


                <Input value={lastAdded.date} type="date" touched={lastAdded.dateTouched} placeholder="Date" onChange={handleChange} name="date" label="" fullWidth={true} />
                <span style={{ color: "red" }}>{lastAdded.dateError}</span>

              </Grid>
              <Grid item lg="4" md={4} sx={{ margin: "auto" }} sm={4}>
                {/* <Button variant='contained' disabled={lastAdded.retailerCode === ""} color='primary' onClick={handleQuery}>Query</Button>&nbsp;
          <Button variant='contained' disabled={lastAdded.retailerCode === ""} color='primary' onClick={handlePrint}>Print</Button>&nbsp; */}
                {/* <Button variant='contained' disabled={lastAdded.retailerCode === ""} color='primary' onClick={handleQuery}>Get Excel</Button>&nbsp; */}
                {/* <Button variant='contained' disabled={lastAdded.retailerCode === ""} color='primary' onClick={downloadPdf}>Get Pdf</Button>&nbsp; */}
                {/* <Button variant='contained' color='primary' onClick={handleReset}>Reset</Button> */}
                <ActionComponent
                  actions={tableData.length <= 0 ? false : true}
                  handlePdf={() => { handlePrint(2) }}
                  reset={false}
                  handleReset={handleReset}
                  handlePrint={() => { handlePrint(1) }}
                  handleQuery={handleQuery}
                  resetShow={true}
                  query={!lastAdded.date}
                  classes={classes}

                />
              </Grid>
            </Grid>
          </>
        )}
        enableColumnOrdering
      />
      <div>
      </div>
    </div>
  );
};

//example of creating a mui dialog modal for creating new rows



export default RetailerWallet;
