import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    lists: [],
   lastAdded: {  
    "startDate": currentDate,
    "endDate": currentDate,  },
}

export const promoRunStatusFetch = createAsyncThunk(
    "promoRunStatusReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/promoRunStatus',arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

//console.log("promoRunStatusFetch", promoRunStatusFetch)

const PromoRunStatusSlice = createSlice({
    name: "promoRunStatusReducer",
    initialState,  

    reducers: {
      
        promoRunStatusLastAdded(state, action) {
            console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
                
                
            }
        },
        
       
      
    },
    extraReducers: (builder) => {
        builder.addCase(promoRunStatusFetch.fulfilled, (state, action) => {
            return {
                ...state,
                lists: action.payload
            }
        })
            .addCase(promoRunStatusFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(promoRunStatusFetch.rejected, (state, action) => {
                //console.log(action)
            })
           
    }
})

export const { promoRunStatusLastAdded} = PromoRunStatusSlice.actions;
export const PromoRunStatusReducer = PromoRunStatusSlice.reducer