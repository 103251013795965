import React, {  useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import MaterialReactTable from 'material-react-table';
import jsPDF from "jspdf";
import { currentDateWithTime } from '../../utlis/CurrentDateGenerator';
import AgentCommonWithDateComponent from "./AgentCommonWithDateComponent"
import { AgentAdjustmentFetch, handleSnackBar } from "../../redux/slice/AgentAdjustmentReportSlice"
import { agentAdjustmentLastAdded } from "../../redux/slice/AgentAdjustmentReportSlice"
import SnackBarComponent from "../../components/snackbar";
export default function AgentAdjustmentStatementReport() {
    const selectValuesRe = useSelector(state => state.AgentAdjustmentReducer);
    const lastAdded = selectValuesRe.lastAdded;
  //  console.log("lastAdded", lastAdded)
    const loader = selectValuesRe?.loader;
    const snackbarValues = selectValuesRe.sucessMessage;
    const tableData = selectValuesRe.agentAdjustmentReports === undefined ? [] :   selectValuesRe.agentAdjustmentReports;
    const dispatch = useDispatch();

    const handlePrint = () => {
        const tableColumns = ["Pay.Date", "Pay.Type", "Bank", "Ref.No", "Pay.Amount","App.Amount","Pay.Status","Update Time"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.date)
                secondArray.push(element?.agent_code)
                secondArray.push(element?.user_name)
               // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.available_credit)
                secondArray.push(element?.current_balance)
                secondArray.push(element?.advance_amount)
                secondArray.push(element?.credit_limit)
                secondArray.push(element?.daily_limit)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });

        doc.text("Agent Wallet Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage("../../kadick_b2c_logo.png", 15, 10, 30, 20);

        doc.autoPrint({styles: {overflow: 'linebreak', fontSize: 8}});
        window.open(doc.output('bloburl'), '_blank');
    }
    const handleInputDateChange = (e) => {
        const { name, value } = e.target;
      //  alert("e.target.value"+value)
      //   console.log("agentCode2",e.tar)
   dispatch(agentAdjustmentLastAdded({ [name]: value}))
  }

    const handlePdf = () => {
        const tableColumns = ["Date", "Code", "User Name", "Available Credit", "Cur.Balance","Adv.Amount","Cre.Limit","Daily Limit"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.date)
                secondArray.push(element?.agent_code)
                secondArray.push(element?.user_name)
               // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.available_credit)
                secondArray.push(element?.current_balance)
                secondArray.push(element?.advance_amount)
                secondArray.push(element?.credit_limit)
                secondArray.push(element?.daily_limit)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true,styles: {overflow: 'linebreak',
        fontSize: 8},
showHeader: 'everyPage', });

        doc.text("Agent Wallet Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage("../../kadick_b2c_logo.png", 15, 10, 30, 20);

        doc.save();
        window.open(doc.output('bloburl'), '_blank');
    }
    const columns = useMemo(
        () => [
            

            {
                accessorKey: 'adjustmentDate',
                header: 'Date',
            },
            {
                accessorKey: 'adjustmentType',
                header: 'Type',
            },
           
            {
                accessorKey: 'refNo',
                header: 'Ref.No',
            },
           
            {
                accessorKey: 'adjAmount',
                header: 'Amount',
            },
            {
                accessorKey: 'adjApprovedAmount',
                header: 'Approved Amount',
            },
            {
                accessorKey: 'adjustmentStatus',
                header: 'Status',
            },
            
            
            {
                accessorKey: 'updateTime',
                header: 'Update Time',
            },
            

        ],
        [],
    );
    const handleQuery = () => {
        dispatch(AgentAdjustmentFetch({ agentCode: lastAdded.agentCode, startDate:lastAdded.startDate, endDate:lastAdded.endDate }))
    }

    const handleInputChange = (agentValues,e) => {
        //  console.log("agentCode",agentValues)
        //   console.log("agentCode2",e.tar)
      dispatch(agentAdjustmentLastAdded({ agentCode: agentValues.agent_code, }))
    }
   
    return (
        <>


            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                renderTopToolbarCustomActions={() => (
                    <AgentCommonWithDateComponent
                        selectValuesRe={selectValuesRe}
                        handleQuery={handleQuery}
                        agentCodeChange={handleInputChange}
                        lastAdded={lastAdded}
                        handlePrint = {handlePrint}
                        handlePdf = {handlePdf}
                        handleInputDateChange={handleInputDateChange}
                    />
                )}

                enableColumnOrdering

            />
               <SnackBarComponent snackbarClosed={() => { dispatch(handleSnackBar({ snackbarOpen: false, message: "", mode: "" })) }} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </>
    )
}