import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, IconButton, Tooltip, } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import {  promoListFetch, promoListLastAdded, popupOpenAndClosed, snackbarClosed, promoListEdit, PromoListEdit } from '../../redux/slice/PromoListSlice';
import SnackBar from '../../components/snackbar';
import PromoListCreateAndEdit from './PromoListAddAndEdit';
const useStyles = makeStyles({
    root: {
      "& .css-sq9qdz": {
        justifyContent: "end",
        flexDirection: "row-reverse"
      }
    }
  });
export default function PromoList() {
  
        
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.PromoListReducer);

  //("selectValuesRe", selectValuesRe)
  const lastAdded = selectValuesRe.lastAdded;
 // console.log("lastAdded parent",lastAdded)
  const snackbarValues = selectValuesRe.sucessMessage;
  ////console.log("snackbarValues",snackbarValues)
  const promoListAddAndEditModal = selectValuesRe.promoListAddAndEditModal;
  // const [createModalOpen, setCreateModalOpen] = useState(false);
  ///const [tableData, setTableData] = useState(selectValuesRe.PromoListReducer.promoLists);
  const [validationErrors, setValidationErrors] = useState({});
  // const [tableData, setTableData] = useState(selectValuesRe.PromoListReducer.promoLists);
  const tableData = selectValuesRe.promoLists;
  const loader = selectValuesRe?.loader;
  //console.log("tableData", tableData)

  useEffect(() => {

    dispatch(promoListFetch())
  }, [])




  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        //  size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        // size: 140,
       
      },
      
      {
        accessorKey: 'sDate',
        header: 'Start Date',
       
      },
      {
        accessorKey: 'eDate',
        header: 'Expiry Date',
       
      },

      {
        accessorKey: 'active',
        header: 'Active',
        
      },
      //   {
      //     accessorKey: 'state',
      //     header: 'State',
      //     muiTableBodyCellEditTextFieldProps: {
      //       select: true, //change to select for a dropdown
      //       children: states.map((state) => (
      //         <MenuItem key={state} value={state}>
      //           {state}
      //         </MenuItem>
      //       )),
      //     },
      //   },
    ],
    [],
  );
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
//   const handleDelete = (row) => {
//     const findElement = tableData.find(element => element.id === row.original.id)
//     ////console.log("tableData",findElement)
//     dispatch(promoListDelete({ promoListId: row.original.id }))

//   }
  const openMod = () => {
    // alert("s")
    dispatch(popupOpenAndClosed({ open: true, mode: "add" }));
    var resetObj = { active: "Y" };
    for (const x in lastAdded) {
      ////console.log("x",x)
      if (x !== "active") {
        Object.assign(resetObj, { [x]: "" })
      }
    }
    ////console.log("resetObj",resetObj)
    dispatch(promoListLastAdded(resetObj))
  }
  const handleEditPopUp = (row) => {
    ////console.log("row",row)
    const findElement = tableData.find(element => element.id === row.original.id)
   console.log("findElement",findElement)
    dispatch(popupOpenAndClosed({ open: true, mode: "edit" }))
   dispatch(promoListLastAdded({  active: findElement.active,description:findElement.name,id:findElement.id,startDate:findElement.sDate,endDate:findElement.eDate }))
  }
  return (
    < div className={classes.root}>
      <MaterialReactTable

        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData === undefined ? [] : tableData}
        state={{
            isLoading: loader,
            showProgressBars: loader,
            showSkeletons: loader
          }}
          initialState={{
            density: "compact",
            isLoading: loader,
            showProgressBars: loader,
            showSkeletons: loader
          }}
        muiTableBodyRowProps={{

          sx: {
            padding: "0px"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "430px",
            minHeight: "430PX"
          }
        }}

        positionActionsColumn="last"
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Edit">
              <IconButton onClick={() => handleEditPopUp(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {/* <Tooltip arrow placement="right" title="Delete">
              <IconButton onClick={() => handleDelete(row)} color="error" >
                <Delete />
              </IconButton>
            </Tooltip> */}
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Button
              color="primary"
              onClick={() => { openMod() }}
              variant="contained"
            >
              Create PromoList
            </Button></>
        )}
      />

      <PromoListCreateAndEdit lastAdded={lastAdded} popupMode={promoListAddAndEditModal.mode} createModalOpen={promoListAddAndEditModal.open} />


      <SnackBar snackbarClosed={() => { dispatch(snackbarClosed({ snackbarOpen: false, message: "", mode: "" })) }} dispatch={dispatch}  mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


      <div>

      </div>


    </div>
  );
};