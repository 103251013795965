import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    operators: [],
    denominations: [],
    loader: false,
    lists: [],
    lastAdded: { operator: "ALL", denomination: "ALL", QueryButtonDisabled: true, operatorDisplay: "ALL", denominationDisplay: "ALL" },
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
}

export const stockSummaryFetch = createAsyncThunk(
    "stockSummaryFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/stockSummary',arg);
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const operatorFetch = createAsyncThunk(
    "StockSummary/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/operators/getActiveOperators', { type: 2 });
            //console.log("response", response.data)
            const operatorsData = response.data;
            operatorsData.forEach(element => {
                Object.assign(element, { label: element.name })
            });
            operatorsData.splice (0,0,{label:"ALL",code:"ALL",id:"ALL",name:"ALL"})
            return operatorsData
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const denominationFetch = createAsyncThunk(
    "StockSummaryDenomiantion/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/cvDen/getActiveDenominations');
            //console.log("response", response.data)
            const denominationData = response.data;
            denominationData.forEach(element => {
                Object.assign(element, { label: element.name })
            });
            denominationData.splice (0,0,{label:"ALL",code:"ALL",id:"ALL",name:"ALL"})
            return denominationData
        }
        catch (error) {
            //console.log(error)
        }
    }
)


//console.log("stockSummaryFetch", stockSummaryFetch)

const StockSummarySlice = createSlice({
    name: "stockSummaryReducer",
    initialState,

    reducers: {
        stockSummaryLastAdded(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },


            }
        },

        snackbarClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(operatorFetch.fulfilled, (state, action) => {
            return {
                ...state,
                operators: action.payload,
                loader: false
            }
        })
            .addCase(operatorFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(operatorFetch.rejected, (state, action) => {
                //console.log(action)
            })
        .addCase(denominationFetch.fulfilled, (state, action) => {
            return {
                ...state,
                denominations: action.payload,
                loader: false
            }
        })
            .addCase(denominationFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(denominationFetch.rejected, (state, action) => {
                //console.log(action)
            }).addCase(stockSummaryFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    lists: action.payload,
                    loader: false
                }
            })
            .addCase(stockSummaryFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(stockSummaryFetch.rejected, (state, action) => {
                //console.log(action)
            })
    }

})

export const { stockSummaryList, stockSummaryLastAdded, snackbarClosed, popupOpenAndClosed, stockSummaryLastAddedValidate } = StockSummarySlice.actions;
export const StockSummaryReducer = StockSummarySlice.reducer