import { makeStyles } from '@material-ui/styles';
import { Clear } from '@mui/icons-material';
import { AppBar, Box, Dialog, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useMemo } from 'react';
import { THEME_COLOR } from '../../constfiles/theme';
import ButtonComponent from '../../components/button';


const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            justifyContent: "end",
            flexDirection: "row"
        },
        '& .MuiIconButton-colorPrimary:hover': {
            background: THEME_COLOR,
            padding: " 1.8%"
        },
    }
});
const InventorySplitDetail = ({open,setDetailOpen,inventorySplitReportDetails,id,loader}) => {
    const classes = useStyles()
  

   
 
    
    const style = {
        // position: 'absolute',
        //top: '40%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: "100%",
        borderRadius: "4px",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        padding: "0px"
    };
 
 
    const columns = useMemo(
        () => [
            {
                accessorKey: 'inventoryId',
                header: 'Inventory Id',
            },
            {
                accessorKey: 'oprCode',
                header: 'Operator',
            },
            {
                accessorKey: 'den',
                header: 'Denomination',
            },
            {
                accessorKey: 'ssno',
                header: 'Start Sl No',
            },
            {
                accessorKey: 'esno',
                header: 'End Sl No',
            },
            {
                accessorKey: 'count',
                header: 'Count',
            },
            {
                accessorKey: 'acount',
                header: 'Avl.Count',
            },
            {
                accessorKey: 'active',
                header: 'Active',
            },
            {
                accessorKey: 'createTime',
                header: 'Create Time',
            },
            {
                accessorKey: 'updateTime',
                header: 'Update Time',
            },
        ],
        [],
    );

   



    // const handlePdf = (value) => {
    //     handleDownloadForFileName(value, "Flexi Report", "flexi_report.pdf", tableData)
    // }
    return (
        <>
            <Dialog
                fullScreen
                open={open}

            >
                <AppBar sx={{ position: 'relative' }} style={{ background: THEME_COLOR }} >
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>


                        <Typography variant="h6" component="div">
                         Inventory Split Detail For id - {id}

                        </Typography>
                        <Typography variant="h6" component="div"> <IconButton style={{ color: "white" }} onClick={() => setDetailOpen(false)}> <Clear /></IconButton></Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={style}>
                    < div className={classes.root}>

                        <MaterialReactTable
                            muiTablePaginationProps={{
                                //rowsPerPage : 5
                            }}
                            state={{
                                isLoading: loader,
                                showProgressBars: loader,
                                showSkeletons: loader
                            }}
                            initialState={{
                                density: "compact",
                                isLoading: loader,
                                showProgressBars: loader,
                                showSkeletons: loader
                            }}
                            displayColumnDefOptions={{

                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 120,
                                },
                            }}
                            enableStickyHeader={true}
                            columns={columns}
                            data={inventorySplitReportDetails}

                            muiTableBodyRowProps={{
                                sx: {
                                    padding: "0px"
                                }
                            }}
                            muiTableContainerProps={{
                                sx: {
                                    maxHeight: "400px",
                                    minHeight: "400PX"
                                }
                            }}
                        
                         
                       
                        />

                        <div>

                        </div>


                    </div>
                    <Grid style={{ textAlign: "center", marginTop: "2%" }}>
                     
                        <ButtonComponent label="Close" name="close"
                            color="primary"

                            onClick={() => setDetailOpen(false)}
                            type="close"
                            variant="contained" />

                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};

//example of creating a mui dialog modal for creating new rows



export default InventorySplitDetail;
