import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate, ModifiedDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    flexiPdfReports: [],
    lastAdded: { reportType: "null", date: currentDate, dateModified: ModifiedDate(currentDate) },
    loader: false

}

export const flexiReportStockFetch = createAsyncThunk(
    "flexiReportStockFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/flexiReport', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


const FlexiReportpdfSlice = createSlice({
    name: "FlexiReportpdfReducer",
    initialState,
    reducers: {
        flexiReportPdfLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },

            }
        },
        handleLoader(state, action) {
            return {
                ...state,
                loader: action.payload,
            }
        },
        handleResetFlexiReport(state, action) {
            return {
                ...state,
                flexiPdfReports: [],
                lastAdded: { reportType: "null", date: currentDate, dateModified: ModifiedDate(currentDate) },
                loader: false

            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(flexiReportStockFetch.fulfilled, (state, action) => {
            return {
                ...state,
                flexiPdfReports: action.payload,
                loader : false,
            }
        })
            .addCase(flexiReportStockFetch.pending, (state, action) => {
                return {
                    ...state,                   
                    loader : true,
                }
            })
            .addCase(flexiReportStockFetch.rejected, (state, action) => {
                //console.log(action)
                return {
                    ...state,                   
                    loader : false,
                }
            })
    }

})

export const { cardVoucherAvailableStocks, flexiReportPdfLastAdded, handleLoader,handleResetFlexiReport } = FlexiReportpdfSlice.actions;
export const FlexiReportpdfReducer = FlexiReportpdfSlice.reducer