import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";



const initialState = {
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    lists: [],
    loader: false,
    retailerFlexiReports: [],
    lastAdded: {
        retailerCode: "ALL",
        retailerCodeDisplay: "ALL",
        "startDate": currentDate,
        "endDate": currentDate,
        queryButtonDisabled: true,
        retailerCodeError: false,
        retailerCodeHelperText: ""
    },
}

export const RetailerFlexiFetch = createAsyncThunk(
    "RetailerFlexiFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('retailerFlexiReport/query', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const RetailerFlexiSlice = createSlice({
    name: "RetailerFlexiReducer",
    initialState,

    reducers: {
        retailerFlexiLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        handleLoader(state, action) {
            console.log(action.payload)
            return {
                ...state,
                loader: action.payload,
            }
        },
        handleSnackBar(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        retailerFlexiLastAddedReset(state, action) {
            return {
                ...state,
                lastAdded: {
                    retailerCode: "ALL",
                    retailerCodeDisplay: "ALL",
                    "startDate": currentDate,
                    "endDate": currentDate,
                    queryButtonDisabled: true,
                    retailerCodeError: false,
                    retailerCodeHelperText: ""
                },
                retailerFlexiReports: [],
                loader: false
            }
        },


    },
    extraReducers: (builder) => {
        builder.addCase(RetailerFlexiFetch.fulfilled, (state, action) => {
            return {
                ...state,
                retailerFlexiReports: action.payload,
                loader: false
            }
        })
            .addCase(RetailerFlexiFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(RetailerFlexiFetch.rejected, (state, action) => {
                return {
                    ...state,
                    loader: false
                }
            })
    }


})

export const { retailerFlexiLastAdded, retailerFlexiLastAddedReset, handleLoader, handleSnackBar } = RetailerFlexiSlice.actions;
export const RetailerFlexiReducer = RetailerFlexiSlice.reducer