import React, { useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, IconButton, Tooltip, } from '@mui/material';
import { Edit } from '@mui/icons-material';
import BranchAddandEdit from './BranchAddAndEdit';
import { useDispatch, useSelector } from 'react-redux';
import SnackBar from '../../components/snackbar';
import { branchDelete, branchFetch, branchLastAdded, handleReset, popupOpenAndClosed, snackbarClosed } from '../../redux/slice/BranchSlice';



const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row-reverse"
    }
  }
});
const Branch = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.BranchReducer);
  //console.log("selectValuesRe", selectValuesRe)
  const lastAdded = selectValuesRe.lastAdded;
  const loader = selectValuesRe?.loader;
  const snackbarValues = selectValuesRe.sucessMessage;
  //console.log("snackbarValues", snackbarValues)
  const branchAddAndEditModal = selectValuesRe.branchAddAndEditModal;

  const tableData = selectValuesRe.branchs === undefined ? [] : selectValuesRe.branchs;


  useEffect(() => {   
    dispatch(handleReset())
    dispatch(branchFetch())
    return () =>{
      dispatch(handleReset())
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Branch',


        //  size: 80,
      },

      {
        accessorKey: 'branchTypes.branchType',
        header: 'Branch Type ',


        //  size: 80,
      },



      {
        accessorKey: 'active',
        header: 'Active',
        Cell: ({ cell }) => (
          cell.getValue() === "Y" ? "Yes" : "No"
        )
      },

    ],
    [],
  );

  const handleDelete = (row) => {
    // const findElement = tableData.find(element=>element.id === row.original.id)
    ////console.log("tableData",findElement)
    dispatch(branchDelete({ branchId: row.original.id }))

  }
  const openMod = () => {
    // alert("s")
    dispatch(popupOpenAndClosed({ open: true, mode: "add", active: "Y" }));
    var resetObj = {};
    for (const x in lastAdded) {
      ////console.log("x",x)
      if (x !== "active") {
        Object.assign(resetObj, { [x]: "" })
      }
    }
    ////console.log("resetObj",resetObj)
    dispatch(branchLastAdded(resetObj, { active: "Y" }))
  }
  const handleEditPopUp = (row) => {
    ////console.log("row",row)
    const findElement = tableData.find(element => element.id === row.original.id)
    ////console.log("tableData",findElement)
    dispatch(popupOpenAndClosed({ open: true, mode: "edit" }))
    dispatch(branchLastAdded({ branchName: findElement.name, branchId: findElement.id, active: findElement.active }))
  }
  return (
    < div className={classes.root}>
      {/* <h3 style={{textAlign:"center"}}>Branch </h3> */}
      <MaterialReactTable
        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}
        state={{
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        initialState={{
          density: "compact",
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        muiTableBodyRowProps={{
          sx: {
            padding: "0px"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX"
          }
        }}
        positionActionsColumn="last"
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Edit">
              <IconButton onClick={() => handleEditPopUp(row)}>
                <Edit />
              </IconButton>
            </Tooltip>

          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Button
              color="primary"
              onClick={() => { openMod() }}
              variant="contained"
            >
              Create Branch
            </Button></>
        )}
      />

      <BranchAddandEdit lastAdded={lastAdded} popupMode={branchAddAndEditModal.mode} createModalOpen={branchAddAndEditModal.open} />


      <SnackBar dispatch={dispatch} snackbarClosed={() => { dispatch(snackbarClosed({ snackbarOpen: false })) }} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


      <div>

      </div>


    </div>
  );
};

//example of creating a mui dialog modal for creating new rows



export default Branch;
