import { combineReducers } from "@reduxjs/toolkit";
import { CardVoucherAvailableStockReducer } from "../slice/CardVoucherAvailableStock";
import { CommonReducer } from "../slice/CommonSlice";
import { FlexiReportpdfReducer } from "../slice/FlexiReportPdfSlice";
import { OperatorReducer } from "../slice/OperatorSlice";
import { RetailerWalletReducer } from "../slice/RetailerWalletSlice";
import { ServiceProviderReducer } from "../slice/ServiceproviderSlice";
import { VoucherReportpdfReducer } from "../slice/VoucherReportSlice";
import { VoucherStockReducer } from "../slice/VoucherStockSlice";
import { VoucherBlockUnblockSliceReducer } from "../slice/VoucherBlockUnBlockSlice";
import { VoucherBulkBlockUnblockSliceReducer } from "../slice/VoucherBulkBlockUnBlockSlice";
import { NewVoucherRequestReducer } from "../slice/NewRequestSlice";
import { VoucherRequestStatusReducer } from "../slice/VoucherRequestStatusSlice";
import { CardVoucherAllStocksReducer } from "../slice/CardVoucherAllStockSlice";
import { CardVoucherNewRequestReducer } from "../slice/CardVoucherNewRequestSlice";
import { CardVoucherRequestStatusReducer } from "../slice/CardVoucherRequestSlice";
import { VoucherGenerationReducer } from "../slice/VoucherGenerationSlice";
import { CardVoucherGenerationReducer } from "../slice/CardVoucherGenerationSlice";
import { SerialNoEnqReducer } from "../slice/SerialNoEnqSlice";
import { OperationSerialNoEnqReducer } from "../slice/OperationSerialNoEnqSlice";
import { GenerationStatuSliceReducer } from "../slice/GenerationStatusSlice";
import { BranchTypeReducer } from "../slice/BranchTypeSlice";
import { StockSummaryReducer } from "../slice/StockSummarySlice.";
import { StockAvailabilityReducer, StockBySerialNoReducer } from "../slice/StockAvailabilitySlice";
import { BranchReducer } from "../slice/BranchSlice";
import { AgentWalletReducer } from "../slice/AgentWalletReportSlice";
import { AgentFlexiReducer } from "../slice/AgentFlexiReportSlice";
import { AgentPaymentReducer } from "../slice/AgentPaymentStatementSlice";
import { RetailerPaymentReducer } from "../slice/RetailerPaymentStatementSlice";
import { CustomerReportReducer } from "../slice/CustomerReportSlice";
import { AgentAdjustmentReducer } from "../slice/AgentAdjustmentReportSlice";
import { RetailerAdjustmentReducer } from "../slice/RetailerAdjustmentReportSlice";
import { PromoDistributionReducer } from "../slice/PromoDistributionSlice";
import { PromoRunStatusReducer } from "../slice/PromoRunStatusSlice";
import { PromoConfigReducer } from "../slice/PromoConfigSlice";
import { PromoWinnerReducer } from "../slice/PromoWinnerSlice";
import { SystsemUserReducer } from "../slice/SystemUserSlice";
import { ProfileReducer } from "../slice/ProfileSlice";
import { UserReducer } from "../slice/UserSlice";
import { SerialSummaryReducer } from "../slice/SerialSummarySlice";
import { SerialConfigReducer } from "../slice/SerialConfigSlice";
import { PromoListReducer } from "../slice/PromoListSlice";
import { PromoReedeemReducer } from "../slice/PromoReedeemSlice";
import { InventorySplitReducer } from "../slice/InventorySplitSlice";
import { OperatorWalletBalanceReducer } from "../slice/OperatorWalletBalanceReportSlice";
import { RetailerFlexiReducer } from "../slice/RetailerFlexiReportSlice";
import { AllocationHistoryReducer } from "../slice/AllocationHistoryReportSlice";
import { StockAllocationReducer } from "../slice/StockAllocationSlice";


const rootReducer = combineReducers({
    OperatorReducer : OperatorReducer,
    ServiceProviderReducer : ServiceProviderReducer,
    CardVoucherAvailableStockReducer:CardVoucherAvailableStockReducer,
    FlexiReportpdfReducer:FlexiReportpdfReducer,
    VoucherReportpdfReducer:VoucherReportpdfReducer,
    CommonReducer:CommonReducer,
    RetailerWalletReducer:RetailerWalletReducer,
    VoucherStockReducer:VoucherStockReducer,
    VoucherBlockUnblockSliceReducer:VoucherBlockUnblockSliceReducer,
    VoucherBulkBlockUnblockSliceReducer:VoucherBulkBlockUnblockSliceReducer,
    NewVoucherRequestReducer:NewVoucherRequestReducer,
    VoucherRequestStatusReducer:VoucherRequestStatusReducer,
    CardVoucherAllStocksReducer:CardVoucherAllStocksReducer,
    CardVoucherNewRequestReducer:CardVoucherNewRequestReducer,
    CardVoucherRequestStatusReducer:CardVoucherRequestStatusReducer,
    VoucherGenerationReducer:VoucherGenerationReducer,
    CardVoucherGenerationReducer:CardVoucherGenerationReducer,
    SerialNoEnqReducer:SerialNoEnqReducer,
    OperationSerialNoEnqReducer:OperationSerialNoEnqReducer,
    GenerationStatuSliceReducer:GenerationStatuSliceReducer,
    BranchTypeReducer:BranchTypeReducer,
    StockSummaryReducer:StockSummaryReducer,
    StockAvailabilityReducer:StockAvailabilityReducer,
    BranchReducer:BranchReducer,
    AgentWalletReducer:AgentWalletReducer,
    AgentFlexiReducer:AgentFlexiReducer,
    AgentPaymentReducer:AgentPaymentReducer,
    RetailerPaymentReducer:RetailerPaymentReducer,
    CustomerReportReducer:CustomerReportReducer,
    AgentAdjustmentReducer:AgentAdjustmentReducer,
    RetailerAdjustmentReducer:RetailerAdjustmentReducer,
    VoucherReportpdfReducer:VoucherReportpdfReducer,
    PromoDistributionReducer : PromoDistributionReducer,
    PromoRunStatusReducer:PromoRunStatusReducer,
    PromoConfigReducer:PromoConfigReducer,
    PromoWinnerReducer:PromoWinnerReducer,
    SystsemUserReducer:SystsemUserReducer,
    ProfileReducer:ProfileReducer,
    UserReducer:UserReducer,
    SerialSummaryReducer:SerialSummaryReducer,
    SerialConfigReducer:SerialConfigReducer,
    PromoListReducer:PromoListReducer,
    PromoReedeemReducer:PromoReedeemReducer,
    InventorySplitReducer:InventorySplitReducer,
    OperatorWalletBalanceReducer:OperatorWalletBalanceReducer,
    RetailerFlexiReducer:RetailerFlexiReducer,
    AllocationHistoryReducer:AllocationHistoryReducer,
    StockAllocationReducer:StockAllocationReducer

})
export default rootReducer;