import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";



const initialState = {
    loader:false,
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    lists: [],
    allocationHistoryReports: [],
    lastAdded: {
        branch: "",
        branchDisplay: "",
        endSerialNo: "",
        startDate: currentDate,
        endDate: currentDate,
        startSerialNo: "",
        type:"branch"

    },
}

export const AllocationHistoryFetch = createAsyncThunk(
    "AllocationHistoryFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('allocationHistory/query', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const AllocationHistorySlice = createSlice({
    name: "AllocationHistoryReducer",
    initialState,

    reducers: {
        allocationHistoryLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        allocationHistoryLastAddedReset(state, action) {
            return {
                ...state,
                lastAdded: {
                    branch: "",
                    branchDisplay: "",
                    endSerialNo: "",
                    startDate: currentDate,
                    endDate: currentDate,
                    startSerialNo: "",
                    type:"branch"

                },
                allocationHistoryReports: []
            }
        },


    },
    extraReducers: (builder) => {
        builder.addCase(AllocationHistoryFetch.fulfilled, (state, action) => {
            return {
                ...state,
                allocationHistoryReports: action.payload,
                loader: false
            }
        })
            .addCase(AllocationHistoryFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(AllocationHistoryFetch.rejected, (state, action) => {
                return {
                    ...state,
                    loader: false
                }
            })
    }


})

export const { allocationHistoryLastAdded, allocationHistoryLastAddedReset } = AllocationHistorySlice.actions;
export const AllocationHistoryReducer = AllocationHistorySlice.reducer