import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    profiles: [],
    profilesCopy: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    profileAddAndEditModal : {
        open : false,
        mode:"add"
    },
    profileFormValidation:{
        profileDescription:"", profileCode:"" 
    }
    , lastAdded: { active: "Y", profileDescription:"", profileCode:"",saveButtonDisabled:true,profileCodeError:false,profileCodeHelperText : ""  },
}

export const profileFetch = createAsyncThunk(
    "ProfileReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/profiles');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const profileAdd = createAsyncThunk(
    "ProfileReducer/add", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/profiles/save/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const profileDelete = createAsyncThunk(
    "ProfileReducer/delete", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/profiles/delete/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const profileUpdate = createAsyncThunk(
    "ProfileReducer/update", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/profiles/update/', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("profileFetch", profileFetch)

const ProfileSlice = createSlice({
    name: "ProfileReducer",
    initialState,  

    reducers: {
        profileList(state, action) {
            // state.profiles = action.payload
            return {
                ...state,
                profiles: action.payload
            }
        },
        profileLastAdded(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
                
                
            }
        },
        
        snackbarClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                profileAddAndEditModal: { ...state.profileAddAndEditModal, ...action.payload  }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(profileFetch.fulfilled, (state, action) => {
            console.log("Action",action)
            return {
                ...state,
                profiles: action.payload
            }
        })
            .addCase(profileFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(profileFetch.rejected, (state, action) => {
                //console.log(action)
            })
            .addCase(profileAdd.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(profileAdd.rejected, (state, action) => {
              
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: action.payload.status,
                        }
                    }
                
            })
            .addCase(profileAdd.fulfilled, (state, action) => {
             
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: action.payload.status,
                        },
                        profileAddAndEditModal : {
                            open : false
                        },
                        profiles : action.payload.profiles
                    }
                
            })
            .addCase(profileUpdate.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(profileUpdate.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(profileUpdate.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:"Profile Update Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        profileAddAndEditModal : {
                            open : false
                        },
                        profiles : action.payload.profiles
                    }
                }
            })
            .addCase(profileDelete.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(profileDelete.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:"Profile Deleted Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                      profiles:action.payload.profiles
                       
                    }
                }
            })
    }
    // extraReducers :(builder)=> {
    //     builder.addCase("ProfileReducer/profileList", (state,action)=>{
    //         state.profilesCopy = action.payload
    //     })
    //     // profileList(state,action){
    //     //     // state.profiles = action.payload
    //     //      return {
    //     //          ...state,
    //     //          profilesCopy : action.payload
    //     //      }
    //     //  }
    // }
})

export const { profileList, profileLastAdded,snackbarClosed, popupOpenAndClosed, profileLastAddedValidate} = ProfileSlice.actions;
export const ProfileReducer = ProfileSlice.reducer