import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate, ModifiedDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    voucherReports: [],
    lastAdded: { reportType: "null", date: currentDate, dateModified: ModifiedDate(currentDate) },
    loader:false,
}

export const VoucherReportStockFetch = createAsyncThunk(
    "VoucherReportStockFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/flexiReport', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)




const VoucherReportpdfSlice = createSlice({
    name: "VoucherReportpdfReducer",
    initialState,

    reducers: {

        VoucherReportPdfLastAdded(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        VoucherReportPdfReset(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                voucherReports: [],
                lastAdded: { reportType: "null", date: currentDate, dateModified: ModifiedDate(currentDate) },
                loader : false
            }
        },
        handleLoader(state, action) {   
            console.log(action.payload)     
            return {
                ...state,
                loader: action.payload,
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(VoucherReportStockFetch.fulfilled, (state, action) => {
            return {
                ...state,
                voucherReports: action.payload,
                loader:false
            }
        })
            .addCase(VoucherReportStockFetch.pending, (state, action) => {
                return {
                    ...state,                   
                    loader : true,
                }
            })
            .addCase(VoucherReportStockFetch.rejected, (state, action) => {
                return {
                    ...state,                   
                    loader : false,
                }
            })
    }

})

export const { VoucherReportPdfLastAdded, VoucherReportPdfReset,handleLoader } = VoucherReportpdfSlice.actions;
export const VoucherReportpdfReducer = VoucherReportpdfSlice.reducer