import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    promoDistributions: [],
    loader: false,
    promoDistributionsCopy: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    promoDistributionAddAndEditModal: {
        open: false,
        mode: "add"
    },
    promoDistributionFormValidation: {
        promoDistributionName: "",
    }
    , lastAdded: { active: "Y", promoDistributionName: "", saveButtonDisabled: true, },
}

export const promoDistributionFetch = createAsyncThunk(
    "promoDistributionReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/promoDistributions');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const promoDistributionAdd = createAsyncThunk(
    "promoDistributionReducer/add", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/promoDistributions/save', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const promoDistributionDelete = createAsyncThunk(
    "promoDistributionReducer/delete", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/promoDistributions/delete', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const promoDistributionUpdate = createAsyncThunk(
    "promoDistributionReducer/update", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/promoDistributions/update', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("promoDistributionFetch", promoDistributionFetch)

const PromoDistributionSlice = createSlice({
    name: "promoDistributionReducer",
    initialState,

    reducers: {
        promoDistributionList(state, action) {
            // state.promoDistributions = action.payload
            return {
                ...state,
                promoDistributions: action.payload
            }
        },
        promoDistributionLastAdded(state, action) {
            //       console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },


            }
        },

        snackbarClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                promoDistributionAddAndEditModal: { ...state.promoDistributionAddAndEditModal, ...action.payload }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(promoDistributionFetch.fulfilled, (state, action) => {
            return {
                ...state,
                promoDistributions: action.payload,
                loader: false,
            }
        })
            .addCase(promoDistributionFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true,
                }
            })
            .addCase(promoDistributionFetch.rejected, (state, action) => {
                return {
                    ...state,
                    loader: false,
                }
            })
            .addCase(promoDistributionAdd.pending, (state, action) => {
                return {
                    ...state,
                    loader: true,
                }
            })
            .addCase(promoDistributionAdd.rejected, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        moed: action.payload.status,
                    },
                }

            })
            .addCase(promoDistributionAdd.fulfilled, (state, action) => {

                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        moed: action.payload.status,
                    },
                    promoDistributions: action.payload.promoDistributions.length <= 0 ? state.promoDistributions : action.payload.promoDistributions,

                    promoDistributionAddAndEditModal: {
                        open: false
                    },


                }
            })
            .addCase(promoDistributionUpdate.pending, (state, action) => {
                return {
                    ...state,
                    loader: true,
                }
            })
            .addCase(promoDistributionUpdate.rejected, (state, action) => {

                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        moed: action.payload.status,
                    },


                }

            })
            .addCase(promoDistributionUpdate.fulfilled, (state, action) => {

                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    },
                    promoDistributionAddAndEditModal: {
                        open: false
                    },
                    promoDistributions: action.payload.promoDistributions.length <= 0 ? state.promoDistributions : action.payload.promoDistributions
                }

            })
            .addCase(promoDistributionDelete.pending, (state, action) => {
                return {
                    ...state,
                    loader: true,
                }
            })
            .addCase(promoDistributionDelete.fulfilled, (state, action) => {

                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        moed: action.payload.status,
                    },
                    promoDistributions: action.payload.promoDistributions.length <= 0 ? state.promoDistributions : action.payload.promoDistributions

                }

            })
    }

})

export const { promoDistributionList, promoDistributionLastAdded, snackbarClosed, popupOpenAndClosed, promoDistributionLastAddedValidate } = PromoDistributionSlice.actions;
export const PromoDistributionReducer = PromoDistributionSlice.reducer