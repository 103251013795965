import React, { useState } from 'react';
import Input from '../../components/input';
import SelectDropdown from "../../components/Select";
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { AppBar, Box, Dialog, Grid, IconButton, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { promoListAdd, promoListLastAdded, promoListUpdate, popupOpenAndClosed, PromoListUpdate, promoListLastAddedReset } from "../../redux/slice/PromoListSlice";
import { THEME_COLOR } from '../../constfiles/theme';
import { Clear } from '@mui/icons-material';
// import { PROFILE_CODE_IS_REQUIRED, PROFILE_CODE_MINIMUM_3_CHACRTERS, PROFILE_NAME_IS_REQUIRED } from '../../../constfiles/formValidation';

export default function PromoListCreateAndEdit({ createModalOpen, popupMode, lastAdded }) {
   // console.log("Last added", lastAdded)
    const [errorObj, setErrorObj] = useState({})
    const dispatch = useDispatch()
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        borderRadius: "4px",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const trStyle = {
        paddingBottom: "2%"
    }
    // const handleSubmit = () => {
    //   //put your validation logic here
    //   onSubmit(values);
    //   onClose();
    // };

    function validation() {
        const errorObj = {}
        const { description, active, startDate, endDate } = lastAdded;
       // console.log("lastAdded", lastAdded)
        if (description) {
            // alert("S")
            if (description?.trim() === "") {
                //     alert("d")
                //  dispatch(promoListLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
                Object.assign(errorObj, { description: "Description is Required" })

            }
            else {
                // alert("dw")
                Object.assign(errorObj, { description: "" })
                if (description?.length < 4) {
                    Object.assign(errorObj, { description: "Description length should be more than 4 in length" })
                    //  dispatch(promoListLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_MINIMUM_3_CHACRTERS  }))
                }
                else {
                    //  alert(description?.length)
                    Object.assign(errorObj, { description: "" })
                    // dispatch(promoListLastAdded())
                }
            }

        }
        else {
            Object.assign(errorObj, { description: "Description is Required" })
        }
        if (active) {
            if (active?.length <= 0) {
                Object.assign(errorObj, { active: "Active is Required" })
                //  dispatch(handleValidation({ active: THEME_COLOR Required" }))
            }
            else if ((active !== "Y") && (active !== "N")) {
                Object.assign(errorObj, { active: "Active is Required" })
            }
            else {
                Object.assign(errorObj, { active: "" })
            }
        }
        else {
            Object.assign(errorObj, { active: "Active is Required" })
        }
        ////console.log("errorObj",errorObj)

        if (startDate) {
            if (endDate) {
                if (endDate.length <= 0) {
                    Object.assign(errorObj, { endDate: "End Date is Required" })

                }
                else if (startDate > endDate) {
                    Object.assign(errorObj, { endDate: "End date should not behind start date" })
                    //dispatch(handleValidation({ endDate: "End date should not behind start date" }))
                }
                else {
                    Object.assign(errorObj, { endDate: "" })
                    //dispatch(handleValidation({ endDate: "" }))
                }
            }
            else {
                Object.assign(errorObj, { endDate: "End Date is Required" })
            }
        }
        setErrorObj(errorObj)
        const checking = Object.values(errorObj).every((item) => item.trim() === "")
        ////console.log(" Object.values(errorObj)", Object.values(errorObj))
       // console.log("checking", checking)
       // console.log("errorObj", errorObj)
        return checking
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // if(validation()) {

        dispatch(promoListLastAdded({ [name]: value, [name + "Touched"]: true }))
        // dispatch(promoListLastAddedValidate({[name]: value }))
        //}
    }
    const buttonUpdate = () => {
        // alert("i")
        if (validation()) {
            dispatch(PromoListUpdate(lastAdded))
        }
    }
    const handleClose = () => {
        dispatch(popupOpenAndClosed({ open: false }))
        dispatch(promoListLastAddedReset())
        setErrorObj({})
    }
    const buttonSave = () => {


        if (validation()) {
            dispatch(promoListAdd(lastAdded))
        }
        //alert(check)
        //  dispatch(promoListAdd(lastAdded))

        //////console.log("checkingFlag",checkingFlag)
        //alert(checkingFlag)
        // else {
        //   dispatch(promoListAdd(lastAdded))
        // }
    }
    return (
        <>

            <Dialog
                fullScreen
                open={createModalOpen}
                onClose={() => handleClose()}
                aria-labelledby="promoList-title"
                aria-describedby="promoList-description"
            >
                <AppBar sx={{ position: 'relative' }} style={{ background: THEME_COLOR }} >
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>


                        <Typography variant="h6" component="div">
                            Promo List Operator  {popupMode === "add" ? "Create" : "Edit - "}{lastAdded?.description}

                        </Typography>
                        <Typography variant="h6" component="div"> <IconButton style={{ color: "white" }} onClick={() => dispatch(popupOpenAndClosed({ open: false }))()}> <Clear /></IconButton></Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={style}>
                    {/* <h3>{popupMode === "add" ? "PromoList Create": "PromoList Edit - "+`${lastAdded?.promoListDescription}` }</h3> */}
                    <>
                        <table style={{ width: "100%" }}>
                            <tr >
                                <td style={trStyle}>
                                    <TextField autoFocus value={lastAdded?.description} error={errorObj?.description} helperText={errorObj?.description} onChange={handleInputChange} name="description" label="Description" fullWidth={true} /></td>
                                <td style={trStyle}>  <SelectDropdown
                                    error={lastAdded.active} value={lastAdded.active} onChange={handleInputChange} name="active" label="Active" fullWidth={true} /></td>
                            </tr>


                            <tr >
                                <td> <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="StartDate"
                                    label="Start Date"
                                    name="startDate"
                                    autoComplete="off"
                                    //  disabled={fieldEnable}
                                    // readOnly={fieldEnable}
                                    error={errorObj.startDate}
                                    helperText={errorObj.startDate}
                                    value={lastAdded.startDate}
                                    type="date"
                                    onChange={handleInputChange} /></td>

                                <td   >
                                    <TextField
                                        margin="normal"
                                        required
                                        style={{ verticalAlign: "sub" }}
                                        fullWidth
                                        id="EndDate"
                                        label="End Date"
                                        name="endDate"
                                        // disabled={fieldEnable}
                                        // readOnly={fieldEnable}
                                        autoComplete="off"
                                        error={errorObj.endDate}
                                        helperText={errorObj.endDate}
                                        value={lastAdded.endDate}
                                        type="date"
                                        onChange={handleInputChange} /></td>
                            </tr>
                        </table>
                        <Grid>
                            <Grid item lg="12" md={12} xs="12" sx={{
                                textAlign: "center", display: "flex", columnGap: "2%", justifyContent: "center"
                            }} sm={12}>
                                {popupMode === "add" ? <ButtonComponent onClick={buttonSave} label="Save" name="promoListAddButton"
                                    color="primary"
                                    disabled={(lastAdded?.promoListCode?.length && lastAdded?.promoListDescription.length) === 0 ? true : false}
                                    type="add"
                                    variant="contained" /> : <ButtonComponent onClick={buttonUpdate} label="Update" name="promoListUpdateButton"
                                        color="primary"
                                        disabled={(lastAdded?.promoListCode?.length && lastAdded?.promoListDescription.length) === 0 ? true : false}
                                        type="add"
                                        variant="contained" />}
                                <ButtonComponent label="Close" name="close"
                                    color="primary"
                                    disabled={false}
                                    onClick={() => handleClose()}
                                    type="close"
                                    variant="contained" />




                            </Grid>
                        </Grid>

                    </>
                </Box>
            </Dialog>
        </>
    );
};
