import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate, ModifiedDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    loader:false,
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    customerReports:[],
    lastAdded: { reportType:null,label:"Select Report", date:currentDate,dateModified:ModifiedDate(currentDate), queryButtonDisabled: true, reportTypeError: false, reportTypeHelperText: "" },
}

export const customerReportFetch = createAsyncThunk(
    "customerReportFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('customerReport/query',arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const CustomerReportSlice = createSlice({
    name: "CustomerReportReducer",
    initialState,

    reducers: {      
        customerReportLastAdded(state, action) {        
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        handleLoader(state, action) {   
            console.log(action.payload)     
            return {
                ...state,
                loader: action.payload,
            }
        },
        customerReportLastAddedReset(state, action) {        
            return {
                ...state,
                lastAdded: {
                    reportType:null, 
                    label:"Select Report",
                    date:currentDate,
                    dateModified:ModifiedDate(currentDate), 
                    queryButtonDisabled: true,
                     reportTypeError: false, 
                     reportTypeHelperText: "" 
                },
                customerReports : []
            }
        },


    },
    extraReducers: (builder) => {
        builder.addCase(customerReportFetch.fulfilled, (state, action) => {
            return {
                ...state,
                customerReports: action.payload,
                loader : false
            }
        })
            .addCase(customerReportFetch.pending, (state, action) => {
                return {
                    ...state,                  
                    loader : true
                }
            })
            .addCase(customerReportFetch.rejected, (state, action) => {
                return {
                    ...state,                  
                    loader : false
                }
            })
        }


})

 export const { customerReportLastAdded,handleLoader,customerReportLastAddedReset } = CustomerReportSlice.actions;
export const CustomerReportReducer = CustomerReportSlice.reducer