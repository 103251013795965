import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    promoLists: [],
    loader: false,
    promoListsCopy: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    promoListAddAndEditModal : {
        open : false,
        mode:"add"
    },
    promoListFormValidation:{
       // promoListDescription:"", promoListCode:"" 
    }
    , lastAdded: { description : "", active : "", startDate : "", endDate : "",active: "Y", saveButtonDisabled:true,promoListCodeError:false,},
}

export const promoListFetch = createAsyncThunk(
    "PromoListReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/promoList/');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const PromoListEdit = createAsyncThunk(
    "PromoListReducer/PromoListEdit", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/promoList/edit/',arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const promoListAdd = createAsyncThunk(
    "PromoListReducer/add", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/promoList/save/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
// export const promoListDelete = createAsyncThunk(
//     "PromoListReducer/delete", async (arg, thunkAPI) => {
//         try {
//             //console.log("arg", arg)
           
//             const response = await axios.post('/promoList/', arg);
//             //console.log("responseadd", response.data)
//             return response.data
//         }
//         catch (error) {
//             //console.log("error", error.response)
//             return error.response.status
//         }
//     }
// )
export const PromoListUpdate = createAsyncThunk(
    "PromoListReducer/update", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/promoList/update/', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("promoListFetch", promoListFetch)

const PromoListSlice = createSlice({
    name: "PromoListReducer",
    initialState,  

    reducers: {
        promoListList(state, action) {
            // state.promoLists = action.payload
            return {
                ...state,
                promoLists: action.payload
            }
        },
        promoListLastAdded(state, action) {
            console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
                
                
            }
        },
        promoListLastAddedReset(state, action) {
            console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: {},
                
                
            }
        },
        snackbarClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                promoListAddAndEditModal: { ...state.promoListAddAndEditModal, ...action.payload  }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(promoListFetch.fulfilled, (state, action) => {
            console.log("Action",action)
            return {
                ...state,
                promoLists: action.payload,
                loader: false
            }
        })
            .addCase(promoListFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(promoListFetch.rejected, (state, action) => {
                //console.log(action)
            }).addCase(PromoListEdit.fulfilled, (state, action) => {
                console.log("Action",action)
                return {
                    ...state,
                    lastAdded: {...state.lastAdded,...action.payload},
                    loader: false
                }
            })
                
            .addCase(PromoListEdit.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
                //console.log(action)
            }).addCase(PromoListEdit.rejected, (state, action) => {
                return {
                    ...state,
                    loader: false
                }
                //console.log(action)
            })
            .addCase(promoListAdd.pending, (state, action) => {
                return {
                    ...state,
                    loader: false
                }
                //console.log(action)
            })
            .addCase(promoListAdd.rejected, (state, action) => {
              
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: action.payload.status,
                        }
                    }
                
            })
            .addCase(promoListAdd.fulfilled, (state, action) => {
             
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: action.payload.status,
                        },
                        promoListAddAndEditModal : {
                            open : false
                        },
                        promoLists : action.payload.promoLists
                    }
                
            })
            .addCase(PromoListUpdate.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(PromoListUpdate.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                        ,
                        loader: false
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        } ,
                        loader: false
                    }
                }
            })
            .addCase(PromoListUpdate.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                // if (action.payload.toString() === "500") {
                //     return {
                //         ...state,
                //         sucessMessage: {
                //             message:"PromoList Update Failed" ,
                //             snackbarOpen: true,
                //             mode: "warning"
                //         }
                //     }
                // }
                // else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        promoListAddAndEditModal : {
                            open : false
                        },
                        promoLists : action.payload.promoLists ,
                        
                        loader: false
                    }
              //  }
            })
            // .addCase(promoListDelete.pending, (state, action) => {
            //     //console.log(action)
            // })
            // .addCase(promoListDelete.fulfilled, (state, action) => {
            //     //console.log("action fulfilled fulfilled==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message:"PromoList Deleted Failed" ,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true
            //             },
            //           promoLists:action.payload.promoLists
                       
            //         }
            //     }
            //})
    }
    // extraReducers :(builder)=> {
    //     builder.addCase("PromoListReducer/promoListList", (state,action)=>{
    //         state.promoListsCopy = action.payload
    //     })
    //     // promoListList(state,action){
    //     //     // state.promoLists = action.payload
    //     //      return {
    //     //          ...state,
    //     //          promoListsCopy : action.payload
    //     //      }
    //     //  }
    // }
})

export const { promoListList, promoListLastAdded,snackbarClosed,promoListLastAddedReset, popupOpenAndClosed, promoListLastAddedValidate} = PromoListSlice.actions;
export const PromoListReducer = PromoListSlice.reducer