import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";



const initialState = {
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    lists: [],
    loader:false,
    agentFlexiReports: [],
    lastAdded: {
        agentCode: "ALL",
        agentCodeDisplay: "ALL",
        "startDate": currentDate,
        "endDate": currentDate,
        queryButtonDisabled: true,
        agentCodeError: false,
        agentCodeHelperText: ""
    },
}

export const AgentFlexiFetch = createAsyncThunk(
    "AgentFlexiFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('agentFlexiReport/query', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const AgentFlexiSlice = createSlice({
    name: "AgentFlexiReducer",
    initialState,

    reducers: {
        agentFlexiLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        handleLoader(state, action) {   
            console.log(action.payload)     
            return {
                ...state,
                loader: action.payload,
            }
        },
        handleSnackBar(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        agentFlexiLastAddedReset(state, action) {
            return {
                ...state,
                lastAdded: {
                    agentCode: "ALL",
                    agentCodeDisplay: "ALL",
                    "startDate": currentDate,
                    "endDate": currentDate,
                    queryButtonDisabled: true,
                    agentCodeError: false,
                    agentCodeHelperText: ""
                },
                agentFlexiReports: [],
                loader:false
            }
        },


    },
    extraReducers: (builder) => {
        builder.addCase(AgentFlexiFetch.fulfilled, (state, action) => {
            return {
                ...state,
                agentFlexiReports: action.payload
            }
        })
            .addCase(AgentFlexiFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(AgentFlexiFetch.rejected, (state, action) => {
                //console.log(action)
            })
    }


})

export const { agentFlexiLastAdded, agentFlexiLastAddedReset,handleLoader,handleSnackBar } = AgentFlexiSlice.actions;
export const AgentFlexiReducer = AgentFlexiSlice.reducer