import { Paper } from "@mui/material";

export default function SerialEnquiryResultTable2(props) {
    const { list, source } = props;
   // console.log("source", source)
   // console.log("list", list)
    return (
        <>
            <Paper style={{ paddingTop: "2%", marginTop: "1%" }}>
                <table id="serialEnquieryTable" style={{ width: "100%", border: "1px solid black" }}>
                    <tr>
                        <td>Service Provider</td>
                        <td>{source === "Main" ? list?.VoucherInfoBelongProviders?.sp_name : list?.sp_name}</td>
                        <td>Operator</td>
                        <td>{source === "Main" ? list?.operators?.opname : list?.operator_code}</td>
                    </tr>
                    <tr>
                        <td>Denomination</td>
                        <td>{list?.denomination}</td>
                        <td>Voucher Type</td>
                        <td>{list?.voucherType}</td>
                    </tr>
                    <tr>
                        <td>Id</td>
                        <td>{list?.id}</td>
                        <td>Batch Number</td>
                        <td>{list?.batchNumber}</td>
                    </tr>
                    <tr>
                        <td>Chain Id</td>
                        <td>{list?.chainId}</td>
                        <td>Dealer Code</td>
                        <td>{list?.dealerCode}</td>
                    </tr>
                    <tr>
                        <td>Expiry  Date</td>
                        <td>{list?.expiryDate}</td>
                        <td>Voucher Request Id</td>
                        <td>{list?.voucherRequestId}</td>
                    </tr>
                    <tr>
                        <td>From  MSISDN</td>
                        <td>{list?.fromMsisdn}</td>
                        <td>To MSISDN</td>
                        <td>{list?.toMsisdn}</td>
                    </tr>
                    <tr>
                        <td >Status</td>
                        <td style={{ color: "blue" }}>{list?.status ? list?.status?.toString() === "1" ? "Available" : list?.status?.toString() === "2" ? "Used" : list?.status?.toString() === "3" ? "Expired" : list?.status?.toString() === "4" ? 'Blocked' : list?.status?.toString() === "5" ? "Suspend" : list?.status?.toString() === "6" ? "Reserved" : list?.status?.toString() === "7" ? "Staged" : "Other" : "-"}</td>
                        <td>Serial No</td>
                        <td>{list?.serialNo}</td>
                    </tr>
                    <tr>
                        <td>Transaction Id</td>
                        <td>{list?.tid}</td>
                        <td>Transaction Time</td>
                        <td>{list?.ttime}</td>
                    </tr>
                    <tr>
                        <td>CV Transaction Id</td>
                        <td>{list?.ctid}</td>
                        <td>Selected</td>
                        <td>{list?.selected}</td>
                    </tr>
                    <tr>
                        <td>Create Time</td>
                        <td>{list?.createTime}</td>
                        <td>Update Time</td>
                        <td>{list?.updateTime}</td>
                    </tr>
                </table>
            </Paper>
            <p>
                Note: Result is from {source === "Main" ? "Main" : "Archive"} Table
            </p>
        </>
    )
}