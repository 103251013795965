import React, {  useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {  cardVoucherAvailableStockFetch,  } from '../../redux/slice/CardVoucherAvailableStock';
import CircularStatic from '../../components/spinner';


const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent : "end",
      flexDirection:"row-reverse"
    }
  }
});
const CardVoucherAvailableStock = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state=>state.CardVoucherAvailableStockReducer);
  const tableData = selectValuesRe?.cardVoucherAvailableStocks;
 const loader = selectValuesRe?.loader;
  useEffect(()=>{   
    dispatch(cardVoucherAvailableStockFetch())

},[])


  const columns = useMemo(
    () => [
      {
        accessorKey: 'coperators.date',
        header: 'Date',
        enableColumnOrdering: false,      
        enableSorting: false,      
      },
      {
        accessorKey: 'coperators.operatorCode',
        header: 'Operator',      
      },
      {
        accessorKey: 'vocuherValue',
        header: 'Denomination',       
      },      
      {
        accessorKey: 'status',
        header: 'Status',    
        Cell: ({ cell }) => (
          //  if (b.status=1,'Available', if(b.status=2,'Used',if(b.status=3,'Expired',if(b.status=4,'Blocked',if(b.status=5,'Suspend', if(b.status=6,'Reserved',if(b.status=7,'Staged','Other')))))))
            cell.getValue()?.toString()=== "1" ? "Available" : cell.getValue()?.toString() === "2" ? "Used" : cell.getValue()?.toString() === "3" ? "Expired" : cell.getValue()?.toString() === "4" ? 'Blocked' : cell.getValue()?.toString() === "5" ? "Suspend" :  cell.getValue()?.toString() === "6" ? "Reserved" :  cell.getValue()?.toString() === "7" ? "Staged" : "Other"
        )
      },
      {
        accessorKey: 'count',
        header: 'Count',
      }
    
    ],
    [],
  );


  return (
    < div className={classes.root }>
      {loader===true ? <CircularStatic /> : null }
      <MaterialReactTable
       state={{
        isLoading: loader,
        showProgressBars: loader,
        showSkeletons: loader
    }}
    initialState={{
        density: "compact",
        isLoading: loader,
        showProgressBars: loader,
        showSkeletons: loader
    }}
     muiTablePaginationProps={{
      //rowsPerPage : 5
     }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}
        
        muiTableBodyRowProps={{
          sx:{
            padding:"0px"
          }
        }}
       muiTableContainerProps={{
        sx:{
          maxHeight:"400px",
          minHeight:"400PX"
        }
       }}    
        enableColumnOrdering      
      />

    <div>
      
    </div>
  
     
    </div>
  );
};

//example of creating a mui dialog modal for creating new rows



export default CardVoucherAvailableStock;
