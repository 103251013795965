import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    //marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },

  },
}));

export default function CustomizedMenus(props) {
  const { name, childs, path } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const buttonStyle = {
    width: "100%",
    textAlign: "left",
    background: "transparent",
    justifyContent: "space-between",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
  }
  const anchorTagStyle = {
    textDecoration: "none",
    color: "black"
  }
  return (
    <div style={{ margin: "2%" }}>
      {childs.length > 0 ? <> <Button
        id="ussd-customized-button"
        aria-controls={open ? 'ussd-customized-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        sx={buttonStyle}
        onClick={childs.length > 0 ? handleClick : null}
        endIcon={childs.length > 0 ? <KeyboardArrowDownIcon /> : null}
      >
        {name}
      </Button> </> : <Link style={anchorTagStyle} to={path}> <Button
        id="ussd-customized-button"
        aria-controls={open ? 'ussd-customized-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        sx={buttonStyle}
        onClick={childs.length > 0 ? handleClick : null}
        endIcon={childs.length > 0 ? <KeyboardArrowDownIcon /> : null}
      >
        {name}
      </Button> </Link>}
      <StyledMenu
        id={name + "ussd-customized-menu"}
        MenuListProps={{
          'aria-labelledby': `${name}` + "ussd-customized-menu",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem> */}
        {childs.map((item, index) => {
          return (
            <Link style={anchorTagStyle} to={item.path}>
              <MenuItem key={index} onClick={handleClose} disableRipple>
                {/* <MoreHorizIcon /> */}
                {item.name}
              </MenuItem>
            </Link>
          )
        })}
      </StyledMenu>
    </div>
  );
}
