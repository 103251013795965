import { Autocomplete, Button, Grid, TextField, debounce } from "@mui/material"
import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import AgentCommonComponent from "./AgentCommonComponent"
import MaterialReactTable from 'material-react-table';
import { throttle } from "lodash"
import jsPDF from "jspdf";
import { currentDateWithTime, daysDiffernece } from '../../utlis/CurrentDateGenerator';
import AgentCommonWithDateComponent from "./AgentCommonWithDateComponent"
import { AgentPaymentFetch, agentPaymentLastAddedReset, handleLoader, handleSnackBar } from "../../redux/slice/AgentPaymentStatementSlice"
import { agentFlexiLastAdded } from "../../redux/slice/AgentFlexiReportSlice"
import { agentPaymentLastAdded } from "../../redux/slice/AgentPaymentStatementSlice"
import SnackBarComponent from "../../components/snackbar"
export default function AgentPaymentStatementReport() {
    const selectValuesRe = useSelector(state => state.AgentPaymentReducer);
    useEffect(() => {
        dispatch(agentPaymentLastAddedReset())
        return () => {
            dispatch(agentPaymentLastAddedReset())
        }
    }, [])
    const lastAdded = selectValuesRe.lastAdded;
    console.log("lastAdded", lastAdded)
    const tableData = selectValuesRe.agentPaymentReports;
    const dispatch = useDispatch();
    const loader = selectValuesRe?.loader;
    const snackbarValues = selectValuesRe.sucessMessage;

    const handleInputDateChange = (e) => {
        const { name, value } = e.target;
        //  alert("e.target.value"+value)
        //   console.log("agentCode2",e.tar)
        dispatch(agentPaymentLastAdded({ [name]: value }))
    }

    const handlePdf = (value) => {
        const tableColumns = ["Pay.Date", "Pay.Type", "Bank", "Ref.No", "Pay.Amount", "App.Amount", "Pay.Status", "Update Time"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.paymentDate)
                secondArray.push(element?.paymentType)
                secondArray.push(element?.bankAccounts?.accountName)
                // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.refNo)
                secondArray.push(element?.payAmount)
                secondArray.push(element?.payApprovedAmount)
                secondArray.push(element?.paymentStatus)
                secondArray.push(element?.updateTime)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, {
            startY: 30, startX: 0, autoSize: true, styles: {
                overflow: 'linebreak',
                fontSize: 8
            },
            showHeader: 'everyPage',
        });

        doc.text("Agent Payment Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage("../../kadick_b2c_logo.png", 15, 10, 30, 20);
        if (value === 1) {
            doc.save("agent_payment_report.pdf");
        }
        else {
            doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
            window.open(doc.output('bloburl'), '_blank');
        }

    }
    const columns = useMemo(
        () => [


            {
                accessorKey: 'paymentDate',
                header: 'Pay.Date',
            },
            {
                accessorKey: 'paymentType',
                header: 'Payment Type',
            },
            {
                accessorKey: 'bankAccounts.accountName',
                header: 'Bank',
            },
            {
                accessorKey: 'refNo',
                header: 'Ref.No',
            },
            {
                accessorKey: 'payAmount',
                header: 'Payment Amount',
            },

            {
                accessorKey: 'payApprovedAmount',
                header: 'Approved Amount',
            },
            {
                accessorKey: 'paymentStatus',
                header: 'Payment Status',
            },


            {
                accessorKey: 'updateTime',
                header: 'Update Time',
            },


        ],
        [],
    );
    const handleQuery = () => {
        //dispatch(handleLoader(true))
        var findDateBetween = daysDiffernece(lastAdded.startDate, lastAdded.endDate)
        if (findDateBetween < 7) {
            dispatch(AgentPaymentFetch({ agentCode: lastAdded.agentCode, startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
            dispatch(handleLoader(false))
            dispatch(handleSnackBar({ snackbarOpen: true, message: "Reports Showing for " + lastAdded.agentCode + " date between " + lastAdded.startDate + " to " + lastAdded.endDate, mode: "success" }))
        }
        else {
            dispatch(handleSnackBar({ snackbarOpen: true, message: "Date between can't exceed more than 7 days", mode: "error" }))
        }
    }

    const handleReset = () => {
        dispatch(agentPaymentLastAddedReset())
    }
    const handleInputChange = (agentValues, e) => {
      //  console.log("agentCode", agentValues)
        //   console.log("agentCode2",e.tar)
        dispatch(agentPaymentLastAdded({ agentCode: agentValues.agent_code, agentCodeDisplay: agentValues.agent_name }))
    }

    return (
        <>


            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                renderTopToolbarCustomActions={() => (
                    <AgentCommonWithDateComponent
                        selectValuesRe={selectValuesRe}
                        handleQuery={handleQuery}
                        agentCodeChange={handleInputChange}
                        lastAdded={lastAdded}
                        handlePrint={() => { handlePdf(2) }}
                        handlePdf={() => { handlePdf(1) }}
                        handleInputDateChange={handleInputDateChange}
                        handleReset={handleReset}
                    />
                )}

                enableColumnOrdering

            />
            <SnackBarComponent snackbarClosed={() => { dispatch(handleSnackBar({ snackbarOpen: false, message: "", mode: "" })) }} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </>
    )
}