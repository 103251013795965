import axios from "axios";
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { currentDateWithTime } from "./CurrentDateGenerator";
require('jspdf-autotable');
export async function getTicketsPdf(data, url) {
    //alert(fileName)
    console.log(data)
    return axios.post(url, { "fileName": data.fileName,"reportDate":data.reportDate }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'arraybuffer'
    })
  }

 export async function printTickets(dataInput,url) {
    //alert(fileName)
    // const { data } = await getTicketsPdf(fileName,'flexiReport/download')
    console.log(dataInput)
    const { data } = await getTicketsPdf(dataInput,url)
    const blob = new Blob([data], { type: 'application/pdf' })
    saveAs(blob, dataInput.fileName+dataInput.reportDate+".pdf")
   
  }


  export const handleDownloadForFileName = (value,title,name,tableData) => {
    const tableColumns = ["File Name"];
    const doc = new jsPDF();
    const firstArray = [];
    var secondArray = [];
    tableData.forEach(element => {
      secondArray = [];
      for (const x in element) {
        secondArray.push(element[x])
      }
      firstArray.push(secondArray)
    });
    doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });
    doc.text(title, 75, 25);
    doc.text(currentDateWithTime, 145, 25);
    doc.addImage("../../kadick_b2c_logo.png", 15, 10, 30, 20);
    if (value === 1) {
      doc.save(name);
    }
    else {
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    }

  }