import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Box, Button,  IconButton,  Tooltip,} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import BranchTypeAddandEdit from './BranchTypeAddAndEdit';
import { useDispatch, useSelector } from 'react-redux';
import SnackBar from '../../components/snackbar';
import { branchTypeDelete, branchTypeFetch,branchTypeLastAdded,popupOpenAndClosed } from '../../redux/slice/BranchTypeSlice';



const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent : "end",
      flexDirection:"row-reverse"
    }
  }
});
const BranchType = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state=>state.BranchTypeReducer);
  console.log("selectValuesRe",selectValuesRe)
  const lastAdded = selectValuesRe.lastAdded;
  const loader = selectValuesRe?.loader;
  const snackbarValues = selectValuesRe.sucessMessage;
    console.log("snackbarValues",snackbarValues)
  const branchTypeAddAndEditModal = selectValuesRe.branchTypeAddAndEditModal;
  // const [createModalOpen, setCreateModalOpen] = useState(false);
  ///const [tableData, setTableData] = useState(selectValuesRe.branchTypeReducer.branchTypes);
  const [validationErrors, setValidationErrors] = useState({});
  // const [tableData, setTableData] = useState(selectValuesRe.branchTypeReducer.branchTypes);
  const tableData = selectValuesRe.branchTypes;
  

  useEffect(()=>{
  //  alert("d")
    dispatch(branchTypeFetch())
 

},[])




  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Branch Type',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
      //  size: 80,
      },
     
     
     
      
      {
        accessorKey: 'active',
        header: 'Active',
        Cell: ({ cell }) => (
          cell.getValue() === "Y"?"Yes" : "No"
          )
      },
 
    ],
    [],
  );
  
  const handleDelete = (row) => {
   // const findElement = tableData.find(element=>element.id === row.original.id)
    //console.log("tableData",findElement)
    dispatch(branchTypeDelete({branchTypeId:row.original.id}))

  }
  const openMod = () => {
   // alert("s")
    dispatch(popupOpenAndClosed ({open:true,mode:"add",active:"Y"}));
    var resetObj = {};
    for(const x in lastAdded) {
      //console.log("x",x)
      if(x !== "active") {
       Object.assign(resetObj,{[x]:""})
      }
    }
    //console.log("resetObj",resetObj)
    dispatch(branchTypeLastAdded(resetObj, {active:"Y"}))
  }
  const handleEditPopUp = (row) =>{
    //console.log("row",row)
    const findElement = tableData.find(element=>element.id === row.original.id)
   //console.log("tableData",findElement)
    dispatch(popupOpenAndClosed ({open:true,mode:"edit"}))
    dispatch(branchTypeLastAdded({branchTypeName:findElement.name,branchTypeId:findElement.id,active:findElement.active}))
  }
  return (
    < div className={classes.root }>
        {/* <h3 style={{textAlign:"center"}}>Branch Type</h3> */}
      <MaterialReactTable
     muiTablePaginationProps={{
      //rowsPerPage : 5
     }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}
        
        muiTableBodyRowProps={{
          sx:{
            padding:"0px"
          }
        }}
       muiTableContainerProps={{
        sx:{
          maxHeight:"400px",
          minHeight:"400PX"
        }
       }}
       state={{
        isLoading: loader,
        showProgressBars: loader,
        showSkeletons: loader
    }}
    initialState={{
        density: "compact",
        isLoading: loader,
        showProgressBars: loader,
        showSkeletons: loader
    }}
        positionActionsColumn = "last"
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Edit">
              <IconButton onClick={()=>handleEditPopUp(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton  onClick={()=>handleDelete(row)} color="error" >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
          <Button
            color="primary"
            onClick={() => {openMod() }}
            variant="contained"
          >
            Create Branch Type
          </Button></>
        )}
      />
   
        <BranchTypeAddandEdit lastAdded={lastAdded} popupMode={branchTypeAddAndEditModal.mode} createModalOpen={branchTypeAddAndEditModal.open} />


<SnackBar dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


    <div>
      
    </div>
  
     
    </div>
  );
};

//example of creating a mui dialog modal for creating new rows



export default BranchType;
