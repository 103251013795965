import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    cardVoucherAvailableStocks: [],
    loader : false,
   
}

export const cardVoucherAvailableStockFetch = createAsyncThunk(
    "CardVoucherAvailableStock/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/cardVoucher');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


//console.log("cardVoucherAvailableStock", cardVoucherAvailableStockFetch)

const CardVoucherAvailableStockSlice = createSlice({
    name: "CardVoucherAvailableStockReducer",
    initialState,  

    reducers: {
      
    },
    extraReducers: (builder) => {
        builder.addCase(cardVoucherAvailableStockFetch.fulfilled, (state, action) => {
            return {
                ...state,
                cardVoucherAvailableStocks: action.payload,
                loader : false,
            }
        })
            .addCase(cardVoucherAvailableStockFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,                   
                    loader : true
                }
            })
            .addCase(cardVoucherAvailableStockFetch.rejected, (state, action) => {
                //console.log(action)
            })
        }
    
})

//  export const { cardVoucherAvailableStocks} = CardVoucherAvailableStockSlice.actions;
export const CardVoucherAvailableStockReducer = CardVoucherAvailableStockSlice.reducer