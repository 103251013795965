import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";



const initialState = {
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    lists: [],
    loader:false,
    operatorWalletBalanceReports: [],
    lastAdded: {
        operatorCode: "ALL",
        operatorCodeDisplay: "ALL",
        "startDate": currentDate,
        "endDate": currentDate,
        queryButtonDisabled: true,
        operatorCodeError: false,
        operatorCodeHelperText: ""
    },
}

export const OperatorWalletBalanceFetch = createAsyncThunk(
    "OperatorWalletBalanceFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('operatorWalletBalance/query', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const OperatorWalletBalanceSlice = createSlice({
    name: "OperatorWalletBalanceReducer",
    initialState,

    reducers: {
        operatorWalletBalanceLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        handleLoader(state, action) {   
            console.log(action.payload)     
            return {
                ...state,
                loader: action.payload,
            }
        },
        handleSnackBar(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        operatorWalletBalanceLastAddedReset(state, action) {
            return {
                ...state,
                lastAdded: {
                    operatorCode: "ALL",
                    operatorCodeDisplay: "ALL",
                    "startDate": currentDate,
                    "endDate": currentDate,
                    queryButtonDisabled: true,
                    operatorCodeError: false,
                    operatorCodeHelperText: ""
                },
                operatorWalletBalanceReports: [],
                loader:false
            }
        },


    },
    extraReducers: (builder) => {
        builder.addCase(OperatorWalletBalanceFetch.fulfilled, (state, action) => {
            return {
                ...state,
                operatorWalletBalanceReports: action.payload
            }
        })
            .addCase(OperatorWalletBalanceFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(OperatorWalletBalanceFetch.rejected, (state, action) => {
                //console.log(action)
            })
    }


})

export const { operatorWalletBalanceLastAdded, operatorWalletBalanceLastAddedReset,handleLoader,handleSnackBar } = OperatorWalletBalanceSlice.actions;
export const OperatorWalletBalanceReducer = OperatorWalletBalanceSlice.reducer