import { Autocomplete, Grid, IconButton, Menu, MenuItem, TextField } from "@mui/material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ButtonComponent from "../../components/button"
import { agentsFetch } from "../../redux/slice/CommonSlice"
import Input from "../../components/input"
import { agentFlexiLastAdded } from "../../redux/slice/AgentFlexiReportSlice"
import { makeStyles } from "@material-ui/styles"
import { Download } from "@mui/icons-material"

const useStyles = makeStyles({
    root: {
     
      "& .MuiOutlinedInput-root": {
        padding : "0px !important",
       // color:"red"
      },
     
      
    }
  });
export default function AgentCommonWithDateComponent(props) {
    const { handleInputDateChange, handleQuery, agentCodeChange, handleReset, handleChange, lastAdded, handlePrint, handlePdf } = props;
    const dispatch = useDispatch()
    const classes = useStyles()
    const commonValues = useSelector(state => state.CommonReducer);
    const agents = commonValues?.agents;
    //console.log("LastAdded", lastAdded)

    useEffect(() => {
        dispatch(agentsFetch())
    }, [agents.length])
    const autoCompleteChange = (e, typeNewValue, name) => {
      //  console.log("typeNewValue", typeNewValue)
        agentCodeChange(typeNewValue)
    }
  
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid container>
                <Grid item lg={3} md={4} sm={4} >
                    <div style={{ width: "90%" }}>
                        {/* <label>Agent</label> */}
                        <Autocomplete
                            id="AgentCode"
                            options={agents}
                          //  className={classes.root}
                        
                            value={lastAdded.agentCodeDisplay}
                            onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "agent") }}
                            sx={{ width: "100%",  }}
                            renderInput={(params) => <Grid item  lg={11} md={11} sm={11}>
                                <TextField name='agent'
                                    error={lastAdded.agentError}
                                
                                    helperText={lastAdded.agentHelperText} {...params}
                                    label="Agent" /></Grid>}
                        />
                    </div>
                </Grid>
                <Grid item lg={3} md={4} sm={4}>
                    <div style={{ width: "90%" }}>
                        {/* <label>Start Date</label> */}
                        <Input value={lastAdded.startDate} type="date" touched={lastAdded.startDateTouched} placeholder="Date"
                            onChange={handleInputDateChange}
                       //     style={{ padding: "9.5px" }}
                            name="startDate" label="Start Date" fullWidth={true} />
                        <span style={{ color: "red" }}>{lastAdded.startDateError}</span></div>
                </Grid>
                <Grid item lg={3} md={4} sm={4}>  <div style={{ width: "90%" }}>
                    {/* <label>End Date</label> */}
                    <Input value={lastAdded.endDate} type="date" touched={lastAdded.endDateTouched} placeholder="Date"
                        onChange={handleInputDateChange}
                        name="endDate" label="End Date" fullWidth={true} />
                    <span style={{ color: "red" }}>{lastAdded.endDateError}</span></div>
                </Grid>

                <Grid item lg={3} md={12} sm={12} sx={{ paddingTop: "0.5%", textAlign: "center" }}>
                    <ButtonComponent
                        variant='contained' color='primary' label="Query"
                        onClick={handleQuery} style={{width:"10%"}}
                    >
                    </ButtonComponent>&nbsp;
                    <ButtonComponent
                        variant='contained' color='primary' label="Reset"
                        onClick={handleReset} style={{width:"10%"}}
                    >
                    </ButtonComponent> &nbsp;
                    <IconButton
                        variant='contained' color='primary' label="Actions"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}

                    ><Download/>
                    </IconButton>
                    <Menu
                        id="download-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handlePdf}>Download Pdf</MenuItem>
                        {/* <MenuItem onClick={handleClose}>Download Excel</MenuItem> */}
                        <MenuItem onClick={handlePrint}>Print</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </>
    )
}