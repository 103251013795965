import React, { useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, FormControl, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/input';
import { ModifiedDate, currentDateWithTime } from '../../utlis/CurrentDateGenerator';
import { customerReportFetch, customerReportLastAdded, customerReportLastAddedReset, handleLoader } from '../../redux/slice/CustomerReportSlice';
import jsPDF from 'jspdf';
import { Download } from '@mui/icons-material';
import ActionComponent from '../../components/ActionComponent';
import { MainContext } from '../../ComponentBuilld';
import { printTickets } from '../../utlis/PdfFunc';
import { THEME_COLOR } from '../../constfiles/theme';

require('jspdf-autotable');

const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row"
    },
    "& .MuiIconButton-colorPrimary": {
      // background: "red",

    },
    '& .MuiIconButton-colorPrimary:hover': {
      background: THEME_COLOR,
    },
  }
});
const CustomerReport = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.CustomerReportReducer);

  const lastAdded = selectValuesRe.lastAdded;
  const tableData = selectValuesRe?.customerReports === undefined ? [] : selectValuesRe?.customerReports;
  const loader = selectValuesRe?.loader;
  //console.log("selectValuesRe",selectValuesRe.loader)
  const reportTypes = [
    { label: "Select Report", value: null },
    { label: "Agent Wallet Report", value: "01" },
    { label: "Retailer Wallet Report", value: "02" }]
  const GetMainContext = React.useContext(MainContext)
  //console.log("GetMainContext", GetMainContext)
  useEffect(() => {
    dispatch(customerReportLastAddedReset())
    return () => {
      dispatch(customerReportLastAddedReset())
    }
  }, [])
  const handleQuery = () => {
    if (lastAdded.reportType !== null) {
      dispatch(customerReportFetch({ reportType: lastAdded.reportType, date: lastAdded.dateModified }))
    }
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: 'fileName',
        header: 'File Name',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: false,
        //  size: 80,
      },


    ],
    [],
  );
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
   // console.log("name", name)
    if (name === "date") {
      dispatch(customerReportLastAdded({ dateModified: ModifiedDate(value), date: value }))
    }
  }
  const reportTypeChange = (e, reportTypeNewValue) => {
   // console.log(reportTypeNewValue)

    if (reportTypeNewValue.value === null) {
      dispatch(customerReportLastAddedReset())
    }
    else {
      dispatch(customerReportLastAdded({ "reportType": reportTypeNewValue.value, label: reportTypeNewValue.label, "reportTypeTouched": true }))
    }
  }

  const handleDownload = (value) => {
    const tableColumns = ["File Name"];
    const doc = new jsPDF();
    const firstArray = [];
    var secondArray = [];
    tableData.forEach(element => {
      secondArray = [];
      for (const x in element) {
        secondArray.push(element[x])
      }
      firstArray.push(secondArray)
    });
    doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });
    doc.text("Customer Report", 75, 25);
    doc.text(currentDateWithTime, 145, 25);
    doc.addImage("../../kadick_b2c_logo.png", 15, 10, 30, 20);
    if (value === 1) {
      doc.save('customer_report.pdf');
    }
    else {
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    }

  }

  const handleReset = () => {
    dispatch(customerReportLastAddedReset())
  }
  // async function printTickets(fileName) {
  //   dispatch(handleLoader(true));
  //   const { data } = await getTicketsPdf(fileName)
  //   const blob = new Blob([data], { type: 'application/pdf' })
  //   saveAs(blob, fileName)
  //   dispatch(handleLoader(false));
  // }

  // async function getTicketsPdf(fileName) {

  //   return axios.post('flexiReport/download', { "fileName": fileName }, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     responseType: 'arraybuffer'
  //   })
 
  // }
  const handleDownload2 = (data) => {
    dispatch(handleLoader(true))
   // console.log(data)
    printTickets(data, 'flexiReport/download')
    dispatch(handleLoader(false))
  }
  return (
    < div className={classes.root} style={{ maxWidth: "1150px" }}>

      <MaterialReactTable
        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        state={{
          isLoading : loader,
          showProgressBars :loader,
          showSkeletons : loader
        }}
        initialState={{
          density: "compact",
          isLoading : loader,
          showProgressBars :loader,
          showSkeletons : loader
        }}
        
        displayColumnDefOptions={{

          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}

        muiTableBodyRowProps={{
          sx: {
            padding: "0px"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX"
          }
        }}
        positionActionsColumn="last"
        // editingMode="modal" //default
        enableColumnOrdering

        //  enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        localization={{
          actions: "Download"
        }}
        enableEditing

        renderTopToolbarCustomActions={() =>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={4}>
              <FormControl >

                <Autocomplete
                  id="ReportType"
                  options={reportTypes}
                  onChange={reportTypeChange}

                  value={lastAdded.label}
                  sx={{ width: GetMainContext.main === false ? "300px" : "250px" }}
                  renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='reportTypeInput' {...params} label="Report Type" /></Grid>}
                />
              </FormControl>

            </Grid>

            <Grid item lg={3} md={3} sm={3} >


              <Input value={lastAdded.date} type="date" touched={lastAdded.dateTouched} placeholder="Date" onChange={handleChange} name="date" label="" fullWidth={true} />
              <span style={{ color: "red" }}>{lastAdded.dateError}</span>

            </Grid>
            <Grid item lg="5" md={5} sx={{ margin: "auto" }} sm={5}>
              <ActionComponent
                actions={true}
                resetShow={true}
                handlePdf={() => { handleDownload(1) }}
                reset={lastAdded.reportType !== null ? false : true}
                handleReset={handleReset}
                handlePrint={() => { handleDownload(2) }}
                handleQuery={handleQuery}
                classes={classes}
                query={lastAdded.reportType !== null ? false : true}
                download={lastAdded.reportType !== null ? false : true}

              />

            </Grid>
          </Grid>
        }
        renderRowActions={({ row, table }) => (

          <>

            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: "center" }}>
              <Tooltip arrow placement="right" title="Download">
                {/* <IconButton onClick={() => printTickets(row.original.fileName)} > */}
                <IconButton onClick={() => handleDownload2({fileName:row.original.fileName, reportDate:row.original.reportDate}, 'flexiReport/download')} >
                  <Download />
                </IconButton>
              </Tooltip>
            </Box></>
        )}

      />
      <div>
      </div>
    </div>
  );
};

//example of creating a mui dialog modal for creating new rows



export default CustomerReport;
