import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";



const initialState = {
    loader:false,
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    lists: [],
    retailerAdjustmentReports: [],
    lastAdded: {
        retailerCode: "ALL",
        retailerCodeDisplay: "ALL",
        "startDate": currentDate,
        "endDate": currentDate,
        queryButtonDisabled: true,
        retailerCodeError: false,
        retailerCodeHelperText: ""
    },
}

export const RetailerAdjustmentFetch = createAsyncThunk(
    "RetailerAdjustmentFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('retailerAdjustmentReport/query', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const RetailerAdjustmentSlice = createSlice({
    name: "RetailerAdjustmentReducer",
    initialState,

    reducers: {
        retailerAdjustmentLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        retailerAdjustmentLastAddedReset(state, action) {
            return {
                ...state,
                lastAdded: {
                    retailerCode: "ALL",
                    retailerCodeDisplay: "ALL",
                    "startDate": currentDate,
                    "endDate": currentDate,
                    queryButtonDisabled: true,
                    retailerCodeError: false,
                    retailerCodeHelperText: ""
                },
                retailerAdjustmentReports: []
            }
        },
        handleSnackBar(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(RetailerAdjustmentFetch.fulfilled, (state, action) => {
            return {
                ...state,
                retailerAdjustmentReports: action.payload,
                loader : false
            }
        })
            .addCase(RetailerAdjustmentFetch.pending, (state, action) => {
                return {
                    ...state,                   
                    loader : true
                }
            })
            .addCase(RetailerAdjustmentFetch.rejected, (state, action) => {
                return {
                    ...state,                   
                    loader : false
                }
            })
    }


})

export const { retailerAdjustmentLastAdded, retailerAdjustmentLastAddedReset,handleSnackBar } = RetailerAdjustmentSlice.actions;
export const RetailerAdjustmentReducer = RetailerAdjustmentSlice.reducer