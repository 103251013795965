import React from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/input';
import SnackBar from '../../components/snackbar';
import { generationStatusLastAdded, getList, snackBarClosedReset } from '../../redux/slice/GenerationStatusSlice';
import CircularStatic from '../../components/spinner';
import { useStylesInput } from '../../utlis/CommonStyles';
require('jspdf-autotable');

const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            flexDirection: "row",
            justifyContent: "spaceBetween",
            alignItems: "center",
            rowGap: "inherit",
        }
    }
});
const GenerationStatus = () => {
    const classNamees = useStyles()
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.GenerationStatuSliceReducer);
    //console.log("selectValuesRe",selectValuesRe)  
    const tableData = selectValuesRe?.lists === undefined ? [] : selectValuesRe?.lists;
    const lastAdded = selectValuesRe?.lastAdded;
    //console.log("lastAdded", lastAdded)
    const snackbarValues = selectValuesRe.sucessMessage;

    const loader = selectValuesRe.loader
    // //console.log("check", check)
    // useEffect(() => {
    //     dispatch(handleVoucherAvailableStockResetDispatch())
    // }, [])



    const handleQuery = () => {
        if (lastAdded.type === "BN") {
            dispatch(getList({ type: "BN", batchNumber: lastAdded.batchNumber }))
        }
        else {
            dispatch(getList({ type: "BD", startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
        }

    }




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "type") {
            if (value === "BN") {
                dispatch(generationStatusLastAdded({ [name]: value, [name + "Touched"]: true, startDate: "", endDate: "" }))
            }
            else {
                dispatch(generationStatusLastAdded({ [name]: value, [name + "Touched"]: true, batchNumber: "" }))
            }
        }
        else {
            dispatch(generationStatusLastAdded({ [name]: value, [name + "Touched"]: true }))
        }

    }



    const snackbarClosed = () => {

        dispatch(snackBarClosedReset())
    }

    const columns = [
        {
            accessorKey: 'voucherRequestss.batchNumber',
            header: 'Batch Number',
        },
        {
            accessorKey: 'voucherRequestss.voucherRequestsOperators.operatorCode',
            header: 'Operator Code',
        },
        {
            accessorKey: 'voucherRequestss.denomination',
            header: 'Den',
        },
        {
            accessorKey: 'startSNo',
            header: 'Start SNO',
        },
        {
            accessorKey: 'endSNo',
            header: 'End SNO',
        },
        {
            accessorKey: 'reqCount',
            header: 'Req.Count',
        },
        {
            accessorKey: 'allocatedCount',
            header: 'Allocated Count',
        },
        {
            accessorKey: 'remaingCount',
            header: 'Remaining Count',
        },

    ]







    return (
        < div className={classNamees.root}>
            {loader === true ? <CircularStatic /> : null}
          
          

            <MaterialReactTable



                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                  }}
                  initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                  }}
                renderTopToolbarCustomActions={({ row }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}> <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row"
                    }}>
                        <FormControl>

                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="type"
                                row
                                value={lastAdded.type}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="BN" control={<Radio />} label="Batch Number" />
                                <FormControlLabel value="BS" control={<Radio />} label="Date Wise" />
                            </RadioGroup>
                        </FormControl>

                        {lastAdded.type === "BS" ?

                            <>
                                <Input style={{ width: "100%" }} value={lastAdded.startDate} placeholder="Start Date" type="date" touched={lastAdded.startDateTouched} onChange={handleInputChange} name="startDate" label="" />&nbsp;
                                <Input value={lastAdded.endDate} style={{ width: "100%" }} placeholder="End Date" type="date" touched={lastAdded.endDateTouched} onChange={handleInputChange} name="endDate" label="" /> &nbsp;

                            </> :
                            <>
                                <Input value={lastAdded.batchNumber} className={useStylesInput.root} style={{ width: "100%" }} placeholder="Please Enter Batch Number" type="text" touched={lastAdded.batchNumberTouched} onChange={handleInputChange} name="batchNumber" label="Batch Number" /> &nbsp;

                            </>

                        }
                        <Button variant='contained' color='primary' onClick={handleQuery}>Query</Button>
                    </div>  <div>


                        </div> </Box>
                )}
                enableColumnOrdering
            />

            <div>

            </div>
            <SnackBar dispatch={dispatch} snackbarClosed={snackbarClosed} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


        </div>

    );

};
export default GenerationStatus;
