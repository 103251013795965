import { makeStyles } from '@material-ui/styles';
import { Details, Download, Info } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import axios from 'axios';
// import { saveAs } from 'file-saver';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/input';

import { ModifiedDate } from '../../utlis/CurrentDateGenerator';
import { getTicketsPdf, handleDownloadForFileName, printTickets } from '../../utlis/PdfFunc';
import DownloadButton from '../../components/DownloadButton';
import { THEME_COLOR } from '../../constfiles/theme';
import { InventoryDetailFetch, InventorySplitFetch, handleResetInventorySplitReport, inventorySplitLastAdded } from '../../redux/slice/InventorySplitSlice';
import InventorySplitDetail from './InventoryDetail';


const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            justifyContent: "end",
            flexDirection: "row"
        },
        '& .MuiIconButton-colorPrimary:hover': {
            background: THEME_COLOR,
            padding: " 1.8%"
        },
    }
});
const InventorySplit = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.InventorySplitReducer);
    const lastAdded = selectValuesRe.lastAdded;
   // console.log(lastAdded)
    const tableData = selectValuesRe?.inventorySplitReports === undefined ? [] :  selectValuesRe?.inventorySplitReports;
    const inventorySplitReportDetails = selectValuesRe?.inventorySplitReportDetails;
    const loader = selectValuesRe?.loader;
    const [detailOpen, setDetailOpen] = useState(false)
    const [id, setId] = useState()
    useEffect(() => {
        dispatch(handleResetInventorySplitReport())
        return () => {
            dispatch(handleResetInventorySplitReport())
        }

    }, [])

    const handleQuery = () => {
        // dispatch(handleLoader(true))
        dispatch(InventorySplitFetch({ startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
        // dispatch(handleLoader(false))
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'splitId',
                header: 'Split Id',
            },
            {
                accessorKey: 'inventoryId',
                header: 'Inventory Id',
            },
            {
                accessorKey: 'splitSummary',
                header: 'Split Summary',
            },
            {
                accessorKey: 'createTime',
                header: 'Create Time',
            },
        ],
        [],
    );
    const openPopup = (id) => {
        setId(id)
        setDetailOpen(true)
        dispatch(InventoryDetailFetch({ id }))
    }
    const handleChange = (e) => {
        const { value, name } = e.target;
        dispatch(inventorySplitLastAdded({ [name]: value }))

    }

    const handleReset = () => {
        dispatch(handleResetInventorySplitReport())
    }

    // const handlePdf = (value) => {
    //     handleDownloadForFileName(value, "Flexi Report", "flexi_report.pdf", tableData)
    // }
    return (
        < div className={classes.root}>

            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                positionActionsColumn="last"
                enableColumnOrdering
                localization={{
                    actions: "Details"
                }}
                enableEditing
                renderRowActions={({ row }) => (
                    <>
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: "center" }}>
                            <Tooltip arrow placement="right" title="Detail">
                                <IconButton
                                    onClick={() => openPopup(row.original.id)}
                                >
                                    <Info />                              </IconButton>
                            </Tooltip>
                        </Box></>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        <Grid container spacing={2}>

                            <Grid item lg="4" md={4} sm={4} >
                                <TextField value={lastAdded.startDate} type="date" placeholder="Date" onChange={handleChange} name="startDate" label="Start Date" fullWidth={true} />
                            </Grid>
                            <Grid item lg="4" md={4} sm={4} >
                                <TextField value={lastAdded.endDate} type="date" placeholder="Date" onChange={handleChange} name="endDate" label="Start Date" fullWidth={true} />
                            </Grid>
                            <Grid item lg="4" md={4} sx={{ margin: "auto" }} sm={4}>
                                <Button variant='contained' disabled={lastAdded.startDate === "" && lastAdded.endDate === ""} color='primary' onClick={handleQuery}>Query</Button>&nbsp;
                                <Button variant='contained' onClick={handleReset} color='primary'>Reset</Button>&nbsp;
                                {/* <DownloadButton classes={classes} handlePdf={() => { handlePdf(1) }} handlePrint={() => { handlePdf(2) }} /> */}
                            </Grid>
                        </Grid>
                    </>
                )}
            />
            <InventorySplitDetail id={id} loader={loader} inventorySplitReportDetails={inventorySplitReportDetails} open={detailOpen} setDetailOpen={setDetailOpen} />
            <div>

            </div>


        </div>
    );
};

//example of creating a mui dialog modal for creating new rows



export default InventorySplit;
