import React, { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete, Box, Button, FormControl, Grid, TextField, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { VoucherBlockAction, VoucherUnblockAction, getVoucherBlockUnblockList, snackBarClosedReset, voucherBlockUnBlockLastAdded } from '../../redux/slice/VoucherBlockUnBlockSlice';
import Input from '../../components/input';
import SnackBar from '../../components/snackbar';
require('jspdf-autotable');

const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            flexDirection: "row",
            justifyContent: "spaceBetween",
            alignItems: "center",
            rowGap: "inherit",
        }
    }
});
const VoucherBlockUnblock = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.VoucherBlockUnblockSliceReducer);
    const tableData = selectValuesRe?.lists === undefined ? [] : selectValuesRe?.lists;
    const lastAdded = selectValuesRe?.lastAdded;
    //console.log("lastAdded", lastAdded)
    const snackbarValues = selectValuesRe.sucessMessage;
    //console.log("snackbarValues",snackbarValues)
    const blockValues = [1, 6, 7, 8];
    // useEffect(() => {
    //     dispatch(handleVoucherAvailableStockResetDispatch())
    // }, [])


    const handleQuery = () => {
        dispatch(getVoucherBlockUnblockList({ type: lastAdded.type, value: lastAdded.inputValue }))
    }

    const handleBlock = () => {
        dispatch(VoucherBlockAction({ type: lastAdded.type, value: lastAdded.inputValue }))
    }
    const handleUnBlock = () => {
        dispatch(VoucherUnblockAction({ type: lastAdded.type, value: lastAdded.inputValue }))
    }


    const typeChange = (e, typeNewValue) => {
        dispatch(voucherBlockUnBlockLastAdded({ "type": typeNewValue?.value, "typeTouched": true }))
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(voucherBlockUnBlockLastAdded({ [name]: value, [name + "Touched"]: true }))
    }

    const snackbarClosed = () => {
        dispatch(snackBarClosedReset())
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'operators.operatorCode',
                header: 'Operator',
            },
            {
                accessorKey: 'vocuherValue',
                header: 'Denomination',
            },
            {
                accessorKey: 'voucherType',
                header: 'Voucher Type',
            },
            {
                accessorKey: 'count',
                header: 'Count',
            },
            {
                accessorKey: 'status',
                header: 'Status',
                Cell: ({ cell }) => (
                    cell.getValue()?.toString()?.toString() === "1" ? "Available" : cell.getValue()?.toString() === "2" ? "Used" : cell.getValue()?.toString() === "3" ? "Expired" : cell.getValue()?.toString() === "4" ? 'Blocked' : cell.getValue()?.toString() === "5" ? "Suspend" : cell.getValue()?.toString() === "6" ? "Reserve" : cell.getValue()?.toString() === "7" ? "Staged" : cell.getValue()?.toString() === "8" ? "Unsold" : "Other"
                )
            },

        ],
        [],
    );
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        < div className={classes.root}>


            <MaterialReactTable
                enableEditing
                renderRowActions={({ row, table }) => (

                    <Box sx={{ textAlign: "center" }}>

                        {blockValues.includes(row?.original?.status) ? <Button variant='contained' color='primary' onClick={handleBlock}>Block</Button> : row?.original?.status === 2 ? "-" : <Button variant='contained' color='primary' onClick={handleUnBlock}>UnBlock</Button>}
                    </Box>

                )}
                positionActionsColumn="last"
                renderTopToolbarCustomActions={() => (
                    <>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }}>
                            <FormControl sx={{ marginRight: "2%", flexDirection: "row" }}>

                                <Autocomplete
                                    id="VoucherBlockUnblockSelect"
                                    options={[{ value: "Batch", label: "By Batch Number" }, { value: "Serial", label: "By Serial Number" }]}
                                    onChange={typeChange}
                                    sx={{ width: "300px", marginRight: "2%",padding:"0px !important", }}
                                    renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='type' sx={{ marginRight: "2%",padding:"0px" }} {...params} label="Type" /></Grid>}
                                />

                                <Input value={lastAdded.inputValue} placeholder={lastAdded.type === "Batch" ? "By Batch Number" : "By Serial Number"} type="text" touched={lastAdded.inputValueTouched} onChange={handleInputChange} name="inputValue" label="" />
                                {/* <span style={{ color: "red" }}>{lastAdded.dateError}</span>  */}
                            </FormControl>
                            <Button variant='contained' color='primary' onClick={handleQuery}>Query</Button>
                        </div>  <div>


                        </div>  </>
                )}
                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}

                enableColumnOrdering
            />

            <div>

            </div>
            <SnackBar dispatch={dispatch} snackbarClosed={snackbarClosed} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


        </div>

    );

};
export default VoucherBlockUnblock;
