import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBarComponent({open,message,mode,dispatch,snackbarClosed}) {
 // const [open, setOpen] = React.useState(false);
  // const {open, setOpen} = props
 
  const handleClose = (event, reason) => {
    //alert('df')
    if (reason === 'clickaway') {
      return;
    }
    dispatch(snackbarClosed({snackbarOpen:false}))
   // setOpen(false);
  };

  return (
    <Stack spacing={2}  sx={{ width: '100%' }}>
     
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={mode} sx={{ width: '100%' }}>
         {message}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </Stack>
  );
}