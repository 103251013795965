import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    promoConfigs: [],
    promoConfigsCopy: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    promoConfigAddAndEditModal : {
        open : false,
        mode:"add"
    },
    promoConfigFormValidation:{
        promoConfigName:"",
    }
    , lastAdded: { active: "Y", promoConfigWinnerText:"", saveButtonDisabled:true,  },
}

export const promoConfigFetch = createAsyncThunk(
    "promoConfigReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/promoConfigs');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const promoConfigAdd = createAsyncThunk(
    "promoConfigReducer/add", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/promoConfigs/save/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const promoConfigDelete = createAsyncThunk(
    "promoConfigReducer/delete", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           const winnerId = arg.promoConfigId;
            const response = await axios.post('/promoConfigs/delete', {id: winnerId});
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const promoConfigUpdate = createAsyncThunk(
    "promoConfigReducer/update", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/promoConfigs/update/', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("promoConfigFetch", promoConfigFetch)

const PromoConfigSlice = createSlice({
    name: "promoConfigReducer",
    initialState,  

    reducers: {
        promoConfigList(state, action) {
            // state.promoConfigs = action.payload
            return {
                ...state,
                promoConfigs: action.payload
            }
        },
        promoConfigLastAdded(state, action) {
     //       console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
                
                
            }
        },
        
        snackbarClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                promoConfigAddAndEditModal: { ...state.promoConfigAddAndEditModal, ...action.payload  }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(promoConfigFetch.fulfilled, (state, action) => {
            return {
                ...state,
                promoConfigs: action.payload
            }
        })
            .addCase(promoConfigFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(promoConfigFetch.rejected, (state, action) => {
                //console.log(action)
            })
            .addCase(promoConfigAdd.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(promoConfigAdd.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(promoConfigAdd.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:"Promo Config Added Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        promoConfigAddAndEditModal : {
                            open : false
                        },
                        promoConfigs : action.payload.promoConfigs
                    }
                }
            })
            .addCase(promoConfigUpdate.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(promoConfigUpdate.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(promoConfigUpdate.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:"Promo Config Update Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        promoConfigAddAndEditModal : {
                            open : false
                        },
                        promoConfigs : action.payload.promoConfigs
                    }
                }
            })
            .addCase(promoConfigDelete.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(promoConfigDelete.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:"Promo Config Deleted Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                      promoConfigs:action.payload.promoConfigs
                       
                    }
                }
            })
    }
    // extraReducers :(builder)=> {
    //     builder.addCase("promoConfigReducer/promoConfigList", (state,action)=>{
    //         state.promoConfigsCopy = action.payload
    //     })
    //     // promoConfigList(state,action){
    //     //     // state.promoConfigs = action.payload
    //     //      return {
    //     //          ...state,
    //     //          promoConfigsCopy : action.payload
    //     //      }
    //     //  }
    // }
})

export const { promoConfigList, promoConfigLastAdded,snackbarClosed, popupOpenAndClosed, promoConfigLastAddedValidate} = PromoConfigSlice.actions;
export const PromoConfigReducer = PromoConfigSlice.reducer