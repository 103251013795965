import React from 'react';
import { Box, Grid, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import Input from '../../components/input';
import SelectDropdown from "../../components/Select";
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { promoDistributionAdd, promoDistributionLastAdded,  promoDistributionUpdate, popupOpenAndClosed } from '../../redux/slice/PromoDistributionSlice';
import { SERVICE_PROVIDER_NAME_IS_REQUIRED,THIS_FIELD_IS_REQUIRED } from '../../constfiles/formValidation';


export default function PromoDistributionAddandEdit({ createModalOpen, popupMode, lastAdded }) {
  //console.log("Last added", lastAdded)
  const dispatch = useDispatch()
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    borderRadius: "4px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // const handleSubmit = () => {
  //   //put your validation logic here
  //   onSubmit(values);
  //   onClose();
  // };

  function validation() {
    const errorObj = {}
    const {promoDistributionName} = lastAdded;
    //   //console.log("lastAdded",lastAdded)
      // if(promoDistributionCode) {
      //   if(promoDistributionCode.trim() ===""){
      // //  dispatch(promoDistributionLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
      //     Object.assign(errorObj,{["promoDistributionCode" + "Error"]: true,["promoDistributionCode" + "HelperText"]:OPERATOR_CODE_IS_REQUIRED })
          
      //   }
      //   if(promoDistributionCode.length < 3) {
      //     Object.assign(errorObj,{["promoDistributionCode" + "Error"]: true,["promoDistributionCode" + "HelperText"]:OPERATOR_CODE_MINIMUM_3_CHACRTERS  })
      // //  dispatch(promoDistributionLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_MINIMUM_3_CHACRTERS  }))
      //   }
      //   else{
      //     Object.assign(errorObj,{["promoDistributionCode" + "Error"]: false,["promoDistributionCode" + "HelperText"]:""})
      //   // dispatch(promoDistributionLastAdded())
      //   }
      // }
      if(promoDistributionName) {
        if(promoDistributionName.trim()===""){
          //  dispatch(promoDistributionLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["promoDistributionName" + "Error"]: true,["promoDistributionName" + "HelperText"]:SERVICE_PROVIDER_NAME_IS_REQUIRED })
        }
      }
    
    
    
      dispatch(promoDistributionLastAdded(errorObj))
      const check = Object.values(errorObj).includes( THIS_FIELD_IS_REQUIRED)
      //console.log(" Object.values(errorObj)", Object.values(errorObj))
      //console.log("check",check)
      return check
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // if(validation()) {
    
    dispatch(promoDistributionLastAdded({ [name]: value, [name + "Touched"]: true}))
    // dispatch(promoDistributionLastAddedValidate({[name]: value }))
    //}
  }
const buttonUpdate = () =>{
  // alert("i")
  if(!validation()){
    dispatch(promoDistributionUpdate({promoDistributionId:lastAdded.promoDistributionId,  promoDistributionName:lastAdded.promoDistributionName,promoDistributionValue:lastAdded.promoDistributionValue,promoBaseValue:lastAdded.promoBaseValue}))
  }
}
  const buttonSave = () => {
   
     
      if(!validation()){
        dispatch(promoDistributionAdd({promoBaseValue:lastAdded.promoBaseValue,promoDistributionName:lastAdded.promoDistributionName,promoDistributionValue:lastAdded.promoDistributionValue}))
      }
      //alert(check)
      //  dispatch(promoDistributionAdd(lastAdded))
    
      ////console.log("checkingFlag",checkingFlag)
      //alert(checkingFlag)
    // else {
    //   dispatch(promoDistributionAdd(lastAdded))
    // }
  }
  return (
    <>

      <Modal
        open={createModalOpen}
        onClose={() => dispatch(popupOpenAndClosed({ open: false }))}
        aria-labelledby="promoDistribution-title"
        aria-describedby="promoDistribution-description"
      >
        <Box sx={style}>
          <h3>{popupMode === "add" ? "Promo Distribution Create": "Promo Distribution Edit - "+`${lastAdded.promoDistributionName}` }</h3>
          <Grid container spacing={2}>
            {/* <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.promoDistributionCode} touched={lastAdded.promoDistributionCodeTouched} error={lastAdded.promoDistributionCodeError } helperText={lastAdded.promoDistributionCodeHelperText} onChange={handleInputChange} name="promoDistributionCode" label="promoDistribution Code" fullWidth={true} maxLength="3" />
            </Grid> */}

            <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.promoDistributionName} touched={lastAdded.promoDistributionNameTouched} onChange={handleInputChange} error={lastAdded.promoDistributionNameError} helperText={lastAdded.promoDistributionNameHelperText} name="promoDistributionName" label="Promo Distribution Name" fullWidth={true} maxLength="20" />
            </Grid>
            <Grid item lg="6" md={6} sx="6" sm={6}>
              <Input value={lastAdded.promoDistributionValue} touched={lastAdded.promoDistributionValueTouched} onChange={handleInputChange} error={lastAdded.promoDistributionValueError} helperText={lastAdded.promoDistributionValueeHelperText} name="promoDistributionValue" label="Distribution Value" fullWidth={true} maxLength="20" />
            </Grid>
            <Grid item lg="6" md={6} sx="6" sm={6}>
              <Input value={lastAdded.promoBaseValue} touched={lastAdded.promoBaseValueTouched} onChange={handleInputChange} error={lastAdded.promoBaseValueError} helperText={lastAdded.promoBaseValueeHelperText} name="promoBaseValue" label="Base Value" fullWidth={true} maxLength="20" />
            </Grid>

          

            <Grid item lg="12" md={12} xs="12" sx={{
              textAlign: "center", display: "flex", columnGap: "2%", justifyContent: "center"
            }} sm={12}>
             {popupMode === "add" ?  <ButtonComponent onClick={buttonSave} label="Save" name="promoDistributionAddButton"
                color="primary"
                disabled={( lastAdded.promoDistributionName.length) === 0 ? true : false}
                type="add"
                variant="contained" /> :  <ButtonComponent onClick={buttonUpdate} label="Update" name="promoDistributionUpdateButton"
                color="primary"
                disabled={(lastAdded.promoDistributionName.length) === 0 ? true : false}
                type="add"
                variant="contained" />}
              <ButtonComponent label="Close" name="close"
                color="primary"
                disabled={false}
                onClick={() => dispatch(popupOpenAndClosed({ open: false }))}
                type="close"
                variant="contained" />




            </Grid>
          </Grid>


        </Box>
      </Modal>
    </>
  );
};
PromoDistributionAddandEdit.prototype = {
  columns: PropTypes.array
}