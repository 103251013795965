import React, { useState, useEffect } from 'react';
import Input from '../../components/input';
import SelectDropdown from "../../components/Select";
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { AppBar, Autocomplete, Box, Dialog, Grid, IconButton, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { serialConfigAdd, serialConfigLastAdded, serialConfigUpdate, popupOpenAndClosed, SerialConfigUpdate, serialConfigLastAddedReset } from "../../redux/slice/SerialConfigSlice";
import { ServiceProviderFetch, OperatorActiveFetch } from "../../redux/slice/CommonSlice";
import { THEME_COLOR } from '../../constfiles/theme';
import { Clear } from '@mui/icons-material';
import { makeStyles } from "@material-ui/styles"
import { useSelector } from "react-redux";
// import { PROFILE_CODE_IS_REQUIRED, PROFILE_CODE_MINIMUM_3_CHACRTERS, PROFILE_NAME_IS_REQUIRED } from '../../../constfiles/formValidation';
const useStyles = makeStyles({
    root: {
        "& .css-63aude-MuiFormLabel-root-MuiInputLabel-root": {
            height: "0.5em !important",
            color: "red"
        },
    }
});
export default function SerialConfigCreateAndEdit({ createModalOpen, popupMode, lastAdded }) {
    //console.log("Last added", lastAdded)
    const [errorObj, setErrorObj] = useState({
        serialPrefixLength: "",
        serialSeqLength: "",
        serialSuffixLength: "",
        voucherPrefixLength: "",
        voucherRandom1Length: "",
        voucherRandom2Length: "",
        pinRandomLength: ""
    })
    const selectValuesRe = useSelector(state => state.CommonReducer);
    const dispatch = useDispatch()
    const classes = useStyles()
    const providers = selectValuesRe?.serviceProviders;
    const operators = selectValuesRe?.activeOperators;
    const operatorCall = () => {
        dispatch(OperatorActiveFetch())
    }
    const serviceproviderCall = () => {
        dispatch(ServiceProviderFetch())
    }
    useEffect(() => {
        Promise.all([operatorCall(), serviceproviderCall()]).then(response => {

        })
    }, [])
    const style = {
        position: 'absolute',
        top: '10%',
        //  left: '50%',
        //  transform: 'translate(-50%, -50%)',
        width: "100%",
        borderRadius: "4px",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        //  paddingTop:"30px"
    };
    const trStyle = {
        paddingBottom: "2%"
    }
    // const handleSubmit = () => {
    //   //put your validation logic here
    //   onSubmit(values);
    //   onClose();
    // };

    function validation() {
        const errorObj = {}
        const { startDate, endDate, serialPrefixLength, serialSeqLength, serialSuffixLength, voucherPrefixLength, voucherRandom1Length, voucherRandom2Length, pinRandomLength } = lastAdded;
        //console.log("lastAdded", lastAdded)

        if (serialPrefixLength?.trim() === "") {
            //  dispatch(serialConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
            Object.assign(errorObj, { ["serialPrefixLength"]: "This field is required" })

        }
        if (serialSeqLength?.trim() === "") {
            //  dispatch(serialConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
            Object.assign(errorObj, { ["serialSeqLength"]: "This field is required" })

        }
        if (serialSuffixLength?.trim() === "") {
            //  dispatch(serialConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
            Object.assign(errorObj, { ["serialSuffixLength"]: "This field is required" })

        }
        if (voucherPrefixLength?.trim() === "") {
            //  dispatch(serialConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
            Object.assign(errorObj, { ["voucherPrefixLength"]: "This field is required" })

        }
        if (voucherRandom1Length?.trim() === "") {
            //  dispatch(serialConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
            Object.assign(errorObj, { ["voucherRandom1Length"]: "This field is required" })

        }
        if (voucherRandom2Length?.trim() === "") {
            //  dispatch(serialConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
            Object.assign(errorObj, { ["voucherRandom2Length"]: "This field is required" })

        }
        if (pinRandomLength?.trim() === "") {
            //  dispatch(serialConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:PROFILE_CODE_IS_REQUIRED }))
            Object.assign(errorObj, { ["pinRandomLength"]: "This field is required" })

        }
        if (startDate) {
            if (endDate.length <= 0) {
                Object.assign(errorObj, { endDate: "End Date is Required" })

            }
            else if (startDate > endDate) {
                Object.assign(errorObj, { endDate: "End date should not behind start date" })
                //dispatch(handleValidation({ endDate: "End date should not behind start date" }))
            }
            else {
                Object.assign(errorObj, { endDate: "" })
                //dispatch(handleValidation({ endDate: "" }))
            }
        }
       // console.log("errorObj", errorObj)
        setErrorObj(errorObj)
        const check = Object.values(errorObj).every((item) => item.trim() === "")
        ////console.log(" Object.values(errorObj)", Object.values(errorObj))
        ////console.log("check",check)
        return check
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // if(validation()) {

        dispatch(serialConfigLastAdded({ [name]: value, [name + "Touched"]: true }))
        // dispatch(serialConfigLastAddedValidate({[name]: value }))
        //}
    }
    const buttonUpdate = () => {
        // alert("i")
        //   if(!validation()){
        dispatch(SerialConfigUpdate( {active: lastAdded.active,
            serialPrefixLength: lastAdded.serialPrefixLength,
            serialSeqLength: lastAdded.serialSeqLength,
            serialSuffixLength: lastAdded.serialSuffixLength,
            voucherPrefixLength: lastAdded.voucherPrefixLength,
            voucherRandom1Length: lastAdded.voucherRandom1Length,
            voucherRandom2Length: lastAdded.voucherRandom2Length,
            pinRandomLength: lastAdded.pinRandomLength,
            provider: lastAdded.provider,
            operator: lastAdded.operator,
            startDate: lastAdded.startDate,
            endDate: lastAdded.endDate,
            id:lastAdded.id}))
        //}
    }
    const handleClose = () => {
        dispatch(popupOpenAndClosed({ open: false }))
        dispatch(serialConfigLastAddedReset())
    }
    const autoCompleteChange = (e, typeNewValue, name) => {
     //   console.log("typeNewValue", typeNewValue)
        //  handleInputChangeValidation(name, typeNewValue)
        dispatch(serialConfigLastAdded({ [name]: typeNewValue?.id, [name + "Display"]: typeNewValue?.label, [name + "typeTouched"]: true }))
    }
    const buttonSave = () => {


        if (validation()) {
            dispatch(serialConfigAdd({
                active: lastAdded.active,
                serialPrefixLength: lastAdded.serialPrefixLength,
                serialSeqLength: lastAdded.serialSeqLength,
                serialSuffixLength: lastAdded.serialSuffixLength,
                voucherPrefixLength: lastAdded.voucherPrefixLength,
                voucherRandom1Length: lastAdded.voucherRandom1Length,
                voucherRandom2Length: lastAdded.voucherRandom2Length,
                pinRandomLength: lastAdded.pinRandomLength,
                provider: lastAdded.provider,
                operator: lastAdded.operator,
                startDate: lastAdded.startDate,
                endDate: lastAdded.endDate
            }))
        }
        //alert(check)
        //  dispatch(serialConfigAdd(lastAdded))

        //////console.log("checkingFlag",checkingFlag)
        //alert(checkingFlag)
        // else {
        //   dispatch(serialConfigAdd(lastAdded))
        // }
    }
    return (
        <>

            <Dialog
                fullScreen
                open={createModalOpen}
                onClose={() => handleClose()}
                aria-labelledby="serialConfig-title"
                aria-describedby="serialConfig-description"
            >
                <AppBar style={{ background: THEME_COLOR }} >
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>


                        <Typography variant="h6" component="div">
                            Serial Configuration
                        </Typography>
                        <Typography variant="h6" component="div"> <IconButton style={{ color: "white" }} onClick={() => dispatch(popupOpenAndClosed({ open: false }))()}> <Clear /></IconButton></Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={style}>
                    {/* <h3>{popupMode === "add" ? "SerialConfig Create": "SerialConfig Edit - "+`${lastAdded?.serialConfigDescription}` }</h3> */}
                    <>
                        <table style={{ width: "100%" }}>
                            {popupMode === "add" ? <tr>
                                <td>
                                    <Autocomplete
                                        id="Provider"
                                        value={lastAdded.providerDisplay}
                                        options={providers}
                                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "provider") }}
                                        //  sx={{ width: "300px" }}
                                        renderInput={(params) =>
                                            <Grid item lg={11} md={11} sm={11}>
                                                <TextField name='providerInput' inputProps={{
                                                    className: classes.root
                                                }} error={lastAdded.providerError} helperText={lastAdded.providerHelperText} {...params} label="Providers" /></Grid>}
                                    /></td>

                                <td>
                                    <Autocomplete
                                        id="Operator"
                                        value={lastAdded.operatorDisplay}
                                        options={operators}
                                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "operator") }}
                                        // sx={{ width: "300px" }}
                                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='operatorInput' error={lastAdded.operatorError} helperText={lastAdded.operatorHelperText} {...params} label="Operators" /></Grid>}
                                    /></td>
                            </tr> : null}
                            <tr >
                                <td style={trStyle}> <TextField value={lastAdded?.serialPrefixLength} error={errorObj?.serialPrefixLength} helperText={errorObj?.serialPrefixLength} onChange={handleInputChange} name="serialPrefixLength" label="Serial Prefix length" fullWidth={true} /></td>
                                <td style={trStyle}> <TextField value={lastAdded?.serialSeqLength} error={errorObj?.serialSeqLength} helperText={errorObj?.serialSeqLength} onChange={handleInputChange} name="serialSeqLength" label="Serial Sequence length" fullWidth={true} /></td>

                            </tr>
                            <tr>
                                <td style={trStyle}> <TextField value={lastAdded?.serialSuffixLength} error={errorObj?.serialSuffixLength} helperText={errorObj?.serialSuffixLength} onChange={handleInputChange} name="serialSuffixLength" label="Serial Suffix length" fullWidth={true} /></td>
                                <td style={trStyle} > <TextField value={lastAdded?.voucherPrefixLength} error={errorObj?.voucherPrefixLength} helperText={errorObj?.voucherPrefixLength} onChange={handleInputChange} name="voucherPrefixLength" label="voucher Prefix length" fullWidth={true} /></td>
                            </tr>
                            <tr>

                                <td style={trStyle}> <TextField value={lastAdded?.voucherRandom1Length} error={errorObj?.voucherRandom1Length} helperText={errorObj?.voucherRandom1Length} onChange={handleInputChange} name="voucherRandom1Length" label="voucher Random1 length" fullWidth={true} /></td>
                                <td style={trStyle}> <TextField value={lastAdded?.voucherRandom2Length} error={errorObj?.voucherRandom2Length} helperText={errorObj?.voucherRandom2Length} onChange={handleInputChange} name="voucherRandom2Length" label="voucher Random2 length" fullWidth={true} /></td>
                            </tr>
                            <tr >

                                <td style={trStyle}>  <TextField value={lastAdded?.pinRandomLength} error={errorObj?.pinRandomLength} helperText={errorObj?.pinRandomLength} onChange={handleInputChange} name="pinRandomLength" label="Pin Random length" fullWidth={true} /></td>
                                <td style={trStyle}>  <SelectDropdown
                                    error={lastAdded.active} value={lastAdded.active} onChange={handleInputChange} name="active" label="Active" fullWidth={true} /></td>
                            </tr>

                            <tr >
                                <td> <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="StartDate"
                                    label="Start Date"
                                    name="startDate"
                                    autoComplete="off"
                                    //  disabled={fieldEnable}
                                    // readOnly={fieldEnable}
                                    error={errorObj?.startDate}
                                    helperText={errorObj?.startDate}
                                    value={lastAdded.startDate}
                                    type="date"
                                    onChange={handleInputChange} /></td>

                                <td colSpan={2}  >
                                    <TextField
                                        margin="normal"
                                        required
                                        style={{ verticalAlign: "sub" }}
                                        fullWidth
                                        id="EndDate"
                                        label="End Date"
                                        name="endDate"
                                        // disabled={fieldEnable}
                                        // readOnly={fieldEnable}
                                        autoComplete="off"
                                        error={errorObj?.endDate}
                                        helperText={errorObj?.endDate}
                                        value={lastAdded.endDate}
                                        type="date"
                                        onChange={handleInputChange} /></td>
                            </tr>
                        </table>
                        <Grid>
                            <Grid item lg="12" md={12} xs="12" sx={{
                                textAlign: "center", display: "flex", columnGap: "2%", justifyContent: "center"
                            }} sm={12}>
                                {popupMode === "add" ? <ButtonComponent onClick={buttonSave} label="Save" name="serialConfigAddButton"
                                    color="primary"
                                    disabled={(lastAdded?.serialConfigCode?.length && lastAdded?.serialConfigDescription.length) === 0 ? true : false}
                                    type="add"
                                    variant="contained" /> : <ButtonComponent onClick={buttonUpdate} label="Update" name="serialConfigUpdateButton"
                                        color="primary"
                                        disabled={(lastAdded?.serialConfigCode?.length && lastAdded?.serialConfigDescription.length) === 0 ? true : false}
                                        type="add"
                                        variant="contained" />}
                                <ButtonComponent label="Close" name="close"
                                    color="primary"
                                    disabled={false}
                                    onClick={() => handleClose()}
                                    type="close"
                                    variant="contained" />




                            </Grid>
                        </Grid>

                    </>
                </Box>
            </Dialog>
        </>
    );
};
