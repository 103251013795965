import { Grid, IconButton, Menu, MenuItem } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ButtonComponent from "../components/button"
import { Download } from "@mui/icons-material"
import { THEME_COLOR } from "../constfiles/theme"




export default function ActionComponent(props) {
    const { handleQuery, lastAdded,classes,query,download, handlePrint, handlePdf,resetShow, handleReset, reset, actions } = props;
   // console.log("actions", actions)
    const dispatch = useDispatch()
    const commonValues = useSelector(state => state.CommonReducer);

    //console.log("LastAdded", lastAdded)



    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>          
           &nbsp; <ButtonComponent
                variant='contained' color='primary' label="Query"
                onClick={handleQuery}
                disabled = {query}
            >
            </ButtonComponent>&nbsp;
            {resetShow === true ? <> <ButtonComponent
                variant='contained' color='primary' label="Reset"
                onClick={handleReset} 
                disabled={reset}
            >
            </ButtonComponent>&nbsp; </> : null}
            {actions === true ? <>

                <IconButton disabled={download} className={classes.root} color='primary' sx={{borderRadius:"2px",padding:"1.3%"}} onClick={handleClick} ><Download  /></IconButton>

                <Menu
                    id="download-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        
                    }}
                >
                    <MenuItem onClick={handlePdf}>Download Pdf</MenuItem>
                    {/* <MenuItem onClick={handleClose}>Download Excel</MenuItem> */}
                    <MenuItem onClick={handlePrint}>Print</MenuItem>
                </Menu> </> : null}
        
        </>
    )
}