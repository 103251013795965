import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate, ModifiedDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    loader : false,
    retailerWallets:[],
    lastAdded: { retailerCode: "ALL",  date:currentDate,dateModified:ModifiedDate(currentDate), queryButtonDisabled: true, retailerCodeError: false, retailerCodeHelperText: "" },
}

export const retailerWalletFetch = createAsyncThunk(
    "retailerWalletFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('retailerWallet/query',arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const RetailerWalletSlice = createSlice({
    name: "RetailerWalletReducer",
    initialState,

    reducers: {      
        retailerWalletLastAdded(state, action) {        
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        retailerWalletLastAddedReset(state, action) {        
            return {
                ...state,
                lastAdded: {
                    retailerCode:"ALL", 
                    date:currentDate,
                    dateModified:ModifiedDate(currentDate), 
                    queryButtonDisabled: true,
                     retailerCodeError: false, 
                     retailerCodeHelperText: "" 
                },
                retailerWallets : []
            }
        },


    },
    extraReducers: (builder) => {
        builder.addCase(retailerWalletFetch.fulfilled, (state, action) => {
            return {
                ...state,
                retailerWallets: action.payload,
                loader : false,
            }
        })
            .addCase(retailerWalletFetch.pending, (state, action) => {
                return {
                    ...state,                   
                    loader : true,
                }
            })
            .addCase(retailerWalletFetch.rejected, (state, action) => {
                return {
                    ...state,                   
                    loader : false,
                }
            })
        }


})

 export const { retailerWalletLastAdded,retailerWalletLastAddedReset } = RetailerWalletSlice.actions;
export const RetailerWalletReducer = RetailerWalletSlice.reducer