import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    retailers: [],
    menus: [],
    agents: [],
    operators: [],
    activeOperators:[],
    serviceProviders : [],
    branches: [],
    branchTypes: [],
    userDetails: {},
    promoDistributions: [],
    users: {
        auth: null
    },
    profiles: []
}

export const retailersFetch = createAsyncThunk(
    "retailersFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/retailerWallet/getRetailers');
            const reteailersData = response.data;

            reteailersData.forEach(element => {
                Object.assign(element, { label: element.retailer_name })
            });
            reteailersData.sort((a, b) => {
                return a.label > b.label ? 1 : -1
            })
            reteailersData.splice(0, 0, { label: "ALL", retailer_code: "ALL" })
            return reteailersData
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const branchesFetch = createAsyncThunk(
    "branchesFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/branch/getActive');
            const branchData = response.data;
            console.log(branchData)
            branchData.forEach(element => {
                Object.assign(element, { label: element.name })
            });
            branchData.sort((a, b) => {
                return a.label > b.label ? 1 : -1
            })
            //  reteailersData.splice(0, 0, { label: "ALL", name: "ALL" })
            return branchData
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const operatorsFetch = createAsyncThunk(
    "operatorsFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/operators/getActiveOperators');
            const operatorData = response.data;
            operatorData.forEach(element => {
                Object.assign(element, { label: element.name })
            });
            operatorData.sort((a, b) => {
                return a.label > b.label ? 1 : -1
            })
            operatorData.splice(0, 0, { label: "ALL", code: "ALL", name: "ALL" })
            return operatorData
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const agentsFetch = createAsyncThunk(
    "agentsFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/details/getAgents');
            const agentsData = response.data;

            agentsData.forEach(element => {
                Object.assign(element, { label: element.agent_code + " - " + element.agent_name })
            });
            agentsData.sort((a, b) => {
                return a.agent_code > b.agent_code ? -1 : 1
            })
            agentsData.splice(0, 0, { label: "ALL", agent_code: "ALL" })
            return agentsData
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const promoDistributionsFetch = createAsyncThunk(
    "promoDistributionsFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/promoDistributions/');
            const promoDistibutionData = response.data;

            promoDistibutionData.forEach(element => {
                Object.assign(element, { label: element.name + " - " + element.distributionValue })
            });
            promoDistibutionData.sort((a, b) => {
                return a.name > b.name ? -1 : 1
            })
            promoDistibutionData.splice(0, 0, { label: "ALL", name: "ALL" })
            return promoDistibutionData
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const profileFetchActive = createAsyncThunk(
    "ProfileReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/profiles/active');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const OperatorActiveFetch = createAsyncThunk(
    "Common/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/operators/getActiveOperators', { type: 3 });
            //console.log("response", response.data)
            const operatorsData = response.data;
            operatorsData.forEach(element => {
                Object.assign(element, { label: element.name })
            });
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


export const ServiceProviderFetch = createAsyncThunk(
    "CommonServiceProviderFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/serviceProviders');
            //console.log("response", response.data)
            const serviceProvidersData = response.data;
            serviceProvidersData.forEach(element => {
                Object.assign(element, { label: element.name })
            });
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const branchTypeFetchActive = createAsyncThunk(
    "branchTypeFetchActive/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/branchType/active');

            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)
const CommonSlice = createSlice({
    name: "RetailerWalletReducer",
    initialState,
    reducers: {
        profileSet(state, action) {
            return {
                ...state,
                users: {
                    ...state.users,
                    ...action.payload
                }
            }
        },
        userDetailsSet(state, action) {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    ...action.payload
                },
                //menus: action.payload.menus
            }
        },
        userMenusSet(state, action) {
            return {
                ...state,
                menus: action.payload
            }
            //menus: action.payload.menus
        }

    },
    extraReducers: (builder) => {
        builder.addCase(retailersFetch.fulfilled, (state, action) => {
            return {
                ...state,
                retailers: action.payload
            }
        })
            .addCase(retailersFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(retailersFetch.rejected, (state, action) => {
                //console.log(action)
            })


            .addCase(operatorsFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    operators: action.payload
                }
            })
            .addCase(operatorsFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(operatorsFetch.rejected, (state, action) => {
                //console.log(action)
            })


            .addCase(agentsFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    agents: action.payload
                }
            })
            .addCase(agentsFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(agentsFetch.rejected, (state, action) => {
                //console.log(action)
            })
            .addCase(promoDistributionsFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    promoDistributions: action.payload
                }
            })
            .addCase(promoDistributionsFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(promoDistributionsFetch.rejected, (state, action) => {
                //console.log(action)
            })
            .addCase(profileFetchActive.fulfilled, (state, action) => {
                return {
                    ...state,
                    profiles: action.payload
                }
            })
            .addCase(profileFetchActive.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(profileFetchActive.rejected, (state, action) => {
                //console.log(action)
            }).addCase(branchesFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    branches: action.payload
                }
            })
            .addCase(branchesFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchesFetch.rejected, (state, action) => {
                //console.log(action)
            }).addCase(branchTypeFetchActive.fulfilled, (state, action) => {
                return {
                    ...state,
                    branchTypes: action.payload
                }
            })
            .addCase(branchTypeFetchActive.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchTypeFetchActive.rejected, (state, action) => {
                //console.log(action)
            }).addCase(OperatorActiveFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    activeOperators: action.payload
                }
            })
            .addCase(OperatorActiveFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(OperatorActiveFetch.rejected, (state, action) => {
                //console.log(action)
            }) .addCase(ServiceProviderFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(ServiceProviderFetch.rejected, (state, action) => {
                //console.log(action)
            }).addCase(ServiceProviderFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    serviceProviders: action.payload
                }
            })
    }

})

export const { profileSet, userDetailsSet, userMenusSet } = CommonSlice.actions;
export const CommonReducer = CommonSlice.reducer