import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SerialSummaryFetch } from '../../redux/slice/SerialSummarySlice';
import SnackBar from '../../components/snackbar';



const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row-reverse"
    }
  }
});
const SerialSummary = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.SerialSummaryReducer);
  const snackbarValues = selectValuesRe.sucessMessage;
  const tableData = selectValuesRe.SerialSummarys === undefined ? [] : selectValuesRe.SerialSummarys;
  const loader = selectValuesRe?.loader;
 // console.log("tableData", tableData)
  useEffect(() => {
    dispatch(SerialSummaryFetch())
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'spName',
        header: 'SP Name',
        enableColumnOrdering: false,
      },
      {
        accessorKey: 'code',
        header: 'Operator Code',
      },
      {
        accessorKey: 'slLength',
        header: 'SL length',

      },
      {
        accessorKey: 'pinLength',
        header: 'Pin length',

      },

    ],
    [],
  );

  return (
    < div className={classes.root}>
      <MaterialReactTable
        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}

        muiTableBodyRowProps={{
          sx: {
            padding: "0px"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX"
          }
        }}
        initialState={{
          density: "compact",
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        state={{
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        enableColumnOrdering

      />
      <SnackBar dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />
      <div>
      </div>
    </div>
  );
};





export default SerialSummary;
