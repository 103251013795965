import { Autocomplete, Grid, IconButton, Menu, MenuItem, TextField } from "@mui/material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ButtonComponent from "../../components/button"
import { operatorsFetch } from "../../redux/slice/CommonSlice"
import { Download } from "@mui/icons-material"

export default function OperatorCommonComponent(props) {
    const { selectValuesRe, handleQuery, operatorCodeChange, handleInputChange2, lastAdded, handlePrint, handlePdf, handleReset, handleInputChange } = props;
    const dispatch = useDispatch()
    const commonValues = useSelector(state => state.CommonReducer);
    //console.log("commonValues", commonValues)
    const operators = commonValues?.operators;


    useEffect(() => {
        dispatch(operatorsFetch())
    }, [operators?.length])
    const autoCompleteChange = (e, typeNewValue, name) => {
      //  console.log("typeNewValue", typeNewValue)
        operatorCodeChange(typeNewValue)
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid container>
                <Grid item lg={3} md={4} sm={4}>
                    <Autocomplete
                        id="OperatorCode"
                        options={operators}
                        value={lastAdded.operatorCodeDisplay}
                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "operator") }}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='operator' error={lastAdded.operatorError} helperText={lastAdded.operatorHelperText} {...params} label="Operator" /></Grid>}
                    />
                </Grid>
                <Grid item lg={3} md={4} sm={4}>
                    <div style={{ width: "90%" }}>
                        {/* <label>Start Date</label> */}
                        <TextField value={lastAdded.startDate} type="date"
                            onChange={handleInputChange2}
                            //     style={{ padding: "9.5px" }}
                            name="startDate" label="Start Date" fullWidth={true} />
                    </div>
                </Grid>
                <Grid item lg={3} md={4} sm={4}>  <div style={{ width: "90%" }}>
                    {/* <label>End Date</label> */}
                    <TextField value={lastAdded.endDate} type="date" placeholder="Date"
                        onChange={handleInputChange2}
                        name="endDate" label="End Date" fullWidth={true} /></div>

                </Grid>
                <Grid item lg={3} md={8} sm={8}>
                    <ButtonComponent
                        style={{ width: "10%" }} variant='contained' color='primary' label="Query"
                        onClick={handleQuery}
                        disabled={!((lastAdded.startDate) && (lastAdded.endDate))}
                    >
                    </ButtonComponent>&nbsp;
                    <ButtonComponent
                        style={{ width: "10%" }} variant='contained' color='primary' label="Reset"
                        onClick={handleReset}
                        disabled={!((lastAdded.startDate) && (lastAdded.endDate))}
                    >
                    </ButtonComponent> &nbsp;
                    <IconButton
                        variant='contained' color='primary' label="Actions"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        style={{ width: "10%" }}
                        disabled={!((lastAdded.startDate) && (lastAdded.endDate))}
                    ><Download />
                    </IconButton>
                    <Menu
                        id="download-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handlePdf}>Download Pdf</MenuItem>
                        {/* <MenuItem onClick={handleClose}>Download Excel</MenuItem> */}
                        <MenuItem onClick={handlePrint}>Print</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </>
    )
}