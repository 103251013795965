import React from 'react';
import { Autocomplete, Box, Grid, Modal, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Input from '../../components/input';
import SelectDropdown, { SelectDropdownRechargeFactor } from "../../components/Select";
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { promoConfigAdd, promoConfigLastAdded,  promoConfigUpdate, popupOpenAndClosed } from '../../redux/slice/PromoConfigSlice';
import { SERVICE_PROVIDER_NAME_IS_REQUIRED,THIS_FIELD_IS_REQUIRED } from '../../constfiles/formValidation';


export default function PromoConfigAddandEdit({promoDistribution, createModalOpen, popupMode, lastAdded }) {
  //console.log("Last added", lastAdded)
  const dispatch = useDispatch()
  const winnerDistributions = [];
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    borderRadius: "4px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // const handleSubmit = () => {
  //   //put your validation logic here
  //   onSubmit(values);
  //   onClose();
  // };

  function validation() {
    const errorObj = {}
    const {promoConfigWinnerText} = lastAdded;
   // console.log("lastAdded",lastAdded)
      // if(promoConfigCode) {
      //   if(promoConfigCode.trim() ===""){
      // //  dispatch(promoConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
      //     Object.assign(errorObj,{["promoConfigCode" + "Error"]: true,["promoConfigCode" + "HelperText"]:OPERATOR_CODE_IS_REQUIRED })
          
      //   }
      //   if(promoConfigCode.length < 3) {
      //     Object.assign(errorObj,{["promoConfigCode" + "Error"]: true,["promoConfigCode" + "HelperText"]:OPERATOR_CODE_MINIMUM_3_CHACRTERS  })
      // //  dispatch(promoConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_MINIMUM_3_CHACRTERS  }))
      //   }
      //   else{
      //     Object.assign(errorObj,{["promoConfigCode" + "Error"]: false,["promoConfigCode" + "HelperText"]:""})
      //   // dispatch(promoConfigLastAdded())
      //   }
      // }
      if(promoConfigWinnerText) {
        if(promoConfigWinnerText.trim()===""){
          //  dispatch(promoConfigLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["promoConfigWinnerText" + "Error"]: true,["promoConfigWinnerText" + "HelperText"]:SERVICE_PROVIDER_NAME_IS_REQUIRED })
        }
      }
    
    
    
      dispatch(promoConfigLastAdded(errorObj))
      const check = Object.values(errorObj).includes( THIS_FIELD_IS_REQUIRED)
      //console.log(" Object.values(errorObj)", Object.values(errorObj))
      //console.log("check",check)
      return check
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // if(validation()) {
    
    dispatch(promoConfigLastAdded({ [name]: value, [name + "Touched"]: true}))
    // dispatch(promoConfigLastAddedValidate({[name]: value }))
    //}
  }
const buttonUpdate = () =>{
  // alert("i")
  if(!validation()){
    dispatch(promoConfigUpdate({promoConfigId:lastAdded.promoConfigId,  promoConfigWinnerText:lastAdded.promoConfigWinnerText,rechargeFactor:lastAdded.rechargeFactor,rechargePrice:lastAdded.rechargePrice, rechargeValue:lastAdded.rechargeValue,  winnerDistribution:lastAdded.winnerDistribution,active:lastAdded.active}))
  }
}
  const buttonSave = () => {
   
     
      if(!validation()){
        dispatch(promoConfigAdd({promoConfigId:lastAdded.promoConfigId,  promoConfigWinnerText:lastAdded.promoConfigWinnerText,rechargeFactor:lastAdded.rechargeFactor,rechargePrice:lastAdded.rechargePrice, rechargeValue:lastAdded.rechargeValue,  winnerDistribution:lastAdded.winnerDistribution,active:lastAdded.active}))
      }
      //alert(check)
      //  dispatch(promoConfigAdd(lastAdded))
    
      ////console.log("checkingFlag",checkingFlag)
      //alert(checkingFlag)
    // else {
    //   dispatch(promoConfigAdd(lastAdded))
    // }
  }
  const autoCompleteChange = (e, typeNewValue, name) => {
  //  console.log("typeNewValue", typeNewValue)
    dispatch(promoConfigLastAdded({ [name]: typeNewValue.id, [name + "Touched"]: true}))
}
  return (
    <>

      <Modal
        open={createModalOpen}
        onClose={() => dispatch(popupOpenAndClosed({ open: false }))}
        aria-labelledby="promoConfig-title"
        aria-describedby="promoConfig-description"
      >
        <Box sx={style}>
          <h3>{popupMode === "add" ? "Promo Config Create": "Promo Config Edit - "+`${lastAdded.promoConfigWinnerText}` }</h3>
          <Grid container spacing={2}>
            {/* <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.promoConfigCode} touched={lastAdded.promoConfigCodeTouched} error={lastAdded.promoConfigCodeError } helperText={lastAdded.promoConfigCodeHelperText} onChange={handleInputChange} name="promoConfigCode" label="promoConfig Code" fullWidth={true} maxLength="3" />
            </Grid> */}

            <Grid item lg="4" md={4} sx="4" sm={4}>
              <Input value={lastAdded.promoConfigWinnerText} touched={lastAdded.promoConfigWinnerTextTouched} onChange={handleInputChange} error={lastAdded.promoConfigWinnerTextError} helperText={lastAdded.promoConfigWinnerTextHelperText} name="promoConfigWinnerText" label="Winner Text" fullWidth={true} maxLength="200" />
            </Grid>
           
            <Grid item lg="4" md={4} sx="12" sm={4}>
              <SelectDropdown value={lastAdded.active} onChange={handleInputChange} name="active" label="Active" fullWidth={true} />
            </Grid>

            <Grid item lg={4} md={4} sm={4}>
                    {/* <label>Winner Distribution</label> */}
                    <Autocomplete
                        id="WinnerDistribution"
                        options={promoDistribution}
                        value={lastAdded.winnerDistributionDisplay}
                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "winnerDistribution") }}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='winnerDistribution' error={lastAdded.winnerDistributionError} helperText={lastAdded.winnerDistributionHelperText} {...params} label="Winner Distribution" /></Grid>}
                    />
                </Grid>

            <Grid item lg="4" md={4} sx="12" sm={4}>
              <SelectDropdown value={lastAdded.rechargePrice} onChange={handleInputChange} name="rechargePrice" label="Recharge Price" fullWidth={true} />
            </Grid>
            <Grid item lg="4" md={4} sx="12" sm={4}>
              <SelectDropdownRechargeFactor value={lastAdded.rechargeFactor} onChange={handleInputChange} name="rechargeFactor" label="Recharge Factor" fullWidth={true} />
            </Grid>
            
            <Grid item lg="4" md={4} sx="4" sm={4}>
              <Input value={lastAdded.rechargeValue} touched={lastAdded.rechargeValueTouched} onChange={handleInputChange} error={lastAdded.rechargeValueTextError} helperText={lastAdded.rechargeValueHelperText} name="rechargeValue" label="Recharge Value" fullWidth={true} maxLength="20" />
            </Grid>
            <Grid item lg="12" md={12} xs="12" sx={{
              textAlign: "center", display: "flex", columnGap: "2%", justifyContent: "center"
            }} sm={12}>
             {popupMode === "add" ?  <ButtonComponent onClick={buttonSave} label="Save" name="promoConfigAddButton"
                color="primary"
                disabled={( lastAdded.promoConfigWinnerText.length) === 0 ? true : false}
                type="add"
                variant="contained" /> :  <ButtonComponent onClick={buttonUpdate} label="Update" name="promoConfigUpdateButton"
                color="primary"
                disabled={(lastAdded.promoConfigWinnerText.length) === 0 ? true : false}
                type="add"
                variant="contained" />}
              <ButtonComponent label="Close" name="close"
                color="primary"
                disabled={false}
                onClick={() => dispatch(popupOpenAndClosed({ open: false }))}
                type="close"
                variant="contained" />




            </Grid>
          </Grid>


        </Box>
      </Modal>
    </>
  );
};
PromoConfigAddandEdit.prototype = {
  columns: PropTypes.array
}