import React, { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { saveAs } from 'file-saver';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip, } from '@mui/material';
import { Download } from '@mui/icons-material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/input';
import { ModifiedDate } from '../../utlis/CurrentDateGenerator';
import { VoucherReportPdfLastAdded, VoucherReportStockFetch, VoucherReportPdfReset, handleLoader } from '../../redux/slice/VoucherReportSlice';
import { useEffect } from 'react';
import { handleDownloadForFileName, printTickets } from '../../utlis/PdfFunc';
import DownloadButton from '../../components/DownloadButton';
import { THEME_COLOR } from '../../constfiles/theme';


const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            justifyContent: "end",
            flexDirection: "row"
        },
        '& .MuiIconButton-colorPrimary:hover': {
            background: THEME_COLOR,
            padding:"1.8%"
          },
    }
});
const EvdVoucherPdfReport = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const selectValuesRe = useSelector(state => state.VoucherReportpdfReducer);
    const lastAdded = selectValuesRe.lastAdded;
    const tableData = selectValuesRe?.voucherReports;
    const loader = selectValuesRe?.loader;
    useEffect(() => {
        dispatch(VoucherReportPdfReset())
        return () => {
            dispatch(VoucherReportPdfReset())
        }
    }, [])
    // async function printTickets(fileName) {
    //     //alert(fileName)
    //     const { data } = await getTicketsPdf(fileName)
    //     const blob = new Blob([data], { type: 'application/pdf' })
    //     saveAs(blob, fileName)
    // }

    // async function getTicketsPdf(fileName) {
    //     //alert(fileName)
    //     return axios.post('flexiReport/download', { "fileName": fileName }, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         },
    //         responseType: 'arraybuffer'
    //     })
    // }
    const handleDownload = (fileName) => {
        dispatch(handleLoader(true))
        printTickets(fileName, 'flexiReport/download')
        setTimeout(()=>{
            dispatch(handleLoader(false))
        },2000)
       
    }

    const handleQuery = () => {
       // dispatch(handleLoader(true))
        dispatch(VoucherReportStockFetch({ reportType: lastAdded.reportType, reportDate: lastAdded.dateModified }))
        //dispatch(handleLoader(false))
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'fileName',
                header: 'File Name',
                enableColumnOrdering: false,
                //enableEditing: false, //disable editing on this column
                enableSorting: false,
                //  size: 80,
            },


        ],
        [],
    );

    const handleReset = () => {
        dispatch(VoucherReportPdfReset())
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        dispatch(VoucherReportPdfLastAdded({ [name]: value, [name + "Touched"]: true }))
        if (name === "date") {
            dispatch(VoucherReportPdfLastAdded({ dateModified: ModifiedDate(value) }))
        }
    }
    const handlePdf = (value) =>{
        handleDownloadForFileName(value,"Voucher Report","voucher_report.pdf",tableData)
      }
    return (
        < div className={classes.root}>
            {/* <Paper sx={{ padding: "2%", margin: "2% 0%" }}>
              
            </Paper> */}
            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                renderTopToolbarCustomActions={() => (
                    <Grid container spacing={2}>
                        <Grid item lg="4" md={4} sx="4" sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id="reportType-simple-select-label">Report</InputLabel>
                                <Select
                                    labelId="reportType-simple-select-label"
                                    id="reportType-simple-select"
                                    value={lastAdded.reportType}
                                    label="Age"
                                    name='reportType'
                                    onChange={handleChange}
                                >
                                    <MenuItem value="null">Select Report Type</MenuItem>
                                    <MenuItem value="101">Daily Voucher Redemption Report</MenuItem>
                                    <MenuItem value="102">Daily Voucher Stock Report</MenuItem>

                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item lg="4" md={4} sm={4} >


                            <Input value={lastAdded.date} type="date" touched={lastAdded.dateTouched} placeholder="Date" onChange={handleChange} name="date" label="" fullWidth={true} />
                            <span style={{ color: "red" }}>{lastAdded.dateError}</span>

                        </Grid>
                        <Grid item lg="4" md={4} sx={{ margin: "auto" }} sm={4}>
                            <Button variant='contained' disabled={lastAdded.reportType === "null"} color='primary' onClick={handleQuery}>Query</Button>&nbsp;
                            <Button variant='contained' color='primary' onClick={handleReset}>Reset</Button>&nbsp;
                            <DownloadButton classes={classes} handlePdf={() => { handlePdf(1) }} handlePrint={() => { handlePdf(2) }} />

                        </Grid>
                    </Grid>
                )}
                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                positionActionsColumn="last"
                // editingMode="modal" //default
                enableColumnOrdering

                localization={{
                    actions: "Download"
                }}
                enableEditing
                renderRowActions={({ row, table }) => (

                    <>

                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: "center" }}>
                            <Tooltip arrow placement="right" title="Download">
                                <IconButton onClick={() => handleDownload({fileName:row.original.fileName, reportDate:row.original.reportDate})} >
                                    <Download />
                                </IconButton>
                            </Tooltip>
                        </Box></>
                )}

            />

            <div>

            </div>


        </div>
    );
};


export default EvdVoucherPdfReport;
