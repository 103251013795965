import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    availableStocks: [],
    allStocks:[],
    loader:false
    

}

export const availableStockFetch = createAsyncThunk(
    "availableStockFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/availableStock');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const allStockFetch = createAsyncThunk(
    "allStockFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/allStock');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


//console.log("availableStockFetch", availableStockFetch)

const StockFetchSlice = createSlice({
    name: "availableStockFetchReducer",
    initialState,

    reducers: {
        handleVoucherAvailableStockResetDispatch (state, action) {
            return {
                ...state,
                availableStocks : []
            }
        },
        handleVoucherAllStockResetDispatch (state, action) {
            return {
                ...state,
                allStocks : []
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(availableStockFetch.fulfilled, (state, action) => {
            return {
                ...state,
                availableStocks: action.payload,
                loader :false,              
            }
        })
            .addCase(availableStockFetch.pending, (state, action) => {
                return {
                    ...state,
                                    
                    loader :true,              
                }
            })
            .addCase(availableStockFetch.rejected, (state, action) => {
                return {
                    ...state,
                    availableStocks: action.payload,
                    loader :false,              
                }
            })
            builder.addCase(allStockFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    allStocks: action.payload,
                    loader:false
                }
            })
                .addCase(allStockFetch.pending, (state, action) => {
                    //console.log(action)
                    return {
                        ...state,
                        loader:true
                    }
                })
                .addCase(allStockFetch.rejected, (state, action) => {
                    //console.log(action)
                    return {
                        ...state,
                        loader:false
                    }
                })
    }

})
export const {handleVoucherAvailableStockResetDispatch,handleVoucherAllStockResetDispatch} = StockFetchSlice.actions
export const VoucherStockReducer = StockFetchSlice.reducer