import { makeStyles } from '@material-ui/styles';
export const useStylesInput = makeStyles({
    root: {       
        "& .css-63aude-MuiFormLabel-root-MuiInputLabel-root" : {
            lineHeight : "0.9em",
          color:"red"
        },
        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input" : {
            height : "10px"
        },
       
    },
  
    input: {
        //height :"10px"
      },
});