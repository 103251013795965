import React from 'react';
import { AppBar, Box, Dialog, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { Clear } from '@mui/icons-material';
import { checkUserName, createNewUser, handleNewUserInputChange, handleResetUserNameRe, handleValidation } from '../../redux/slice/UserSlice';
import SelectDropdown, { SelectDropdownProfiles } from '../../components/Select';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { profileFetchActive } from '../../redux/slice/CommonSlice';
import { THEME_COLOR } from '../../constfiles/theme';

export default function NewUser({ newSysuserModalOpen, newUser, setNewSysuserModalOpen }) {
  
    const dispatch = useDispatch();
    const selectValuesRe = useSelector(state => state.CommonReducer);
    const UserReducer = useSelector(state => state.UserReducer);
    const fieldEnable = !UserReducer.fieldEnable;
   // const profiles = selectValuesRe.profiles === undefined ? [] : selectValuesRe.profiles.filter(item=>item.id === 20 || item.id === 21 || item.id === 22 || item.id === 60 || item.id === 90);
   const profiles = selectValuesRe.profiles ;
    const reservedFieldEnable = !UserReducer.reservedFieldEnable;
    const errorObj = UserReducer.newUserFormValidation;
    const responseCode = UserReducer.responseCode;
   // console.log("errorObj", errorObj)
    useEffect(() => {
        dispatch(profileFetchActive())
    }, [profiles.length])
    useEffect(() => {
        dispatch(handleResetUserNameRe())
    }, [])
    const style = {
        // position: 'absolute',
        //top: '40%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: "100%",
        borderRadius: "4px",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        padding: "0px"
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
     //   console.log(name,value)
        dispatch(handleNewUserInputChange({ [name]: value }))
    }

    const handlePopupClose = (e, userId) => {

        dispatch(handleResetUserNameRe())
        setNewSysuserModalOpen(false)
    }


    const handleResetUserName = (e, userId) => {
        dispatch(handleResetUserNameRe())
    }
    const handleCheckUserName = () => {
      //  alert(newUser.userName)
        const insideErrorObj = {userName:""}
        if (newUser?.userName?.length  === 0) {
          //  alert("d")
            Object.assign(insideErrorObj, { userName: "User Name is Required" })
        }
        else if (newUser?.userName?.length  <= 4) {
            //  alert("d")
              Object.assign(insideErrorObj, { userName: "User Name Should be moer than 4 characters" })
          }
        dispatch(handleValidation(insideErrorObj))
        const checking = Object.values(insideErrorObj).every((item) => item.trim() === "")
       // console.log("insideErrorObj 2",insideErrorObj)
      //  console.log(checking)
        if (checking) {
            dispatch(checkUserName({ userName: newUser.userName }))
        }
    }

    const createUser = () => {
        const insideErrorObj = {};
        const firstName = newUser.firstName;
        const lastName = newUser.lastName;
        const password = newUser.password;
        const repassword = newUser.repassword;
        const active = newUser.active;
        const profile = newUser.profile;
        const startDate = newUser.startDate;
        const endDate = newUser.endDate;
        const email = newUser.email;
        if (firstName) {
            if (firstName?.length <= 0) {
                Object.assign(insideErrorObj, { firstName: "First Name is Required" })
                //dispatch(handleValidation({ firstName: "First Name is Required" }))
            }
            else {
                Object.assign(insideErrorObj, { firstName: "" })
                //dispatch(handleValidation({ firstName: "" }))
            }
        }
        else {
            //dispatch(handleValidation({ firstName: "First Name is Required" }))
            Object.assign(insideErrorObj, { firstName: "First Name is Required" })
        }
        if (lastName) {
            if (lastName?.length <= 0) {
                // dispatch(handleValidation({ lastName: "Last Name is Required" }))
                Object.assign(insideErrorObj, { lastName: "Last Name is Required" })
            }
            else {
                // dispatch(handleValidation({ lastName: "" }))
                Object.assign(insideErrorObj, { lastName: "" })
            }
        }
        else {
            //  dispatch(handleValidation({ lastName: "Last Name is Required" }))
            Object.assign(insideErrorObj, { lastName: "Last Name is Required" })
        }
        if (password) {
            if (password?.length <= 0) {
                //   dispatch(handleValidation({ password: "Password is Required" }))
                Object.assign(insideErrorObj, { password: "Password is Required" })
            }
            else {
                // dispatch(handleValidation({ password: "" }))
                Object.assign(insideErrorObj, { password: "" })
            }
        }
        else {
            Object.assign(insideErrorObj, { password: "Password is Required" })
            //   dispatch(handleValidation({ password: "Password is Required" }))
        }
        if (repassword) {
            if (repassword?.length <= 0) {
                Object.assign(insideErrorObj, { repassword: "Re-Password is Required" })
                // dispatch(handleValidation({ repassword: "Re-Password is Required" }))
            }
            else if (repassword !== password) {
                //  dispatch(handleValidation({ repassword: "Password and Repassword Should be same" }))
                Object.assign(insideErrorObj, { repassword: "Password and Repassword Should be same" })
            }
            else {
                //  dispatch(handleValidation({ repassword: "" }))
                Object.assign(insideErrorObj, { repassword: "" })
            }
        }
        else {
            Object.assign(insideErrorObj, { repassword: "Re-Password is Required" })
            //dispatch(handleValidation({ repassword: "Re-Password is Required" }))
        }
        if (active) {
            if (active?.length <= 0) {
                Object.assign(insideErrorObj, { active: "Active is Required" })
                //  dispatch(handleValidation({ active: "Active is Required" }))
            }
            else if ((active !== "Y") && (active !== "N")) {
                Object.assign(insideErrorObj, { active: "Active is Required" })
            }
            else {
                Object.assign(insideErrorObj, { active: "" })
            }
        }
        else {
            Object.assign(insideErrorObj, { active: "Active is Required" })
        }
        if (profile) {
            if (profile?.length <= 0) {
                Object.assign(insideErrorObj, { profile: "Profile is Required" })
                //dispatch(handleValidation({ profile: "Profile is Required" }))
            }
            else {
                Object.assign(insideErrorObj, { profile: "" })
            }
        }
        else {
            Object.assign(insideErrorObj, { profile: "Profile is Required" })
        }
        if (startDate) {
            if (endDate.length <= 0) {
                Object.assign(insideErrorObj, { endDate: "End Date is Required" })

            }
            else if (startDate > endDate) {
                Object.assign(insideErrorObj, { endDate: "End date should not behind start date" })
                //dispatch(handleValidation({ endDate: "End date should not behind start date" }))
            }
            else {
                Object.assign(insideErrorObj, { endDate: "" })
                //dispatch(handleValidation({ endDate: "" }))
            }
        }
        if (email) {
            if (email?.length <= 0) {
                Object.assign(insideErrorObj, { email: "E-Mail is Required" })
                //  dispatch(handleValidation({ email: "E-Mail is Required" }))
            }
            else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                //dispatch(handleValidation({ email: "Please Enter valid email" }))
                Object.assign(insideErrorObj, { email: "Please Enter valid email" })
            }
            else {
                Object.assign(insideErrorObj, { email: "" })
                //   dispatch(handleValidation({ email: "" }))
            }
        }
        else {
            Object.assign(insideErrorObj, { email: "E-Mail is Required" })
            // dispatch(handleValidation({ email: "E-Mail is Required" }))
        }
       // console.log(insideErrorObj)
        dispatch(handleValidation(insideErrorObj))
        const checking = Object.values(insideErrorObj).every((item) => item.trim() === "")
        //console.log("checking", checking)
       // console.log("checking", errorObj)
        if (checking) {
            dispatch(createNewUser({
                userName: newUser.userName, firstName: firstName, lastName: lastName
                , password: password, repassword: repassword, active: active, startDate: startDate,
                endDate: endDate, profile: profile, email: newUser.email
            }))  
            if(responseCode) {
                setNewSysuserModalOpen(false)
            }          
        }
        //}
    }
    return (
        <>
            <Dialog
                fullScreen
                open={newSysuserModalOpen}

            >
                <AppBar sx={{ position: 'relative' }} style={{ background: THEME_COLOR }} >
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6" component="div">
                            New  User Create
                        </Typography>
                        <Typography variant="h6" component="div"> <IconButton style={{ color: "white" }} onClick={() => handlePopupClose()}> <Clear /></IconButton></Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={style}>
                    <table style={{ width: "100%", border: "1px solid black" }}>
                        <tr style={{ border: "1px solid black" }}>
                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }}>
                                <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="UserName"
                                    label="User Name"
                                    name="userName"
                                    autoComplete="off"
                                    disabled={reservedFieldEnable}
                                    readOnly={reservedFieldEnable}
                                    value={newUser.userName}
                                    error={errorObj.userName}
                                    helperText={errorObj.userName}
                                    onChange={handleInputChange}

                                //inputProps={{ maxLength: 4 }}
                                /> </th>
                            <th style={{ textAlign: "left", padding: "0% 10px" }}>
                                <ButtonComponent variant='contained' color='primary' label="Check User Name"
                                    onClick={(e) => { handleCheckUserName(e) }}
                                    disabled={reservedFieldEnable}

                                >

                                </ButtonComponent>
                                <ButtonComponent variant='contained' color='primary' label="Reset"
                                    onClick={(e) => { handleResetUserName() }}
                                // disabled={reservedFieldEnable}

                                ></ButtonComponent>
                                {newUser.userNameMessageStatus === "success" ?
                                    <span style={{ color: "blue" }}> {newUser.userNameMessage}</span> :
                                    <span style={{ color: "red" }}> {newUser.userNameMessage}</span>
                                }
                            </th>

                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }} >
                                <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="FirstName"
                                    label="First Name"
                                    disabled={fieldEnable}
                                    readOnly={fieldEnable}
                                    name="firstName"
                                    autoComplete="off"
                                    value={newUser.firstName}
                                    error={errorObj.firstName}
                                    helperText={errorObj.firstName}
                                    onChange={handleInputChange} /></th>

                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }} > <TextField
                                margin="normal"
                                required
                                style={{ verticalAlign: "sub" }}
                                fullWidth
                                id="LastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="off"
                                disabled={fieldEnable}
                                error={errorObj.lastName}
                                helperText={errorObj.lastName}
                                readOnly={fieldEnable}
                                value={newUser.lastName}
                                onChange={handleInputChange} /> </th>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }} >
                                <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="Password"
                                    label="Password"
                                    name="password"
                                    disabled={fieldEnable}
                                    readOnly={fieldEnable}
                                    autoComplete="off"
                                    value={newUser.password}
                                    error={errorObj.password}
                                    helperText={errorObj.password}
                                    type="password"
                                    onChange={handleInputChange} /></th>

                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }} >
                                <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="RePassword"
                                    disabled={fieldEnable}
                                    readOnly={fieldEnable}
                                    label="RePassword"
                                    name="repassword"
                                    autoComplete="off"
                                    value={newUser.repassword}
                                    type="password"
                                    error={errorObj.repassword}
                                    helperText={errorObj.repassword}
                                    onChange={handleInputChange} /> </th>
                        </tr>
                        <tr style={{ border: "" }}>
                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }} >
                                <SelectDropdown disabled={fieldEnable}
                                    readOnly={fieldEnable} error={errorObj.active} value={newUser.active} onChange={handleInputChange} name="active" label="Active" fullWidth={true} />
                            </th>
                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }} >
                                <SelectDropdownProfiles disabled={fieldEnable}
                                    readOnly={fieldEnable} error={errorObj.profile} options={profiles} value={newUser.profile} onChange={handleInputChange} name="profile" label="Profile" fullWidth={true} />
                            </th>

                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }} >
                                <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="StartDate"
                                    label="Start Date"
                                    name="startDate"
                                    autoComplete="off"
                                    disabled={fieldEnable}
                                    readOnly={fieldEnable}
                                    error={errorObj.startDate}
                                    helperText={errorObj.startDate}
                                    value={newUser.startDate}
                                    type="date"
                                    onChange={handleInputChange} /></th>

                            <th style={{ border: "", textAlign: "left", padding: "0% 10px" }} >
                                <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="EndDate"
                                    label="End Date"
                                    name="endDate"
                                    disabled={fieldEnable}
                                    readOnly={fieldEnable}
                                    autoComplete="off"
                                    error={errorObj.endDate}
                                    helperText={errorObj.endDate}
                                    value={newUser.endDate}
                                    type="date"
                                    onChange={handleInputChange} /> </th>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <th colspan={2} style={{ border: "", textAlign: "left", padding: "0% 10px" }} >
                                <TextField
                                    margin="normal"
                                    required
                                    style={{ verticalAlign: "sub" }}
                                    fullWidth
                                    id="Email"
                                    label="E - Mail"
                                    disabled={fieldEnable}
                                    readOnly={fieldEnable}
                                    name="email"
                                    autoComplete="off"
                                    value={newUser.email}
                                    error={errorObj.email}
                                    helperText={errorObj.email}
                                    type="email"
                                    onChange={handleInputChange} /></th>
                        </tr>
                        {/* <tr >
                            <th style={{ textAlign: "center" }} colSpan={2}>
                                <ButtonComponent label="Close" name="close"
                                    color="primary"
                                    disabled={false}
                                    onClick={() => setNewSysuserModalOpen(false)}
                                    type="close"
                                    variant="contained" />


                            </th>
                        </tr> */}
                    </table>
                    <Grid style={{ textAlign: "center", marginTop: "2%" }}>
                        <ButtonComponent label="Create" name="Create"
                            color="primary"
                            disabled={fieldEnable}
                            onClick={() => createUser()}

                            type="button"
                            variant="contained" />
                        <ButtonComponent label="Close" name="close"
                            color="primary"

                            onClick={() => handlePopupClose()}
                            type="close"
                            variant="contained" />

                    </Grid>

                </Box>
            </Dialog>


        </>
    )
}