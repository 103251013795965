import { Autocomplete, Grid, Menu, MenuItem, TextField } from "@mui/material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ButtonComponent from "./button"
import { agentsFetch } from "../redux/slice/CommonSlice"
import Input from "./input"
import { agentFlexiLastAdded } from "../redux/slice/AgentFlexiReportSlice"


export default function DateBetweenComponent(props) {
    const { handleInputDateChange, handleQuery, agentCodeChange, handleChange, lastAdded, handlePrint, handlePdf } = props;
    const dispatch = useDispatch()
    const commonValues = useSelector(state => state.CommonReducer);
    const agents = commonValues?.agents;
   // console.log("LastAdded",lastAdded)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {/* <Grid container> */}
               
                <Grid item lg={4} md={4} sm={4}>
                    <div style={{ width: "90%" }}>
                        <label>Start Date</label>
                        <Input value={lastAdded.startDate} type="date" touched={lastAdded.startDateTouched} placeholder="Date"
                            onChange={handleInputDateChange}
                            name="startDate" label="" fullWidth={true} />
                        <span style={{ color: "red" }}>{lastAdded.startDateError}</span></div>
                </Grid>
                <Grid item lg={4} md={4} sm={4}>  <div style={{ width: "90%" }}>
                    <label>End Date</label>
                    <Input value={lastAdded.endDate} type="date" touched={lastAdded.endDateTouched} placeholder="Date"
                        onChange={handleInputDateChange}
                        name="endDate" label="" fullWidth={true} />
                    <span style={{ color: "red" }}>{lastAdded.endDateError}</span></div>
                </Grid>
{/* 
                <Grid item lg={4} md={4} sm={4} sx={{ paddingTop: "0.5%", textAlign: "center" }}>
                    <ButtonComponent
                        variant='contained' color='primary' label="Query"
                        onClick={handleQuery}
                    >
                    </ButtonComponent>&nbsp;
                    <ButtonComponent
                        variant='contained' color='primary' label="Reset"
                    //  onClick={handleReset}
                    >
                    </ButtonComponent> &nbsp;
                    <ButtonComponent
                        variant='contained' color='primary' label="Actions"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}

                    >
                    </ButtonComponent>
                    <Menu
                        id="download-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handlePdf}>Download Pdf</MenuItem>
                        <MenuItem onClick={handleClose}>Download Excel</MenuItem>
                        <MenuItem onClick={handlePrint}>Print</MenuItem>
                    </Menu>
                </Grid> */}
            {/* </Grid> */}
        </>
    )
}