import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate, ModifiedDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    inventorySplitReports: [],
    inventorySplitReportDetails: [],
    lastAdded: { startDate: currentDate, endDate: currentDate },
    loader: false

}

export const InventorySplitFetch = createAsyncThunk(
    "InventorySplitFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/inventorySplit', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const InventoryDetailFetch = createAsyncThunk(
    "InventorySplitFetch/detail", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/inventorySplit/detail', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const InventorySplitSlice = createSlice({
    name: "InventorySplitReducer",
    initialState,
    reducers: {
        inventorySplitLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },

            }
        },
        handleResetInventorySplitReport(state, action) {
            return {
                ...state,
                inventorySplitReports: [],
                inventorySplitReportDetails : [],
                lastAdded: { startDate: currentDate, endDate: currentDate },
                loader: false

            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(InventorySplitFetch.fulfilled, (state, action) => {
            return {
                ...state,
                inventorySplitReports: action.payload,
                loader: false
            }
        })
            .addCase(InventorySplitFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(InventorySplitFetch.rejected, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: false
                }
            }).addCase(InventoryDetailFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    inventorySplitReportDetails: action.payload,
                    loader: false
                }
            })
                .addCase(InventoryDetailFetch.pending, (state, action) => {
                    return {
                        ...state,
                        loader: true
                    }
                })
                .addCase(InventoryDetailFetch.rejected, (state, action) => {
                    return {
                        ...state,
                        loader: false
                    }
                })
    }

})

export const { inventorySplitLastAdded, handleResetInventorySplitReport } = InventorySplitSlice.actions;
export const InventorySplitReducer = InventorySplitSlice.reducer