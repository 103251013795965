import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const intialNewuser = {
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    active: "",
    profile: "",
    password: "",
    repassword: "",
    startDate: "",
    endDate: "",
    availableMessage: "",
    userNameMessage: "",
    userNameMessageStatus: ""
}
const initialState = {
    users: [],
    controlEditValidation : {},
    fieldEnable: false,
    reservedFieldEnable: true,
    passwordResets: {
        password: "",
        repassword: ""
    },
    newUser: {
        userName: "",
        firstName: "",
        lastName: "",
        email: "",
        active: "",
        profile: "",
        password: "",
        repassword: "",
        startDate: "",
        endDate: "",
        availableMessage: "",
        userNameMessage: "",
        userNameMessageStatus: ""
    },
    newUserFormValidation: {

    },
    currentOtp: {
        otpValue: "",
        date: ""
    },
    controlDetail: {
        otps: [{
            "staticOtp": ""
        }]
    },
    dynamicOtpDetails: {
        qrCode: "",
        pin: "",
    },
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },


}

export const systemUserFetch = createAsyncThunk(
    "systemUserFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/sysuser');
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const systemUserEditOtpModal = createAsyncThunk(
    "systemUserEditOtpModal/edit", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/sysuser/dynamicotp/detail', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const systemUserControlModal = createAsyncThunk(
    "systemUserControlModal/edit", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/sysuser/control/detail', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const getCurrentOtp = createAsyncThunk(
    "systemUserEditOtpModal/getCurrentOtp", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('sysuser/dynamicotp/currentOtp', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const pinUpdate = createAsyncThunk(
    "systemUserEditOtpModal/pinUpdate", async (arg, thunkAPI) => {
        try {
            console.log("arg", arg)

            const response = await axios.post('/sysuser/dynamicotp/pinupdate', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const GenerateKey = createAsyncThunk(
    "systemUserEditOtpModal/GenerateKey", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/sysuser/dynamicotp/generateKey', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)

export const handleLock = createAsyncThunk(
    "systemUserControlOtpModal/handleLock", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/sysuser/control/lock', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const handleUnLock = createAsyncThunk(
    "systemUserControlOtpModal/handleUnLock", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/sysuser/control/unlock', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const handlePasswordReset = createAsyncThunk(
    "systemUserControlOtpModal/handlePasswordReset", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/sysuser/control/passwordReset', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const handleUpdateEdit = createAsyncThunk(
    "systemUserControlOtpModal/handleUpdateEdit", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/sysuser/control/editupdate', arg);
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const handleStaticOTP = createAsyncThunk(
    "systemUserControlOtpModal/handleStaticOTP", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/sysuser/control/staticotp/update', arg);
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const updateOtpType = createAsyncThunk(
    "systemUserControlOtpModal/updateOtpType", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/sysuser/control/otptype/update', arg);
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const checkUserName = createAsyncThunk(
    "systemUserControlOtpModal/checkUserName", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/sysuser/newUser/checkUserName', arg);
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)



export const createNewUser = createAsyncThunk(
    "systemUserControlOtpModal/createNewUser", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/sysuser/newUser/createNewUser', arg);
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


const SystsemUserSlice = createSlice({
    name: "systsemUserReducer",
    initialState,

    reducers: {
        handleUpdateDynamicPopup(state, action) {
            // state.users = action.payload
            return {
                ...state,
                dynamicOtpDetails: {
                    ...state.dynamicOtpDetails, ...action.payload
                }
            }
        },
        handlePasswordChange(state, action) {
            // state.users = action.payload
            return {
                ...state,
                passwordResets: {
                    ...state.passwordResets, ...action.payload
                }
            }
        },
        handleNewUserInputChange(state, action) {
            // state.users = action.payload
            return {
                ...state,
                newUser: {
                    ...state.newUser, ...action.payload
                }
            }
        },
        handleValidation(state, action) {
            // state.users = action.payload
            return {
                ...state,
                newUserFormValidation: {
                    ...state.newUserFormValidation, ...action.payload
                }
            }
        },
        handleResetUserNameRe(state, action) {
            // state.users = action.payload
            return {
                ...state,
                reservedFieldEnable: true,
                newUser: {
                    userNameMessage: "",
                    userNameMessageStatus: "",
                    userName: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    active: "",
                    profile: "",
                    password: "",
                    repassword: "",
                    startDate: new Date(),
                    endDate: new Date()
                },
                fieldEnable: false,
                newUserFormValidation: {}
            }
        },
        handleOtpTypeRadioChange(state, action) {
            // state.users = action.payload
            return {
                ...state,
                controlDetail: {
                    ...state.controlDetail,
                    otps: [{
                        ...state.controlDetail.otps[0], ...action.payload
                    }]
                }
            }
        },
        handleStaticOtpTypeChange(state, action) {
            // state.users = action.payload
            return {
                ...state,
                controlDetail: {
                    ...state.controlDetail,
                    otps: [{
                        ...state.controlDetail.otps[0], ...action.payload
                    }]
                }
            }
        },
        handleUpdateControlPopup(state, action) {
            // state.users = action.payload
            return {
                ...state,
                controlDetail: {
                    ...state.controlDetail, ...action.payload
                }
            }
        },
        snackbarClosed(state, action) {

            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(systemUserFetch.fulfilled, (state, action) => {
            return {
                ...state,
                users: action.payload
            }
        })
            .addCase(systemUserFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(systemUserFetch.rejected, (state, action) => {
                //console.log(action)
            })
            .addCase(systemUserEditOtpModal.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(systemUserEditOtpModal.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(systemUserEditOtpModal.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "Get User Detail Failed",
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        // sucessMessage: {
                        //     message: action.payload.message,
                        //     snackbarOpen: true
                        // },                      
                        dynamicOtpDetails: {
                            ...state.dynamicOtpDetails, ...action.payload.data,
                            // userId:
                        }
                    }
                }
            })
            .addCase(getCurrentOtp.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(getCurrentOtp.rejected, (state, action) => {
                //console.log("action==>", action)


            })
            .addCase(getCurrentOtp.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "Get Current OTP Failed",
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        currentOtp: {
                            otpValue: action.payload.otpvalue,
                            date: action.payload.date,
                        }

                    }
                }
            })
            .addCase(pinUpdate.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(pinUpdate.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "Pin Update Failed",
                            snackbarOpen: true,
                            mode: "warning"
                        },
                        dynamicOtpDetails: {
                            ...state.dynamicOtpDetails, pin: action.payload.pin,

                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        dynamicOtpDetails: {
                            ...state.dynamicOtpDetails, pin: action.payload.pin,

                        }
                        // users:action.payload.users

                    }
                }
            })
            .addCase(pinUpdate.rejected, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "Get Current OTP Failed",
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        currentOtp: {
                            otpValue: action.payload.otpvalue,
                            date: action.payload.date,
                        }

                    }
                }
            })
            .addCase(GenerateKey.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(GenerateKey.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "Generate Key Failed",
                            snackbarOpen: true,
                            mode: "warning"
                        },
                        dynamicOtpDetails: {
                            ...state.dynamicOtpDetails, keyString: action.payload.keyString,
                            qrCode: action.payload.qrCode,

                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        dynamicOtpDetails: {
                            ...state.dynamicOtpDetails, keyString: action.payload.keyString,
                        }

                    }
                }
            })
            .addCase(GenerateKey.rejected, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)

                return {
                    ...state,
                    sucessMessage: {
                        message: "Pin Update Failed",
                        snackbarOpen: true,
                        mode: "warning"
                    },
                    dynamicOtpDetails: {
                        ...state.dynamicOtpDetails, pin: action.payload.pin,

                    }
                }

            })
            .addCase(systemUserControlModal.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(systemUserControlModal.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(systemUserControlModal.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "Get User Detail Failed",
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        controlDetail: {
                            ...state.controlDetail, ...action.payload,

                        }
                    }
                }
            })
            .addCase(handleLock.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(handleLock.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(handleLock.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "error"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        controlDetail: {
                            ...state.controlDetail, ...action.payload.data,

                        },
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "success"
                        }
                    }
                }
            })
            .addCase(handleUnLock.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(handleUnLock.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(handleUnLock.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "error"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        controlDetail: {
                            ...state.controlDetail, ...action.payload.data,

                        },
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "success"
                        }
                    }
                }
            })
            .addCase(handleUpdateEdit.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(handleUpdateEdit.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(handleUpdateEdit.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "error"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        controlDetail: {
                            ...state.controlDetail, ...action.payload.data,

                        }
                        ,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "success"
                        }
                    }
                }
            })
            .addCase(handlePasswordReset.pending, (state, action) => {
            })
            .addCase(handlePasswordReset.fulfilled, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    }
                }
            })
            .addCase(handlePasswordReset.rejected, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    }
                }
            })
            .addCase(handleStaticOTP.pending, (state, action) => {
            })
            .addCase(handleStaticOTP.fulfilled, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    }
                }
            })
            .addCase(handleStaticOTP.rejected, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    }
                }
            })
            .addCase(updateOtpType.pending, (state, action) => {
            })
            .addCase(updateOtpType.fulfilled, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    }
                }
            })
            .addCase(updateOtpType.rejected, (state, action) => {
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    }
                }
            })
            .addCase(checkUserName.pending, (state, action) => {
            })
            .addCase(checkUserName.fulfilled, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    },
                    newUser: {
                        ...state.newUser, availableMessage: action.payload.data, userNameMessage: action.payload.message, userNameMessageStatus: action.payload.status
                    },
                    fieldEnable: action.payload.count <= 0 ? true : false,
                    reservedFieldEnable: action.payload.count <= 0 ? false : true,
                }
            })
            .addCase(checkUserName.rejected, (state, action) => {
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    },

                }
            })

            .addCase(createNewUser.pending, (state, action) => {
            })
            .addCase(createNewUser.fulfilled, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    },
                    users: Boolean(action.payload.users) === true ? action.payload.users : state.users,
                    newUser: action.payload.statusCode === 202 ? state.newUser : intialNewuser,
                    newUserFormValidation: action.payload.statusCode === 202 ? state.newUserFormValidation : {},
                    fieldEnable: action.payload.statusCode === 200 ? false : true,
                    reservedFieldEnable: action.payload.statusCode === 200 ? true : false,
                }
            })
            .addCase(createNewUser.rejected, (state, action) => {
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status
                    },

                }
            })
    }

})

export const { handleUpdateDynamicPopup, snackbarClosed, handleValidation, handleResetUserNameRe, handleStaticOtpTypeChange, handleNewUserInputChange, handleOtpTypeRadioChange, handlePasswordChange, serviceProviderLastAddedValidate, handleUpdateControlPopup } = SystsemUserSlice.actions;
export const SystsemUserReducer = SystsemUserSlice.reducer