import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    operators: [],
    operatorsCopy: [],
    loader: false,
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    operatorAddAndEditModal: {
        open: false,
        mode: "add"
    },
    operatorFormValidation: {
        operatorName: "", operatorCode: ""
    }
    , lastAdded: { active: "Y", operatorName: "", operatorCode: "", saveButtonDisabled: true, operatorCodeError: false, operatorCodeHelperText: "" },
}

export const operatorFetch = createAsyncThunk(
    "OperatorReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/operators');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const operatorAdd = createAsyncThunk(
    "OperatorReducer/add", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/operators/save/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const operatorDelete = createAsyncThunk(
    "OperatorReducer/delete", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/operators/delete/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const operatorUpdate = createAsyncThunk(
    "OperatorReducer/update", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/operators/update/', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("operatorFetch", operatorFetch)

const OperatorSlice = createSlice({
    name: "OperatorReducer",
    initialState,
    reducers: {
        operatorList(state, action) {
            // state.operators = action.payload
            return {
                ...state,
                operators: action.payload
            }
        },
        operatorLastAdded(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },


            }
        },

        snackbarClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                operatorAddAndEditModal: { ...state.operatorAddAndEditModal, ...action.payload }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(operatorFetch.fulfilled, (state, action) => {
            //   console.log("Action",action)
            return {
                ...state,
                operators: action.payload,
                loader: false
            }
        })
            .addCase(operatorFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(operatorFetch.rejected, (state, action) => {
                return {
                    ...state,
                    loader: false
                }
            })
            .addCase(operatorAdd.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(operatorAdd.rejected, (state, action) => {
                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    }
                }

            })
            .addCase(operatorAdd.fulfilled, (state, action) => {

                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true
                    },
                    operatorAddAndEditModal: {
                        open: false
                    },
                    operators: action.payload.operators.length <= 0 ? state.operators : action.payload.operators
                }

            })
            .addCase(operatorUpdate.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(operatorUpdate.rejected, (state, action) => {

                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    }
                }

            })
            .addCase(operatorUpdate.fulfilled, (state, action) => {

                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    },
                    operatorAddAndEditModal: {
                        open: false
                    },
                    operators: action.payload.operators.length <= 0 ? state.operators : action.payload.operators
                }

            })
            .addCase(operatorDelete.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(operatorDelete.fulfilled, (state, action) => {

                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true
                    },
                    operators: action.payload.operators

                }
            })
    }
})
export const { operatorList, operatorLastAdded, snackbarClosed, popupOpenAndClosed, operatorLastAddedValidate } = OperatorSlice.actions;
export const OperatorReducer = OperatorSlice.reducer