import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Button, IconButton,  } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
// import ServiceProviderAddandEdit from './ServiceProviderAddandEdit';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarClosed, UserControlModal, UserEditOtpModal, UserFetch } from '../../redux/slice/UserSlice';
import DynamicOtp from './DynamicOtp';
import SnackBarComponent from '../../components/snackbar';
import Control from './UserControl';
import NewUser from './NewUser';
import { THEME_COLOR } from '../../constfiles/theme';
import DOTPIMG from"../../images/dotp.png";
import ControlImage from"../../images/accept.png";
import SOTPIMG from"../../images/sotp.png";
const useStyles = makeStyles({
    root: {
      "& .css-sq9qdz": {
        justifyContent: "end",
        flexDirection: "row-reverse"
      }
    }
  });

const User = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.UserReducer);
    const snackbarValues = selectValuesRe.sucessMessage;
    const [validationErrors, setValidationErrors] = useState({});
    const tableData = selectValuesRe.users;
    const dynamicOtpDetails = selectValuesRe.dynamicOtpDetails;
    const newUser = selectValuesRe.newUser;
    const controlDetail = selectValuesRe.controlDetail;
    const passwordResets = selectValuesRe.passwordResets;
    const currentOtp = selectValuesRe.currentOtp;
  //  console.log("snackbarValues", snackbarValues)
    const [dynamicModalOpen, setDynamicModalOpen] = React.useState(false)
    const [controlModalOpen, setControlModalOpen] = React.useState(false)
    const [newSysuserModalOpen, setNewSysuserModalOpen] = React.useState(false)
    useEffect(() => {
      //  alert("hi")
        dispatch(UserFetch())
    }, [])
    const openDynamicOtpModal = (userId) => {
        //  alert("s")
        setDynamicModalOpen(true)
        dispatch(UserEditOtpModal({ userId: userId }))

    }
    const openControlOtpModal = (userId) => {
        //  alert("s")
        setControlModalOpen(true)
        dispatch(UserControlModal({ userId: userId }))

    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'userName',
                header: 'User Name',
            },
            {
                accessorKey: 'firstName',
                header: 'First Name',
            },
            {
                accessorKey: 'lastName',
                header: 'Last Name',
                // Cell: ({ cell }) => (
                //   cell.getValue() === "Y"?"Yes" : "No"
                //   )
            },
            {
                accessorKey: 'active',
                header: 'Active',
                Cell: ({ cell }) => (
                    cell.getValue() === "Y" ? "Yes" : "No"
                )
            },
            {
                accessorKey: 'otpType',
                header: 'Otp Type',
                Cell: ({ cell }) => (

                    <IconButton onClick={() => { openDynamicOtpModal(cell.row.original.userId) }}>
                        <img src={DOTPIMG} />
                    </IconButton>
                )
            },
            {
                accessorKey: 'control',
                header: 'Control',
                Cell: ({ cell }) => (

                    <IconButton onClick={() => { openControlOtpModal(cell.row.original.userId) }}>
                        <img src={ControlImage}/>
                    </IconButton>
                )
            },
        ],
        [],
    );



    const handleSnackBar = () => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    return (
        <div className={classes.root}>

            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                initialState={{
                    density: "compact"
                }}
                //   enableEditing
                //  editingMode="modal" //default
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                // positionActionsColumn="last"
                //     editingMode="modal" //default
                enableColumnOrdering
                renderTopToolbarCustomActions={() => (
                    <>
                      <Button
                        color="primary"
                        onClick={() => { setNewSysuserModalOpen(true) }}
                        variant="contained"
                        style={{background:THEME_COLOR}}
                      >
                        Create User
                      </Button></>
                  )}

            />


            <div>

            </div>
        
           <NewUser 
            newSysuserModalOpen={newSysuserModalOpen}
            setNewSysuserModalOpen={setNewSysuserModalOpen}
            newUser={newUser}
          
           />


            <DynamicOtp
                dynamicModalOpen={dynamicModalOpen}
                setDynamicModalOpen={setDynamicModalOpen}
                dynamicOtpDetails={dynamicOtpDetails}
                currentOtp={currentOtp}
            />

            <Control
                dynamicModalOpen={controlModalOpen}
                setDynamicModalOpen={setControlModalOpen}
                controlDetail={controlDetail}
                passwordResets = {passwordResets}
              //  currentOtp={currentOtp}
            />

            <SnackBarComponent dispatch={dispatch} snackbarClosed={handleSnackBar} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </div>


    );
};

//example of creating a mui dialog modal for creating new rows




export default User;
