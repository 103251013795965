import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";



const initialState = {
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    lists: [],
    loader:false,
    agentPaymentReports: [],
    lastAdded: {
        agentCode: "ALL",
        agentCodeDisplay: "ALL",
        "startDate": currentDate,
        "endDate": currentDate,
        queryButtonDisabled: true,
        agentCodeError: false,
        agentCodeHelperText: ""
    },
}

export const AgentPaymentFetch = createAsyncThunk(
    "AgentPaymentFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('agentPaymentReport/query', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const AgentPaymentSlice = createSlice({
    name: "AgentPaymentReducer",
    initialState,
   
    reducers: {
        agentPaymentLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        handleLoader(state, action) {   
          //  console.log(action.payload)     
            return {
                ...state,
                loader: action.payload,
            }
        },
        handleSnackBar(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        agentPaymentLastAddedReset(state, action) {
            return {
                ...state,
                lastAdded: {
                    agentCode: "ALL",
                    agentCodeDisplay: "ALL",
                    "startDate": currentDate,
                    "endDate": currentDate,
                    queryButtonDisabled: true,
                    agentCodeError: false,
                    agentCodeHelperText: ""
                },
                agentPaymentReports: []
            }
        },


    },
    extraReducers: (builder) => {
        builder.addCase(AgentPaymentFetch.fulfilled, (state, action) => {
            return {
                ...state,
                agentPaymentReports: action.payload
            }
        })
            .addCase(AgentPaymentFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(AgentPaymentFetch.rejected, (state, action) => {
                //console.log(action)
            })
    }


})

export const { agentPaymentLastAdded, agentPaymentLastAddedReset,handleLoader,handleSnackBar } = AgentPaymentSlice.actions;
export const AgentPaymentReducer = AgentPaymentSlice.reducer