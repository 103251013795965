import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Input from "../../components/input";
import { getListSerialNoEnq, lastAddedReset, serialEnqLastAdded, snackbarClosed } from "../../redux/slice/SerialNoEnqSlice";
import { THIS_FIELD_IS_REQUIRED } from "../../constfiles/formValidation";
import ButtonComponent from "../../components/button";
import "../../App.css"
import CircularStatic from "../../components/spinner";
import SerialEnquiryResultTable from "../CommonComponent/SerialEnquiryResultTable";
import SnackBarComponent from "../../components/snackbar";
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles({
    root: {
        "& .MuiAutocomplete-input": {
            padding: "2px !important",
           // color:"red"
        },
        "& .css-151lqer-MuiAutocomplete-root": {
            padding: "5px !important",
        },
        "& .css-63aude-MuiFormLabel-root-MuiInputLabel-root" : {
            lineHeight:"0.8em"
        },
        "& .css-63aude-MuiFormLabel-root-MuiInputLabel-root" : {
            lineHeight : "0.9em",
          //  color:"red"
        },
        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input" : {
            height : "11.4px"
        },
    }
});
export default function MtnSerialEnquiry() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.SerialNoEnqReducer);
    const operators = selectValuesRe?.operators;
    const snackbarValues = selectValuesRe.sucessMessage;
    const lastAdded = selectValuesRe.lastAdded
    const list = selectValuesRe.lists[0]
    // console.log("lastAdded", lastAdded);
    const loader = selectValuesRe?.loader;

    useEffect(() => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
        dispatch(lastAddedReset({ serialNo: lastAdded.serialNo, operator: lastAdded.operator }))
        return () => {
            dispatch(snackbarClosed({ snackbarOpen: false }))
            dispatch(lastAddedReset({ serialNo: lastAdded.serialNo, operator: lastAdded.operator }))
        }
    }, [])
    const autoCompleteChange = (e, typeNewValue, name) => {
        //   console.log("typeNewValue", typeNewValue)
        handleInputChangeValidation(name, typeNewValue)
        dispatch(serialEnqLastAdded({ [name]: typeNewValue?.value, [name + "Display"]: typeNewValue?.label, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(serialEnqLastAdded({ [name]: value, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
    }
    function handleInputChangeValidation(name, value) {
        if (value.toString().trim() === "") {
            dispatch(serialEnqLastAdded({ [name + "Error"]: true, [name + "HelperText"]: THIS_FIELD_IS_REQUIRED }))
        }
        else {
            dispatch(serialEnqLastAdded({ [name + "Error"]: false, [name + "HelperText"]: "" }))
        }
    }

    const handleClick = () => {
        dispatch(getListSerialNoEnq({ serialNo: lastAdded.serialNo, operator: lastAdded.operator }))
    }
    const handleReset = () => {
        dispatch(lastAddedReset({ serialNo: lastAdded.serialNo, operator: lastAdded.operator }))
    }
    const snackbarClosedReset = () => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    return (
        <>
            {loader === true ? <CircularStatic /> : null}
            {/* <h3 style={{textAlign:"center"}}>MTN Serial Number Inquiry</h3> */}
            <Grid container style={{alignItems:"center"}}>
                <Grid item lg={4} md={4} sm={4}>
                    <Autocomplete
                        id="Operator"
                        options={operators}
                        className={classes.root}
                       // style={{   padding: "6px !important",}}
                        value={lastAdded.operatorDisplay}
                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "operator") }}
                        sx={{ width: "100%",  padding: "6px !important",color:"red" }}
                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField   
                         //sx={{ width: "100%",  padding: "6px !important",color:"red" }} 
                         name='operator' error={lastAdded.operatorError} helperText={lastAdded.operatorHelperText} {...params} label="Operators" /></Grid>}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={4}>
                    <Input
                        value={lastAdded.serialNo}
                        //  style={{ width: "72%" }}
                        fullWidth={true}
                        placeholder="Serial No"
                        type="text"
                        className={classes.root}
                        touched={lastAdded.serialNoTouched}
                        onChange={handleInputChange}
                        name="serialNo"
                        parentStyle={{ width: "100%" }}
                        error={lastAdded.serialNoError}
                        helperText={lastAdded.serialNoHelperText}
                        label="SerialNo" />
                </Grid>
                <Grid item lg={4} md={4} sm={4} sx={{
                    paddingLeft: "1%",
                    display: "flex",
                    alignItems: "center"
                }}>

                    <ButtonComponent
                        variant='contained' color='primary' label="Check" style={{ alignSelf: "center" }}
                        onClick={handleClick} disabled={!(lastAdded.operator && lastAdded.serialNo)}
                    >
                    </ButtonComponent>&nbsp;
                    <ButtonComponent
                        variant='contained' color='primary' label="Reset" style={{ alignSelf: "center" }}
                        onClick={handleReset}
                    >
                    </ButtonComponent>
                </Grid>
            </Grid>

            <SerialEnquiryResultTable list={list} />
            <SnackBarComponent dispatch={dispatch} snackbarClosed={snackbarClosedReset} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </>
    )
}