import { makeStyles } from '@material-ui/styles';
import { Download } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import axios from 'axios';
// import { saveAs } from 'file-saver';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/input';
import { flexiReportPdfLastAdded, flexiReportStockFetch, handleLoader, handleResetFlexiReport } from '../../redux/slice/FlexiReportPdfSlice';
import { ModifiedDate } from '../../utlis/CurrentDateGenerator';
import { getTicketsPdf, handleDownloadForFileName, printTickets } from '../../utlis/PdfFunc';
import DownloadButton from '../../components/DownloadButton';
import { THEME_COLOR } from '../../constfiles/theme';


const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row"
    },
    '& .MuiIconButton-colorPrimary:hover': {
      background: THEME_COLOR,
      padding:" 1.8%"
    },
  }
});
const EvdFlexiPdfReport = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.FlexiReportpdfReducer);
  const lastAdded = selectValuesRe.lastAdded;
  const tableData = selectValuesRe?.flexiPdfReports === undefined ? [] : selectValuesRe?.flexiPdfReports;
  const loader = selectValuesRe?.loader;
  // async function printTickets(fileName,url) {
  //   //alert(fileName)
  //   // const { data } = await getTicketsPdf(fileName,'flexiReport/download')
  //   const { data } = await getTicketsPdf(fileName,url)
  //   const blob = new Blob([data], { type: 'application/pdf' })
  //   saveAs(blob, fileName)
  // }
  // printTickets(fileName,'flexiReport/download')
  // async function getTicketsPdf(fileName) {
  //   //alert(fileName)
  //   return axios.post('flexiReport/download', { "fileName": fileName }, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     responseType: 'arraybuffer'
  //   })
  // }

  const handleDownload = (data) => {
    dispatch(handleLoader(true))
    console.log(data)
     printTickets(data, 'flexiReport/download')
    setTimeout(()=>{
      dispatch(handleLoader(false))
  },1500)
 
  }
  useEffect(() => {
    dispatch(handleResetFlexiReport())
    return () => {
      dispatch(handleResetFlexiReport())
    }

  }, [])

  const handleQuery = () => {
    //dispatch(handleLoader(true))
    dispatch(flexiReportStockFetch({ reportType: lastAdded.reportType, reportDate: lastAdded.dateModified }))
    //dispatch(handleLoader(false))
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: 'fileName',
        header: 'File Name',
        enableColumnOrdering: false,
        //enableEditing: false, //disable editing on this column
        enableSorting: false,
        //  size: 80,
      },


    ],
    [],
  );

  const handleChange = (e) => {
    const { value, name } = e.target;
    dispatch(flexiReportPdfLastAdded({ [name]: value, [name + "Touched"]: true }))
    if (name === "date") {
      dispatch(flexiReportPdfLastAdded({ dateModified: ModifiedDate(value) }))
    }
  }

  const handleReset = () => {
    dispatch(handleResetFlexiReport())
  }

  const handlePdf = (value) =>{
    handleDownloadForFileName(value,"Flexi Report","flexi_report.pdf",tableData)
  }
  return (
    < div className={classes.root}>

      <MaterialReactTable
        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        state={{
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        initialState={{
          density: "compact",
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        displayColumnDefOptions={{

          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}

        muiTableBodyRowProps={{
          sx: {
            padding: "0px"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX"
          }
        }}
        positionActionsColumn="last"
        enableColumnOrdering
        localization={{
          actions: "Download"
        }}
        enableEditing
        renderRowActions={({ row }) => (
          <>
            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: "center" }}>
              <Tooltip arrow placement="right" title="Download">
                <IconButton onClick={() => handleDownload({fileName:row.original.fileName, reportDate:row.original.reportDate}, 'flexiReport/download')} >
                  <Download />
                </IconButton>
              </Tooltip>
            </Box></>
        )}
        renderTopToolbarCustomActions={() => (
          <>

            <Grid container spacing={2}>
              <Grid item lg="4" md={4} sx="4" sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="reportType-simple-select-label">Report</InputLabel>
                  <Select
                    labelId="reportType-simple-select-label"
                    id="reportType-simple-select"
                    value={lastAdded.reportType}
                    label="Age"
                    name='reportType'
                    onChange={handleChange}
                  >
                    <MenuItem value="null">Select Report Type</MenuItem>
                    <MenuItem value="01">Daily Flexi Sales by Operator/Channel</MenuItem>
                    <MenuItem value="02">Daily Flexi Sales By Operator</MenuItem>
                    <MenuItem value="03">Daily Flexi Sales By channel</MenuItem>
                    <MenuItem value="04">Daily Flexi Sales Trend By Hour</MenuItem>
                    <MenuItem value="05">Daily Flexi Sales Trend  By Hour Operator</MenuItem>
                    <MenuItem value="06">Daily Flexi Sale Trend By Hour Channel</MenuItem>
                    <MenuItem value="07">Week Flexi Sale</MenuItem>
                    <MenuItem value="08">Daily Flexi Count By Operator</MenuItem>
                    <MenuItem value="09">Daily Flexi Count BY Status</MenuItem>
                    <MenuItem value="10">Daily Flexi Count By Status Operator</MenuItem>
                  </Select>
                </FormControl>

              </Grid>

              <Grid item lg="4" md={4} sm={4} >


                <Input value={lastAdded.date} type="date" touched={lastAdded.dateTouched} placeholder="Date" onChange={handleChange} name="date" label="" fullWidth={true} />
                <span style={{ color: "red" }}>{lastAdded.dateError}</span>

              </Grid>
              <Grid item lg="4" md={4} sx={{ margin: "auto" }} sm={4}>
                <Button variant='contained' disabled={lastAdded.reportType === "null"} color='primary' onClick={handleQuery}>Query</Button>&nbsp;
                <Button variant='contained' onClick={handleReset} color='primary'>Reset</Button>&nbsp;
               <DownloadButton classes = {classes} handlePdf={()=>{handlePdf(1)}}  handlePrint={()=>{handlePdf(2)}} />
              </Grid>
            </Grid>
          </>
        )}
      />

      <div>

      </div>


    </div>
  );
};

//example of creating a mui dialog modal for creating new rows



export default EvdFlexiPdfReport;
