import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    SerialSummarys: [],
    SerialSummarysCopy: [],
    loader:false,
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    SerialSummaryAddAndEditModal : {
        open : false,
        mode:"add"
    },
    SerialSummaryFormValidation:{
        SerialSummaryName:"",
    }
    , lastAdded: { active: "Y", SerialSummaryName:"", saveButtonDisabled:true,  },
}

export const SerialSummaryFetch = createAsyncThunk(
    "SerialSummaryReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/serialConfig/summary/list');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

// export const SerialSummaryAdd = createAsyncThunk(
//     "SerialSummaryReducer/add", async (arg, thunkAPI) => {
//         try {
//             //console.log("arg", arg)
           
//             const response = await axios.post('/branchType/save/', arg);
//             //console.log("responseadd", response.data)
//             return response.data
//         }
//         catch (error) {
//             //console.log("error", error.response)
//             return error.response.status
//         }
//     }
// )
// export const SerialSummaryDelete = createAsyncThunk(
//     "SerialSummaryReducer/delete", async (arg, thunkAPI) => {
//         try {
//             //console.log("arg", arg)
           
//             const response = await axios.post('/SerialSummarys/delete/', arg);
//             //console.log("responseadd", response.data)
//             return response.data
//         }
//         catch (error) {
//             //console.log("error", error.response)
//             return error.response.status
//         }
//     }
// )
// export const SerialSummaryUpdate = createAsyncThunk(
//     "SerialSummaryReducer/update", async (arg, thunkAPI) => {
//         try {
//             //console.log("arg", arg)
           
//             const response = await axios.post('/SerialSummarys/update/', arg);
//             //console.log("responseupdate", response.data)
//             return response.data
//         }
//         catch (error) {
//             //console.log("error", error.response)
//             return error.response.status
//         }
//     }
// )
//console.log("SerialSummaryFetch", SerialSummaryFetch)

const SerialSummarySlice = createSlice({
    name: "SerialSummaryReducer",
    initialState,  

    reducers: {
        SerialSummaryList(state, action) {
            // state.SerialSummarys = action.payload
            return {
                ...state,
                SerialSummarys: action.payload
            }
        },
        SerialSummaryLastAdded(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
                
                
            }
        },
        
        snackbarClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        // popupOpenAndClosed(state,action){
        //     //console.log("action",action)
        //     return {
        //         ...state,
        //         SerialSummaryAddAndEditModal: { ...state.SerialSummaryAddAndEditModal, ...action.payload  }
        //     }
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(SerialSummaryFetch.fulfilled, (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                SerialSummarys: action.payload,
                loader: false
            }
        })
            .addCase(SerialSummaryFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
                //console.log(action)
            })
            .addCase(SerialSummaryFetch.rejected, (state, action) => {
                //console.log(action)
            })
            // .addCase(SerialSummaryAdd.pending, (state, action) => {
            //     //console.log(action)
            // })
            // .addCase(SerialSummaryAdd.rejected, (state, action) => {
            //     //console.log("action==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            // })
            // .addCase(SerialSummaryAdd.fulfilled, (state, action) => {
            //     //console.log("action fulfilled fulfilled==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message:"Service Provider Added Failed" ,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true
            //             },
            //             SerialSummaryAddAndEditModal : {
            //                 open : false
            //             },
            //             SerialSummarys : action.payload.SerialSummarys
            //         }
            //     }
            // })
            // .addCase(SerialSummaryUpdate.pending, (state, action) => {
            //     //console.log(action)
            // })
            // .addCase(SerialSummaryUpdate.rejected, (state, action) => {
            //     //console.log("action==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            // })
            // .addCase(SerialSummaryUpdate.fulfilled, (state, action) => {
            //     //console.log("action fulfilled fulfilled==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message:"Service Provider Update Failed" ,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true
            //             },
            //             SerialSummaryAddAndEditModal : {
            //                 open : false
            //             },
            //             SerialSummarys : action.payload.SerialSummarys
            //         }
            //     }
            // })
            // .addCase(SerialSummaryDelete.pending, (state, action) => {
            //     //console.log(action)
            // })
            // .addCase(SerialSummaryDelete.fulfilled, (state, action) => {
            //     //console.log("action fulfilled fulfilled==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message:"Service Provider Deleted Failed" ,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true
            //             },
            //           SerialSummarys:action.payload.SerialSummarys
                       
            //         }
            //     }
            // })
    }
    // extraReducers :(builder)=> {
    //     builder.addCase("SerialSummaryReducer/SerialSummaryList", (state,action)=>{
    //         state.SerialSummarysCopy = action.payload
    //     })
    //     // SerialSummaryList(state,action){
    //     //     // state.SerialSummarys = action.payload
    //     //      return {
    //     //          ...state,
    //     //          SerialSummarysCopy : action.payload
    //     //      }
    //     //  }
    // }
})

export const { SerialSummaryList, snackbarClosed, popupOpenAndClosed} = SerialSummarySlice.actions;
export const SerialSummaryReducer = SerialSummarySlice.reducer