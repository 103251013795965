import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    promoReedeems: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    loader: false,
    lastAdded: { serialNo: "" },
}

export const promoReedeemFetch = createAsyncThunk(
    "promoReedeemReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/promoReedeem', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            console.log(error)
        }
    }
)


const PromoReedeemSlice = createSlice({
    name: "promoReedeemReducer",
    initialState,

    reducers: {
        promoReedeemList(state, action) {
            // state.promoReedeem = action.payload
            return {
                ...state,
                promoReedeem: action.payload
            }
        },
        promoReedeemHandleReset(state, action) {
            // state.promoReedeem = action.payload
            return {
                ...state,
                promoReedeems: [],
                lastAdded: { serialNo: "" }

            }
        },
        promoReedeemLastAdded(state, action) {

            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },


            }
        },

        snackbarClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(promoReedeemFetch.fulfilled, (state, action) => {
            return {
                ...state,
                loader: false,
                sucessMessage: {
                    message: action.payload.message,
                    snackbarOpen: true,
                    mode: action.payload.status,
                },
                promoReedeems: action.payload.promoReedems.length <= 0 ? state.promoReedems : action.payload.promoReedems,
                
            }
        })
            .addCase(promoReedeemFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(promoReedeemFetch.rejected, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: false
                }
            })
    }
})

export const { promoReedeemList, promoReedeemLastAdded, promoReedeemHandleReset, snackbarClosed } = PromoReedeemSlice.actions;
export const PromoReedeemReducer = PromoReedeemSlice.reducer