import { ArrowUpwardOutlined, Clear } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { THEME_COLOR } from "../constfiles/theme";

export default function ButtonComponent(props) {
    const coreStyle = {
        background : THEME_COLOR,
        marginRight:"0.5%"
    }
    const {name, color,style, disabled, variant, type,label,onClick} = props;
    return (
        <Button
        onClick={onClick}
        id={name}
        name ={name}
        color={color}
        disabled = {disabled}
        style={Boolean(style) === true ? style : coreStyle   }
        variant	= {variant}
        startIcon = {type==="add" ? <ArrowUpwardOutlined/> : type==="close"?<Clear/> : null}
        //defaultValue="Hello World"
    >{label}</Button>
    )
}