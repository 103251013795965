import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    lists: [],
    loader: false,
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
}

export const voucherGenerationList = createAsyncThunk(
    "voucherGenerationList/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/voucherGen/list');
            return response.data
        }
        catch (error) {
            console.log(error)
        }
    }
)

export const voucherGenerationProcess = createAsyncThunk(
    "voucherGenerationProcess/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/voucherGen/generate',arg);
            return response.data
        }
        catch (error) {
            console.log(error)
        }
    }
)

const VoucherGenerationReducerSlice = createSlice({
    name: "VoucherGenerationReducer",
    initialState,
    reducers: {
        voucherGenerationReset(state, action) {
            return {
                ...state,
                lists: []
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(voucherGenerationList.fulfilled, (state, action) => {
            return {
                ...state,
                lists: action.payload,
                loader: false,
            }
        })
            .addCase(voucherGenerationList.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(voucherGenerationList.rejected, (state, action) => {
                //console.log(action)
            }).
            addCase(voucherGenerationProcess.fulfilled, (state, action) => {
                return {
                    ...state,
                    lists: action.payload,
                    loader: false,
                }
            })
                .addCase(voucherGenerationProcess.pending, (state, action) => {
                    //console.log(action)
                    return {
                        ...state,
                        loader: true
                    }
                })
                .addCase(voucherGenerationProcess.rejected, (state, action) => {
                    //console.log(action)
                })
    }
})

export const { voucherGenerationReset } = VoucherGenerationReducerSlice.actions
export const VoucherGenerationReducer = VoucherGenerationReducerSlice.reducer