import { Autocomplete, Grid, IconButton, Menu, MenuItem, TextField } from "@mui/material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ButtonComponent from "../../components/button"
import {  retailersFetch } from "../../redux/slice/CommonSlice"
import Input from "../../components/input"
import { Download } from "@mui/icons-material"



export default function RetailerCommonWithDateComponent(props) {
    const { handleInputDateChange, handleQuery, retailerCodeChange, handleReset, lastAdded, handlePrint, handlePdf } = props;
    const dispatch = useDispatch()
    const CommonReducer = useSelector(state => state.CommonReducer);
 const retailers = CommonReducer.retailers  === undefined ? [] : CommonReducer.retailers;
    //console.log("LastAdded",lastAdded)

    useEffect(() => {
        dispatch(retailersFetch())
    }, [retailers.length])
    const autoCompleteChange = (e, typeNewValue, name) => {
       // console.log("typeNewValue", typeNewValue)
        retailerCodeChange(typeNewValue)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid container>
                <Grid item lg={3} md={4} sm={4}>
                    {/* <label>Retailer</label> */}
                    <Autocomplete
                        id="RetailerCode"
                        options={retailers}
                        value={lastAdded.retailerCodeDisplay}
                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "Retailer") }}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='Retailer' error={lastAdded.RetailerError} helperText={lastAdded.RetailerHelperText} {...params} label="Retailer" /></Grid>}
                    />
                </Grid>
                <Grid item lg={3} md={4} sm={4}>
                    <div style={{ width: "90%" }}>
                        {/* <label>Start Date</label> */}
                        <Input value={lastAdded.startDate} type="date" touched={lastAdded.startDateTouched} placeholder="Date" label="Start Date"
                            onChange={handleInputDateChange}
                            name="startDate"  fullWidth={true} />
                        <span style={{ color: "red" }}>{lastAdded.startDateError}</span></div>
                </Grid>
                <Grid item lg={3} md={4} sm={4}>  <div style={{ width: "90%" }}>
                    {/* <label>End Date</label> */}
                    <Input value={lastAdded.endDate} type="date" touched={lastAdded.endDateTouched} placeholder="Date" label="End Date"
                        onChange={handleInputDateChange}
                        name="endDate"  fullWidth={true} />
                    <span style={{ color: "red" }}>{lastAdded.endDateError}</span></div>
                </Grid>

                <Grid item lg={3} md={12} sm={12} sx={{ paddingTop: "0.5%", textAlign: "center" }}>
                    <ButtonComponent
                        variant='contained' color='primary' label="Query"
                        onClick={handleQuery} style={{width:"10%"}} disabled = {!(lastAdded.startDate && lastAdded.endDate)}
                    >
                    </ButtonComponent>&nbsp;
                    <ButtonComponent
                        variant='contained' color='primary' label="Reset"
                       onClick={handleReset} style={{width:"10%"}}
                    >
                    </ButtonComponent> &nbsp;
                    <IconButton
                    variant='contained' color='primary' label="Actions"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    disabled = {!(lastAdded.startDate && lastAdded.endDate)}
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    
                    ><Download
                    
                    /></IconButton>
                 
                    <Menu
                        id="download-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handlePdf}>Download Pdf</MenuItem>
                        {/* <MenuItem onClick={handleClose}>Download Excel</MenuItem> */}
                        <MenuItem onClick={handlePrint}>Print</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </>
    )
}