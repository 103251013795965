import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    lists: [],
    loader:false,
    lastAdded: {
        "startDate": currentDate,
        "endDate": currentDate,
    },
    VoucherStatusInfoModal: {
        open: false,
    },
    VoucherStatusEditModal: {
        open: false,
    },
    infoModalValues: {},
    editModalValues : {},
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    }
}




export const listFetch = createAsyncThunk(
    "RequestStatusFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/voucherRequestStatus/list', arg);
            //console.log("response", response.data)
            //   const serviceProvidersData = response.data;
            // serviceProvidersData.forEach(element => {
            //     Object.assign(element, { label: element.name })
            // });
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const RequestUpdate = createAsyncThunk(
    "RequestUpdate/update", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/voucherRequestStatus/update', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)



const VoucherRequestStatusReducerSlice = createSlice({
    name: "oucherRequestStatusReducer",
    initialState,
    reducers: {
        voucherRequestStatusLastAdded(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        requestUpdateLastAdded(state, action) {
            //    //console.log("errpr ope", action.payload)
                return {
                    ...state,
                    editModalValues: { ...state.editModalValues, ...action.payload },
                }
            },
        snackbarClosed(state,action){
           
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        emptyTable(state,action){           
            return {
                ...state,
                lists:[],
                lastAdded: {
                    "startDate": currentDate,
                    "endDate": currentDate,
                },
            }
        },
        popupOpenAndClosed(state, action) {
                //console.log("errpr ope", action.payload)
            return {
                ...state,
                VoucherStatusInfoModal: {
                    open: action.payload
                },
            }
        },
        editPopupOpenAndClosed(state, action) {
           
        return {
            ...state,
            VoucherStatusEditModal: {
                open: action.payload
            },
        }
    },

        lastAddedReset(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,

                lastAdded: {
                    "startDate": currentDate,
                    "endDate": currentDate,
                }
            }
        },
        InfoModalValues(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                infoModalValues: action.payload
            }
        },
        EditModalValues(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                editModalValues: action.payload
            }
        },
        snackbarClosed(state, action) {
            //  //console.log("action", action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(listFetch.fulfilled, (state, action) => {
            return {
                ...state,
                lists: action.payload,
                loader:false
            }
        })
            .addCase(listFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader:true
                }
            })
            .addCase(listFetch.rejected, (state, action) => {
                return {
                    ...state,
                    loader:false
                }
            })
            .addCase(RequestUpdate.pending, (state, action) => {
                return {
                    ...state,
                    loader:true
                }
            })
            .addCase(RequestUpdate.rejected, (state, action) => {
             
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        loader:false,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        loader:false,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(RequestUpdate.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        loader:false,
                        sucessMessage: {
                            message:"Request Update Added Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        loader:false,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },  
                        lists:action.payload.lists                     
                      
                    }
                }
            })

    }

})

export const {editPopupOpenAndClosed, voucherRequestStatusLastAdded, snackbarClosed,emptyTable, lastAddedReset, popupOpenAndClosed,InfoModalValues,EditModalValues,requestUpdateLastAdded } = VoucherRequestStatusReducerSlice.actions;
export const VoucherRequestStatusReducer = VoucherRequestStatusReducerSlice.reducer