import { Autocomplete, Grid, Paper, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Input from "../../components/input";
import { makeStyles } from '@material-ui/styles';
import {   promoReedeemFetch, promoReedeemHandleReset, promoReedeemLastAdded, snackbarClosed } from "../../redux/slice/PromoReedeemSlice";
import { THIS_FIELD_IS_REQUIRED } from "../../constfiles/formValidation";
import ButtonComponent from "../../components/button";
import "../../App.css"
import CircularStatic from "../../components/spinner";
import MaterialReactTable from 'material-react-table';
import SnackBarComponent from "../../components/snackbar";
import Radio from "../../components/Radio";
import RadioComponent from "../../components/Radio";
import { promoListLastAddedReset } from "../../redux/slice/PromoListSlice";
import { useEffect } from "react";


const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            flexDirection: "row",
            justifyContent: "spaceBetween",
            alignItems: "center",
            rowGap: "inherit",
        },
        "& .css-63aude-MuiFormLabel-root-MuiInputLabel-root" : {
            lineHeight : "0.9em",
          //  color:"red"
        },
        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input" : {
            height : "10px"
        },
       
    },
  
    input: {
        //height :"10px"
      },
});

export default function PromoReedeem() {
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.PromoReedeemReducer);
   
    const snackbarValues = selectValuesRe.sucessMessage;
    const lastAdded = selectValuesRe.lastAdded

    const classes = useStyles()
    const tableData = selectValuesRe.promoReedeems === undefined ? [] : selectValuesRe.promoReedeems;
     //console.log("lastAdded", lastAdded);
    const loader = selectValuesRe?.loader;
    useEffect(()=>{
        dispatch(promoReedeemHandleReset())
        dispatch(snackbarClosed({ snackbarOpen: false }))
        return () =>{
            dispatch(promoReedeemHandleReset())
            dispatch(snackbarClosed({ snackbarOpen: false }))
        }
    },[])
    const columns = useMemo(
        () => [
            {
                accessorKey: 'serialNo',
                header: 'Serial No',

            },
            {
                accessorKey: 'dealerCode',
                header: 'Dealer',           

            },
            {
                accessorKey: 'operator',
                header: 'Operator',

            },
            {
                accessorKey: 'voucherValue',
                header: 'Denomination',

            },
            {
                accessorKey: 'redeemed',
                header: 'Redeemed?',

            },            
            {
                accessorKey: 'date',
                header: 'Date',

            },
        ],
        [],
    );
    const handleInputChange = (e) => {
        const { name, value } = e.target;
       if(isNaN(value)) {
            dispatch(promoReedeemLastAdded({ [name]: value}))
       }
       else{
        dispatch(promoReedeemLastAdded({ [name]: "", }))

       }
    }
     const handleClick = () => {
       dispatch(promoReedeemFetch({ serialNo: lastAdded.serialNo, type: lastAdded.type,date:lastAdded.date }))
    }
    const handleReset = () => {
       dispatch(promoReedeemHandleReset())
       dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    const snackbarClosedReset = () => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    return (
        <div className={classes.root}>
            {loader === true ? <CircularStatic /> : null}
            {/* <h3 style={{ textAlign: "center" }}>MTN Serial Number Inquiry</h3> */}
           

            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
           
            renderTopToolbarCustomActions={() => (
                <Grid container>

                <Grid item lg={4} md={4} sm={4}>
                  
                    <TextField
                        value={lastAdded.serialNo}
                        //  style={{ width: "72%" }}
                        fullWidth={true}
                        placeholder="Serial No"
                        type="text"
                     
                  //      touched={lastAdded.serialNoTouched}
                        onChange={handleInputChange}
                        name="serialNo"
                        InputProps={{
                            className: classes.input,
                          }}
                        label="Serial No" />
                </Grid>
                <Grid item lg={4} md={4} sm={4} sx={{
                    paddingLeft: "1%",alignSelf:"center"
                }}>
                   

                    <ButtonComponent
                        variant='contained' color='primary' label="Check" disabled={lastAdded.serialNo.length <= 0}
                        onClick={handleClick}
                    >
                    </ButtonComponent>&nbsp;
                    <ButtonComponent
                        variant='contained' color='primary' label="Reset"
                        onClick={handleReset}
                    >
                    </ButtonComponent>
                </Grid>
            </Grid>
            )}
            />
            <SnackBarComponent dispatch={dispatch} snackbarClosed={snackbarClosedReset} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </div>
    )
}