import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";


const initialState = {
    promoWinners: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    loader:false,
   
    promoWinnerFormValidation:{
        promoWinnerName:"",
    }
    , lastAdded: { type: "Date", date:currentDate, serialNo:""  },
}

export const promoWinnerFetch = createAsyncThunk(
    "promoWinnerReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/promoWinner',arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


const PromoWinnerSlice = createSlice({
    name: "promoWinnerReducer",
    initialState,  

    reducers: {
        promoWinnerList(state, action) {
            // state.promoWinner = action.payload
            return {
                ...state,
                promoWinner: action.payload
            }
        },
        promoWinnerLastAdded(state, action) {       
        
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
                
                
            }
        },
        
        snackbarClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                promoWinnerAddAndEditModal: { ...state.promoWinnerAddAndEditModal, ...action.payload  }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(promoWinnerFetch.fulfilled, (state, action) => {
            return {
                ...state,
                promoWinners: action.payload,
                loader: false
            }
        })
            .addCase(promoWinnerFetch.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(promoWinnerFetch.rejected, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: false
                }
            })
            // .addCase(promoWinnerAdd.pending, (state, action) => {
            //     //console.log(action)
            // })
            // .addCase(promoWinnerAdd.rejected, (state, action) => {
            //     //console.log("action==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            // })
            // .addCase(promoWinnerAdd.fulfilled, (state, action) => {
            //     //console.log("action fulfilled fulfilled==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message:"Service Provider Added Failed" ,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true
            //             },
            //             promoWinnerAddAndEditModal : {
            //                 open : false
            //             },
            //             promoWinner : action.payload.promoWinner
            //         }
            //     }
            // })
            // .addCase(promoWinnerUpdate.pending, (state, action) => {
            //     //console.log(action)
            // })
            // .addCase(promoWinnerUpdate.rejected, (state, action) => {
            //     //console.log("action==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            // })
            // .addCase(promoWinnerUpdate.fulfilled, (state, action) => {
            //     //console.log("action fulfilled fulfilled==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message:"Service Provider Update Failed" ,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true
            //             },
            //             promoWinnerAddAndEditModal : {
            //                 open : false
            //             },
            //             promoWinner : action.payload.promoWinner
            //         }
            //     }
            // })
            // .addCase(promoWinnerDelete.pending, (state, action) => {
            //     //console.log(action)
            // })
            // .addCase(promoWinnerDelete.fulfilled, (state, action) => {
            //     //console.log("action fulfilled fulfilled==>", action)
            //     if (action.payload.toString() === "500") {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message:"Service Provider Deleted Failed" ,
            //                 snackbarOpen: true,
            //                 mode: "warning"
            //             }
            //         }
            //     }
            //     else {
            //         return {
            //             ...state,
            //             sucessMessage: {
            //                 message: action.payload.message,
            //                 snackbarOpen: true
            //             },
            //           promoWinner:action.payload.promoWinner
                       
            //         }
            //     }
            // })
    }
    // extraReducers :(builder)=> {
    //     builder.addCase("promoWinnerReducer/promoWinnerList", (state,action)=>{
    //         state.promoWinnerCopy = action.payload
    //     })
    //     // promoWinnerList(state,action){
    //     //     // state.promoWinner = action.payload
    //     //      return {
    //     //          ...state,
    //     //          promoWinnerCopy : action.payload
    //     //      }
    //     //  }
    // }
})

export const { promoWinnerList, promoWinnerLastAdded,snackbarClosed, popupOpenAndClosed, promoWinnerLastAddedValidate} = PromoWinnerSlice.actions;
export const PromoWinnerReducer = PromoWinnerSlice.reducer