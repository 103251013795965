import React, { useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, IconButton, Tooltip, } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import OperatorCreateAndEdit from './OperatorCreateAndEdit';
import { useDispatch, useSelector } from 'react-redux';
import { operatorDelete, operatorFetch, operatorLastAdded, popupOpenAndClosed, snackbarClosed } from '../../redux/slice/OperatorSlice';
import SnackBar from '../../components/snackbar';


const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row-reverse"
    }
  }
});
const Operator = () => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.OperatorReducer);

  //console.log("selectValuesRe", selectValuesRe)
  const lastAdded = selectValuesRe.lastAdded;
  const snackbarValues = selectValuesRe.sucessMessage;
  const loader = selectValuesRe?.loader;
  const operatorAddAndEditModal = selectValuesRe.operatorAddAndEditModal;
  // const [createModalOpen, setCreateModalOpen] = useState(false);
  ///const [tableData, setTableData] = useState(selectValuesRe.OperatorReducer.operators);

  // const [tableData, setTableData] = useState(selectValuesRe.OperatorReducer.operators);
  const tableData = selectValuesRe.operators;

  //console.log("tableData", tableData)

  useEffect(() => {

    dispatch(operatorFetch())
  }, [])


  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        //  size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        // size: 140,

      },
      {
        accessorKey: 'code',
        header: 'Code',
        // size: 140,

      },


      {
        accessorKey: 'active',
        header: 'Active',
        //    size: 80,

      },

    ],
    [],
  );
  const handleDelete = (row) => {
    ////console.log("tableData",findElement)
    dispatch(operatorDelete({ operatorId: row.original.id }))

  }
  const openMod = () => {
    // alert("s")
    dispatch(popupOpenAndClosed({ open: true, mode: "add" }));
    var resetObj = { active: "Y" };
    for (const x in lastAdded) {
      ////console.log("x",x)
      if (x !== "active") {
        Object.assign(resetObj, { [x]: "" })
      }
    }
    ////console.log("resetObj",resetObj)
    dispatch(operatorLastAdded(resetObj))
  }
  const handleEditPopUp = (row) => {
    ////console.log("row",row)
    const findElement = tableData.find(element => element.id === row.original.id)
    ////console.log("tableData",findElement)
    dispatch(popupOpenAndClosed({ open: true, mode: "edit" }))
    dispatch(operatorLastAdded({ operatorCode: findElement.code, operatorName: findElement.name, operatorId: findElement.id, active: findElement.active }))
  }
  return (
    < div className={classes.root}>
      <MaterialReactTable

        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData === undefined ? [] : tableData}
        state={{
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        initialState={{
          density: "compact",
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        muiTableBodyRowProps={{

          sx: {
            padding: "0px"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX"
          }
        }}

        positionActionsColumn="last"
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Edit">
              <IconButton onClick={() => handleEditPopUp(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton onClick={() => handleDelete(row)} color="error" >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Button
              color="primary"
              onClick={() => { openMod() }}
              variant="contained"
            >
              Create Operator
            </Button></>
        )}
      />

      <OperatorCreateAndEdit lastAdded={lastAdded} popupMode={operatorAddAndEditModal.mode} createModalOpen={operatorAddAndEditModal.open} />


      <SnackBar  snackbarClosed={() => { dispatch(snackbarClosed({ snackbarOpen: false, message: "", mode: "" })) }} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


      <div>

      </div>


    </div>
  );
};



export default Operator;
