import React from 'react';
import { Box, Grid, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import Input from '../../components/input';
import SelectDropdown from "../../components/Select";
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { operatorAdd, operatorLastAdded,  operatorUpdate, popupOpenAndClosed } from '../../redux/slice/OperatorSlice';
import { OPERATOR_CODE_IS_REQUIRED, OPERATOR_CODE_MINIMUM_3_CHACRTERS, OPERATOR_NAME_IS_REQUIRED } from '../../constfiles/formValidation';

export default function OperatorCreateAndEdit({ createModalOpen, popupMode, lastAdded }) {
  ////console.log("Last added", lastAdded)
  const dispatch = useDispatch()
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    borderRadius: "4px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // const handleSubmit = () => {
  //   //put your validation logic here
  //   onSubmit(values);
  //   onClose();
  // };

  function validation() {
    const errorObj = {}
    const {operatorCode,operatorName} = lastAdded;
    //   ////console.log("lastAdded",lastAdded)
      if(operatorCode) {
        if(operatorCode.trim() ===""){
      //  dispatch(operatorLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["operatorCode" + "Error"]: true,["operatorCode" + "HelperText"]:OPERATOR_CODE_IS_REQUIRED })
          
        }
        if(operatorCode.length < 3) {
          Object.assign(errorObj,{["operatorCode" + "Error"]: true,["operatorCode" + "HelperText"]:OPERATOR_CODE_MINIMUM_3_CHACRTERS  })
      //  dispatch(operatorLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_MINIMUM_3_CHACRTERS  }))
        }
        else{
          Object.assign(errorObj,{["operatorCode" + "Error"]: false,["operatorCode" + "HelperText"]:""})
        // dispatch(operatorLastAdded())
        }
      }
      if(operatorName) {
        if(operatorName.trim()===""){
          //  dispatch(operatorLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["operatorName" + "Error"]: true,["operatorName" + "HelperText"]:OPERATOR_NAME_IS_REQUIRED })
        }
      }
      ////console.log("errorObj",errorObj)
    
    
      dispatch(operatorLastAdded(errorObj))
      const check = Object.values(errorObj).includes(OPERATOR_CODE_MINIMUM_3_CHACRTERS || OPERATOR_NAME_IS_REQUIRED || OPERATOR_CODE_IS_REQUIRED)
      ////console.log(" Object.values(errorObj)", Object.values(errorObj))
      ////console.log("check",check)
      return check
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // if(validation()) {
    
    dispatch(operatorLastAdded({ [name]: value, [name + "Touched"]: true}))
    // dispatch(operatorLastAddedValidate({[name]: value }))
    //}
  }
const buttonUpdate = () =>{
  // alert("i")
  if(!validation()){
    dispatch(operatorUpdate({operatorId:lastAdded.operatorId, operatorCode:lastAdded.operatorCode, operatorName:lastAdded.operatorName,active:lastAdded.active}))
  }
}
  const buttonSave = () => {
   
     
      if(!validation()){
        dispatch(operatorAdd(lastAdded))
      }
      //alert(check)
      //  dispatch(operatorAdd(lastAdded))
    
      //////console.log("checkingFlag",checkingFlag)
      //alert(checkingFlag)
    // else {
    //   dispatch(operatorAdd(lastAdded))
    // }
  }
  return (
    <>

      <Modal
        open={createModalOpen}
        onClose={() => dispatch(popupOpenAndClosed({ open: false }))}
        aria-labelledby="operator-title"
        aria-describedby="operator-description"
      >
        <Box sx={style}>
          <h3>{popupMode === "add" ? "Operator Create": "Operator Edit - "+`${lastAdded.operatorName}` }</h3>
          <Grid container spacing={2}>
            <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.operatorCode} touched={lastAdded.operatorCodeTouched} error={lastAdded.operatorCodeError } helperText={lastAdded.operatorCodeHelperText} onChange={handleInputChange} name="operatorCode" label="Operator Code" fullWidth={true} maxLength="3" />
            </Grid>

            <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.operatorName} touched={lastAdded.operatorNameTouched} onChange={handleInputChange} error={lastAdded.operatorNameError} helperText={lastAdded.operatorNameHelperText} name="operatorName" label="Operator Name" fullWidth={true} maxLength="20" />
            </Grid>


            <Grid item lg="12" md={12} sx="12" sm={12}>
              <SelectDropdown value={lastAdded.active} onChange={handleInputChange} name="active" label="Active" fullWidth={true} />
            </Grid>

            <Grid item lg="12" md={12} xs="12" sx={{
              textAlign: "center", display: "flex", columnGap: "2%", justifyContent: "center"
            }} sm={12}>
             {popupMode === "add" ?  <ButtonComponent onClick={buttonSave} label="Save" name="operatorAddButton"
                color="primary"
                disabled={(lastAdded.operatorCode.length && lastAdded.operatorName.length) === 0 ? true : false}
                type="add"
                variant="contained" /> :  <ButtonComponent onClick={buttonUpdate} label="Update" name="operatorUpdateButton"
                color="primary"
                disabled={(lastAdded.operatorCode.length && lastAdded.operatorName.length) === 0 ? true : false}
                type="add"
                variant="contained" />}
              <ButtonComponent label="Close" name="close"
                color="primary"
                disabled={false}
                onClick={() => dispatch(popupOpenAndClosed({ open: false }))}
                type="close"
                variant="contained" />




            </Grid>
          </Grid>


        </Box>
      </Modal>
    </>
  );
};
OperatorCreateAndEdit.prototype = {
  columns: PropTypes.array
}