import React from 'react';
import { Autocomplete, Box, Grid, Modal, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { RequestUpdate, editPopupOpenAndClosed, requestUpdateLastAdded } from '../../../redux/slice/VoucherRequestStatusSlice';
import Button from "../../../components/button";
import "../../../App.css"
import Input from '../../../components/input';
export default function RequestStatusEditModal({ open, editModalValues,lastAdded }) {
    //console.log("Last added", editModalValues)
    const dispatch = useDispatch()

    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        borderRadius: "4px",
        bgcolor: 'background.paper',
        // border :  '2px solid #000',
        boxShadow: 24,
        p: 4,
        overFlow: "auto"
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(requestUpdateLastAdded({ [name]: value, [name + "typeTouched"]: true }))
    }
    const servers = [{
        name: "164", label: "164", id: "164"
    },
    {
        name: "202", label: "202", id: "202"
    }]

    const autoCompleteChange = (e, typeNewValue, name) => {
        //console.log("typeNewValue", typeNewValue)
        dispatch(requestUpdateLastAdded({ [name]: typeNewValue?.id, [name + "typeTouched"]: true }))
    }

    const updateVoucherStatus = () => {
        dispatch(RequestUpdate({id:editModalValues.id, count:editModalValues.count, status : editModalValues.status, denomination : editModalValues.denomination, server : editModalValues.server,startDate: lastAdded.startDate, endDate: lastAdded.endDate}))
        dispatch(editPopupOpenAndClosed(false))
    }
    return (
        <>

            <Modal
                open={open}
                onClose={() => dispatch(editPopupOpenAndClosed(false))}
                aria-labelledby="voucher-request-title"
                aria-describedby="voucher-request-description"
            >
                <Box sx={style}>
              <h3 style={{textAlign:"center"}}>Voucher Request Status Edit  # {editModalValues.id}</h3>

                        
                    <table id="RequestStatusEditModalTable" style={{ width: "100%", border: "1px solid black",padding:"2%" }}>
                       
                        <tr>
                            <td>
                                Denomination
                            </td>
                            <td><Input
                                value={editModalValues?.denomination}
                                //  style={{ width: "72%" }}
                                fullWidth={true}
                                placeholder="Denomination"
                                type="text"
                                touched={editModalValues?.denominationTouched}
                                onChange={handleInputChange}
                                name="denomination"
                                parentStyle={{ width: "72%" }}
                                //label="Denomination" 
                                /> 
                                &nbsp;</td>
                        </tr>



                        <tr>
                            <td>
                                Count
                            </td>
                            <td><Input
                                value={editModalValues?.count}
                                //  style={{ width: "72%" }}
                                fullWidth={true}
                                placeholder="Count"
                                type="text"
                                touched={editModalValues?.countTouched}
                                onChange={handleInputChange}
                                name="count"
                                parentStyle={{ width: "72%" }}
                              //  label="Count" 
                                /> &nbsp;</td>
                        </tr>
                        <tr>
                            <td>Server</td>
                            <td>
                                <Autocomplete
                                    id="Server"
                                    options={servers}
                                    value={editModalValues?.server}
                                    onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "server") }}
                                    sx={{ width: "72%" }}
                                    renderInput={(params) => <Grid item lg={11} md={11} sm={11}>
                                        <TextField name='ServerInput' {...params} label="Servers"

                                        /></Grid>}
                                /></td>


                        </tr>
                        <tr>

                            <td style={{ border: "none", textAlign: "center" }} colSpan={4}>
                                <Button variant='contained' color='primary' label="Update"
                                    onClick={updateVoucherStatus}>
                                </Button> &nbsp;
                                <Button variant='contained' color='primary' label="Close"
                                    onClick={() => dispatch(editPopupOpenAndClosed(false))}>
                                    Close</Button>
                            </td>
                        </tr>
                    </table>
                </Box>

            </Modal>
        </>
    );
};
