import React from 'react';
import { Box, Modal } from '@mui/material';
import { useDispatch } from 'react-redux';
import { popupOpenAndClosed } from '../../../redux/slice/VoucherRequestStatusSlice';
import Button from "../../../components/button";
import "../../../App.css"
export default function RequestStatusInfoModal({ open, infoModalValues, lastAdded }) {
   //console.log("Last added", lastAdded)
   const dispatch = useDispatch()
   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "80%",
      borderRadius: "4px",
      bgcolor: 'background.paper',
      // border :  '2px solid #000',
      boxShadow: 24,
      p: 4,
      overFlow: "auto"
   };





   return (
      <>

         <Modal
            open={open}
            onClose={() => dispatch(popupOpenAndClosed(false))}
            aria-labelledby="voucher-request-title"
            aria-describedby="voucher-request--description"
         >
            <Box sx={style}>
               <table id="RequestStatusInfoModalTable" style={{ width: "100%", border: "1px solid black" }}>
                  <tr>
                     <td style={{ textAlign: "center", }} colSpan={2}><h3>Voucher Request Status Info  # {infoModalValues.voucher_request_id}</h3></td>

                  </tr>
                  <tr>
                     <td><span># </span> :  {infoModalValues.voucher_request_id}</td>
                     <td><span>Service Name </span> :  {infoModalValues?.providers?.sp_name}</td>
                  </tr>

                  <tr>
                     <td><span>Operator</span>  :  {infoModalValues?.voucherRequestsOperators?.operator_code}</td>
                     <td><span>Denomination </span> :  {infoModalValues?.denomination}</td>
                  </tr>

                  <tr>
                     <td><span>Request Count</span>  :  {infoModalValues?.count}</td>
                     <td><span>Target Sever </span> :  {infoModalValues?.server}</td>
                  </tr>

                  <tr>
                     <td><span>Version</span>  :  {infoModalValues?.version}</td>
                     <td><span>Batch Number</span> :  {infoModalValues?.batch_number}</td>
                  </tr>

                  <tr>
                     <td><span>Validity </span>  :  {infoModalValues?.validity}</td>
                     <td><span>Status</span> :  {infoModalValues?.request_status}</td>
                  </tr>



                  <tr>
                     <td><span>Start time </span>  :  {infoModalValues?.start_time}</td>
                     <td><span>Stop Time</span> :  {infoModalValues?.stop_time}</td>
                  </tr>



                  <tr>
                     <td><span>Generated  Count </span>  :  {infoModalValues?.icount}</td>
                     <td><span>Duplicate Count</span> :  {infoModalValues?.dcount}</td>
                  </tr>

                  <tr>
                     <td colSpan={2}><span>File Name </span>  :  {infoModalValues?.file_name}</td>

                  </tr>

                  <tr>
                     <td colSpan={2}><span>Generated (Y/N)  :  </span>{infoModalValues?.generate}</td>

                  </tr>

                  <tr>
                     <td><span>Generate By : </span>{infoModalValues?.generate_by}</td>
                     <td><span>Generate Time : </span>{infoModalValues?.generate_time}</td>
                  </tr>

                  <tr>
                     <td colSpan={2}><span>Download (Y/N) :  </span>{infoModalValues?.download}</td>

                  </tr>

                  <tr>
                     <td><span>Download By : </span>{infoModalValues?.download_by}</td>
                     <td><span>Download Time : </span>{infoModalValues?.download_time}</td>
                  </tr>
                  <tr>
                     <td colSpan={2}><span>Upload (Y/N) :  </span>{infoModalValues?.upload}</td>

                  </tr>

                  <tr>
                     <td><span>Upload By : </span>{infoModalValues?.upload_by}</td>
                     <td><span>Upload Time : </span>{infoModalValues?.upload_time}</td>
                  </tr>

                  <tr>
                     <td style={{ border: "none", textAlign: "center" }} colSpan={4}>
                        <Button variant='contained' color='primary' label="Close"
                           onClick={() => dispatch(popupOpenAndClosed(false))}>
                           Close</Button>
                     </td>
                  </tr>
               </table>
            </Box>

         </Modal>
      </>
   );
};
