import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const intialLists = [{
    VoucherInfoBelongProviders :{
      sp_name : "-"
    },
    operators : {
     opname : "-"
    },
    denomination : "-",
    voucherType : "-",
    id:"-",
    batchNumber:"-",
    chainId:"-",
    dealerCode:"-",
    expiryDate:"-",
    voucherRequestId:"-",
    status:"",
    serialNo:"-",
    tid:"-",
    ttime:"-",
   
 }]
const initialState = {
    loader:false,
    operators: [{
        name: "MTN Card Voucher", label: "MTN Card Voucher", value: 91
    }, {
        name: "MTN USSD Voucher", label: "MTN USSD Voucher", value: 1
    }],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    }
    , lastAdded: {
        "serialNo": "",
        "operator": "",
        operatorDisplay : ""

    },
    lists:intialLists
}





export const getListSerialNoEnq = createAsyncThunk(
    "getListSerialNoEnq/add", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/serialNoEnq/getList', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)




const SerialNoEnqSlice = createSlice({
    name: "SerialNoEnqReducer",
    initialState,
    reducers: {
       serialEnqLastAdded(state, action) {
            console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        lastAddedReset(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: {
                    "serialNo": "",
                    "operator": ""

                },
                lists:intialLists
            }
        },
        snackbarClosed(state, action) {
            //  //console.log("action", action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListSerialNoEnq.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(getListSerialNoEnq.rejected, (state, action) => {

                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(getListSerialNoEnq.fulfilled, (state, action) => {
                console.log("action fulfilled fulfilled==>", action)
                
                if (action.payload.statusCode.toString() === "202") {
                    return {
                        ...state,
                        lists:intialLists,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        },
                        loader: false
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        lists:action.payload.response,
                        loader: false

                    }
                }
            })

         
    }

})

export const { serialEnqLastAdded, snackbarClosed, lastAddedReset } = SerialNoEnqSlice.actions;
export const SerialNoEnqReducer = SerialNoEnqSlice.reducer