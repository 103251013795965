import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useSelector,useDispatch } from "react-redux";
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import ButtonComponent from "../../components/button";
import "../../App.css"
import CircularStatic from "../../components/spinner";
import SnackBarComponent from "../../components/snackbar";
import { denominationFetch, operatorFetch, snackbarClosed, StockAllocationFetch, stockAllocationLastAdded } from "../../redux/slice/StockAllocationSlice";
import Input from "../../components/input";
import StockAllocationTable from "./StockAllocationTable";
import { branchesFetch } from "../../redux/slice/CommonSlice";

const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            justifyContent: "end",
            flexDirection: "row"
        }
    }
});
export default function StockAllocation() {
    const classes = useStyles()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(operatorFetch())
        dispatch(denominationFetch())
        dispatch(branchesFetch())
    }, [])

    const selectValuesRe = useSelector(state => state.StockAllocationReducer);
    const operators = selectValuesRe?.operators;
    const denominations = selectValuesRe?.denominations;
    const snackbarValues = selectValuesRe.sucessMessage;
    const lastAdded = selectValuesRe.lastAdded
    const tableData = selectValuesRe.lists
    //console.log("lastAdded", lastAdded);
    const loader = selectValuesRe?.loader;
    const columns = useMemo(
        () => [
            {
                accessorKey: 'operators.code',
                header: 'Operator',
            },
            {
                accessorKey: 'denomination',
                header: 'Den.',

            },
            {
                accessorKey: 'ssno',
                header: 'SSNO',

            },
            {
                accessorKey: 'esno',
                header: 'ESNO',

            },

            {
                accessorKey: 'aCount',
                header: 'Available Count',

            },
            {
                accessorKey: 'cTime',
                header: 'Create Time',

            },
        ],
        [],
    );

    const handleInputChange = (e) => {
        const { name, value } = e.target;
       // console.log(name, value)
        dispatch(stockAllocationLastAdded({ [name]: value, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
    }
    const autoCompleteChange = (e, typeNewValue, name) => {
      //  console.log("typeNewValue", typeNewValue)
        handleInputChangeValidation(name, typeNewValue)
        if (name === "operator") {
            dispatch(stockAllocationLastAdded({ [name]: typeNewValue?.id, [name + "Display"]: typeNewValue?.label, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
        }
        else {
            dispatch(stockAllocationLastAdded({ [name]: typeNewValue?.code, [name + "Display"]: typeNewValue?.label, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
        }
    }


    function handleInputChangeValidation(name, value) {
        if (value.toString().trim() === "") {
            //    dispatch(operationSerialEnqLastAdded({ [name + "Error"]: true, [name + "HelperText"]: THIS_FIELD_IS_REQUIRED }))
        }
        else {
            // dispatch(operationSerialEnqLastAdded({ [name + "Error"]: false, [name + "HelperText"]: "" }))
        }
    }

    const handleClick = () => {
        dispatch(StockAllocationFetch({ operator: lastAdded.operator, denomination: lastAdded.denomination, ssno: lastAdded.startSerialNo, esno: lastAdded.endSerialNo }))
    }
    const handleReset = () => {
        //    dispatch(lastAddedReset({ serialNo: lastAdded.serialNo, operator: lastAdded.operator }))
    }
    const snackbarClosedReset = () => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    return (
        <>
            {loader === true ? <CircularStatic /> : null}
            {/* <h3 style={{ textAlign: "center" }}>Stock Allocation</h3> */}

            < div className={classes.root}>
            <Grid container>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Autocomplete
                                        id="Operator"
                                        options={operators}
                                        value={lastAdded.operatorDisplay}
                                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "operator") }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='operator' error={lastAdded.operatorError} helperText={lastAdded.operatorHelperText} {...params} label="operator" /></Grid>}
                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Autocomplete
                                        id="Denomination"
                                        options={denominations}
                                        value={lastAdded.denominationDisplay}
                                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "denomination") }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='denomination' error={lastAdded.denominationError} helperText={lastAdded.denominationHelperText} {...params} label="denomination" /></Grid>}
                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Input
                                        value={lastAdded.startSerialNo}
                                        //  style={{ width: "72%" }}
                                        fullWidth={true}
                                        placeholder="Start Serial No"
                                        type="text"
                                        touched={lastAdded.startSerialNoTouched}
                                        onChange={handleInputChange}
                                        name="startSerialNo"
                                        parentStyle={{ width: "100%" }}
                                        error={lastAdded.startSerialNoError}
                                        helperText={lastAdded.startSerialNoHelperText}
                                        label="Start Serial No" />  </Grid>
                                &nbsp;
                                <Grid item lg={2} md={2} sm={2}>
                                    <Input
                                        value={lastAdded.endSerialNo}
                                        //  style={{ width: "72%" }}
                                        fullWidth={true}
                                        placeholder="End Serial No"
                                        type="text"
                                        touched={lastAdded.endSerialNoTouched}
                                        onChange={handleInputChange}
                                        name="endSerialNo"
                                        parentStyle={{ width: "100%" }}
                                        error={lastAdded.endSerialNoError}
                                        helperText={lastAdded.endSerialNoHelperText}
                                        label="End Serial No" />  </Grid>
                                <Grid item lg={3} md={3} sm={3} sx={{
                                    paddingLeft: "1%"
                                }}>

                                    <ButtonComponent
                                        variant='contained' color='primary' label="Check"
                                        onClick={handleClick}
                                    >
                                    </ButtonComponent>&nbsp;
                                    <ButtonComponent
                                        variant='contained' color='primary' label="Reset"
                                        onClick={handleReset}
                                    >
                                    </ButtonComponent>
                                </Grid>
                            </Grid>
              <StockAllocationTable lastAdded={lastAdded} list={tableData} />
            </div>

            <SnackBarComponent dispatch={dispatch} snackbarClosed={snackbarClosedReset} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </>
    )
}