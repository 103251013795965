import React, {  useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { availableStockFetch } from '../../redux/slice/VoucherStockSlice';
import CircularStatic from '../../components/spinner';
ChartJS.register(ArcElement, Tooltip, Legend);





function StockChartAvailable() {

  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.VoucherStockReducer);
  const tableData = selectValuesRe?.availableStocks;
  const [operatorData, setOperatorData] = useState([])
  const [count, setCount] = useState([])
  const loader = selectValuesRe?.loader
  //console.log("operatorData", operatorData)
  //console.log("tableData", tableData)
  useEffect(() => {
    dispatch(availableStockFetch())
  }, [dispatch])

  useEffect(() => {

    setOperatorData(tableData.map(item => {
      return item.coperators.operatorCode+" - "+item.vocuherValue
    }))
    setCount(tableData.map(item => {
      return item.count
    }))
  }, [tableData.length,tableData])
  const data = {
    labels: operatorData,
    datasets: [
      {
        label: '# of Stocks',
        data: count,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          '#32831B',
          '#00FFFF',
          '#FF5733',
          '#E7A395',
          '#E7D495',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return( <div style={{height:"450px", width:"100%",textAlign:"-webkit-center",margin:"auto"}}> 
  <h3>Available Stock Chart</h3>
  {loader===true ? <CircularStatic /> : null }
  <Pie data={data}  /></div>);
}
export default StockChartAvailable;