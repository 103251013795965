const date = new Date();
export const currentDay  = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
export const currentMonth  = date.getMonth() < 10 ? "0"+(date.getMonth()+1) : (date.getMonth()+1);
//console.log("currentDay",currentDay)
export const currentDate = date.getFullYear().toString()+"-0"+(date.getMonth()+1).toString()+"-"+currentDay;
export const currentDateWithTime = date.getFullYear().toString()+"-0"+(date.getMonth()+1).toString()+"-"+currentDay+" "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();

export function ModifiedDate(date){
    // var returnString = "";
    // var splitString = date.toString().split("-");
    // for(var i=0;i < splitString.length;i++){
    //     //console.log("i",splitString[i])
    //     returnString += splitString[i]
    // }
    // return returnString;
    var returnString = date.replace(/-/g,"");
    return returnString;
}

export const daysDiffernece = (date_1, date_2) =>{
    let firstDate = new Date(date_1)
    let secondDate = new Date(date_2)
    let difference = secondDate.getTime() - firstDate.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
}

export const checkDateValid = (date_1, date_2) =>{
    let firstDate = new Date(date_1)
    let secondDate = new Date(date_2)
    const firstModifDate = firstDate.getMonth()+1+"/"+firstDate.getDate()+"/"+firstDate.getFullYear()
    const secondModifDate = secondDate.getMonth()+1+"/"+secondDate.getDate()+"/"+secondDate.getFullYear()
    console.log(firstModifDate)
    console.log(secondModifDate)
    if(firstDate){
        if(firstModifDate === secondModifDate) {
            return true
        }
        else if(firstModifDate < secondModifDate) {
            return true
        }
        else{
            return false
        }
    }
}