import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    branchTypes: [],
    branchTypesCopy: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    branchTypeAddAndEditModal : {
        open : false,
        mode:"add"
    },
    branchTypeFormValidation:{
        branchTypeName:"",
    }
    , lastAdded: { active: "Y", branchTypeName:"", saveButtonDisabled:true,  },
}

export const branchTypeFetch = createAsyncThunk(
    "branchTypeReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/branchType');
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const branchTypeAdd = createAsyncThunk(
    "branchTypeReducer/add", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/branchType/save/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const branchTypeDelete = createAsyncThunk(
    "branchTypeReducer/delete", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/branchType/delete/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const branchTypeUpdate = createAsyncThunk(
    "branchTypeReducer/update", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/branchType/update/', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("branchTypeFetch", branchTypeFetch)

const BranchTypeSlice = createSlice({
    name: "branchTypeReducer",
    initialState,  

    reducers: {
        branchTypeList(state, action) {
            // state.branchTypes = action.payload
            return {
                ...state,
                branchTypes: action.payload
            }
        },
        branchTypeLastAdded(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
                
                
            }
        },
        
        snackbarClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                branchTypeAddAndEditModal: { ...state.branchTypeAddAndEditModal, ...action.payload  }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(branchTypeFetch.fulfilled, (state, action) => {
            return {
                ...state,
                branchTypes: action.payload
            }
        })
            .addCase(branchTypeFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchTypeFetch.rejected, (state, action) => {
                //console.log(action)
            })
            .addCase(branchTypeAdd.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchTypeAdd.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(branchTypeAdd.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:"Branch Type Added Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        branchTypeAddAndEditModal : {
                            open : false
                        },
                        branchTypes : action.payload.branchTypes
                    }
                }
            })
            .addCase(branchTypeUpdate.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchTypeUpdate.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(branchTypeUpdate.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:"Branch Type Update Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                        branchTypeAddAndEditModal : {
                            open : false
                        },
                        branchTypes : action.payload.branchTypes
                    }
                }
            })
            .addCase(branchTypeDelete.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchTypeDelete.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message:"Branch Type Deleted Failed" ,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                      branchTypes:action.payload.branchTypes
                       
                    }
                }
            })
    }
   
})

export const { branchTypeList, branchTypeLastAdded,snackbarClosed, popupOpenAndClosed, branchTypeLastAddedValidate} = BranchTypeSlice.actions;
export const BranchTypeReducer = BranchTypeSlice.reducer