import React from 'react';
import { AppBar, Box, Collapse, Dialog, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Toolbar, Typography } from '@mui/material';
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { Clear } from '@mui/icons-material';
import { handleLock, handleOtpTypeRadioChange, handlePasswordChange, handlePasswordReset, handleStaticOTP, handleStaticOtpTypeChange, handleUnLock, handleUpdateControlPopup, handleUpdateEdit, updateOtpType } from '../../redux/slice/UserSlice';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { THEME_COLOR } from '../../constfiles/theme';
const UserControl = function ({ dynamicModalOpen, passwordResets, setDynamicModalOpen, controlDetail }) {
  //  console.log("controlDetailssss", controlDetail);
    const [open, setOpen] = React.useState(false);
    const [lockOpen, setLockOpen] = React.useState(false);
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [otpTypeOpen, setOtpTypeOpen] = React.useState(false);
    const [staticOtpOpen, setStaticOtpOpen] = React.useState(false);
    const [editvalidation, setEditValidation] = React.useState({ firstName: "", lastName: "", active: "", email: "" });
    const [passwordValidation, setPasswordValidation] = React.useState({ password: "", repassword: "" });
    const [staticOtpValidation, setStaticOtpValidation] = React.useState({ staticOtp: "", pin: "" });
    const userId = controlDetail.userId;
    //console.log("userId", userId)
    //  const qrCode = controlDetail;
    const otpArray = controlDetail.otps[0];
    
    //console.log(editvalidation)
    const dispatch = useDispatch()
    const style = {
        // position: 'absolute',
        //top: '40%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: "100%",
        borderRadius: "4px",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const handleClick = () => {
        setOpen(!open);
    };
    const handleLockClick = () => {
        setLockOpen(!lockOpen);
    };
    const handlePasswordClick = () => {
        setPasswordOpen(!passwordOpen);
    };
    const handleOtpTypeClick = () => {
        setOtpTypeOpen(!otpTypeOpen);
    };
    const handleStaticOtpTypeClick = () => {
        setStaticOtpOpen(!staticOtpOpen);
    };
    // const Transition = (React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    // }));
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(handleUpdateControlPopup({ [name]: value }))
    }
    const handlePasswordChangeControl = (e) => {
        const { name, value } = e.target;
        dispatch(handlePasswordChange({ [name]: value }))
    }
    const handlePasswordResetControl = (e, userId) => {
        const insideErrorObj = {};
        const password = passwordResets.password;
        const repassword = passwordResets.repassword;
        if (password) {
            if (password?.length <= 0) {
                //   dispatch(handleValidation({ password: "Password is Required" }))
                Object.assign(insideErrorObj, { password: "Password is Required" })
            }
            else {
                // dispatch(handleValidation({ password: "" }))
                Object.assign(insideErrorObj, { password: "" })
            }
        }
        else {
            Object.assign(insideErrorObj, { password: "Password is Required" })
            //   dispatch(handleValidation({ password: "Password is Required" }))
        }
        if (repassword) {
            if (repassword?.length <= 0) {
                Object.assign(insideErrorObj, { repassword: "Re-Password is Required" })
                // dispatch(handleValidation({ repassword: "Re-Password is Required" }))
            }
            else if (repassword !== password) {
                //  dispatch(handleValidation({ repassword: "Password and Repassword Should be same" }))
                Object.assign(insideErrorObj, { repassword: "Password and Repassword Should be same" })
            }
            else {
                //  dispatch(handleValidation({ repassword: "" }))
                Object.assign(insideErrorObj, { repassword: "" })
            }
        }
        else {
            Object.assign(insideErrorObj, { repassword: "Re-Password is Required" })
            //dispatch(handleValidation({ repassword: "Re-Password is Required" }))
        }
        setPasswordValidation(insideErrorObj);
        const checking = Object.values(insideErrorObj).every((item) => item.trim() === "")

        if (checking) {

            dispatch(handlePasswordReset({ userId: userId, password: passwordResets.password, repassword: passwordResets.repassword }))
            setPasswordValidation({});
            dispatch(handlePasswordChange({ "password": "", repassword: "" }))
        }
    }
   // console.log("staticOtpValidation",staticOtpValidation)
    const handleEditUpdateControl = (e, userId) => {
        const firstName = controlDetail.firstName;
        const lastName = controlDetail.lastName;
        const active = controlDetail.active;
        const email = controlDetail.email;
        const insideErrorObj = {};
        if (firstName) {
            if (firstName?.length <= 0) {
                Object.assign(insideErrorObj, { firstName: "First Name is Required" })
                //dispatch(handleValidation({ firstName: "First Name is Required" }))
            }
            else {
                Object.assign(insideErrorObj, { firstName: "" })
                //dispatch(handleValidation({ firstName: "" }))
            }
        }
        else {
            //dispatch(handleValidation({ firstName: "First Name is Required" }))
            Object.assign(insideErrorObj, { firstName: "First Name is Required" })
        }
        if (lastName) {
            if (lastName?.length <= 0) {
                // dispatch(handleValidation({ lastName: "Last Name is Required" }))
                Object.assign(insideErrorObj, { lastName: "Last Name is Required" })
            }
            else {
                // dispatch(handleValidation({ lastName: "" }))
                Object.assign(insideErrorObj, { lastName: "" })
            }
        }
        else {
            //  dispatch(handleValidation({ lastName: "Last Name is Required" }))
            Object.assign(insideErrorObj, { lastName: "Last Name is Required" })
        }
        if (active) {
            if (active?.length <= 0) {
                Object.assign(insideErrorObj, { active: "Active is Required" })
                //  dispatch(handleValidation({ active: "Active is Required" }))
            }
            else if ((active !== "Y") && (active !== "N")) {
                Object.assign(insideErrorObj, { active: "Active is Required" })
            }
            else {
                Object.assign(insideErrorObj, { active: "" })
            }
        }
        else {
            Object.assign(insideErrorObj, { active: "Active is Required" })
        }
        if (email) {
            if (email?.length <= 0) {
                Object.assign(insideErrorObj, { email: "E-Mail is Required" })
                //  dispatch(handleValidation({ email: "E-Mail is Required" }))
            }
            else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                //dispatch(handleValidation({ email: "Please Enter valid email" }))
                Object.assign(insideErrorObj, { email: "Please Enter valid email" })
            }
            else {
                Object.assign(insideErrorObj, { email: "" })
                //   dispatch(handleValidation({ email: "" }))
            }
        }
        else {
            Object.assign(insideErrorObj, { email: "E-Mail is Required" })
            // dispatch(handleValidation({ email: "E-Mail is Required" }))
        }
        const checking = Object.values(insideErrorObj).every((item) => item.trim() === "")
       // console.log("checking", checking)
        setEditValidation(insideErrorObj)
        //  console.log("checking", errorObj)
        if (checking) {
            dispatch(handleUpdateEdit({ userId: userId, firstName: firstName, lastName: lastName, active: active, email: email }))
            setEditValidation({})
        }
    }

    const handleLockControl = (e, userId) => {
        dispatch(handleLock({ userId: userId }))
    }
    const handleUnLockControl = (e, userId) => {
        dispatch(handleUnLock({ userId: userId }))
    }
    const handleOtpRadioChange = (e) => {
        const { name, value } = e.target;
     //   console.log(name, value)
        dispatch(handleOtpTypeRadioChange({ [name]: value }))
    }
    const handleStaticOtpChange = (e) => {
        dispatch(handleStaticOtpTypeChange({ [e.target.name]: e.target.value }))
    }
    const staticOtpAndPinUpdate = (e, userId) => {
        const insideObj = {}
        const staticOtp = otpArray?.staticOtp;
        const pin = otpArray?.pin
        if (staticOtp?.length === 0) {
            Object.assign(insideObj,{ "staticOtp": "Static OTP is Required"})
            // setStaticOtpValidation((prevState) => {
            //     return {
            //         ...prevState,
            //         "staticOtp": "Static OTP is Required"
            //     }
            // })
        }
        else if (staticOtp?.length !== 8) {
            // setStaticOtpValidation((prevState) => {
            //     return {
            //         ...prevState,
            //         "staticOtp": "Static OTP Should be 8 characters in length"
            //     }
            // })
            Object.assign(insideObj,{ "staticOtp": "Static OTP Should be 8 characters in length"})
        }
        else if (pin?.length === 0) {
            // setStaticOtpValidation((prevState) => {
            //     return {
            //         ...prevState,
            //         "pin": "Pin is Required"
            //     }
            // })
            Object.assign(insideObj,{  "pin": "Pin is Required"})
        }
        else if (pin?.length !== 4) {
            // setStaticOtpValidation((prevState) => {
            //     return {
            //         ...prevState,
            //         "pin": "Pin Should be 4 characters in length"
            //     }
            // })
            Object.assign(insideObj,{  "pin": "Pin Should be 4 characters in length"})
        }
        setStaticOtpValidation(insideObj)
        const checking = Object.values(insideObj).every((item) => item.trim() === "")
        if (checking) {
            dispatch(handleStaticOTP({ staticOtp: staticOtp, pin: pin, userId: userId }))
        }
    }
    const handleUpdateOtpType = (e, userId) => {
        dispatch(updateOtpType({ otpDynamic: otpArray?.otpDynamic, userId: userId }))
    }
    return (
        <>
            <Dialog
                fullScreen
                open={dynamicModalOpen}

            >
                <AppBar sx={{ position: 'relative' }} style={{ background: THEME_COLOR }} >
                    <Toolbar style={{ display: "flex", justifyContent: "space-between", }}>


                        <Typography variant="h6" component="div">
                            User Detail

                        </Typography>
                        <Typography variant="h6" component="div"> <IconButton style={{ color: "white" }} onClick={() => setDynamicModalOpen(false)}> <Clear /></IconButton></Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={style}>
                    <List
                        sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"

                    >

                        <ListItemButton onClick={handleClick} style={{ backgroundColor: "gainsboro" }}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: "white" }}>
                            <Grid container spacing={2}>
                                <Grid item lg="6" >
                                    <TextField
                                        margin="normal"
                                        required
                                        style={{ verticalAlign: "sub" }}
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        autoComplete="off"
                                        autoFocus
                                        error={editvalidation.firstName}
                                        helperText={editvalidation.firstName}
                                        value={controlDetail.firstName}
                                        onChange={handleInputChange}
                                    // inputProps={{ maxLength: 4 }}
                                    />
                                </Grid>
                                <Grid item lg="6">

                                    <TextField
                                        margin="normal"
                                        required
                                        style={{ verticalAlign: "sub" }}
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="off"
                                        error={editvalidation.lastName}
                                        helperText={editvalidation.lastName}
                                        value={controlDetail.lastName}
                                        onChange={handleInputChange}
                                    // inputProps={{ maxLength: 4 }}
                                    />
                                </Grid>
                                <Grid item lg="6">
                                    <FormControl fullWidth>
                                        <InputLabel id="active-active">Active</InputLabel>
                                        <Select
                                            labelId="active"
                                            id="active"
                                            value={controlDetail.active}
                                            label="Active"
                                            name='active'
                                            error={editvalidation.active}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="Y">Yes</MenuItem>
                                            <MenuItem value="N">No</MenuItem>
                                        </Select></FormControl>
                                </Grid>
                                <Grid item lg="6" style={{ marginTop: "0px", paddingTop: "0px" }}>

                                    <TextField
                                        margin="normal"
                                        required
                                        style={{ verticalAlign: "sub" }}
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        autoComplete="off"
                                        error={editvalidation.email}
                                        type='email'
                                        value={controlDetail.email}
                                        //value={controlDetail.pin}
                                        helperText={editvalidation.email}
                                        onChange={handleInputChange}
                                    // inputProps={{ maxLength: 4 }}
                                    />
                                </Grid>
                                <Grid item lg="12" style={{ marginTop: "0px", paddingTop: "0px", textAlign: "center", marginBottom: "2%" }}>

                                    <ButtonComponent variant='contained' color='primary' label="Update"
                                        onClick={(e) => { handleEditUpdateControl(e, userId) }}
                                    // disabled={reset}
                                    ></ButtonComponent> </Grid>
                            </Grid>
                        </Collapse>
                        <ListItemButton onClick={handleLockClick} style={{ backgroundColor: "gainsboro" }}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Lock/Unlock" />
                            {lockOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={lockOpen} timeout="auto" unmountOnExit style={{ backgroundColor: "white" }}>
                            <table style={{ border: "1px solid black", width: "100%" }}>
                                <tr>
                                    <th style={{ textAlign: "left" }}><h4>Lock/Unlock</h4></th>
                                    <th>

                                        {controlDetail.locked !== "Y" ? <ButtonComponent variant='contained' color='primary' label="Lock"
                                            onClick={(e) => { handleLockControl(e, userId) }}
                                        // disabled={reset}
                                        >

                                        </ButtonComponent> : <ButtonComponent variant='contained' color='primary' label="Unlock"
                                            onClick={(e) => { handleUnLockControl(e, userId) }}
                                        // disabled={reset}
                                        ></ButtonComponent>}</th>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "left" }}><h4>Locked Time</h4></th>
                                    <th> {controlDetail.locked !== "Y" ? " - " : controlDetail.lockedTime}</th>
                                </tr>
                            </table>

                        </Collapse>
                        <ListItemButton onClick={handlePasswordClick} style={{ backgroundColor: "gainsboro" }}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Password Reset" />
                            {passwordOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={passwordOpen} timeout="auto" unmountOnExit style={{ backgroundColor: "white" }}>
                            <Grid container spacing={2}>
                                <Grid item lg="12" >

                                    <TextField
                                        margin="normal"
                                        type='password'
                                        required
                                        style={{ verticalAlign: "sub" }}
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        name="password"
                                        autoComplete="off"
                                        value={passwordResets.password}
                                        onChange={handlePasswordChangeControl}
                                        error={passwordValidation.password}
                                        helperText={passwordValidation.password}
                                    // inputProps={{ maxLength: 4 }}
                                    />
                                </Grid>
                                <Grid item lg="12">

                                    <TextField
                                        margin="normal"
                                        required
                                        type='password'
                                        style={{ verticalAlign: "sub" }}
                                        fullWidth
                                        id="repassword"
                                        label="Re - Password"
                                        name="repassword"
                                        autoComplete="off"
                                        error={passwordValidation.repassword}
                                        helperText={passwordValidation.repassword}
                                        value={passwordResets.repassword}
                                        onChange={handlePasswordChangeControl}
                                    // inputProps={{ maxLength: 4 }}
                                    />
                                </Grid>
                                <Grid item lg="12" style={{ marginTop: "0px", paddingTop: "0px", textAlign: "center", marginBottom: "2%" }}>
                                    <ButtonComponent variant='contained' color='primary' label="Password Reset"
                                        onClick={(e) => { handlePasswordResetControl(e, userId) }}
                                    // disabled={reset}
                                    ></ButtonComponent>                            </Grid>
                            </Grid>
                        </Collapse>
                        <ListItemButton onClick={handleOtpTypeClick} style={{ backgroundColor: "gainsboro" }}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="OTP Type" />
                            {otpTypeOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={otpTypeOpen} timeout="auto" unmountOnExit style={{ backgroundColor: "white" }}>
                            <Grid container spacing={2}>
                                <FormControl style={{ marginTop: "2%", marginLeft: "1%" }}>
                                    <FormLabel id="otp-type-label">OTP Type</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="otp-type-label"
                                        value={otpArray?.otpDynamic}
                                        name="otp-type-group"
                                        row
                                        onChange={handleOtpRadioChange}
                                    >
                                        <FormControlLabel value="N" name='otpDynamic' control={<Radio />} label="Static Otp" />
                                        <FormControlLabel value="Y" name='otpDynamic' control={<Radio />} label="Dynamic OTP" />

                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                            <Grid style={{ marginBottom: "2%", textAlign: "center" }}>
                                <ButtonComponent variant='contained' color='primary' label="Update Otp Type"
                                    onClick={(e) => { handleUpdateOtpType(e, userId) }}
                                // disabled={reset}
                                ></ButtonComponent>
                            </Grid>
                        </Collapse>

                        {otpArray?.otpDynamic === "N" ? <> <ListItemButton onClick={handleStaticOtpTypeClick} style={{ backgroundColor: "gainsboro" }}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Static OTP" />
                            {staticOtpOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                            <Collapse in={staticOtpOpen} timeout="auto" unmountOnExit style={{ backgroundColor: "white" }}>
                                <Grid container spacing={2}>
                                    <Grid item lg="12" >

                                        <TextField
                                            margin="normal"
                                            required
                                            style={{ verticalAlign: "sub" }}
                                            fullWidth
                                            id="staticOtp"
                                            label="Static OTP"
                                            name="staticOtp"
                                            autoComplete="off"
                                            autoFocus
                                            type='text'
                                            value={otpArray?.staticOtp}
                                            onChange={handleStaticOtpChange}
                                            error={staticOtpValidation.staticOtp}
                                            helperText={staticOtpValidation.staticOtp}
                                            inputProps={{ maxLength: 8 }}
                                        />
                                    </Grid>
                                    <Grid item lg="12" style={{ paddingTop: '0px' }}>

                                        <TextField
                                            margin="normal"
                                            required
                                            style={{ verticalAlign: "sub" }}
                                            fullWidth
                                            id="staticOtpPin"
                                            label="Pin"
                                            name="pin"
                                            autoComplete="off"
                                            autoFocus
                                            type='text'
                                            value={otpArray?.pin}
                                            onChange={handleStaticOtpChange}
                                            error={staticOtpValidation.pin}
                                            helperText={staticOtpValidation.pin}
                                            inputProps={{ maxLength: 4 }}
                                        />
                                    </Grid>
                                    <Grid style={{ marginBottom: "2%", textAlign: "center", width: "100%" }}>
                                        <ButtonComponent variant='contained' color='primary' label="Update"
                                            onClick={(e) => { staticOtpAndPinUpdate(e, userId) }}
                                        // disabled={reset}
                                        ></ButtonComponent>
                                    </Grid>
                                </Grid>
                            </Collapse> </> : null}
                    </List>
                </Box>
            </Dialog>


        </>
    );
};
export default UserControl