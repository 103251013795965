import { Autocomplete, Grid, IconButton, Menu, MenuItem, TextField } from "@mui/material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ButtonComponent from "../../components/button"
import { agentsFetch } from "../../redux/slice/CommonSlice"
import { Download } from "@mui/icons-material"

export default function AgentCommonComponent(props) {
    const {selectValuesRe, handleQuery, agentCodeChange,lastAdded,handlePrint,handlePdf,handleReset} = props;
    const dispatch = useDispatch()
    const commonValues = useSelector(state => state.CommonReducer); 
    const agents = commonValues.agents  === undefined ? [] : commonValues.agents;
 //   console.log("LastAdded",lastAdded)
 
    useEffect(() => {
        dispatch(agentsFetch())
    }, [agents.length])
    const autoCompleteChange = (e, typeNewValue, name) => {
    //    console.log("typeNewValue", typeNewValue)
        agentCodeChange(typeNewValue)
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid container>
                <Grid item lg={4} md={4} sm={4}>
                    <Autocomplete
                        id="AgentCode"
                        options={agents}
                        value={lastAdded.agentCodeDisplay}
                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "agent") }}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='agent' error={lastAdded.agentError} helperText={lastAdded.agentHelperText} {...params} label="Agent" /></Grid>}
                    />
                </Grid>
                <Grid item lg={8} md={8} sm={8}>
                    <ButtonComponent
                        variant='contained' color='primary' label="Query"
                    onClick={handleQuery}
                    >
                    </ButtonComponent>&nbsp;
                    <ButtonComponent
                        variant='contained' color='primary' label="Reset"
                      onClick={handleReset}
                    >
                    </ButtonComponent> &nbsp;
                    <IconButton
                        variant='contained' color='primary' label="Actions"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}

                    ><Download />
                    </IconButton>
                    <Menu
                        id="download-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handlePdf}>Download Pdf</MenuItem>
                        {/* <MenuItem onClick={handleClose}>Download Excel</MenuItem> */}
                        <MenuItem onClick={handlePrint}>Print</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </>
    )
}