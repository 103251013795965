import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { currentDate } from "../../utlis/CurrentDateGenerator";



const initialState = {
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    lists: [],
    loader:false,
    retailerPaymentReports: [],
    lastAdded: {
        retailerCode: "ALL",
        retailerCodeDisplay: "ALL",
        "startDate": currentDate,
        "endDate": currentDate,
        queryButtonDisabled: true,
        retailerCodeError: false,
        retailerCodeHelperText: ""
    },
}

export const RetailerPaymentFetch = createAsyncThunk(
    "RetailerPaymentFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('retailerPaymentReport/query', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const RetailerPaymentSlice = createSlice({
    name: "RetailerPaymentReducer",
    initialState,

    reducers: {
        RetailerPaymentLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        handleSnackBar(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        RetailerPaymentLastAddedReset(state, action) {
            return {
                ...state,
                lastAdded: {
                    retailerCode: "ALL",
                    retailerCodeDisplay: "ALL",
                    "startDate": currentDate,
                    "endDate": currentDate,
                    queryButtonDisabled: true,
                    retailerCodeError: false,
                    retailerCodeHelperText: ""
                },
                retailerPaymentReports: []
            }
        },


    },
    extraReducers: (builder) => {
        builder.addCase(RetailerPaymentFetch.fulfilled, (state, action) => {
            return {
                ...state,
                retailerPaymentReports: action.payload,
                loader:false
            }
        })
            .addCase(RetailerPaymentFetch.pending, (state, action) => {
                return {
                    ...state,                 
                    loader:true
                }
            })
            .addCase(RetailerPaymentFetch.rejected, (state, action) => {
                return {
                    ...state,                 
                    loader:false
                }
            })
    }


})

export const { RetailerPaymentLastAdded, RetailerPaymentLastAddedReset,handleSnackBar } = RetailerPaymentSlice.actions;
export const RetailerPaymentReducer = RetailerPaymentSlice.reducer