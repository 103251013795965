import React from 'react';
import { Box, Grid, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import Input from '../../components/input';
import SelectDropdown from "../../components/Select";
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';

import { SERVICE_PROVIDER_NAME_IS_REQUIRED } from '../../constfiles/formValidation';
import { branchTypeLastAdded, popupOpenAndClosed, branchTypeAdd, branchTypeUpdate } from '../../redux/slice/BranchTypeSlice';


export default function BranchTypeAddandEdit({ createModalOpen, popupMode, lastAdded }) {
  //console.log("Last added", lastAdded)
  const dispatch = useDispatch()
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    borderRadius: "4px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // const handleSubmit = () => {
  //   //put your validation logic here
  //   onSubmit(values);
  //   onClose();
  // };

  function validation() {
    const errorObj = {}
    const {branchTypeName} = lastAdded;
    //   //console.log("lastAdded",lastAdded)
      // if(serviceProviderCode) {
      //   if(serviceProviderCode.trim() ===""){
      // //  dispatch(branchTypeLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
      //     Object.assign(errorObj,{["serviceProviderCode" + "Error"]: true,["serviceProviderCode" + "HelperText"]:OPERATOR_CODE_IS_REQUIRED })
          
      //   }
      //   if(serviceProviderCode.length < 3) {
      //     Object.assign(errorObj,{["serviceProviderCode" + "Error"]: true,["serviceProviderCode" + "HelperText"]:OPERATOR_CODE_MINIMUM_3_CHACRTERS  })
      // //  dispatch(branchTypeLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_MINIMUM_3_CHACRTERS  }))
      //   }
      //   else{
      //     Object.assign(errorObj,{["serviceProviderCode" + "Error"]: false,["serviceProviderCode" + "HelperText"]:""})
      //   // dispatch(branchTypeLastAdded())
      //   }
      // }
      if(branchTypeName) {
        if(branchTypeName.trim()===""){
          //  dispatch(branchTypeLastAdded({[name + "Error"]: true,[name + "helperText"]:OPERATOR_CODE_IS_REQUIRED }))
          Object.assign(errorObj,{["branchTypeName" + "Error"]: true,["branchTypeName" + "HelperText"]:SERVICE_PROVIDER_NAME_IS_REQUIRED })
        }
      }
      //console.log("errorObj",errorObj)
    
    
      dispatch(branchTypeLastAdded(errorObj))
      const check = Object.values(errorObj).includes(SERVICE_PROVIDER_NAME_IS_REQUIRED)
      //console.log(" Object.values(errorObj)", Object.values(errorObj))
      //console.log("check",check)
      return check
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // if(validation()) {
    
    dispatch(branchTypeLastAdded({ [name]: value, [name + "Touched"]: true}))
    // dispatch(branchTypeLastAddedValidate({[name]: value }))
    //}
  }
const buttonUpdate = () =>{
  // alert("i")
  if(!validation()){
    dispatch(branchTypeUpdate({branchTypeId:lastAdded.branchTypeId,  branchTypeName:lastAdded.branchTypeName,active:lastAdded.active}))
  }
}
  const buttonSave = () => {
   
     
      if(!validation()){
        dispatch(branchTypeAdd(lastAdded))
      }
     
  }
  return (
    <>

      <Modal
        open={createModalOpen}
        onClose={() => dispatch(popupOpenAndClosed({ open: false }))}
        aria-labelledby="serviceProvider-title"
        aria-describedby="serviceProvider-description"
      >
        <Box sx={style}>
          <h3>{popupMode === "add" ? "Branch Type Create": "Branch Type Edit - "+`${lastAdded.branchTypeName}` }</h3>
          <Grid container spacing={2}>
            {/* <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.serviceProviderCode} touched={lastAdded.serviceProviderCodeTouched} error={lastAdded.serviceProviderCodeError } helperText={lastAdded.serviceProviderCodeHelperText} onChange={handleInputChange} name="serviceProviderCode" label="serviceProvider Code" fullWidth={true} maxLength="3" />
            </Grid> */}

            <Grid item lg="12" md={12} sx="12" sm={12}>
              <Input value={lastAdded.branchTypeName} touched={lastAdded.branchTypeNameTouched} onChange={handleInputChange} error={lastAdded.branchTypeNameError} helperText={lastAdded.branchTypeNameHelperText} name="branchTypeName" label="Branch Type Name" fullWidth={true} maxLength="20" />
            </Grid>


            <Grid item lg="12" md={12} sx="12" sm={12}>
              <SelectDropdown value={lastAdded.active} onChange={handleInputChange} name="active" label="Active" fullWidth={true} />
            </Grid>

            <Grid item lg="12" md={12} xs="12" sx={{
              textAlign: "center", display: "flex", columnGap: "2%", justifyContent: "center"
            }} sm={12}>
             {popupMode === "add" ?  <ButtonComponent onClick={buttonSave} label="Save" name="serviceProviderAddButton"
                color="primary"
                disabled={( lastAdded.branchTypeName.length) === 0 ? true : false}
                type="add"
                variant="contained" /> :  <ButtonComponent onClick={buttonUpdate} label="Update" name="serviceProviderUpdateButton"
                color="primary"
                disabled={(lastAdded.branchTypeName.length) === 0 ? true : false}
                type="add"
                variant="contained" />}
              <ButtonComponent label="Close" name="close"
                color="primary"
                disabled={false}
                onClick={() => dispatch(popupOpenAndClosed({ open: false }))}
                type="close"
                variant="contained" />




            </Grid>
          </Grid>


        </Box>
      </Modal>
    </>
  );
};
