import React from 'react';
import { AppBar, Box, Dialog, IconButton, Slide, TextField, Toolbar, Typography } from '@mui/material';
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { Clear } from '@mui/icons-material';
import { GenerateKey, getCurrentOtp, handleUpdateDynamicPopup, pinUpdate } from '../../redux/slice/SystemUserSlice';
import { THEME_COLOR } from '../../constfiles/theme';

const DynamicOtp = function ({ dynamicModalOpen, currentOtp, setDynamicModalOpen, dynamicOtpDetails }) {
  console.log("dynamicOtpDetails", dynamicOtpDetails.qrCode);
  const userId = dynamicOtpDetails.userId;
  console.log(userId)
  const [pinValidation, setPinValidation] = React.useState({ pin: "" })
  const qrCode = dynamicOtpDetails.qrCode;
  const dispatch = useDispatch()
  const style = {
    // position: 'absolute',
    //top: '40%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: "100%",
    borderRadius: "4px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleInputChange = (e) => {
    if (!isNaN(e.target.value)) {
      dispatch(handleUpdateDynamicPopup({ pin: e.target.value }))
      setPinValidation({ pin: "" })
    }
    else {
      setPinValidation({ pin: "Please Enter Valid Number" })
      dispatch(handleUpdateDynamicPopup({ pin: "" }))
    }
  }

  const handlePinUpdate = (e, userId) => {
    // alert("d")
    if (dynamicOtpDetails.pin !== "") {
      if (dynamicOtpDetails.pin.length !== 4) {
        setPinValidation({ pin: "Pin Should be 4 charaters in length" })
      }
      else {
        dispatch(pinUpdate({ userId: userId, pin: dynamicOtpDetails.pin }))
      }

    }
    else {
      setPinValidation({ pin: "Pin is Required" })
    }


  }
  const getCurrentOtpFromScreen = (e, userId) => {

    dispatch(getCurrentOtp({ userId: userId }))
  }

  const generateKey = (e, userId) => {
    dispatch(GenerateKey({ userId: userId }))
  }
  return (
    <>
      <Dialog
        fullScreen
        open={dynamicModalOpen}

      >
        <AppBar sx={{ position: 'relative' }} style={{ background: THEME_COLOR }} >
          <Toolbar style={{ display: "flex", justifyContent: "space-between", }}>


            <Typography variant="h6" component="div">
              User Detail

            </Typography>
            <Typography variant="h6" component="div"> <IconButton style={{ color: "white" }} onClick={() => setDynamicModalOpen(false)}> <Clear /></IconButton></Typography>
          </Toolbar>
        </AppBar>
        <Box sx={style}>
          <table style={{ width: "100%" }}>
            <tr style={{ border: "1px solid black" }}><th style={{ border: "1px solid black", textAlign: "left", padding: "0.4% 10px" }} colSpan={3}>Issuer : {dynamicOtpDetails.issuer} </th></tr>
            <tr style={{ border: "1px solid black" }}><th style={{ border: "1px solid black", textAlign: "left", padding: "0.4% 10px" }} colSpan={3}>Secret Key : {dynamicOtpDetails.keyString}
              <ButtonComponent variant='contained' color='primary' label="Generate Key String"
                onClick={(e) => { generateKey(e, userId) }}
              // disabled={reset}

              >

              </ButtonComponent></th></tr>
            <tr style={{ border: "1px solid black" }}><th style={{ border: "1px solid black", textAlign: "left", padding: "0.4% 10px" }} colSpan={2}>Hex Code : {dynamicOtpDetails.hexCode} </th>
              <th style={{ border: "1px solid black", textAlign: "left", padding: "0.4% 10px" }} >Type: {dynamicOtpDetails.otpType} </th>
            </tr>
            <tr style={{ border: "1px solid black" }}><th style={{ border: "1px solid black", textAlign: "left", padding: "0.4% 10px" }} colSpan={2}>Digits : {dynamicOtpDetails.digits} </th>
              <th style={{ border: "1px solid black", textAlign: "left", padding: "0.4% 10px" }} >Algorithm: {dynamicOtpDetails.alg} </th>
            </tr>
            <tr style={{ border: "1px solid black" }}><th style={{ border: "1px solid black", textAlign: "left", padding: "0.4% 10px" }} colSpan={3}>Interval : {dynamicOtpDetails.interval} </th></tr>
            <tr style={{ border: "1px solid black" }}>
              <th style={{ border: "1px solid black", textAlign: "left", padding: "0.4% 10px" }} >
                <img src={qrCode} alt="qrcode" />
              </th>
              <th style={{ border: "1px solid black", textAlign: "center", padding: "0.4% 10px" }} >

                <TextField
                  margin="normal"
                  required
                  style={{ verticalAlign: "sub" }}
                  fullWidth
                  id="pin"
                  label="Pin"
                  name="pin"
                  autoComplete="off"
                  autoFocus
                  error={pinValidation.pin}
                  helperText={pinValidation.pin}
                  value={dynamicOtpDetails.pin}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 4 }}
                />
                <ButtonComponent variant='contained' color='primary' label="Pin Update"
                  onClick={(e) => { handlePinUpdate(e, userId) }}
                // disabled={reset}

                >

                </ButtonComponent>
              </th>

              <th style={{ border: "1px solid black", textAlign: "center", padding: "0.4% 10px" }} >
                <h4>OTP : {currentOtp.otpValue}</h4>
                <h4>Date : {currentOtp.date}</h4>
                <ButtonComponent variant='contained' color='primary' label="Get Current Otp"
                  onClick={(e) => { getCurrentOtpFromScreen(e, userId) }}
                // disabled={reset}

                >

                </ButtonComponent>
              </th>
            </tr>
            <tr >
              <th style={{ textAlign: "center" }} colSpan={3}>
                <ButtonComponent label="Close" name="close"
                  color="primary"
                  disabled={false}
                  onClick={() => setDynamicModalOpen(false)}
                  type="close"
                  variant="contained" />


              </th>
            </tr>
          </table>


        </Box>
      </Dialog>


    </>
  );
};
export default React.memo(DynamicOtp)