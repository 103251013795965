import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    operators: [],
    serviceProviders: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    }
    , lastAdded: {
        "operator": "",
        "operatortypeTouched": false,
        "server": "",
        "servertypeTouched": false,
        "provider": "",
        "providertypeTouched": false,
        "status": "",
        "statustypeTouched": false,
        "denomination": "",
        "denominationtypeTouched": false,
        "count": "",
        "counttypeTouched": false,
        "version": "",
        "versiontypeTouched": false,
        "validityPeriod": "",
        "validityPeriodtypeTouched": false,
    }
}

export const operatorFetch = createAsyncThunk(
    "NewRequest/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/cardVoucher/getActiveCardVoucher', { type: 2 });
            //console.log("response", response.data)
            const operatorsData = response.data;
            operatorsData.forEach(element => {
                Object.assign(element, { label: element.name })
            });
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


export const serviceProviderFetch = createAsyncThunk(
    "NewRequestserviceProviderFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/serviceProviders');
            //console.log("response", response.data)
            const serviceProvidersData = response.data;
            serviceProvidersData.forEach(element => {
                Object.assign(element, { label: element.name })
            });
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const CardVoucherNewRequestAdd = createAsyncThunk(
    "NewRequest/add", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('cardVoucher/newRequest', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)


const CardVoucherNewRequestReducerSlice = createSlice({
    name: "CardVoucherNewRequestReducerSlice",
    initialState,
    reducers: {
        cardVoucherNewRequestLastAdded(state, action) {
            console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        cardVoucherLastAddedReset(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: {
                    "operator": "",
                    "operatortypeTouched": false,
                    "server": "",
                    "servertypeTouched": false,
                    "provider": null,
                    "providertypeTouched": false,
                    "status": "",
                    "statustypeTouched": false,
                    "denomination": "",
                    "denominationtypeTouched": false,
                    "count": "",
                    "counttypeTouched": false,
                    "version": "",
                    "versiontypeTouched": false,
                    "validityPeriod": "",
                    "validityPeriodtypeTouched": false,
                },
            }
        },
        snackbarClosed(state, action) {
            //  //console.log("action", action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(operatorFetch.fulfilled, (state, action) => {
            return {
                ...state,
                operators: action.payload
            }
        })
            .addCase(operatorFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(operatorFetch.rejected, (state, action) => {
                //console.log(action)
            })
            .addCase(CardVoucherNewRequestAdd.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(CardVoucherNewRequestAdd.rejected, (state, action) => {

                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(CardVoucherNewRequestAdd.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "New Request Added Failed",
                            snackbarOpen: true,
                            mode: "error"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },

                    }
                }
            })
            .addCase(serviceProviderFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(serviceProviderFetch.rejected, (state, action) => {
                //console.log(action)
            }).addCase(serviceProviderFetch.fulfilled, (state, action) => {
                return {
                    ...state,
                    serviceProviders: action.payload
                }
            })

    }

})

export const { cardVoucherNewRequestLastAdded, snackbarClosed, cardVoucherLastAddedReset } = CardVoucherNewRequestReducerSlice.actions;
export const CardVoucherNewRequestReducer = CardVoucherNewRequestReducerSlice.reducer