import { TextField } from "@mui/material";
import React from "react";
import "../fieldset.css";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
export default function RadioComponent(props) {
    const {radioValue, handleChange, fullWidth, label, maxLength, parentStyle, onChange, error, helperText, accept, touched, value, type, placeholder, name } = props;
    // //console.log("helperText",helperText)
    // //console.log("error",error)
   // console.log("checked",checked)
  
    return (
        // <TextField
        //     type="radio"
        //     sx={{parentStyle,border:"none"}}          
        //     id={name}
        //     name={name}        
        //     required="true"
        //     value={value}          
        //     error={error}
        //     onChange={onChange}
        //     checked = {checked}
        //     accept={accept}
        //     inputProps={{
        //         maxlength: maxLength,
        //         style: {style,border:"none"}
        //     }}
      
        // />
        <FormControl>
        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
        <RadioGroup
          aria-labelledby="radio-group"
          name={name}
          value={value}
         onChange={handleChange}
        >
          {/* <FormControlLabel value="female" control={<Radio />} label="Female" /> */}
          <FormControlLabel value={radioValue} control={<Radio />} label={label} />
        </RadioGroup>
      </FormControl>
    )
}