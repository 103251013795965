import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    serialConfigs: [],
    loader: false,
    serialConfigsCopy: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    serialConfigAddAndEditModal: {
        open: false,
        mode: "add"
    },
    lastAdded: {
        active: "Y",
        serialPrefixLength: "",
        serialSeqLength: "",
        serialSuffixLength: "",
        voucherPrefixLength: "",
        voucherRandom1Length: "",
        voucherRandom2Length: "",
        pinRandomLength: ""
    },
}

export const serialConfigFetch = createAsyncThunk(
    "SerialConfigReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/serialConfig/config/');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)
export const SerialConfigEdit = createAsyncThunk(
    "SerialConfigReducer/SerialConfigEdit", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/serialConfig/config/edit/', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const serialConfigAdd = createAsyncThunk(
    "SerialConfigReducer/add", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/serialConfig/config/save/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)

export const SerialConfigUpdate = createAsyncThunk(
    "SerialConfigReducer/update", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/serialConfig/config/update/', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("serialConfigFetch", serialConfigFetch)

const SerialConfigSlice = createSlice({
    name: "SerialConfigReducer",
    initialState,

    reducers: {
        serialConfigList(state, action) {
            // state.serialConfigs = action.payload
            return {
                ...state,
                serialConfigs: action.payload
            }
        },
        serialConfigLastAdded(state, action) {
            console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },


            }
        },
        serialConfigLastAddedReset(state, action) {
            console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: {},


            }
        },
        snackbarClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                serialConfigAddAndEditModal: { ...state.serialConfigAddAndEditModal, ...action.payload }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(serialConfigFetch.fulfilled, (state, action) => {
            console.log("Action", action)
            return {
                ...state,
                serialConfigs: action.payload,
                loader: false
            }
        })
            .addCase(serialConfigFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(serialConfigFetch.rejected, (state, action) => {
                //console.log(action)
            }).addCase(SerialConfigEdit.fulfilled, (state, action) => {
                console.log("Action", action)
                return {
                    ...state,
                    lastAdded: { ...state.lastAdded, ...action.payload },
                    loader: false
                }
            })

            .addCase(SerialConfigEdit.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
                //console.log(action)
            }).addCase(SerialConfigEdit.rejected, (state, action) => {
                return {
                    ...state,
                    loader: false
                }
                //console.log(action)
            })
            .addCase(serialConfigAdd.pending, (state, action) => {
                return {
                    ...state,
                    loader: false
                }
                //console.log(action)
            })
            .addCase(serialConfigAdd.rejected, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    }
                }

            })
            .addCase(serialConfigAdd.fulfilled, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    },
                    serialConfigAddAndEditModal: {
                        open: false
                    },
                    serialConfigs: action.payload.serialConfigs
                }

            })
            .addCase(SerialConfigUpdate.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(SerialConfigUpdate.rejected, (state, action) => {
                //console.log("action==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                        ,
                        loader: false
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: "warning"
                        },
                        loader: false
                    }
                }
            })
            .addCase(SerialConfigUpdate.fulfilled, (state, action) => {
             
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true
                    },
                    serialConfigAddAndEditModal: {
                        open: false
                    },
                    serialConfigs: action.payload.serialConfigs,

                    loader: false
                }
                //  }
            })
    }
})

export const { serialConfigList, serialConfigLastAdded, snackbarClosed, serialConfigLastAddedReset, popupOpenAndClosed, serialConfigLastAddedValidate } = SerialConfigSlice.actions;
export const SerialConfigReducer = SerialConfigSlice.reducer