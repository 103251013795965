import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import Input from "../../components/input";
import ButtonComponent from "../../components/button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NewRequestAdd, lastAddedReset, newRequestLastAdded, operatorFetch, serviceProviderFetch, snackbarClosed } from "../../redux/slice/NewRequestSlice";
import SnackBarComponent from "../../components/snackbar";
import { THIS_FIELD_IS_REQUIRED } from "../../constfiles/formValidation";
import { makeStyles } from "@material-ui/styles"
const useStyles = makeStyles({
    root: {
        "& .css-63aude-MuiFormLabel-root-MuiInputLabel-root": {
            height: "0.5em !important",
            color: "red"
        },
        "& .css-1ar3cjx-MuiAutocomplete-root": {
            padding: "5px !important",
        },
        "& .MuiOutlinedInput-root": {
            padding: "5px !important",
        },
        "& .css-63aude-MuiFormLabel-root-MuiInputLabel-root": {
            lineHeight: "0.8em"
        },
        "& .css-63aude-MuiFormLabel-root-MuiInputLabel-root": {
            lineHeight: "0.9em",
            //  color:"red"
        },
        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            height: "1.5px"
        },
    }
});
export default function NewRequest() {
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.NewVoucherRequestReducer);
    const providers = selectValuesRe?.serviceProviders;
    const operators = selectValuesRe?.operators;
    const snackbarValues = selectValuesRe.sucessMessage;
    const classes = useStyles()
    const servers = [{
        name: "164", label: "164", id: "164"
    },
    {
        name: "202", label: "202", id: "202"
    }]
    const statuss = [{
        name: "1", label: "Available", id: "1"
    },
    {
        name: "4", label: "Block", id: "4"
    }]
    const lastAdded = selectValuesRe.lastAdded
    //console.log("lastAdded", lastAdded)
    const operatorCall = () => {
        dispatch(operatorFetch())
    }

    const serviceproviderCall = () => {
        dispatch(serviceProviderFetch())
    }

    const autoCompleteChange = (e, typeNewValue, name) => {
       // console.log("typeNewValue", typeNewValue)
        handleInputChangeValidation(name, typeNewValue)
        dispatch(newRequestLastAdded({ [name]: typeNewValue?.id, [name + "Display"]: typeNewValue?.label, [name + "typeTouched"]: true }))
    }

    const handleInputChange = (e) => {
        //   //console.log(e.target.value)
        const { name, value } = e.target;
        handleInputChangeValidation(name, value)
        dispatch(newRequestLastAdded({ [name]: value, [name + "typeTouched"]: true }))
    }
    function handleInputChangeValidation(name, value) {
        if (value.toString().trim() === "") {
            dispatch(newRequestLastAdded({ [name + "Error"]: true, [name + "HelperText"]: THIS_FIELD_IS_REQUIRED }))
        }
        else {
            dispatch(newRequestLastAdded({ [name + "Error"]: false, [name + "HelperText"]: "" }))
        }
    }
    function validation() {
        // alert("Df")

        const errorObj = {}
        const { count, denomination, operator, provider, server, status, validityPeriod, version } = lastAdded;
     //   console.log("inside lastAdded", lastAdded)
        if (provider.toString().trim() === "") {
            Object.assign(errorObj, { ["provider" + "Error"]: true, ["provider" + "HelperText"]: THIS_FIELD_IS_REQUIRED })
        }
        else {
            Object.assign(errorObj, { ["provider" + "Error"]: false, ["provider" + "HelperText"]: "" })
        }
        if (operator.toString().trim() === "") {
            Object.assign(errorObj, { ["operator" + "Error"]: true, ["operator" + "HelperText"]: THIS_FIELD_IS_REQUIRED })
        }
        else {
            Object.assign(errorObj, { ["operator" + "Error"]: false, ["operator" + "HelperText"]: "" })
        }
        if (server.toString().trim() === "") {
            Object.assign(errorObj, { ["server" + "Error"]: true, ["server" + "HelperText"]: THIS_FIELD_IS_REQUIRED })
        }
        else {
            Object.assign(errorObj, { ["server" + "Error"]: false, ["server" + "HelperText"]: "" })
        }
        if (status.toString().trim() === "") {
            Object.assign(errorObj, { ["status" + "Error"]: true, ["status" + "HelperText"]: THIS_FIELD_IS_REQUIRED })
        }
        else {
            Object.assign(errorObj, { ["status" + "Error"]: false, ["status" + "HelperText"]: "" })
        }
        if (count.toString().trim() === "") {
            Object.assign(errorObj, { ["count" + "Error"]: true, ["count" + "HelperText"]: THIS_FIELD_IS_REQUIRED })
        }
        else {
            Object.assign(errorObj, { ["count" + "Error"]: false, ["count" + "HelperText"]: "" })
        }
        if (denomination.toString().trim() === "") {
            Object.assign(errorObj, { ["denomination" + "Error"]: true, ["denomination" + "HelperText"]: THIS_FIELD_IS_REQUIRED })
        }
        else {
            Object.assign(errorObj, { ["denomination" + "Error"]: false, ["denomination" + "HelperText"]: "" })
        }
        if (validityPeriod.toString().trim() === "") {
            Object.assign(errorObj, { ["validityPeriod" + "Error"]: true, ["validityPeriod" + "HelperText"]: THIS_FIELD_IS_REQUIRED })
        }
        else {
            Object.assign(errorObj, { ["validityPeriod" + "Error"]: false, ["validityPeriod" + "HelperText"]: "" })
        }
        if (version.toString().trim() === "") {
            Object.assign(errorObj, { ["version" + "Error"]: true, ["version" + "HelperText"]: THIS_FIELD_IS_REQUIRED })
        }
        else {
            Object.assign(errorObj, { ["version" + "Error"]: false, ["version" + "HelperText"]: "" })
        }

       // console.log("errorObj", errorObj)
        dispatch(newRequestLastAdded(errorObj))
      //  console.log("errorObj", errorObj)
        const check = Object.values(errorObj).includes(THIS_FIELD_IS_REQUIRED)

        return check
    }
    const handleCreate = () => {
        // alert("outside")
        if (!validation()) {
            dispatch(NewRequestAdd({
                operator: lastAdded.operator,
                denomination: lastAdded.denomination,
                count: lastAdded.count,
                provider: lastAdded.provider,
                server: lastAdded.server,
                status: lastAdded.status,
                validityPeriod: lastAdded.validityPeriod,
                version: lastAdded.version,
            }))

            dispatch(lastAddedReset())
        }
    }
    const handleReset = () => {

        dispatch(lastAddedReset())
    }
    const snackbarClosedReset = () => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    useEffect(() => {
        snackbarClosedReset()
        Promise.all([operatorCall(), serviceproviderCall()]).then(response => {

        })
    }, [])


    return (
        <>
            <table style={{ width: "100%" }}>
                <tr>
                    <td>Provider</td>
                    <td>
                        <Autocomplete
                            id="Provider"
                            value={lastAdded.providerDisplay}
                            options={providers}
                            onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "provider") }}
                            className={classes.root}
                            sx={{ width: "80%", padding: "6px !important" }}
                            renderInput={(params) =>
                                <Grid item lg={11} md={11} sm={11}>
                                    <TextField name='providerInput' inputProps={{
                                        className: classes.root
                                    }} error={lastAdded.providerError} helperText={lastAdded.providerHelperText} {...params} label="Providers" /></Grid>}
                        /></td>

                    <td>Operator</td>
                    <td>
                        <Autocomplete
                            id="Operator"
                            // className={classes.root}
                            value={lastAdded.operatorDisplay}
                            options={operators}
                            onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "operator") }}
                            className={classes.root}
                            sx={{ width: "80%", padding: "6px !important", color: "red" }}
                            renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField inputProps={{
                                className: classes.root
                            }} name='operatorInput' error={lastAdded.operatorError} helperText={lastAdded.operatorHelperText} {...params} label="Operators" /></Grid>}
                        /></td>
                </tr>



                <tr>
                    <td>Server</td>
                    <td>
                        <Autocomplete
                            id="Server"
                            options={servers}
                            className={classes.root}
                            sx={{ width: "80%", padding: "6px !important", color: "red" }}
                            value={lastAdded.serverDisplay}
                            onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "server") }}
                            // sx={{ width: "300px" }}
                            renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField
                                inputProps={{
                                    className: classes.root
                                }}
                                error={lastAdded.serverError} helperText={lastAdded.serverHelperText} name='ServerInput' {...params} label="Servers" /></Grid>}
                        /></td>

                    <td>Voucher Status</td>
                    <td>
                        <Autocomplete
                            id="VoucherStatus"
                            options={statuss}
                            value={lastAdded.statusDisplay}
                            onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "status") }}
                            className={classes.root}
                            sx={{ width: "80%", padding: "6px !important", color: "red" }}
                            renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='statusINput' error={lastAdded.statusError} helperText={lastAdded.statusHelperText} {...params} label="VoucherStatus" /></Grid>}
                        /></td>
                </tr>
                <tr>
                    <td>Denomination</td>
                    <td  style={{paddingLeft: "0.6%",paddingTop:"0.7%"}}><Input
                        value={lastAdded.denomination}
                        //  style={{ width: "78%" }}
                        fullWidth={true}
                        placeholder="Denomination"
                        className={classes.root}
                        sx={{ width: "80%", padding: "6px !important", color: "red" }}
                        type="text"
                        touched={lastAdded.denominationTouched}
                        onChange={handleInputChange}
                        name="denomination"
                        parentStyle={{ width: "78%" }}
                        error={lastAdded.denominationError}
                        helperText={lastAdded.denominationHelperText}
                        label="Denomination" /> &nbsp;</td>

                    <td>Count</td>
                    <td  style={{paddingLeft: "0.6%",paddingTop:"0.7%"}}><Input
                        value={lastAdded.count}
                        //  style={{ width: "78%" }}
                        fullWidth={true}
                        placeholder="Count"
                        className={classes.root}
                            sx={{ width: "80%", padding: "6px !important", color: "red" }}
                        type="text"
                        touched={lastAdded.countTouched}
                        onChange={handleInputChange}
                        name="count"
                        error={lastAdded.countError}
                        helperText={lastAdded.countHelperText}
                        parentStyle={{ width: "78%" }}

                        label="Count" /> &nbsp;</td>
                </tr>

                <tr>
                    <td>Version</td>
                    <td  style={{paddingLeft: "0.6%",paddingTop:"1%"}}><Input
                        value={lastAdded.version}
                        //  style={{ width: "78%" }}
                        fullWidth={true}
                        className={classes.root}
                            sx={{ width: "80%", padding: "6px !important", color: "red" }}
                        placeholder="Version"
                        type="text"
                        touched={lastAdded.versionTouched}
                        onChange={handleInputChange}
                        name="version"
                        error={lastAdded.versionError}
                        helperText={lastAdded.versionHelperText}
                        parentStyle={{ width: "78%" }}
                        label="Version" /> &nbsp;</td>

                    <td>Validity Period</td>
                    <td  style={{paddingLeft: "0.6%",paddingTop:"1%"}}><Input
                        value={lastAdded.validityPeriod}
                        //  style={{ width: "78%" }}
                        fullWidth={true}
                        placeholder="Validity Period"
                        type="text"
                        className={classes.root}
                            sx={{ width: "80%", padding: "6px !important", color: "red" }}
                        touched={lastAdded.validityPeriodTouched}
                        onChange={handleInputChange}
                        name="validityPeriod"
                        error={lastAdded.validityPeriodError}
                        helperText={lastAdded.validityPeriodHelperText}
                        parentStyle={{ width: "78%" }}
                        label="ValidityPeriod" /> &nbsp;</td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                    <td colSpan={4}>
                        <ButtonComponent
                            variant='contained' color='primary' label="Create"
                            onClick={handleCreate}
                        >

                        </ButtonComponent>&nbsp;
                        <ButtonComponent
                            variant='contained' color='primary' label="Reset"
                            onClick={handleReset}
                        >
                        </ButtonComponent>
                    </td>
                </tr>
            </table>
            <SnackBarComponent dispatch={dispatch} snackbarClosed={snackbarClosedReset} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


        </>
    )
}