import React, { useMemo,useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Button, IconButton, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/input';
import { InfoModalValues, listFetch, popupOpenAndClosed, voucherRequestStatusLastAdded, EditModalValues, editPopupOpenAndClosed, snackbarClosed,emptyTable } from '../../../redux/slice/CardVoucherRequestSlice';
import { Edit } from '@mui/icons-material';
import { Info } from '@material-ui/icons';
import CardRequestStatusInfoModal from './CardVoucherRequestStatusInfoModal';
import CardRequestStatusEditModal from './CardVoucherRequestStatusEditModal';
import SnackBarComponent from '../../../components/snackbar';
import { checkDateValid, daysDiffernece } from '../../../utlis/CurrentDateGenerator';

const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            flexDirection: "row",
            justifyContent: "spaceBetween",
            alignItems: "center",
            rowGap: "inherit",
        }
    }
});
const CardVoucherRequestStatus = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.CardVoucherRequestStatusReducer);
    const tableData = selectValuesRe?.lists === undefined ? [] : selectValuesRe?.lists;
    const lastAdded = selectValuesRe?.lastAdded;
    //console.log("lastAdded", lastAdded)
    const infoOpen = selectValuesRe.VoucherStatusInfoModal.open
    const editOpen = selectValuesRe.VoucherStatusEditModal.open
    const infoModalValues = selectValuesRe.infoModalValues;
    const editModalValues = selectValuesRe.editModalValues;
    const loader = selectValuesRe.loader;
    const snackbarValues = selectValuesRe.sucessMessage;
    //console.log("snackbarValues",snackbarValues)


    const handleQuery = () => {
        
        var findDateBetween = daysDiffernece(lastAdded.startDate, lastAdded.endDate)
        var checkDate = checkDateValid(lastAdded.startDate, lastAdded.endDate)
        if (checkDate) {
            if (findDateBetween < 30) {
                dispatch(listFetch({ startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
            }
            else {
                dispatch(snackbarClosed({ snackbarOpen: true, message: "Date between can't exceed more than 30 days", mode: "error" }))
            }
        }
        else {
            dispatch(snackbarClosed({ snackbarOpen: true, message: "Start date should not be greater than end date", mode: "error" }))
        }
    }

    useEffect(() => {
        dispatch(emptyTable())
        return () => {
            dispatch(emptyTable())
        }
    }, [])
    const columns = useMemo(
        () => [
            {
                accessorKey: 'voucher_request_id',
                header: '#',
                size: "5"
            },
            // {
            //     accessorKey: 'provider',
            //     header: 'Provider',
            //     size : "10"

            // },
            {
                accessorKey: 'voucherRequestsOperators.operator_code',
                header: 'Operator',
                size: "10"
            },
            {
                accessorKey: 'denomination',
                header: 'Den.',
                size: "10"
            },
            {
                accessorKey: 'count',
                header: 'Req.Count',
                size: "5"

            },
            {
                accessorKey: 'batch_number',
                header: 'Batch No',
                size: "10"

            },
            {
                accessorKey: 'icount',
                header: 'Gen.Count',
                size: "5"

            },
            {
                accessorKey: 'request_status',
                header: 'Status',
                size: "10"
            },
        ],
        [],
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        // //console.log("Name",name)
        // //console.log("value",value)
        dispatch(voucherRequestStatusLastAdded({ [name]: value, [name + "Touched"]: true }))
    }
    const ResetCall = () => {
        dispatch(emptyTable())
    }
    const openInfoModal = (id) => {
        const tableDataFilter = tableData.find(element => element.voucher_request_id === id)
        //console.log("tableDataFilter",tableDataFilter)
        dispatch(popupOpenAndClosed(true))
        dispatch(InfoModalValues(tableDataFilter))
    }

    const openEditModal = (id) => {
        const tableDataFilter = tableData.find(element => element.voucher_request_id === id)
        //console.log("tableDataFilter",tableDataFilter)
        dispatch(editPopupOpenAndClosed(true))
        dispatch(EditModalValues({ id: tableDataFilter.voucher_request_id, count: tableDataFilter.count, server: tableDataFilter.server, denomination: tableDataFilter.denomination, startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
    }

    const snackbarRemove = () => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    return (
        < div className={classes.root}>


            <MaterialReactTable
                enableEditing
                renderRowActions={({ row, table }) => (

                    <>
                        <IconButton onClick={() => { openInfoModal(row?.original?.voucher_request_id) }}>
                            <Info />
                        </IconButton>
                        <IconButton>
                            <Edit onClick={() => { openEditModal(row?.original?.voucher_request_id) }} />
                        </IconButton>

                    </>

                )}
                positionActionsColumn="last"
                renderTopToolbarCustomActions={() => (
                    <>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            columnGap: "2%"
                        }}>
                            <div>
                                <label>Start Date</label>
                                <Input value={lastAdded.startDate} type="date" touched={lastAdded.startDateTouched} placeholder="Date"
                                    onChange={handleChange}
                                    name="startDate" label=""    style={{padding:"9.5px"}}  fullWidth={true} />
                                <span style={{ color: "red" }}>{lastAdded.startDateError}</span></div>
                            <div>
                                <label>End Date</label>
                                <Input value={lastAdded.endDate}    style={{padding:"9.5px"}}   type="date" touched={lastAdded.endDateTouched} placeholder="Date"
                                    onChange={handleChange}
                                    name="endDate" label="" fullWidth={true} />
                                <span style={{ color: "red" }}>{lastAdded.endDateError}</span></div>

                            <Button variant='contained' style={{alignSelf: "end"}} color='primary' onClick={handleQuery}>Query</Button>
                            <Button style={{ alignSelf: "end" }} variant='contained' color='primary' onClick={ResetCall}>Reset</Button>
                        </div>  <div>


                        </div>  </>
                )}
                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                  }}
                  initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                  }}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}

                enableColumnOrdering
            />

            <div>

            </div>
            <SnackBarComponent dispatch={dispatch} snackbarClosed={snackbarRemove} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

            <CardRequestStatusInfoModal infoModalValues={infoModalValues} open={infoOpen} />
            <CardRequestStatusEditModal editModalValues={editModalValues} open={editOpen} lastAdded={lastAdded} />


        </div>

    );

};
export default CardVoucherRequestStatus;
