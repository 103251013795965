import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    cardVoucherAllStocks: [],
    loader: false,
    cardVoucherReservedStocks: [],
    cardVoucherStagedStocks: []

}

export const cardVoucherAllStocksFetch = createAsyncThunk(
    "CardVoucherAllStcoks/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/cardVoucher/all');
            return response.data
        }
        catch (error) {
            console.log(error)
        }
    }
)

export const cardVoucherReservedStocksFetch = createAsyncThunk(
    "CardVoucherReservedStcoks/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/cardVoucher/reserved');
            return response.data
        }
        catch (error) {
            console.log(error)
        }
    }
)


export const cardVoucherStagedStocksFetch = createAsyncThunk(
    "CardVoucherStagedStcoks/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/cardVoucher/staged');
            return response.data
        }
        catch (error) {
            console.log(error)
        }
    }
)

const CardVoucherAllStockReducer = createSlice({
    name: "CardVoucherAllStcoksReducer",
    initialState,

    reducers: {
        handleCardVoucherAllStockResetDispatch(state, action) {
            return {
                ...state,
                cardVoucherAllStocks: []
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(cardVoucherAllStocksFetch.fulfilled, (state, action) => {
            return {
                ...state,
                cardVoucherAllStocks: action.payload,
                loader: false,
            }
        })
            .addCase(cardVoucherAllStocksFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(cardVoucherAllStocksFetch.rejected, (state, action) => {
                //console.log(action)
            })
        ///
        builder.addCase(cardVoucherReservedStocksFetch.fulfilled, (state, action) => {
            return {
                ...state,
                cardVoucherReservedStocks: action.payload,
                loader: false,
            }
        })
            .addCase(cardVoucherReservedStocksFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(cardVoucherReservedStocksFetch.rejected, (state, action) => {
                //console.log(action)
            })
        ////
        builder.addCase(cardVoucherStagedStocksFetch.fulfilled, (state, action) => {
            return {
                ...state,
                cardVoucherStagedStocks: action.payload,
                loader: false,
            }
        })
            .addCase(cardVoucherStagedStocksFetch.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(cardVoucherStagedStocksFetch.rejected, (state, action) => {
                //console.log(action)
            })
    }
})

export const { handleCardVoucherAllStockResetDispatch } = CardVoucherAllStockReducer.actions
export const CardVoucherAllStocksReducer = CardVoucherAllStockReducer.reducer