import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    branchs: [],
    branchsCopy: [],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    branchAddAndEditModal: {
        open: false,
        mode: "add"
    },
    branchFormValidation: {
        branchName: "",
    }
    , lastAdded: { active: "Y", branchName: "", saveButtonDisabled: true, },
}

export const branchFetch = createAsyncThunk(
    "branchReducer/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/branch');
            console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const branchAdd = createAsyncThunk(
    "branchReducer/add", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/branch/save', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const branchDelete = createAsyncThunk(
    "branchReducer/delete", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/branch/delete', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const branchUpdate = createAsyncThunk(
    "branchReducer/update", async (arg, thunkAPI) => {
        try {
            //console.log("arg", arg)

            const response = await axios.post('/branch/update', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("branchFetch", branchFetch)

const BranchSlice = createSlice({
    name: "branchReducer",
    initialState,

    reducers: {
        branchList(state, action) {
            // state.branchs = action.payload
            return {
                ...state,
                branchs: action.payload
            }
        },
        branchLastAdded(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },


            }
        },

        snackbarClosed(state, action) {
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        handleReset(state, action) {
            return {
                ...state,
                lastAdded: { active: "Y", branchName: "", branchType: "", },
                sucessMessage: {
                    snackbarOpen: false,
                    message: "",
                    mode: "success"
                },
                branchAddAndEditModal: {
                    open: false,
                    mode: "add"
                },
            }
        },    
    popupOpenAndClosed(state, action) {
        //console.log("action",action)
        return {
            ...state,
            branchAddAndEditModal: { ...state.branchAddAndEditModal, ...action.payload }
        }
    },
    },
    extraReducers: (builder) => {
        builder.addCase(branchFetch.fulfilled, (state, action) => {
            return {
                ...state,
                branchs: action.payload
            }
        })
            .addCase(branchFetch.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchFetch.rejected, (state, action) => {
                //console.log(action)
            })
            .addCase(branchAdd.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchAdd.rejected, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    }
                }

            })
            .addCase(branchAdd.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    },
                    branchAddAndEditModal: {
                        open: false
                    },
                    branchs: action.payload.branchs.length === 0 ? state.branchs : action.payload.branchs

                }
            })
            .addCase(branchUpdate.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchUpdate.rejected, (state, action) => {
                //console.log("action==>", action)
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        mode: action.payload.status,
                    }
                }
            })
            .addCase(branchUpdate.fulfilled, (state, action) => {

                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        status: action.payload.status,
                    },
                    branchAddAndEditModal: {
                        open: false
                    },
                    branchs: action.payload.branchs.length === 0 ? state.branchs : action.payload.branchs
                }

            })
            .addCase(branchDelete.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(branchDelete.fulfilled, (state, action) => {
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true,
                        status: action.payload.status,
                    },
                    branchs: action.payload.branchs.length === 0 ? state.branchs : action.payload.branchs

                }

            })
    }

})

export const { branchList, branchLastAdded, snackbarClosed, popupOpenAndClosed, branchLastAddedValidate,handleReset } = BranchSlice.actions;
export const BranchReducer = BranchSlice.reducer