import React, { useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, IconButton, Tooltip, } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import ServiceProviderAddandEdit from './ServiceProviderAddandEdit';
import { useDispatch, useSelector } from 'react-redux';
import { serviceProviderDelete, serviceProviderFetch, serviceProviderLastAdded, popupOpenAndClosed, snackbarClosed } from '../../redux/slice/ServiceproviderSlice';
import SnackBar from '../../components/snackbar';



const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row-reverse"
    }
  }
});
const ServiceProvider = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.ServiceProviderReducer);
  const loader = selectValuesRe?.loader;
  //console.log("selectValuesRe",selectValuesRe)
  const lastAdded = selectValuesRe.lastAdded;
  //console.log("lastAdded",lastAdded)
  const snackbarValues = selectValuesRe.sucessMessage;
  //console.log("snackbarValues",snackbarValues)
  const serviceProviderAddAndEditModal = selectValuesRe.serviceProviderAddAndEditModal;
  // const [createModalOpen, setCreateModalOpen] = useState(false);
  ///const [tableData, setTableData] = useState(selectValuesRe.serviceProviderReducer.serviceProviders);
  // const [validationErrors, setValidationErrors] = useState({});
  // const [tableData, setTableData] = useState(selectValuesRe.serviceProviderReducer.serviceProviders);
  const tableData = selectValuesRe.serviceProviders;


  useEffect(() => {
    dispatch(serviceProviderFetch())


  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        //  size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',

        // size: 140,

      },


      {
        accessorKey: 'active',
        header: 'Active',
        Cell: ({ cell }) => (
          cell.getValue() === "Y" ? "Yes" : "No"
        )
      },

    ],
    [],
  );

  const handleDelete = (row) => {
    dispatch(serviceProviderDelete({ serviceProviderId: row.original.id }))

  }
  const openMod = () => {
    // alert("s")
    dispatch(popupOpenAndClosed({ open: true, mode: "add", active: "Y" }));
    var resetObj = {};
    for (const x in lastAdded) {
      //console.log("x",x)
      if (x !== "active") {
        Object.assign(resetObj, { [x]: "" })
      }
    }
    //console.log("resetObj",resetObj)
    dispatch(serviceProviderLastAdded(resetObj, { active: "Y" }))
  }
  const handleEditPopUp = (row) => {
    //console.log("row",row)
    const findElement = tableData.find(element => element.id === row.original.id)
    //console.log("tableData",findElement)
    dispatch(popupOpenAndClosed({ open: true, mode: "edit" }))
    dispatch(serviceProviderLastAdded({ serviceProviderName: findElement.name, serviceProviderId: findElement.id, active: findElement.active }))
  }
  return (
    < div className={classes.root}>
      <MaterialReactTable
        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={selectValuesRe.serviceProviders === undefined ? [] : selectValuesRe.serviceProviders}
        state={{
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        initialState={{
          density: "compact",
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        muiTableBodyRowProps={{
          sx: {
            padding: "0px"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX"
          }
        }}
        positionActionsColumn="last"
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Edit">
              <IconButton onClick={() => handleEditPopUp(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton onClick={() => handleDelete(row)} color="error" >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Button
              color="primary"
              onClick={() => { openMod() }}
              variant="contained"
            >
              Create Service Provider
            </Button></>
        )}
      />

      <ServiceProviderAddandEdit lastAdded={lastAdded} popupMode={serviceProviderAddAndEditModal.mode} createModalOpen={serviceProviderAddAndEditModal.open} />


      <SnackBar  snackbarClosed={() => { dispatch(snackbarClosed({ snackbarOpen: false, message: "", mode: "" })) }} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


      <div>

      </div>


    </div>
  );
};



export default ServiceProvider;
