import React, { useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import SnackBar from '../../components/snackbar';
import { Box, IconButton, Tooltip } from '@mui/material';
import { OneKPlus } from '@mui/icons-material';
import { cardVoucherGenerationList, cardVoucherGenerationProcess } from '../../redux/slice/CardVoucherGenerationSlice';


const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row-reverse"
    }
  }
});
const CardVoucherGeneration = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.CardVoucherGenerationReducer);
  const snackbarValues = selectValuesRe.sucessMessage;
  const tableData = selectValuesRe.lists === undefined ? [] : selectValuesRe.lists;
  const loader = selectValuesRe.loader;



  useEffect(() => { dispatch(cardVoucherGenerationList()) }, [])




  const columns = useMemo(
    () => [
      {
        accessorKey: 'providers.sp_name',
        header: 'Provider',
        enableColumnOrdering: true,
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'operators.operator_name',
        header: 'Operator',
        size: 140,

      },
      {
        accessorKey: 'count',
        header: 'Count',
        size: 140,

      },

      {
        accessorKey: 'denomination',
        header: 'Den',
        size: 80,

      },
      {
        accessorKey: 'validity',
        header: 'Validity',
        size: 80,

      },
      {
        accessorKey: 'request_status',
        header: 'Status',
        size: 80,

      },
    ],
    [],
  );
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const cardVoucherGenerate = (id) => {
    dispatch(cardVoucherGenerationProcess({ id: id }))
  }


  return (
    < div className={classes.root}>
      <MaterialReactTable
        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}

        muiTableBodyRowProps={{
          sx: {
            padding: "0px"
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX"
          }
        }}
        state={{
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        initialState={{
          density: "compact",
          isLoading: loader,
          showProgressBars: loader,
          showSkeletons: loader
        }}
        positionActionsColumn="last"
        enableEditing
        enableColumnOrdering
        renderRowActions={({ row, table }) => (

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Voucher Generation">
              <IconButton onClick={() => { cardVoucherGenerate(row?.original?.voucher_request_id) }} >
                <OneKPlus />
              </IconButton>
            </Tooltip>


          </Box>
        )}


      />



      <SnackBar dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


      <div>

      </div>


    </div>
  );
};



export default CardVoucherGeneration;
