import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    lists: [],

    lastAdded: { type: "BN", typeTouched: false, startDate: new Date(), endDate: new Date(), startDateTouched: false, endDateTouched: false },
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    fileList: [],
    loader: false

}

export const getList = createAsyncThunk(
    "GenerationStatu/list", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/genStatus/list', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)
//console.log("availableStockFetch", getList)

const GenerationStatusSlice = createSlice({
    name: "GenerationStatuReducer",
    initialState,

    reducers: {
        generationStatusLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },

        snackBarClosedReset(state, action) {
            return {
                ...state,
                sucessMessage: {
                    snackbarOpen: false,
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getList.fulfilled, (state, action) => {
            return {
                ...state,
                lists: action.payload.list,
                sucessMessage: {
                    message: action.payload.message,
                    snackbarOpen: true,
                    mode: "success"
                },
                loader: false,
            }
        })
            .addCase(getList.pending, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: true,
                }
            })
            .addCase(getList.rejected, (state, action) => {
                //console.log(action)
                return {
                    ...state,
                    loader: false,
                    sucessMessage: {
                        message: "Error",
                        snackbarOpen: true,
                        mode: "success"
                    },
                }
            })






    }

})
export const { generationStatusLastAdded, handleVoucherAllStockResetDispatch, snackBarClosedReset } = GenerationStatusSlice.actions
export const GenerationStatuSliceReducer = GenerationStatusSlice.reducer