import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    serviceProviders: [],
    serviceProvidersCopy: [],
    loader :false,
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    serviceProviderAddAndEditModal : {
        open : false,
        mode:"add"
    },
    serviceProviderFormValidation:{
        serviceProviderName:"",
    }
    , lastAdded: { active: "Y", serviceProviderName:"", saveButtonDisabled:true,  },
}

export const serviceProviderFetch = createAsyncThunk(
    "serviceProviderReducer/fetch", async (_arg, _thunkAPI) => {
        try {
            const response = await axios.get('/serviceProviders');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const serviceProviderAdd = createAsyncThunk(
    "serviceProviderReducer/add", async (arg, _thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/serviceProviders/save/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const serviceProviderDelete = createAsyncThunk(
    "serviceProviderReducer/delete", async (arg, _thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/serviceProviders/delete/', arg);
            //console.log("responseadd", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
export const serviceProviderUpdate = createAsyncThunk(
    "serviceProviderReducer/update", async (arg, _thunkAPI) => {
        try {
            //console.log("arg", arg)
           
            const response = await axios.post('/serviceProviders/update/', arg);
            //console.log("responseupdate", response.data)
            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)
//console.log("serviceProviderFetch", serviceProviderFetch)

const ServiceProviderSlice = createSlice({
    name: "serviceProviderReducer",
    initialState,  
    reducers: {
        serviceProviderList(state, action) {
            // state.serviceProviders = action.payload
            return {
                ...state,
                serviceProviders: action.payload
            }
        },
        serviceProviderLastAdded(state, action) {
            //console.log("errpr ope",action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
                
                
            }
        },
        
        snackbarClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
        popupOpenAndClosed(state,action){
            //console.log("action",action)
            return {
                ...state,
                serviceProviderAddAndEditModal: { ...state.serviceProviderAddAndEditModal, ...action.payload  }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(serviceProviderFetch.fulfilled, (state, action) => {
            return {
                ...state,
                serviceProviders: action.payload,
                loader  :false,
            }
        })
            .addCase(serviceProviderFetch.pending, (state, _action) => {
                return {
                    ...state,                   
                    loader  :true,
                }
            })
            .addCase(serviceProviderFetch.rejected, (state, _action) => {
                return {
                    ...state,                   
                    loader  :false,
                }
            })
            .addCase(serviceProviderAdd.pending, (state, _action) => {
                return {
                    ...state,                   
                    loader  :true,
                }
            })
            .addCase(serviceProviderAdd.rejected, (state, action) => {
              
                    return {
                        ...state,
                        loader : false,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode:action.payload.status,
                        }
                    }
                
            })
            .addCase(serviceProviderAdd.fulfilled, (state, action) => {
          
                    return {
                        ...state,
                        loader:false,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode:action.payload.status,
                        },
                        serviceProviderAddAndEditModal : {
                            open : false
                        },
                        serviceProviders : action.payload.serviceProviders.length <= 0 ? state.serviceProviders :  action.payload.serviceProviders
                    }
             
            })
            .addCase(serviceProviderUpdate.pending, (state, _action) => {
                return {
                    ...state,                   
                    loader  :true,
                }
            })
            .addCase(serviceProviderUpdate.rejected, (state, action) => {              
                    return {
                        ...state,
                        loader:false,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode: action.payload.status
                        }
                    }
                
            })
            .addCase(serviceProviderUpdate.fulfilled, (state, action) => {
              
                    return {
                        ...state,
                        loader : false,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode : action.payload.status
                        },
                        serviceProviderAddAndEditModal : {
                            open : false
                        },
                        serviceProviders : action.payload.serviceProviders.length <= 0 ? state.serviceProviders :  action.payload.serviceProviders
                    
                }
            })
            .addCase(serviceProviderDelete.pending, (state, _action) => {
                return {
                    ...state,                   
                    loader  :true,
                }
            })
            .addCase(serviceProviderDelete.fulfilled, (state, action) => {
            
                    return {
                        ...state,
                        loader : false,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true,
                            mode : action.payload.status
                        },
                        serviceProviders : action.payload.serviceProviders.length <= 0 ? state.serviceProviders :  action.payload.serviceProviders
                       
                    }
                
            })
    }

})

export const { serviceProviderList, serviceProviderLastAdded,snackbarClosed, popupOpenAndClosed, serviceProviderLastAddedValidate} = ServiceProviderSlice.actions;
export const ServiceProviderReducer = ServiceProviderSlice.reducer