import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, LinearProgress } from '@mui/material';



export default function CircularStatic() { 

  return (
    <Box >
       <LinearProgress />
    </Box>
  );
}