import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Route, Routes } from 'react-router';
import Operator from './views/operator/Operator';
import { makeStyles } from '@material-ui/styles';
import ServiceProvider from './views/serviceprovider/ServiceProvider';
import SignIn from './views/signin/signin';
import ComponentBuild from './ComponentBuilld';
import CardVoucherAvailableStock from './views/CardVoucher/CardVoucherAvailableStock';
import EvdFlexiPdfReport from './views/Reports/EvdPdfFlexiReport';
import EvdVoucherPdfReport from './views/Reports/VoucherReport';
import RetailerWallet from './views/Reports/RetailerWallet';
import AvailableStock from './views/Voucher/AvailableStock';
import AllStock from './views/Voucher/AllStock';
import VoucherBlockUnblock from './views/Voucher/VoucherBlockUnblock';
import VoucherBulkBlockUnblock from './views/Voucher/VoucherBulkBlockUnblock';
import NewRequest from './views/Voucher/NewRequest';
import VoucherRequestStatus from './views/Voucher/RequestStatus/RequestStatus';
import StockChart from './views/Voucher/StockChart';
import StockChartAvailable from './views/Voucher/StockChartAvailable';
import CardVoucherAllStock from './views/CardVoucher/CardVoucherAllStock';
import CardVoucherAllStockChart from './views/CardVoucher/CardVoucherAllStockChart';
import CardVoucherAvailableStockChart from './views/CardVoucher/CardVoucherAvailableStockChart';
import CardVoucherReservedStockChart from './views/CardVoucher/CardVoucherReservedStockChart';
import CardVoucherStagedStockChart from './views/CardVoucher/CardVoucherStagedStockChart';
import CardVoucherNewRequest from './views/CardVoucher/CardVoucherNewRequest';
import CardVoucherRequestStatus from './views/CardVoucher/RequestStatus/CardVoucherRequestStatus';
import VoucherGeneration from './views/operation/VoucherGeneration';
import CardVoucherGeneration from './views/operation/CardVoucherGeneration';
import SerialEnquiry from './views/operation/SerialEnquiry';
import MtnSerialEnquiry from './views/SerialInquiry/MtnSerialEnquiry';
import GenerationStatus from './views/operation/GenerationStatus';
import BranchType from './views/CardInventory/BranchType';
import StockSummary from './views/CardInventory/StockSummary';
import StockSummaryBySerialNo from './views/CardInventory/StockBySerialNo';
import StockAvailability from './views/CardInventory/StockBySerialNo';
import Branch from './views/CardInventory/Branch';
import AgentWalletReport from './views/Reports/AgentWalletReport';
import AgentFlexiReport from './views/Reports/AgentFlexiReport';
import AgentPaymentStatementReport from './views/Reports/AgentPaymentStatementReport';
import RetailerPaymentStatementReport from './views/Reports/RetailerPaymentStatementReport';
import CustomerReport from './views/Reports/CustomerReport';
import AgentAdjustmentStatementReport from './views/Reports/AgentAdjustmentReport';
import RetailerAdjustmentStatementReport from './views/Reports/RetailerAdjustmentReport';
import PromoDistribution from './views/Promo/PromoDistribution';
import PromoRunStatus from './views/Promo/PromoRunStatus';
import PromoConfig from './views/Promo/PromoConfig';
import SystemUser from './views/Access/SystemUser';
import Profile from './views/Access/Profile/Profile';
import NewSystemUser from './views/Access/NewSystemUser';
import User from './views/Access/User';
import SerialSummary from './views/SerialConfig/SerialSummary';
import SerialConfig from './views/SerialConfig/SerialConfig';
import PromoList from './views/Promo/PromoList';
import PromoReedeem from './views/Promo/PromoReedem';
import InventorySplit from './views/operation/InventorySplit';
import OperatorWalletBalanceReport from './views/Reports/OperatorWalletBalanceReport';
import RetailerFlexiReport from './views/Reports/RetailerFlexiReport';
import AllocationHistory from './views/Reports/AllocationHistory';
import StockAllocation from './views/CardInventory/StockAllocation';
import PageNotFound from "./components/PageNotFound"
import SuperAdminSignin from './views/signin/SuperAdminRootSignin';
import SuperAdminRootSignin from './views/signin/SuperAdminRootSignin';
import SuperAdminSystemSignin from './views/signin/SuperAdminSystemSignin';
const PromoWinner = React.lazy(() => import("./views/Promo/PromoWinner"));

const drawerWidth = 240;
console.log(window.location.origin)

if (window.location.origin === "http://localhost:3000") {
  axios.defaults.baseURL = "http://localhost:3002/";
}
else {
  axios.defaults.baseURL = "http://184.168.30.70:3002/";
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    background: 'linear-gradient(45deg, #FE7659 30%, #ff8e53 90%)',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const useStyles = makeStyles({
  root: {
    "& .css-usg3v-MuiDrawer-docked .MuiDrawer-paper": {
      //   background: "linear-gradient(45deg, #FE7659 30%, #ff8e53 90%)",

    }
  }
});
export default function App() {
  const theme = useTheme();
  const classes = useStyles()
  const selectValuesRe = useSelector(state => state.CommonReducer);
  const authCode = selectValuesRe.users.auth;
  // React.useEffect(()=>{
  //     axios.get("http://localhost:3001/").then(response=>{
  //       //console.log("Repsonse===>",response.data)
  //     })
  // })
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // React.useEffect(()=>{
  //   setAutoFreeze(false);
  // },[])

  return (

    <Routes>
      <Route path='/' element={<SignIn />} />
      <Route path='/root/' element={<SuperAdminRootSignin />} />
      <Route path='/system/' element={<SuperAdminSystemSignin />} />
      <Route path='/admin/' element={<SuperAdminSignin />} />
      {authCode === 1 ?
        <>
          <Route path='serviceProvider' element={<ComponentBuild title="Service Provider" Component={<ServiceProvider />} />} />
          <Route path='operator' element={<ComponentBuild title="Operator" Component={<Operator />} />} />
          <Route path='cardVoucherAvailableStock' element={<ComponentBuild title="Card Voucher Available Stock" Component={<CardVoucherAvailableStock />} />} />
          <Route path='flexiReport' element={<ComponentBuild title="Flexi Report" Component={<EvdFlexiPdfReport />} />} />
          <Route path='voucherReport' element={<ComponentBuild title="Voucher Report" Component={<EvdVoucherPdfReport />} />} />
          <Route path='retailerWallet' element={<ComponentBuild title="Retailer Wallet" Component={<RetailerWallet />} />} />
          <Route path='availableStock' element={<ComponentBuild title="Available Stock" Component={<AvailableStock />} />} />
          <Route path='allStock' element={<ComponentBuild title=" All Stock" Component={<AllStock />} />} />
          <Route path='voucherBlock' element={<ComponentBuild title="Voucher Block" Component={<VoucherBlockUnblock />} />} />
          <Route path='bulkvoucherBlock' element={<ComponentBuild title="Voucher Bulk Block/Unblock" Component={<VoucherBulkBlockUnblock />} />} />
          <Route path='newRequest' element={<ComponentBuild title="New Request" Component={<NewRequest />} />} />
          <Route path='requestStatus' element={<ComponentBuild title="Request Status" Component={<VoucherRequestStatus />} />} />
          <Route path='stockChart' element={<ComponentBuild title="Stock Chart" Component={<StockChart />} />} />
          <Route path='stockChartAvailable' element={<ComponentBuild title="Available Stock Chart" Component={<StockChartAvailable />} />} />
          <Route path='allStockCard' element={<ComponentBuild title="Card Voucher All Stock" Component={<CardVoucherAllStock />} />} />
          <Route path='stockChartCard' element={<ComponentBuild title="Card Voucher Stock Chart" Component={<CardVoucherAllStockChart />} />} />
          <Route path='stockChartAvailableCard' element={<ComponentBuild title="Card Voucher Available Stock Chart" Component={<CardVoucherAvailableStockChart />} />} />
          <Route path='stockChartReservedCard' element={<ComponentBuild title="Card Voucher Reserved Stock Chart" Component={<CardVoucherReservedStockChart />} />} />
          <Route path='stockChartStagedCard' element={<ComponentBuild title="Card Voucher Staged Stock Chart" Component={<CardVoucherStagedStockChart />} />} />
          <Route path='newRequestCard' element={<ComponentBuild title="Card Voucher New Request" Component={<CardVoucherNewRequest />} />} />
          <Route path='requestStatusCard' element={<ComponentBuild title="Card Voucher Request Status" Component={<CardVoucherRequestStatus />} />} />
          <Route path='voucherGen' element={<ComponentBuild title="Voucher Generation" Component={<VoucherGeneration />} />} />
          <Route path='cardVoucherGen' element={<ComponentBuild title="Card Voucher Generation" Component={<CardVoucherGeneration />} />} />
          <Route path='mtnSerialInquiry' element={<ComponentBuild title="MTN Serial No Enquiry" Component={<MtnSerialEnquiry />} />} />
          <Route path='serialInquiry' element={<ComponentBuild title=" Serial No Enquiry" Component={<SerialEnquiry />} />} />
          <Route path='genState' element={<ComponentBuild title="Voucher Generation Status" Component={<GenerationStatus />} />} />
          <Route path='branchType' element={<ComponentBuild title="Branch Type" Component={<BranchType />} />} />
          <Route path='branch' element={<ComponentBuild title="Branch" Component={<Branch />} />} />
          <Route path='stockSummary' element={<ComponentBuild title="Stock Summary" Component={<StockSummary />} />} />
          <Route path='stockBySerialNo' element={<ComponentBuild title="Stock By Serial No" Component={<StockAvailability />} />} />
          <Route path='agentWalletReport' element={<ComponentBuild title="Agent Wallet Report" Component={<AgentWalletReport />} />} />
          <Route path='agentFlexiReport' element={<ComponentBuild title="Agent Flexi Report" Component={<AgentFlexiReport />} />} />
          <Route path='agentPaymentReport' element={<ComponentBuild title="Agent Payment Report" Component={<AgentPaymentStatementReport />} />} />
          <Route path='retailerPaymentReport' element={<ComponentBuild Component={<RetailerPaymentStatementReport />} />} />
          <Route path='customerReport' element={<ComponentBuild title="Customer Report" Component={<CustomerReport />} />} />
          <Route path='agentAdjustmentReport' element={<ComponentBuild title="Agent Adjustment Report" Component={<AgentAdjustmentStatementReport />} />} />
          <Route path='retailerAdjustmentReport' element={<ComponentBuild title="Retailer Adjustment Report" Component={<RetailerAdjustmentStatementReport />} />} />
          <Route path='promoDistribution' element={<ComponentBuild title="Promo Distribution" Component={<PromoDistribution />} />} />
          <Route path='promoRunStatus' element={<ComponentBuild title="Promo Run Status" Component={<PromoRunStatus />} />} />
          <Route path='promoConfig' element={<ComponentBuild title="Promo Config" Component={<PromoConfig />} />} />
          <Route path='promoWinner' element={<ComponentBuild title="Promo Winner" Component={<React.Suspense fallback={<div>Loading....</div>}><PromoWinner /></React.Suspense>} />} />
          <Route path='/systemUser' element={<ComponentBuild title="System User" Component={<SystemUser />} />} />
          <Route path='/user' element={<ComponentBuild title="User" Component={<User />} />} />
          <Route path='/profile' element={<ComponentBuild title="Profile" Component={<Profile />} />} />
          <Route path='/snSummary' element={<ComponentBuild title="Serial No Summary" Component={<SerialSummary />} />} />
          <Route path='/slPinConfig' element={<ComponentBuild title="Serial Pin Config" Component={<SerialConfig />} />} />
          <Route path='/promoList' element={<ComponentBuild title="Promo List" Component={<PromoList />} />} />
          <Route path='/promoReedem' element={<ComponentBuild title="Promo Reedem" Component={<PromoReedeem />} />} />
          <Route path='/inventorySplit' element={<ComponentBuild title="Inventory Split" Component={<InventorySplit />} />} />
          <Route path='/oprWalletBalance' element={<ComponentBuild title="Operator Wallet Balance" Component={<OperatorWalletBalanceReport />} />} />
          <Route path='/retailerFlexiReport' element={<ComponentBuild title="Retailer Flexi Report" Component={<RetailerFlexiReport />} />} />
          <Route path='/allocationHistory' element={<ComponentBuild Component={<AllocationHistory />} />} />
          <Route path='/stockAllocation' element={<ComponentBuild title="Allocation History" Component={<StockAllocation />} />} />

          {/* <Route path='/newSystemUser' element={<ComponentBuild Component={<NewSystemUser />} />} /> */}
          <Route path='/' element={<SignIn />} /></>
        : null}

{authCode === 11 ?
        <>
        
          <Route path='cardVoucherAvailableStock' element={<ComponentBuild title="Card Voucher Available Stock" Component={<CardVoucherAvailableStock />} />} />
          <Route path='flexiReport' element={<ComponentBuild title="Flexi Report" Component={<EvdFlexiPdfReport />} />} />
          <Route path='voucherReport' element={<ComponentBuild title="Voucher Report" Component={<EvdVoucherPdfReport />} />} />
          <Route path='retailerWallet' element={<ComponentBuild title="Retailer Wallet" Component={<RetailerWallet />} />} />
          <Route path='availableStock' element={<ComponentBuild title="Available Stock" Component={<AvailableStock />} />} />
          <Route path='allStock' element={<ComponentBuild title=" All Stock" Component={<AllStock />} />} />
          <Route path='voucherBlock' element={<ComponentBuild title="Voucher Block" Component={<VoucherBlockUnblock />} />} />
          <Route path='bulkvoucherBlock' element={<ComponentBuild title="Voucher Bulk Block/Unblock" Component={<VoucherBulkBlockUnblock />} />} />
          <Route path='newRequest' element={<ComponentBuild title="New Request" Component={<NewRequest />} />} />
          <Route path='requestStatus' element={<ComponentBuild title="Request Status" Component={<VoucherRequestStatus />} />} />
          <Route path='stockChart' element={<ComponentBuild title="Stock Chart" Component={<StockChart />} />} />
          <Route path='stockChartAvailable' element={<ComponentBuild title="Available Stock Chart" Component={<StockChartAvailable />} />} />
          <Route path='allStockCard' element={<ComponentBuild title="Card Voucher All Stock" Component={<CardVoucherAllStock />} />} />
          <Route path='stockChartCard' element={<ComponentBuild title="Card Voucher Stock Chart" Component={<CardVoucherAllStockChart />} />} />
          <Route path='stockChartAvailableCard' element={<ComponentBuild title="Card Voucher Available Stock Chart" Component={<CardVoucherAvailableStockChart />} />} />
          <Route path='stockChartReservedCard' element={<ComponentBuild title="Card Voucher Reserved Stock Chart" Component={<CardVoucherReservedStockChart />} />} />
          <Route path='stockChartStagedCard' element={<ComponentBuild title="Card Voucher Staged Stock Chart" Component={<CardVoucherStagedStockChart />} />} />
          <Route path='newRequestCard' element={<ComponentBuild title="Card Voucher New Request" Component={<CardVoucherNewRequest />} />} />
          <Route path='requestStatusCard' element={<ComponentBuild title="Card Voucher Request Status" Component={<CardVoucherRequestStatus />} />} />
        
          <Route path='mtnSerialInquiry' element={<ComponentBuild title="MTN Serial No Enquiry" Component={<MtnSerialEnquiry />} />} />
          <Route path='serialInquiry' element={<ComponentBuild title=" Serial No Enquiry" Component={<SerialEnquiry />} />} />
          <Route path='genState' element={<ComponentBuild title="Voucher Generation Status" Component={<GenerationStatus />} />} />
        
          <Route path='stockSummary' element={<ComponentBuild title="Stock Summary" Component={<StockSummary />} />} />
          <Route path='stockBySerialNo' element={<ComponentBuild title="Stock By Serial No" Component={<StockAvailability />} />} />
          <Route path='agentWalletReport' element={<ComponentBuild title="Agent Wallet Report" Component={<AgentWalletReport />} />} />
          <Route path='agentFlexiReport' element={<ComponentBuild title="Agent Flexi Report" Component={<AgentFlexiReport />} />} />
          <Route path='agentPaymentReport' element={<ComponentBuild title="Agent Payment Report" Component={<AgentPaymentStatementReport />} />} />
          <Route path='retailerPaymentReport' element={<ComponentBuild Component={<RetailerPaymentStatementReport />} />} />
          <Route path='customerReport' element={<ComponentBuild title="Customer Report" Component={<CustomerReport />} />} />
          <Route path='agentAdjustmentReport' element={<ComponentBuild title="Agent Adjustment Report" Component={<AgentAdjustmentStatementReport />} />} />
          <Route path='retailerAdjustmentReport' element={<ComponentBuild title="Retailer Adjustment Report" Component={<RetailerAdjustmentStatementReport />} />} />
        
          <Route path='/user' element={<ComponentBuild title="User" Component={<User />} />} />
        
          <Route path='/snSummary' element={<ComponentBuild title="Serial No Summary" Component={<SerialSummary />} />} />
        
        
          <Route path='/oprWalletBalance' element={<ComponentBuild title="Operator Wallet Balance" Component={<OperatorWalletBalanceReport />} />} />
          <Route path='/retailerFlexiReport' element={<ComponentBuild title="Retailer Flexi Report" Component={<RetailerFlexiReport />} />} />
          <Route path='/allocationHistory' element={<ComponentBuild Component={<AllocationHistory />} />} />
          

          {/* <Route path='/newSystemUser' element={<ComponentBuild Component={<NewSystemUser />} />} /> */}
          <Route path='/' element={<SignIn />} /></>
        : null}



      {authCode === 22 ?
        <>
          <Route path='operator' element={<ComponentBuild title="Operator" Component={<Operator />} />} />
          <Route path='flexiReport' element={<ComponentBuild title="Flexi Report" Component={<EvdFlexiPdfReport />} />} />


        </>

        : null}


      {authCode === 10 ?
        <>

          <Route path='cardVoucherAvailableStock' element={<ComponentBuild title="Card Voucher Available Stock" Component={<CardVoucherAvailableStock />} />} />
          <Route path='flexiReport' element={<ComponentBuild title="Flexi Report" Component={<EvdFlexiPdfReport />} />} />
          <Route path='voucherReport' element={<ComponentBuild title="Voucher Report" Component={<EvdVoucherPdfReport />} />} />
          <Route path='retailerWallet' element={<ComponentBuild title="Retailer Wallet" Component={<RetailerWallet />} />} />
          <Route path='availableStock' element={<ComponentBuild title="Available Stock" Component={<AvailableStock />} />} />
          <Route path='allStock' element={<ComponentBuild title=" All Stock" Component={<AllStock />} />} />
          <Route path='voucherBlock' element={<ComponentBuild title="Voucher Block" Component={<VoucherBlockUnblock />} />} />
          <Route path='bulkvoucherBlock' element={<ComponentBuild title="Voucher Bulk Block/Unblock" Component={<VoucherBulkBlockUnblock />} />} />
          <Route path='newRequest' element={<ComponentBuild title="New Request" Component={<NewRequest />} />} />
          <Route path='requestStatus' element={<ComponentBuild title="Request Status" Component={<VoucherRequestStatus />} />} />
          <Route path='stockChart' element={<ComponentBuild title="Stock Chart" Component={<StockChart />} />} />
          <Route path='stockChartAvailable' element={<ComponentBuild title="Available Stock Chart" Component={<StockChartAvailable />} />} />
          <Route path='allStockCard' element={<ComponentBuild title="Card Voucher All Stock" Component={<CardVoucherAllStock />} />} />
          <Route path='stockChartCard' element={<ComponentBuild title="Card Voucher Stock Chart" Component={<CardVoucherAllStockChart />} />} />
          <Route path='stockChartAvailableCard' element={<ComponentBuild title="Card Voucher Available Stock Chart" Component={<CardVoucherAvailableStockChart />} />} />
          <Route path='stockChartReservedCard' element={<ComponentBuild title="Card Voucher Reserved Stock Chart" Component={<CardVoucherReservedStockChart />} />} />
          <Route path='stockChartStagedCard' element={<ComponentBuild title="Card Voucher Staged Stock Chart" Component={<CardVoucherStagedStockChart />} />} />
          <Route path='newRequestCard' element={<ComponentBuild title="Card Voucher New Request" Component={<CardVoucherNewRequest />} />} />
          <Route path='requestStatusCard' element={<ComponentBuild title="Card Voucher Request Status" Component={<CardVoucherRequestStatus />} />} />


          <Route path='mtnSerialInquiry' element={<ComponentBuild title="MTN Serial No Enquiry" Component={<MtnSerialEnquiry />} />} />
          <Route path='serialInquiry' element={<ComponentBuild title=" Serial No Enquiry" Component={<SerialEnquiry />} />} />
          <Route path='genState' element={<ComponentBuild title="Voucher Generation Status" Component={<GenerationStatus />} />} />
          <Route path='branchType' element={<ComponentBuild title="Branch Type" Component={<BranchType />} />} />
          <Route path='branch' element={<ComponentBuild title="Branch" Component={<Branch />} />} />
          <Route path='stockSummary' element={<ComponentBuild title="Stock Summary" Component={<StockSummary />} />} />
          <Route path='stockBySerialNo' element={<ComponentBuild title="Stock By Serial No" Component={<StockAvailability />} />} />
          <Route path='agentWalletReport' element={<ComponentBuild title="Agent Wallet Report" Component={<AgentWalletReport />} />} />
          <Route path='agentFlexiReport' element={<ComponentBuild title="Agent Flexi Report" Component={<AgentFlexiReport />} />} />
          <Route path='agentPaymentReport' element={<ComponentBuild title="Agent Payment Report" Component={<AgentPaymentStatementReport />} />} />
          <Route path='retailerPaymentReport' element={<ComponentBuild Component={<RetailerPaymentStatementReport />} />} />
          <Route path='customerReport' element={<ComponentBuild title="Customer Report" Component={<CustomerReport />} />} />
          <Route path='agentAdjustmentReport' element={<ComponentBuild title="Agent Adjustment Report" Component={<AgentAdjustmentStatementReport />} />} />
          <Route path='retailerAdjustmentReport' element={<ComponentBuild title="Retailer Adjustment Report" Component={<RetailerAdjustmentStatementReport />} />} />
          <Route path='promoDistribution' element={<ComponentBuild title="Promo Distribution" Component={<PromoDistribution />} />} />
          <Route path='promoRunStatus' element={<ComponentBuild title="Promo Run Status" Component={<PromoRunStatus />} />} />
          <Route path='promoConfig' element={<ComponentBuild title="Promo Config" Component={<PromoConfig />} />} />
          <Route path='promoWinner' element={<ComponentBuild title="Promo Winner" Component={<React.Suspense fallback={<div>Loading....</div>}><PromoWinner /></React.Suspense>} />} />
          <Route path='/systemUser' element={<ComponentBuild title="System User" Component={<SystemUser />} />} />
          <Route path='/user' element={<ComponentBuild title="User" Component={<User />} />} />
          <Route path='/profile' element={<ComponentBuild title="Profile" Component={<Profile />} />} />
          <Route path='/snSummary' element={<ComponentBuild title="Serial No Summary" Component={<SerialSummary />} />} />
          <Route path='/slPinConfig' element={<ComponentBuild title="Serial Pin Config" Component={<SerialConfig />} />} />
          <Route path='/promoList' element={<ComponentBuild title="Promo List" Component={<PromoList />} />} />
          <Route path='/promoReedem' element={<ComponentBuild title="Promo Reedem" Component={<PromoReedeem />} />} />

          <Route path='/oprWalletBalance' element={<ComponentBuild title="Operator Wallet Balance" Component={<OperatorWalletBalanceReport />} />} />
          <Route path='/retailerFlexiReport' element={<ComponentBuild title="Retailer Flexi Report" Component={<RetailerFlexiReport />} />} />
          <Route path='/allocationHistory' element={<ComponentBuild Component={<AllocationHistory />} />} />
          <Route path='/stockAllocation' element={<ComponentBuild title="Allocation History" Component={<StockAllocation />} />} />

          {/* <Route path='/newSystemUser' element={<ComponentBuild Component={<NewSystemUser />} />} /> */}
          <Route path='/' element={<SignIn />} /></>
        : null}
      {authCode === 90 ? <>


        <Route path='cardVoucherAvailableStock' element={<ComponentBuild title="Card Voucher Available Stock" Component={<CardVoucherAvailableStock />} />} />
        <Route path='flexiReport' element={<ComponentBuild title="Flexi Report" Component={<EvdFlexiPdfReport />} />} />
        <Route path='voucherReport' element={<ComponentBuild title="Voucher Report" Component={<EvdVoucherPdfReport />} />} />
        <Route path='retailerWallet' element={<ComponentBuild title="Retailer Wallet" Component={<RetailerWallet />} />} />
        <Route path='availableStock' element={<ComponentBuild title="Available Stock" Component={<AvailableStock />} />} />
        <Route path='allStock' element={<ComponentBuild title=" All Stock" Component={<AllStock />} />} />

        <Route path='requestStatus' element={<ComponentBuild title="Request Status" Component={<VoucherRequestStatus />} />} />
        <Route path='stockChart' element={<ComponentBuild title="Stock Chart" Component={<StockChart />} />} />
        <Route path='stockChartAvailable' element={<ComponentBuild title="Available Stock Chart" Component={<StockChartAvailable />} />} />
        <Route path='allStockCard' element={<ComponentBuild title="Card Voucher All Stock" Component={<CardVoucherAllStock />} />} />
        <Route path='stockChartCard' element={<ComponentBuild title="Card Voucher Stock Chart" Component={<CardVoucherAllStockChart />} />} />
        <Route path='stockChartAvailableCard' element={<ComponentBuild title="Card Voucher Available Stock Chart" Component={<CardVoucherAvailableStockChart />} />} />
        <Route path='stockChartReservedCard' element={<ComponentBuild title="Card Voucher Reserved Stock Chart" Component={<CardVoucherReservedStockChart />} />} />
        <Route path='stockChartStagedCard' element={<ComponentBuild title="Card Voucher Staged Stock Chart" Component={<CardVoucherStagedStockChart />} />} />

        <Route path='requestStatusCard' element={<ComponentBuild title="Card Voucher Request Status" Component={<CardVoucherRequestStatus />} />} />


        <Route path='mtnSerialInquiry' element={<ComponentBuild title="MTN Serial No Enquiry" Component={<MtnSerialEnquiry />} />} />
        <Route path='serialInquiry' element={<ComponentBuild title=" Serial No Enquiry" Component={<SerialEnquiry />} />} />
        <Route path='genState' element={<ComponentBuild title="Voucher Generation Status" Component={<GenerationStatus />} />} />

        <Route path='stockSummary' element={<ComponentBuild title="Stock Summary" Component={<StockSummary />} />} />
        <Route path='stockBySerialNo' element={<ComponentBuild title="Stock By Serial No" Component={<StockAvailability />} />} />
        <Route path='agentWalletReport' element={<ComponentBuild title="Agent Wallet Report" Component={<AgentWalletReport />} />} />
        <Route path='agentFlexiReport' element={<ComponentBuild title="Agent Flexi Report" Component={<AgentFlexiReport />} />} />
        <Route path='agentPaymentReport' element={<ComponentBuild title="Agent Payment Report" Component={<AgentPaymentStatementReport />} />} />
        <Route path='retailerPaymentReport' element={<ComponentBuild Component={<RetailerPaymentStatementReport />} />} />
        <Route path='customerReport' element={<ComponentBuild title="Customer Report" Component={<CustomerReport />} />} />
        <Route path='agentAdjustmentReport' element={<ComponentBuild title="Agent Adjustment Report" Component={<AgentAdjustmentStatementReport />} />} />
        <Route path='retailerAdjustmentReport' element={<ComponentBuild title="Retailer Adjustment Report" Component={<RetailerAdjustmentStatementReport />} />} />

        <Route path='/snSummary' element={<ComponentBuild title="Serial No Summary" Component={<SerialSummary />} />} />

        <Route path='/promoReedem' element={<ComponentBuild title="Promo Reedem" Component={<PromoReedeem />} />} />

        <Route path='/oprWalletBalance' element={<ComponentBuild title="Operator Wallet Balance" Component={<OperatorWalletBalanceReport />} />} />
        <Route path='/retailerFlexiReport' element={<ComponentBuild title="Retailer Flexi Report" Component={<RetailerFlexiReport />} />} />
        <Route path='/allocationHistory' element={<ComponentBuild Component={<AllocationHistory />} />} />


        {/* <Route path='/newSystemUser' element={<ComponentBuild Component={<NewSystemUser />} />} /> */}
        <Route path='/' element={<SignIn />} /></> : null}

        {authCode === 60 ?  <Route path='operator' element={<ComponentBuild title="Operator" Component={<Operator />} />} /> : null }
        {authCode === 21 ?<> <Route path='serviceProvider' element={<ComponentBuild title="Service Provider" Component={<ServiceProvider />} />} />
        
          <Route path='cardVoucherAvailableStock' element={<ComponentBuild title="Card Voucher Available Stock" Component={<CardVoucherAvailableStock />} />} />
          <Route path='flexiReport' element={<ComponentBuild title="Flexi Report" Component={<EvdFlexiPdfReport />} />} />
          <Route path='voucherReport' element={<ComponentBuild title="Voucher Report" Component={<EvdVoucherPdfReport />} />} />
          <Route path='retailerWallet' element={<ComponentBuild title="Retailer Wallet" Component={<RetailerWallet />} />} />
          <Route path='availableStock' element={<ComponentBuild title="Available Stock" Component={<AvailableStock />} />} />
          <Route path='allStock' element={<ComponentBuild title=" All Stock" Component={<AllStock />} />} />
          <Route path='voucherBlock' element={<ComponentBuild title="Voucher Block" Component={<VoucherBlockUnblock />} />} />
          <Route path='bulkvoucherBlock' element={<ComponentBuild title="Voucher Bulk Block/Unblock" Component={<VoucherBulkBlockUnblock />} />} />
          <Route path='newRequest' element={<ComponentBuild title="New Request" Component={<NewRequest />} />} />
          <Route path='requestStatus' element={<ComponentBuild title="Request Status" Component={<VoucherRequestStatus />} />} />
          <Route path='stockChart' element={<ComponentBuild title="Stock Chart" Component={<StockChart />} />} />
          <Route path='stockChartAvailable' element={<ComponentBuild title="Available Stock Chart" Component={<StockChartAvailable />} />} />
          <Route path='allStockCard' element={<ComponentBuild title="Card Voucher All Stock" Component={<CardVoucherAllStock />} />} />
          <Route path='stockChartCard' element={<ComponentBuild title="Card Voucher Stock Chart" Component={<CardVoucherAllStockChart />} />} />
          <Route path='stockChartAvailableCard' element={<ComponentBuild title="Card Voucher Available Stock Chart" Component={<CardVoucherAvailableStockChart />} />} />
          <Route path='stockChartReservedCard' element={<ComponentBuild title="Card Voucher Reserved Stock Chart" Component={<CardVoucherReservedStockChart />} />} />
          <Route path='stockChartStagedCard' element={<ComponentBuild title="Card Voucher Staged Stock Chart" Component={<CardVoucherStagedStockChart />} />} />
        
          <Route path='requestStatusCard' element={<ComponentBuild title="Card Voucher Request Status" Component={<CardVoucherRequestStatus />} />} />
        
        
          <Route path='mtnSerialInquiry' element={<ComponentBuild title="MTN Serial No Enquiry" Component={<MtnSerialEnquiry />} />} />
          <Route path='serialInquiry' element={<ComponentBuild title=" Serial No Enquiry" Component={<SerialEnquiry />} />} />
          <Route path='genState' element={<ComponentBuild title="Voucher Generation Status" Component={<GenerationStatus />} />} />
        
          <Route path='stockSummary' element={<ComponentBuild title="Stock Summary" Component={<StockSummary />} />} />
          <Route path='stockBySerialNo' element={<ComponentBuild title="Stock By Serial No" Component={<StockAvailability />} />} />
          <Route path='agentWalletReport' element={<ComponentBuild title="Agent Wallet Report" Component={<AgentWalletReport />} />} />
          <Route path='agentFlexiReport' element={<ComponentBuild title="Agent Flexi Report" Component={<AgentFlexiReport />} />} />
          <Route path='agentPaymentReport' element={<ComponentBuild title="Agent Payment Report" Component={<AgentPaymentStatementReport />} />} />
          <Route path='retailerPaymentReport' element={<ComponentBuild Component={<RetailerPaymentStatementReport />} />} />
          <Route path='customerReport' element={<ComponentBuild title="Customer Report" Component={<CustomerReport />} />} />
          <Route path='agentAdjustmentReport' element={<ComponentBuild title="Agent Adjustment Report" Component={<AgentAdjustmentStatementReport />} />} />
          <Route path='retailerAdjustmentReport' element={<ComponentBuild title="Retailer Adjustment Report" Component={<RetailerAdjustmentStatementReport />} />} />
          <Route path='/snSummary' element={<ComponentBuild title="Serial No Summary" Component={<SerialSummary />} />} />
          <Route path='/oprWalletBalance' element={<ComponentBuild title="Operator Wallet Balance" Component={<OperatorWalletBalanceReport />} />} />
          <Route path='/retailerFlexiReport' element={<ComponentBuild title="Retailer Flexi Report" Component={<RetailerFlexiReport />} />} />
          <Route path='/allocationHistory' element={<ComponentBuild Component={<AllocationHistory />} />} />
       </> :null}
       {authCode === 20 ?<>
       
          <Route path='flexiReport' element={<ComponentBuild title="Flexi Report" Component={<EvdFlexiPdfReport />} />} />
          <Route path='voucherReport' element={<ComponentBuild title="Voucher Report" Component={<EvdVoucherPdfReport />} />} />
          <Route path='retailerWallet' element={<ComponentBuild title="Retailer Wallet" Component={<RetailerWallet />} />} />
          <Route path='availableStock' element={<ComponentBuild title="Available Stock" Component={<AvailableStock />} />} />
          <Route path='allStock' element={<ComponentBuild title=" All Stock" Component={<AllStock />} />} />
        
         
        
          <Route path='agentFlexiReport' element={<ComponentBuild title="Agent Flexi Report" Component={<AgentFlexiReport />} />} />
          <Route path='agentPaymentReport' element={<ComponentBuild title="Agent Payment Report" Component={<AgentPaymentStatementReport />} />} />
          <Route path='retailerPaymentReport' element={<ComponentBuild Component={<RetailerPaymentStatementReport />} />} />
          <Route path='customerReport' element={<ComponentBuild title="Customer Report" Component={<CustomerReport />} />} />
          <Route path='agentAdjustmentReport' element={<ComponentBuild title="Agent Adjustment Report" Component={<AgentAdjustmentStatementReport />} />} />
          <Route path='retailerAdjustmentReport' element={<ComponentBuild title="Retailer Adjustment Report" Component={<RetailerAdjustmentStatementReport />} />} />
        
          
          <Route path='/user' element={<ComponentBuild title="User" Component={<User />} />} />
          </> : null } 
      <Route path="*" element={<PageNotFound />} />
    </Routes>

  );
}
{/* <Route path='/newSystemUser' element={<ComponentBuild Component={<NewSystemUser />} />} /> */ }