import { Grid, IconButton, Menu, MenuItem } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ButtonComponent from "../components/button"
import { Download } from "@mui/icons-material"
import { THEME_COLOR } from "../constfiles/theme"




export default function DownloadButton(props) {
    const { classes,  download, handlePrint,handlePdf } = props;


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton disabled={download} className={classes.root} color='primary' sx={{ borderRadius: "2px", backgroundColor: THEME_COLOR, color: "white", padding: "1.3%" }} onClick={handleClick} ><Download /></IconButton>

            <Menu
                id="download-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',

                }}
            >
                <MenuItem onClick={handlePdf}>Download Pdf</MenuItem>
                {/* <MenuItem onClick={handleClose}>Download Excel</MenuItem> */}
                <MenuItem onClick={handlePrint}>Print</MenuItem>
            </Menu> </> 
        
        
    )
}