import { TextField } from "@mui/material";
import React from "react";

export default function Input(props) {
    const {name, fullWidth, label,sx, maxLength, parentStyle,onChange,error,className,helperText,accept,touched,value, type,placeholder,style} = props;
    // //console.log("helperText",helperText)
    // //console.log("error",error)
    console.log("error",style)
    return (
        <TextField
        type = {!type ? "text" : type }
        sx={parentStyle || sx}
        placeholder = {!placeholder ? "" : placeholder }
        id={name}
        name ={name}
        fullWidth ={fullWidth}
        className={className}
        label={label}
        required="true"
        value={value}
        helperText={helperText}
        error={error}
        onChange={onChange}
        accept={accept}
        inputProps={{
        maxlength : maxLength,
              style:style,
              
        }}
        //defaultValue="Hello World"
    />
    )
}