import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
    loader: false,
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    lists: [],
    agentWallets: [],
    lastAdded: {
        agentCode: "ALL",
        agentCodeDisplay: "ALL",
        queryButtonDisabled: true,
        agentCodeError: false,
        agentCodeHelperText: "",
        startDate: ""
    },
}

export const agentWalletFetch = createAsyncThunk(
    "AgentWalletFetch/fetch", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('agentWalletReport/query', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

const AgentWalletSlice = createSlice({
    name: "AgentWalletReducer",
    initialState,

    reducers: {
        agentWalletLastAdded(state, action) {
            console.log("action.payload===>", action.payload)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        AgentWalletLastAddedReset(state, action) {
            return {
                ...state,
                lastAdded: {
                    agentCode: "ALL",
                    queryButtonDisabled: true,
                    agentCodeError: false,
                    agentCodeHelperText: "",
                    agentCodeDisplay: "ALL",
                },
                agentWallets: []
            }
        },
        handleLoader(state, action) {
            console.log(action.payload)
            return {
                ...state,
                loader: action.payload,
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(agentWalletFetch.fulfilled, (state, action) => {
            return {
                ...state,
                agentWallets: action.payload,
                loader : false
            }
        })
            .addCase(agentWalletFetch.pending, (state, action) => {
                return {
                    ...state,                   
                    loader : true
                }
            })
            .addCase(agentWalletFetch.rejected, (state, action) => {
                return {
                    ...state,                   
                    loader : false
                }
            })
    }


})

export const { agentWalletLastAdded, AgentWalletLastAddedReset, handleLoader } = AgentWalletSlice.actions;
export const AgentWalletReducer = AgentWalletSlice.reducer