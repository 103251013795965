import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    lists: [],
    check:false,
    lastAdded: { type: "BF", typeTouched: false, startSerialNumber: "", endSerialNumber: "", startSerialNumberTouched: false, endSerialNumberTouched: false },
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    },
    fileList: []

}

export const getBulkVoucherBlockUnblockList = createAsyncThunk(
    "voucherBulkBlockUnBlock/list", async (arg, thunkAPI) => {
        const requestPrepare = {};
        if (arg.type === "BS") {
            Object.assign(requestPrepare, { type: arg.type, ssno: arg.startSerialNumber, esno: arg.endSerialNumber })
        }

        try {
            const response = await axios.post('/voucherBulkBlock/list', requestPrepare);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


export const uploadFile = createAsyncThunk(
    "voucherBulkBlockUnBlock/upload", async (arg, thunkAPI) => {
        //console.log("arg", arg)
        try {
            const response = await axios.post('/voucherBulkBlock/upload', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const VoucherBlockAction = createAsyncThunk(
    "voucherBlock/block", async (arg, thunkAPI) => {
        //console.log("arg", arg)
        //console.log("thunkAPI", thunkAPI)
        try {
            const response = await axios.post('/voucherBlock/block', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const VoucherUnblockAction = createAsyncThunk(
    "voucherBlock/unblock", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/voucherBlock/unblock', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const VoucherBulkBlockAction = createAsyncThunk(
    "VoucherBulkBlockAction/bulkblock", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/voucherBulkBlock/bulkblock', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const VoucherBulkUnBlockAction = createAsyncThunk(
    "VoucherBulkBlockAction/bulkunblock", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/voucherBulkBlock/bulkunblock', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const handleVoucherSerialBlockUnblock = createAsyncThunk(
    "VoucherBulkBlockAction/serialbulkblock", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/voucherBulkBlock/serialbulkblockunblock', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)

export const VoucherBulkUnblockGetFileList = createAsyncThunk(
    "VoucherBulkUnblockGetFileList/VoucherBulkUnblockGetFileList", async (arg, thunkAPI) => {
        try {
            const response = await axios.get('/voucherBulkBlock/filelist');
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


export const VoucherBulkUnblockGetFileContent = createAsyncThunk(
    "voucherBulkBlock/checkfile", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/voucherBulkBlock/checkfile', arg);
            //console.log("response", response.data)
            return response.data
        }
        catch (error) {
            //console.log(error)
        }
    }
)


//console.log("availableStockFetch", getBulkVoucherBlockUnblockList)

const VoucherBulkBlockUnblockSlice = createSlice({
    name: "VoucherBulkBlockUnblockReducer",
    initialState,

    reducers: {
        voucherBulkBlockUnBlockLastAdded(state, action) {
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        checkClicked  (state,action) {
            return {
                ...state,
                check: action.payload,
            }
        },
        snackBarClosedReset(state, action) {
            return {
                ...state,
                sucessMessage: {
                    snackbarOpen: false,
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBulkVoucherBlockUnblockList.fulfilled, (state, action) => {
            return {
                ...state,
                lists: action.payload
            }
        })
            .addCase(getBulkVoucherBlockUnblockList.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(getBulkVoucherBlockUnblockList.rejected, (state, action) => {
                //console.log(action)
            })
        builder.addCase(VoucherBlockAction.fulfilled, (state, action) => {
            //console.log("action fulfilled fulfilled==>", action)
            if (action.payload.toString() === "500") {
                return {
                    ...state,
                    sucessMessage: {
                        message: " action.payload.message",
                        snackbarOpen: true,
                        mode: "warning"
                    }
                }
            }
            else {
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true
                    },
                    lists: action.payload.voucherInfo
                }
            }
        })
            .addCase(VoucherBlockAction.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(VoucherBlockAction.rejected, (state, action) => {
                //console.log(VoucherBlockAction)
            })

        builder.addCase(VoucherUnblockAction.fulfilled, (state, action) => {
            //console.log("action fulfilled fulfilled==>", action)
            if (action.payload.toString() === "500") {
                return {
                    ...state,
                    sucessMessage: {
                        message: " action.payload.message",
                        snackbarOpen: true,
                        mode: "warning"
                    }
                }
            }
            else {
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true
                    },
                    lists: action.payload.voucherInfo
                }
            }
        })
            .addCase(VoucherUnblockAction.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(VoucherUnblockAction.rejected, (state, action) => {
                //console.log(VoucherUnblockAction)
            })


        builder.addCase(uploadFile.fulfilled, (state, action) => {
            //console.log("action fulfilled fulfilled==>", action)
            if (action.payload.toString() === "500") {
                return {
                    ...state,
                    sucessMessage: {
                        message: " action.payload.message",
                        snackbarOpen: true,
                        mode: "warning"
                    }
                }
            }
            else {
                return {
                    ...state,
                    sucessMessage: {
                        message: action.payload.message,
                        snackbarOpen: true
                    },
                    lists: action.payload.voucherInfo
                }
            }
        })
            .addCase(uploadFile.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(uploadFile.rejected, (state, action) => {
                //console.log(uploadFile)
            })



        builder.addCase(VoucherBulkUnblockGetFileList.fulfilled, (state, action) => {
            //console.log("action fulfilled fulfilled==>", action)
            if (action.payload.toString() === "500") {
                return {
                    ...state,
                    fileList: action.payload
                }
            }
            else {
                return {
                    ...state,
                    fileList: action.payload
                }
            }
        })
            .addCase(VoucherBulkUnblockGetFileList.pending, (state, action) => {
                //console.log(action)
            })
            .addCase(VoucherBulkUnblockGetFileList.rejected, (state, action) => {
                //console.log(VoucherUnblockAction)
            })

            builder.addCase(VoucherBulkUnblockGetFileContent.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action.payload)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        lists: action.payload, 
                        fileList : []
                    }
                }
                else {
                    //console.log("Redux check file", action.payload)
                    return {
                        ...state,
                        lists: action.payload,
                        fileList : []
                    }
                }
            })
                .addCase(VoucherBulkUnblockGetFileContent.pending, (state, action) => {
                    //console.log(action)
                })
                .addCase(VoucherBulkUnblockGetFileContent.rejected, (state, action) => {
                    //console.log(VoucherBulkUnblockGetFileContent)
                })
                builder.addCase(VoucherBulkBlockAction.fulfilled, (state, action) => {
                    //console.log("action fulfilled fulfilled==>", action)
                    if (action.payload.toString() === "500") {
                        return {
                            ...state,
                            lists: action.payload
                        }
                    }
                    else {
                        return {
                            ...state,
                            lists: action.payload.voucherInfo,
                            sucessMessage: {
                                message: action.payload.message,
                                snackbarOpen: true
                            },
                        }
                    }
                })
                    .addCase(VoucherBulkBlockAction.pending, (state, action) => {
                        //console.log(action)
                    })
                    .addCase(VoucherBulkBlockAction.rejected, (state, action) => {
                        //console.log(VoucherBulkUnblockGetFileContent)
                    })
                    builder.addCase(VoucherBulkUnBlockAction.fulfilled, (state, action) => {
                        //console.log("action fulfilled fulfilled==>", action)
                        if (action.payload.toString() === "500") {
                            return {
                                ...state,
                                lists: action.payload
                            }
                        }
                        else {
                            return {
                                ...state,
                                lists: action.payload.voucherInfo,
                                sucessMessage: {
                                    message: action.payload.message,
                                    snackbarOpen: true
                                },
                            }
                        }
                    })
                        .addCase(VoucherBulkUnBlockAction.pending, (state, action) => {
                            //console.log(action)
                        })
                        .addCase(VoucherBulkUnBlockAction.rejected, (state, action) => {
                            //console.log(VoucherBulkUnblockGetFileContent)
                        })


                        builder.addCase(handleVoucherSerialBlockUnblock.fulfilled, (state, action) => {
                            //console.log("action fulfilled fulfilled==>", action)
                            if (action.payload.toString() === "500") {
                                return {
                                    ...state,
                                    lists: action.payload
                                }
                            }
                            else {
                                return {
                                    ...state,
                                    lists: action.payload.voucherInfo,
                                    sucessMessage: {
                                        message: action.payload.message,
                                        snackbarOpen: true
                                    },
                                }
                            }
                        })
                            .addCase(handleVoucherSerialBlockUnblock.pending, (state, action) => {
                                //console.log(action)
                            })
                            .addCase(handleVoucherSerialBlockUnblock.rejected, (state, action) => {
                                //console.log(VoucherBulkUnblockGetFileContent)
                            })
                    
    }

})
export const {checkClicked, voucherBulkBlockUnBlockLastAdded, handleVoucherAllStockResetDispatch, snackBarClosedReset } = VoucherBulkBlockUnblockSlice.actions
export const VoucherBulkBlockUnblockSliceReducer = VoucherBulkBlockUnblockSlice.reducer